import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class LogEmpresa extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            qtd_total: 0,
            auth: this.core.getAuth()            
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    generateRangeDate(){
        let range = [];

        let now = moment().date(1);
        range.push(moment(now));

        for(let i = 0; i < 5; i++){
            let mes = now.month() -1;
            now.month(mes);

            let date = moment(now);
            range.push(date);
        }

        range = range.map((data, index) => {
            return <option key={index + 1} value={data.format("YYYY-MM-DD")}>
                {data.format("MMMM")}
            </option>
        });

        //adiciona index 0 frase de seleção
        range.splice(0,0, <option key={0} checked value="">Selecione um mês</option>)
        return range;
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
            empresa_pesquisa : event.target.empresa_pesquisa.value
        }

        data.pesquisa_parcial =  'nao';
    
        this.props.action.loadingStatus();

        console.log("Fetch Plano");
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-logs", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            console.log("Planos=>response.content:", response.content)
        self.setState({
            relatorio: response.content, 
            qtd_total: response.content.length
        });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });    
    }

    getTableShow(){
        console.log("getTableShow=>");
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{String(data.id_log).padStart(5,"0")}</td>
                <td>{data.origem}</td>
                <td>{data.nome}</td>
                <td>{data.cpf}</td>
                <td>{data.email}</td>
                <td>{data.data_log}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={6}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa.</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Log Empresas</h2>
                <p>Consulte o log de acessos dos Empregadores</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe o Empregador para Pesquisa.
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Razao Social/Nome ou CNPJ/CPF" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary">pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panel-body container-fluid">
                            <table className="table table-striped table-doc">
                                <thead>
                                    <tr>
                                        <thead>
                                            <td>Total de Logs</td>
                                        </thead>
                                        <tbody>
                                            <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                        </tbody>
                                    </tr>


                                    <tr>
                                        <td>Id</td>
                                        <td>Origem</td>
                                        <td>Usuario</td>
                                        <td>CPF</td>
                                        <td>E-mail</td>
                                        <td>Data</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableContent}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(LogEmpresa))
