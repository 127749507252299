            import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';

//images
import Logo from '../../../images/logo.png';

class HomeLogin extends React.Component{
    constructor(props) {
        super(props);
        this.props.action.hideManager();
        this.handleLogin = this.handleLogin.bind(this);
    }

   componentWillMount(){
        $('body').addClass('page-login layout-full page-dark');
   }

   componentWillUnmount(){
        $('body').removeClass('page-login layout-full page-dark');
   }

    handleLogin(event){
        event.preventDefault();
        this.props.action.getUser(event.target.email.value, event.target.password.value)
        window.sessionStorage.removeItem('lista_envios')
    }

    getContent(){
         return  <div className="page vertical-align text-center">
            <div className="page-content vertical-align-middle">
                <div className="brand">
                    <img className="brand-img" src={Logo} alt="Benefício Social Familar" />
                </div>
                <p>Entre com usuário e senha para acessar o sistema.</p>
                <form onSubmit={this.handleLogin}>
                    <div className="form-group form-material floating" data-plugin="formMaterial">
                        <input type="email" className="form-control empty" id="inputEmail" name="email" required="" placeholder="Email" />
                    </div>
                    <div className="form-group form-material floating" data-plugin="formMaterial">
                        <input type="password" className="form-control empty" id="inputPassword" name="password" required="" placeholder="Senha" />
                    </div>
                    <div className="form-group clearfix">
                        {/*<div className="checkbox-custom checkbox-inline checkbox-primary float-left">
                            <input type="checkbox" id="inputCheckbox" name="remember" />
                            <label htmlFor="inputCheckbox">Lembrar de mim</label>
                        </div>
                        <a className="float-right" href="forgot-password.html">Esqueci a Senha?</a>*/}
                    </div>
                    <button type="submit" className="btn btn-primary btn-block">ENTRAR</button>
                </form>
                <footer className="page-copyright page-copyright-inverse">
                    <p>BENEFÍCIO SOCIAL FAMILIAR© 2017.<br/>  <small>TODOS OS DIREITOS RESERVADOS</small>.</p>
                </footer>
            </div>
        </div>;
    }

    render(){
       if(this.props.user !== false){
            window.location.href = '/manager/dashboard';
            return <div></div>;
       }else{
            return this.getContent();
       }
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions
        }, dispatch)
    }
}

export default withRouter(connect(mapStateProps, mapDispatchProps)(HomeLogin));
