import Core from '../class/Core';


export default (state = false, payload) => {
    let core = new Core();
    switch(payload.type){
        case 'USER_LOGIN':
            state = payload.data
            let data = payload.data.expires_in * 1000;
            data = new Date(data);
            core.setStorage('manager_log', payload.data, data);
            return state;
        case 'USER_LOGOUT':
            //bug não deletava o cookie, esta deletando direto agora
            // window.document.cookie = 'manager_log=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'

            core.removeStorage('manager_log')
            state = false;
            return state;
        case 'USER_IS_AUTH':
            state = core.getStorage('manager_log');
            if(state){
                return state;
            }else{
                return false;
            }
        default:
            return state;
    }
}
