export default (state = [], payload) => {
    switch(payload.type){
        case 'INSERT_LISTA_ENVIO_EMAILS':
            state = payload.data.splice(0)
            return state
            break
        case 'CLEAR_LISTA_ENVIO_EMAILS':
            window.sessionStorage.removeItem('lista_envios')
            state = []
            return state
            break
        default:
           
            return state;
    }
}
