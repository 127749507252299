import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class EmpresaTrabalhadorUpload extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            mensagem_processamento: null,
            qtd_total: 0,
            auth: this.core.getAuth(),
            processado: "invisible"  
        }
        this.getTableShow       = this.getTableShow.bind(this);
        this.handlerSubmit      = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
        console.log(this.state.auth);
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;
        let form = new FormData();

        let data = {
        }

        data.plano = event.target.plano.value;
        data.empresa_pesquisa = event.target.empresa_pesquisa.value;
        data.nome_arquivo = event.target.arquivo.files[0];

        form.append("empresa_pesquisa", data.empresa_pesquisa);
        form.append("plano", data.plano);
        form.append("nome_arquivo", data.nome_arquivo);

        this.props.action.loadingStatus();

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/empresa-trabalhador-upload", {
            method: 'POST',
            body: form
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.setState({
                relatorio: response.content[0].lista_trabalhadores,
                qtd_total: response.content[0].lista_trabalhadores.length,
                mensagem_processamento: response.content[0].mensagem_processamento,
                processado: "visible" 
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    getTableShow(){

        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.nome}</td>
                <td>{data.funcao}</td>
                <td>{data.nascimento}</td>
                <td>{data.admissao}</td>
                <td>{data.cpf}</td>
                <td>{data.sexo}</td>
                <td>{data.mensagem1}</td>
                <td>{data.mensagem2}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.state.qtd_total > 0){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={8}>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Trabalhador - Empresa - Upload</h2>
                <h3>Cadastre os Trabalhadores da Sua Empresa</h3>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                        Informe o Plano e o CNPJ da Empresa
                        </h3>
                        <div  className="seleciona-trabalhador" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Todas as Empresas" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input className="form-control" type="file" name="arquivo" placeholder="Nome" required onChange={(event)=> { 
                                            this.setState({
                                                processado: 'invisible'
                                            });
                                            return false
                                        }}/>
                                    </div>

                                    <div className="col-sm-1">
                                        <button type="Submit" ref="formulario" className="btn btn-primary">processar</button>
                                    </div>

                                    {/*}
                                    <div>
                                        <button type="submit" className={`btn btn-info ${this.state.processado}`} onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal"> <i className="fa fa-download"></i> Gerar em Excel</button>
                                    </div>*/}

                                </div>
                            </form>
                        </div>
                    </div>
                        
                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                    <td>Trabalhadores</td>
                                    <td></td>
                                    <td>Observação</td>
                                </thead>
                                <tbody>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                    <td></td>
                                    <td>{this.state.mensagem_processamento}</td>
                                </tbody>
                            </tr>
                        </table>

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>#nome</td>
                                    <td>#funcao</td>
                                    <td>#nascimento</td>
                                    <td>#admissao</td>
                                    <td>#cpf</td>
                                    <td>#sexo</td>
                                    <td>Planilha</td>
                                    <td>Validações</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(EmpresaTrabalhadorUpload))
