import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import moment from 'moment';
import InputMask from 'react-input-mask';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class AgendaVencimento extends React.Component{
    constructor(props){
        super(props);

        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;

        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            trabs: [],
            qtd_total: 0,
            vlr_total: 0,
            qtd_trabalhadores: 0,
            empresa: null,
            nnumero: null,
            contaColuna:0

        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
        this.handlerSubmit();
    }

    handlerSubmit(event){
        //event.preventDefault();
        let self = this;

        //let data = {
        //}

        //data.mesano_vecto = event.target.mesano_vecto.value;

        //this.state.paramters = "?";
        //this.state.paramters += '&tipo=pas';
        //this.state.paramters += '&mes=' + data.mesano_vecto;
        

        if (this.state.excel != 'sim') {
            this.props.action.loadingStatus();

            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/agenda-vencimento", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'GET',
                //body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                console.log('Entra no sucesso')

                self.setState({
                    relatorio: response.content,
                    qtd_total: response.content.length,
                    contaColuna:11
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });   
        } else {
            console.log("Chamando Excel!", this.state.paramters);
            if (this.consisteParametros()) {
                console.log("Chamando Excel OK");
                let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-empresa-impressoes-pas"+this.state.paramters;
                window.location.href = urlExcel+'&token='+self.core.getToken();     
            }
        }
    }

    


    showLista(){
        console.log("showLista1");
        this.state.excel = 'nao'
    }

    showExcel(){
        console.log("showExcel1");
        this.state.excel = 'sim'
    }

    consisteParametros(){
        console.log("consisteParametros");
        //nao tenenhuma regra para essa geracao de excel
        return true;
    }


    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            let botao1 = null;
            let botao2 = null;
            let contaColuna = 0;
            //console.log(data);
                if (data.cobranca === 'BSFOnline') {
                    botao1 = 
                        <td>
                            <button className="btn btn-info" onClick={event => this.showTrabs(data.id_empr_blto_impr, data.razao_social, data.nosso_numero)} data-toggle="modal" data-target="#trabModal">Trabalhadores</button>
                        </td>
                    botao2 = 
                        <td>
                            <button className="btn btn-info" onClick={event => this.showBoleto(data.token_impr, data.id_empr_blto_impr)} data-toggle="modal" data-target="#boletoModal">Boleto</button>
                        </td>
                } else {
                    botao1 = 
                        <td>
                            ----------x----------
                        </td>
                    botao2 = 
                        <td>
                            ----x----
                        </td>
                }
                let row = [];
                

        if(data.ativo){
            return <tr key={index} >
                <td><strong>{data.id}</strong></td>
                <td><strong>{data.inicio}</strong></td>
                <td><strong>{data.final}</strong></td>
                <td><strong>{data.data}</strong></td>
                
            </tr>
        } else {
            return <tr key={index} >
                <td>{data.id}</td>
                <td>{data.inicio}</td>
                <td>{data.final}</td>
                <td>{data.data}</td>
                
            </tr>
        }
        }
        );
        
    }

    render(){
        let tableContent;
        let tableTrabalhadores;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.trabs.length){
            tableTrabalhadores = this.state.trabs;
        }
        else {
            tableTrabalhadores = <tr>
                        <td colSpan={5}>
                            <b> Não foram encontrados vencimentos cadastrados.</b>
                        </td>
                        </tr>
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={15}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Agenda de Vencimentos</h2>
                
               

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Mudança de vencimento boleto para vencimentos em dias não úteis
                        </h3>
                       
                    </div>

                    <div className="panel-body container-fluid">
                        {/* formulario envia para handlerSubmitPlanoTrabalhadores*/}
                       
                      

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Data Vencto De</td>
                                    <td>Data Vencto Até</td>
                                    <td>Nova Data de Vencto</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(AgendaVencimento))
