import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';
import Alert from 'react-s-alert';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';
import { isNullOrUndefined } from 'util';

class PagamentosDuplicados extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            trabs: [],
            qtd_total: 0,
            vlr_total: 0,
            qtd_arquivos: 0,
            paramters: null,
            critica: 'nao',            
            excel: 'nao'            
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        data.date_ini = event.target.date_ini.value;
        data.date_fim = event.target.date_fim.value;

        var hoje = new Date();
        if (data.date_ini === "") {
            data.date_ini = '01/06/2017';;
        }
        if (data.date_fim === "") {
            data.date_fim = hoje.toLocaleDateString();
        }

        this.state.paramters = "?";
        this.state.paramters += 'date_ini=' + data.date_ini;
        this.state.paramters += '&date_fim=' + data.date_fim;
        this.state.paramters += '&token=' + this.core.getToken();
        console.log("Parametros.:", this.state.paramters);

        this.critica = 'ok';

        if (this.state.excel != 'sim' && (this.critica === 'ok')) {
            this.props.action.loadingStatus();

            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-pagamentos-duplicados", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                let vlr_pago = 0
                let qtd_pago = 0
                response.content.map ((val, index) => {
                    vlr_pago += parseFloat(val.vlr_pago)
                    qtd_pago += 1
                })
                self.setState({
                    relatorio: response.content, 
                    qtd_arquivos: response.content.length,
                    qtd_total: qtd_pago,
                    vlr_total: vlr_pago
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });
        }
        if (this.state.excel === 'sim' && (this.critica === 'ok')) {
            console.log("Chamando Excel:", data.date_ini, "!", data.date_fim, "!");
            if (this.consisteParametros(data.date_ini, data.date_fim)) {
                console.log("Chamando Excel OK");
                let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-pagamentos-duplicados"+this.state.paramters;
                window.location.href = urlExcel        
            }
        }

    }

    showLista(){
        console.log("showLista1");
        this.state.excel = 'nao'
    }

    showExcel(){
        console.log("showExcel1");
        this.state.excel = 'sim'
    }

    consisteParametros(prm_date_ini, prm_date_fim){
        console.log("consisteParametros");
        if (((prm_date_ini === '') || isNullOrUndefined(prm_date_ini))) {
            Alert.error("Data Inicial deve ser informada.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 3000
            });

            this.state.excel = 'nao'
            return false;    
        }
        if (((prm_date_fim === '') || isNullOrUndefined(prm_date_fim))) {
            Alert.error("Data Final deve ser informada.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 3000
            });

            this.state.excel = 'nao'
            return false;    
        }
        return true;
    }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.razao_social}</td>
                <td>{data.cnpj}</td>
                <td>{data.placa}</td>
                <td>{data.id_empr_plno}</td>
                <td>{data.dt_vecto}</td>
                <td>{data.dt_pagto}</td>
                <td style={{textAlign:"right"}}>{data.vlr_pago.toLocaleString('de-DE')}</td>
                <td>{data.id_empr_blto}</td>
                <td>{data.nosso_numero}</td>
                <td>{data.ativos}</td>
                <td>{data.ate12m}</td>
                <td>{data.apos12m}</td>
                <td>{data.semdireito}</td>
                <td>{data.opositor}</td>
                <td>{data.idademaxima}</td>
                <td>{data.cobranca}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={16}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Pagamentos - Pagamentos em Duplicidade</h2>
                <p>Consulte os pagamentos em duplicidade da Cobranca Registrada</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                        Informe o Intervalo de Datas para Pesquisa.
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="date_ini" id="data_ini2" placeholder="Data início" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="date_fim" id="data_fim2" placeholder="Data fim" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>

                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                        
                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                <td>Totais</td>
                                <td>Qtd Pagamentos</td>
                                <td>Valor Total Pago</td>
                                </thead>
                                <tbody>
                                <td></td>
                                <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                <td style={{textAlign:"right"}}>R$ {this.state.vlr_total.toLocaleString('de-DE')}</td>
                                </tbody>
                            </tr>
                        </table>

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>Empresa</td>
                                    <td>CNPJ</td>
                                    <td>Plano</td>
                                    <td>Empr/Plno</td>
                                    <td>Dt Vecto</td>
                                    <td>Dt Pagto</td>
                                    <td style={{textAlign:"right"}}>Valor Pago</td>
                                    <td>Id Blto</td>
                                    <td>N.Numero</td>                                    <td>Ativos</td>
                                    <td>-12m</td>
                                    <td>+12m</td>
                                    <td>Sem Direito</td>
                                    <td>Opositor</td>
                                    <td>Idade Maxima</td>
                                    <td>Cobranca</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(PagamentosDuplicados))
