import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect} from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class OperadoresAPOIAR extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            operador_res: [],
            operador_dados: [],
            renderModal: <div></div>,
            qtd_total: 0,
            paramters: null  
        }

        this.getOperador = this.getOperador.bind(this);
        this.getTableShow = this.getTableShow.bind(this);
        this.showOperador = this.showOperador.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
            empresa_pesquisa: event.target.empresa_pesquisa.value
        }

        if(event.target.operador_pesquisa.value !== ""){
            data.operador_pesquisa = event.target.operador_pesquisa.value;
        }

        switch(event.target.ativo.value){
            case "1":
                data.ativo = "sim";
                data.pendente = "";
                data.porempresa = "";
                break;
            case "2":
                data.ativo = "";
                data.pendente = "sim";
                data.porempresa = "";
                break;
            case "3":
                data.ativo = "";
                data.pendente = "";
                data.porempresa = "";
                break;
            case "4":
                data.ativo = "";
                data.pendente = "sim";
                data.porempresa = "sim";
                break;
            case "5":
                data.ativo = "";
                data.pendente = "nao";
                data.porempresa = "sim";
                break;
            default:
                data.ativo = "";
                data.pendente = "";
                data.porempresa = "";
                break;
        }

        data.pesquisa_parcial =  'nao';
        data.apoiar           =  'sim';

        this.props.action.loadingStatus();

        if (data.porempresa === "sim") {
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-operadores-porempresa", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                self.setState({
                    relatorio: response.content, 
                    qtd_total: response.content.length
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });
                
        } else {
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-operadores", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                self.setState({
                    relatorio: response.content, 
                    qtd_total: response.content.length
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });    
        }
    }

    showOperador(prm_id_empr_oprdr){
        this.setState({
            operador_res: []
        })
        let self = this;
        let data = {
            id_empr_oprdr : prm_id_empr_oprdr
        }

        let urlDadosOperador = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/empresa-operador-consulta";        
        this.props.action.loadingStatus();

        fetch(urlDadosOperador, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })

        .then(res => self.core.responseConvert(res))
        .then((response) => {
            
            console.log("Dados Operador=>", response.content)
                
            self.setState({
               // operador_res: opearador_html,
                operador_dados: response.content
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        })
    }

    getOperador(){
        return <div class="row">
                    <div className="form-group">
                        <label>Nome</label>
                        <input className="form-control" type="text" name="nome_empr_oprdr" required
                            value={this.state.operador_dados.nome_empr_oprdr || ""} label="Nome"
                            onChange={(e) => this.setState({
                                operador_dados: Object.assign(this.state.operador_dados, {nome_empr_oprdr: e.target.value})
                            })}
                        />
                    </div>

                    <div className="form-group">
                        <label>CPF</label>
                        <InputMask mask="999.999.999-99" className="form-control" name="cpf_empr_oprdr" required
                            value={this.state.operador_dados.cpf_empr_oprdr || ""}
                            onChange={(e) => this.setState({
                                operador_dados: Object.assign(this.state.operador_dados, {cpf_empr_oprdr: e.target.value})
                            })}

                        />
                    </div>

                    <div className="form-group">
                        <label>Email</label>
                        <input className="form-control" name="email_empr_oprdr" required
                            value={this.state.operador_dados.email_empr_oprdr || ""}
                            onChange={(e) => this.setState({
                                operador_dados: Object.assign(this.state.operador_dados, {email_empr_oprdr: e.target.value})
                            })}

                        />
                    </div>

                    <div className="form-group">
                        <table className="table table-striped table-doc">
                            <tbody>
                                <td>
                                    <button className="btn btn-danger"  onClick={event => this.desativarOperador(this.state.operador_dados.id_empr_oprdr)} data-toggle="modal" data-target="#DesativaroprdrModal">Desativar</button>
                                </td>
                                <td>
                                    <button className="btn btn-info"  onClick={event => this.ativarOperador(this.state.operador_dados.id_empr_oprdr)} data-toggle="modal" data-target="#AtivaroprdrModal">Ativar</button>
                                </td>
                                <td>
                                    <button className="btn btn-info"  onClick={event => this.atualizarOperador(this.state.operador_dados.id_empr_oprdr)} data-toggle="modal" data-target="#AtualizaroprdrModal">Atualizar</button>
                                </td>
                            </tbody>
                        </table>
                    </div> 
            </div>;
     }

    ativarOperador(prm_id_empr_oprdr){
        let self=this;
        this.state.paramters = '?id_empr_oprdr=' + prm_id_empr_oprdr;
        let urlAtivarOperador = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/empresa-operador-ativar"+this.state.paramters;
        fetch(urlAtivarOperador, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(this.state.paramters)})

        .then(res => self.core.responseConvert(res))
        .then((response) => {
             self.refs.formulario.click()
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });

}

    desativarOperador(prm_id_empr_oprdr){
        let self=this;
        this.state.paramters = '?id_empr_oprdr=' + prm_id_empr_oprdr;
        let urlDesativarOperador = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/empresa-operador-desativar"+this.state.paramters;
        fetch(urlDesativarOperador, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(this.state.paramters)})

        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.refs.formulario.click()
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    
    }

    atualizarOperador(prm_id_empr_oprdr){
        let self=this;
        let urlAtualizarOperador = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/empresa-operador-atualizar";
        console.log("Dados Operador=>", this.state.operador_dados);

        fetch(urlAtualizarOperador, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(this.state.operador_dados)
        })

        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.refs.formulario.click()
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }


    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            let botao1 = null;
            /*
            if (data.situacao_operador === 'ativo') {
                botao1 =                    
                    <button type="button" className="btn btn-danger"  onClick={event =>{  this.desativarOperador(data.id_empr_oprdr)}} data-toggle="modal" data-target="#btn1Modal">Desativar</button>
            } else {
                botao1 = 
                    <button type="button" className="btn btn-info"  onClick={event =>{  this.ativarOperador(data.id_empr_oprdr)}} data-toggle="modal" >Ativar</button>
            }
            */
            let botao2 = null;  
            botao2 =    
                <button className="btn btn-info" onClick={event => this.showOperador(data.id_empr_oprdr)} data-toggle="modal" data-target="#oprdrModal">Alterar</button>

            return <tr key={index} >
                <td>{data.id_empr_oprdr}</td>
                <td>{data.id_empr}</td>
                <td>{data.cnpj}</td>
                <td>{data.razao_social}</td>
                <td>{data.nome_operador}</td>
                <td>{data.cpf_operador}</td>
                <td>{data.email_operador}</td>
                <td>{data.situacao_operador}</td>
                <td>{data.responsavel_empr}</td>
                <td>{botao2}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        let tableOperador;

        tableOperador = this.getOperador()

        if (this.props.redirect.url) {
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={10}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Operadores (APOIAR) dos Empregadores Cadastrados no BSFOnline</h2>
                <p>Consulte os Operadores dos Empregadores no Sistema de Cobrança BSFonline</p>

                <div className="modal fade" id="oprdrModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Alterar Dados do Operador</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {tableOperador}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe os parametros abaixo para a pesquisa de operadores dos Empregadores.
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form  onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Todos os Empregadores" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <input name="operador_pesquisa" id="operador_pesquisa" placeholder="Todos os Operadores" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                        <label> <b>Situação</b> </label> <br/>
                                            <input type="radio" name="ativo" value="1" /> Autorizados <br/>
                                            <input type="radio" name="ativo" value="2" /> Pendentes <br/>
                                            <input type="radio" name="ativo" value="3" /> Autorizados e Pendentes <br/>
                                            <input type="radio" name="ativo" value="4" /> Apenas Pendentes (Por Empresas) <br/>
                                            <input type="radio" name="ativo" value="5" /> Ao menos 1 Autorizado (Por Empresa) <br/>
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" ref="formulario" className="btn btn-primary">pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">

                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                    <td>Qtd Operadores: {this.state.qtd_total.toLocaleString('de-DE')}</td>
                                </thead>
                                <tbody>
                                    <td></td>
                                </tbody>
                            </tr>
                        </table>
                        

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>Id Operador</td>
                                    <td>Id Empresa</td>
                                    <td>CNPJ/CPF</td>
                                    <td>Emregador</td>
                                    <td>Nome Operador</td>
                                    <td>CPF</td>
                                    <td>Email</td>
                                    <td>Situacao</td>
                                {/* <td>Ação</td>  */}
                                    <td>Responsavel Empresa</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(OperadoresAPOIAR))
