import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class ResumoEventos extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            qtd_total: 0,
            vlr_total: 0,
            excel:'nao'
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }
    showLista(){
        console.log("showLista1");
        this.state.excel = 'nao'
    }

    showExcel(){
        console.log("showExcel1");
        this.state.excel = 'sim'
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {}

        data.date_ini = event.target.date_ini.value;
        data.date_fim = event.target.date_fim.value;
       // data.mesano_vecto = event.target.mesano_vecto.value;
       // data.pesquisa_parcial =  'sim';

        var hoje = new Date();
        var ontem =  moment(hoje.toLocaleDateString(), "DD/MM/YYYY").subtract(1, 'days');
        //if (data.date_ini === "") {
        //    data.date_ini = ontem.format('DD/MM/YYYY');
        //}
        //if (data.date_fim === "") {
        //    data.date_fim = ontem.format('DD/MM/YYYY');
        //}

        this.props.action.loadingStatus();
        let url = ''
        if(event.target.tipo.value=='S'){
            url = self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/webben/suspenso?dtinicio="+event.target.date_ini.value+"&dtfinal="+event.target.date_fim.value;
        
        } else {
            url = self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/webben/status?dtinicio="+event.target.date_ini.value+"&dtfinal="+event.target.date_fim.value;
        
        }
        
        if(event.target.plano.value){
            console.log(event.target.plano.value);
            url+='&plano='+event.target.plano.value;
        }


        if(this.state.excel=='sim'){

           
                window.location.href = url+'&token='+self.core.getToken()+'&tipo=xlsx';   
                self.props.action.loadedStatus();

        } else {
            console.log(url);
            fetch(url, {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'GET',
                //body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                self.setState({
                    relatorio: response.content, 
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });
        }
    }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
           

            
            return <tr key={index} >
                <td>{data.razao}</td>
                <td>{data.cnpj}</td>
                <td>{data.plano}</td>
                <td>{data.recnum}</td>
                <td>{data.dt_ult_pagto}</td>
                <td>{data.dt_antigo_vcto}</td>
                <td>{data.protocolo}</td>
                <td>{data.tipo}</td>
                <td>{data.cadastro}</td>
                <td>{data.entrada}</td>
                <td>{data.saida}</td>
                <td>{data.destino}</td>
                <td>{data.cobranca}</td>
                <td>{data.situacao}</td>
                <td>{data.resp_cobr_plano}</td>
                <td>{data.valor_assistencia}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={16}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Resumo Eventos</h2>
                <p>Consulte o eventos que receberam notificação de multa inadimplente</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe o Intervalo de Datas para Pesquisa.
                        </h3>
                        {/*<div  className="seleciona-mes-doc" >*/}
                        <div className="panel-body container-fluid">
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label>Data Inicio:<small>(Comunicado)</small></label>
                                            <InputMask name="date_ini" id="data_ini2" placeholder="Data início" className="form-control" mask="99/99/9999"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label>Data Fim:<small>(Comunicado)</small></label>
                                            <InputMask name="date_fim" id="data_fim2" placeholder="Data fim" className="form-control" mask="99/99/9999"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label>Plano</label>
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>
                                   
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                             <b>Tipo</b>  <br/>
                                             <label><input type="radio" name="tipo" value="N" /> Notificado </label><br/>
                                             <label><input type="radio" name="tipo" value="S" /> Suspenso </label><br/>
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <label></label>
                                        <tr>
                                            <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                        </tr>
                                    </div>
                                    <div className="col-sm-1">
                                    <label></label>
                                        <tr>
                                            <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                        </tr>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>Empregador</td>
                                    <td>CNPJ/CPF</td>
                                    <td>Plano</td>
                                    <td>Recnum</td>
                                    <td>Ult.Pagto</td>
                                    <td>Ant.Vcto</td>
                                    <td>Protocolo</td>
                                    <td>Tipo</td>
                                    <td>Comunicado</td>
                                    <td>Entrada na Fila</td>
                                    <td>Saida da Fila</td>
                                    <td>Destino</td>
                                    <td>Situação</td>
                                    <td>Status</td>
                                    <td>Resp.Cobr.Plano</td>
                                    <td>Assistência</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(ResumoEventos))
