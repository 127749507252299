import Alert from 'react-s-alert';
import { Cookies } from 'react-cookie';
import Base64 from 'base-64';

let config = require('../config.json');
let cookies = new Cookies()

class Core {
    /**
     * Retorna a Url do serviço
     * 
     * @returns 
     * @memberof Core
     */
    getUrl(){
        if(process.env.NODE_ENV && process.env.NODE_ENV === "development"){
            let url = window.location.hostname;
            if(url === 'localhost'){
                return config.url_loc;
            }else{
                return config.url_dev;
            }
        }else{
            return config.url_pro;
        }
    }

    /**
     * Faz aa conversão do response em caso de erros envia
     * uma promisse ao catch com o erro do servidor
     */
    responseConvert(response){
        if (!response.ok) {
            throw response.json();
        }
        return response.json();
    }

    /**
     * função para capturar os erros das chamadas fetch ao servidor
     * qualquer chamada fetch pode usar esse metodo no catch.
     */
    catchFecth(err){
        if(err instanceof Promise){
            err.then((result) => {
                Alert.error(result.message, {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 8000
                });
            });
        }else{
            Alert.error(err.message, {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 8000
            });
        }
    }

    //coloca a aplicação em status de carregando
    loadingStatus(dispatch){
        dispatch({
            type: 'STATUS_LOADING', 
        });
    }

    //retira a aplicação do status de carregando
    loadedStatus(dispatch){
        dispatch({
            type: 'STATUS_LOADED', 
        });
    }

    //segurança para salvar dados em localstorage
    setStorage(name, value, expires = null){
        value = Base64.encode(JSON.stringify(value));
        let option = {
            path: "/"
        };
        if(expires){
            option.expires = expires;
        }
        window.sessionStorage.setItem(name, value)
        // cookies.set(name, value, option)
    }

    //segurança para salvar dados em localstorage
    getStorage(name){
        // let value = cookies.get(name);
        let value = window.sessionStorage.getItem(name);
        if(value){
            return JSON.parse(Base64.decode(value));
        }else{
            return false
        }
    }

    removeStorage(name){
        window.sessionStorage.removeItem(name)
        // cookies.remove(name)
    }

    getToken(){
        // let value = cookies.get('manager_log');
        let value = window.sessionStorage.getItem('manager_log');
        if(value){
            value = JSON.parse(Base64.decode(value));
            //TODO: utilizara data validacao e expiração
            // let data = new Date(value.expires_in * 1000);
            // data.setTime();
            // console.log(data);
            return value.token;
        }else{
            return "";
        }
    }

    isAuth(){
        let user = this.getStorage('manager_log');
        if(user){
            return true;
        }else{
            this.removeStorage('manager_log');
            window.location.href = "/";
        }
    }

    getAuth(){
        let user = this.getStorage('manager_log');
        return user;
    }

    getVersionAPI(){
        return config.vesion_api;
    }

    getPlanosCorretora(){
        let user = this.getStorage('manager_log');
        
        if(user.tipo === "TR"){
            return user.segmentos.map((data, index) => {
                return data.id;
            });
        }else{
            return ["all"];
        }
    }

    formatAutorizationToken(token){
        return "Bearer " + token;
    }

    getUrlConecta(){
       /* let url = window.location.hostname;
        if(url === 'localhost'){
            return config.url_conecta_dev
        }else{
            return config.url_conecta_pro
        }*/

        if (process.env.NODE_ENV && process.env.NODE_ENV === "development") {
            let url = window.location.hostname;
            if (url === 'localhost') {
                console.log("local");
                return config.url_conecta_loc;
            } else {
                return config.url_conecta_dev;
            }
        } else {
            return config.url_conecta_pro;
        }

    }

    getHeadersFetch(json = true){
        if(json){
            return  {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getToken()
            }
        }else{
            return {
                'Authorization': 'Bearer ' + this.getToken()
            }
        }
    }
}

export default Core;


