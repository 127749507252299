import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class TrabalhadorPagamento_CMD extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            trabs: [],
            listFavorecidos:[],
            qtd_total: 0,
            excel: 'nao',
            paramters: null          
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    callfavorecidos = async () => {
        const self = this
        let arr = []
        try {
            let res = await fetch( self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/relacao-assistencia",{
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + self.core.getToken()
                },
            })

            if (!res.ok) {
                throw res.json();
            }

            res = await res.json()

            arr = res.content.map(( el, ind) => {
                return <option key={ "fav__" + ind } value={ el.enti_cd_nseq }>{ el.enti_ds_sigla }</option>
            })

            this.setState({
                listFavorecidos: arr
            })

            return true
        } catch (error) {
            // console.log("erro", error)
            return false
        }
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
        this.callfavorecidos()
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        //data.plano = event.target.plano.value;
        data.date_ini   = event.target.date_ini.value;
        data.date_fim   = event.target.date_fim.value;
        // data.favorecido = '1543';
        data.favorecido = event.target.favorecido.value;
        data.excel      = 'nao';

        this.state.paramters = "?";
        this.state.paramters += 'date_ini=' + data.date_ini;
        this.state.paramters += '&date_fim=' + data.date_fim;
        this.state.paramters += '&favorecido=' + data.favorecido;
        this.state.paramters += '&token=' + this.core.getToken();


        if (this.state.excel != 'sim') {
            data.excel      = 'nao';
            this.props.action.loadingStatus();
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/excel-trabalhador-assistencia", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                let qtd_trabalhadores1 = 0
                let qtd_trabalhadores2 = 0
                this.setState.qtd_total = 0
                qtd_trabalhadores1 = response.resumo.total_trabalhadores_mes
                qtd_trabalhadores2 = response.resumo.total_trabalhadores_acordos

                /*
                response.content.map ((val, index) => {
                    qtd_trabalhadores1+=1 
                })
                */
                self.setState({
                    relatorio: response.content, 
                    //qtd_trabalhadores2: response.content.length,
                    qtd_total: qtd_trabalhadores1 + qtd_trabalhadores2
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });    
        }
        if (this.state.excel === 'sim') {
            data.excel      = 'sim';
            this.state.paramters += '&excel=' + data.excel;
            let urlExcel = null;
            urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-trabalhador-assistencia"+this.state.paramters;
            window.location.href = urlExcel   
        }

    }

    showLista(){
        console.log("showLista1");
        this.setState({
             excel: 'nao'
        });
    }

    showExcel(){
        console.log("showExcel1");
        this.setState({
             excel: 'sim'
        });
    }



    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.nome}</td>
                <td>{data.cpf}</td>
                <td>{data.dt_nascimento}</td>
                <td>{data.sexo}</td>
                <td>{data.dt_admissao}</td>
                <td>{data.dt_inclusao}</td>
                <td>{data.razao_social}</td>
                <td>{data.cnpj}</td>
                <td>{data.placa}</td>
                <td>{data.data_vencimento}</td>
                <td>{data.data_pagamento}</td>
                <td>{data.nosso_numero}</td>
                <td>{data.situacao}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={13}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa.</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Relação de Trabalhador - Pagamento</h2>
                <p>Consulte os Trabalhadores Cadastrados nas Empresas no Sistema BSFOnline e que constam em boletos pagos pelas empresas</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                        Informe o intervalo de pagamento para a consulta dos trabalhadores
                        </h3>
                        <div  className="seleciona-trabalhador" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="date_ini" id="data_ini2" placeholder="Data início Pagto" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="date_fim" id="data_fim2" placeholder="Data fim Pagto" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <select name="favorecido" id="favorecido_val" className="form-control" required>
                                                <option selected value={""}>SELECIONE UM TIPO</option>
                                                { this.state.listFavorecidos }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                        
                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                <td>Totais</td>
                                <td>Trabalhadores</td>
                                </thead>
                                <tbody>
                                <td></td>
                                <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>

                                </tbody>
                            </tr>
                        </table>

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>Nome</td>
                                    <td>CPF</td>
                                    <td>Nascimento</td>
                                    <td>Sexo</td>
                                    <td>Admissao</td>
                                    <td>Inclusao</td>
                                    <td>Empresa</td>
                                    <td>CNPJ</td>
                                    <td>Plano</td>
                                    <td>Dt Vcto</td>
                                    <td>Dt Pgto</td>
                                    <td>N.Numero</td>
                                    <td>Situacao</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(TrabalhadorPagamento_CMD))
