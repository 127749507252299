import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';
import Alert from 'react-s-alert';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';
import { isNull } from 'util';
import { isNullOrUndefined } from 'util';

class ControleComplemento extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            qtd_total: 0,
            vlr_total: 0,
            qtd_tbldr_pagos: 0,
            qtd_tbldr_novos: 0,
            auth: this.core.getAuth(),
            paramters: null,
            critica: 'nao',            
            excel: 'nao'            
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        console.log("handlerSubmit");
        event.preventDefault();
        let self = this;
 
        let data = {
        }

        data.plano              = event.target.plano.value;
        data.empresa_pesquisa   = ''; //event.target.empresa_pesquisa.value;
        data.mesano_vecto_de    = event.target.mesano_vecto_de.value;
        data.mesano_vecto_ate   = event.target.mesano_vecto_ate.value;
        data.pesquisa_parcial   = 'nao';


        this.state.paramters = "?";
        this.state.paramters += 'empresa_pesquisa=' + data.empresa_pesquisa;
        this.state.paramters += '&mesano_vecto_de=' + data.mesano_vecto_de;
        this.state.paramters += '&mesano_vecto_ate=' + data.mesano_vecto_ate;
        this.state.paramters += '&plano=' + data.plano;
        this.state.paramters += '&pesquisa_parcial=' + data.pesquisa_parcial;
        this.state.paramters += '&excel=' + this.state.excel;
        this.state.paramters += '&token=' + this.core.getToken();

        console.log("Parametros=>", this.state.paramters);
        this.critica = 'ok';

        if (isNullOrUndefined(data.mesano_vecto_de) || (data.mesano_vecto_de === "") ) {
            Alert.error("Mes/Ano do Vencimento (de) deve ser informado.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 3000
            });    
            this.critica = 'erro';
        }

        if (isNullOrUndefined(data.mesano_vecto_ate) || (data.mesano_vecto_ate === "") ) {
            Alert.error("Mes/Ano do Vencimento (até) deve ser informado.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 3000
            });    
            this.critica = 'erro';
        }

        if (isNullOrUndefined(data.plano) || (data.plano === "") ) {
            Alert.error("Plano deve ser informado.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 3000
            });    
            this.critica = 'erro';
        }

        

        if (this.state.excel != 'sim' && (this.critica === 'ok')) {
            this.props.action.loadingStatus();

    
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-controle-complemento" + this.state.paramters, {
                headers: {
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'GET'
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                let qtd_tbldr_pagos = 0
                let qtd_tbldr_novos = 0
                response.content.map ((val, index) => {
                    qtd_tbldr_pagos += parseFloat(val.qtd_trabalhadores_pagos)
                    qtd_tbldr_novos += parseFloat(val.qtd_trabalhadores_novos)
                })
                self.setState({
                    relatorio: response.content, 
                    qtd_total: response.content.length,
                    qtd_tbldr_pagos: qtd_tbldr_pagos,
                    qtd_tbldr_novos: qtd_tbldr_novos
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });    
        } 
        if (this.state.excel === 'sim') {
            console.log("Chamando Excel:");
            let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/lista-empresa-controle-complemento" + this.state.paramters;
            window.location.href = urlExcel        
        } 
    }

    showLista(){
        console.log("showLista1");
        this.state.excel = 'nao'
    }

    showExcel(){
        console.log("showExcel1");
        this.state.excel = 'sim'
    }

    consisteParametros(prm_mesano_vecto_de){
        console.log("consisteParametros");
        if (((prm_mesano_vecto_de === '') || isNullOrUndefined(prm_mesano_vecto_de))) {
            Alert.error("Mes/Ano do Vencimento deve ser informado.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 3000
            });

            this.state.excel = 'nao'
            return false;    
        }
        return true;
    }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            let vlr_ult = null 
            if (data.qtd_impr > 1) {
                vlr_ult = <td style={{textAlign:"right"}}>R$ {parseFloat(data.vlr_ult_blto).toFixed(2)}</td>
            } else {
                vlr_ult = <td></td>
            }   
            return <tr key={index} >
                <td>{data.cnpj}</td>
                <td>{data.empresa}</td>
                <td>{data.dt_vcto_empr_blto}</td>
                <td>{data.qtd_trabalhadores_pagos}</td>
                <td>{data.qtd_trabalhadores_novos}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        this.state.excel = 'nao'
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={3}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Empresa - Controle de Trabalhadores - Complemento</h2>
                <p>Análise dos Boletos de Trabalhadores</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe o Intervalo de Datas para Pesquisa
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>
                              
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="mesano_vecto_de" id="mesano_vecto_de" placeholder="Mês/Ano Vencimento (de)" className="form-control" mask="99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="mesano_vecto_ate" id="mesano_vecto_ate" placeholder="Mês/Ano Vencimento (até)" className="form-control" mask="99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                    <td>Totais</td>
                                    <td>Qtd Pagamentos</td>
                                    <td>Qtd Trabalhadores Pagos</td>
                                    <td>Qtd Trabalhadores Novos</td>
                                </thead>
                                <tbody>
                                    <td></td>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_tbldr_pagos.toLocaleString('de-DE')}</td>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_tbldr_novos.toLocaleString('de-DE')}</td>
                                </tbody>
                            </tr>
                        </table>
                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                <td>CNPJ</td>
                                <td>Empresa</td>
                                    <td>Vencimento</td>
                                    <td>Trabalhadores Pagos</td>
                                    <td>Trabalhadores Novos</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(ControleComplemento))
