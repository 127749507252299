import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import base64 from 'base-64'
import { withRouter, Redirect } from 'react-router-dom';
import Alert from 'react-s-alert';
import Select from 'react-select';
import { ModalContainer, ModalDialog } from 'react-modal-dialog-react16';
import $ from 'jquery'
import SweetAlert from 'sweetalert-react';
import '../../../assets/css/sweetalert.css';

//class
import Core from '../../../class/Core';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class CreateEnvioEmails extends React.Component {
    constructor(props) {
        super(props);
        this.core = new Core();
        this.core.isAuth();

        this.state = {
            dados: {},
            modal: false,
            modelos: [],
            modelosRaw: [],
            planos: [],
            selecteds: [],
            excecoes: [],
            exceto: [],
            html_content: null,
            redirect: null,
            selectType: {
                select: true,
                lista: false
            },
            range: {
                num1: 0,
                num2: 0
            },
            show: false
        };

        this.handlerSubmit = this.handlerSubmit.bind(this);
        this.handlerSelect = this.handlerSelect.bind(this);
        this.handlerSelectExcecoes = this.handlerSelectExcecoes.bind(this);
        this.handleTypeEmail = this.handleTypeEmail.bind(this)
        this.typeSelect = this.typeSelect.bind(this)
        this.formatRanger = this.formatRanger.bind(this)
        this.removeSelectedLista = this.removeSelectedLista.bind(this)

        this.removeSelectedListaExcecoes = this.removeSelectedListaExcecoes.bind(this)
        this.ModalInfosend = this.ModalInfosend.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    componentDidMount() {
        $('.form-select-lista').hide()

        //TESTE
        $('.teste-box').slideDown()
        $('.empresa-box').slideUp()
        $('.lista-box').slideUp()
        $('.empresas-required').removeAttr("required")
        $('.teste-required').attr("required", "")
        $('.upload-lista').removeAttr("required")
    }

    componentWillMount() {
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();

        this.props.action.loadingStatus();


        fetch(self.core.getUrlConecta() + "emails/modelo", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
        }
        )
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                // console.log(response);
                let data = response.content.sort((a, b) => a.id_eml_modl - b.id_eml_modl).map((data, index) => {
                    return <option key={index + 1} value={data.id_eml_modl}>{data.id_eml_modl} - {data.assunto_eml_modl}</option>;
                });

                data.splice(0, 0, <option key={0} value="">SELECIONE UM MODELO</option>);
                self.setState({
                    modelos: data,
                    modelosRaw: response.content
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err);
                self.props.action.loadedStatus();
            });

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "planos", {
            //fetch(self.core.getUrl() + self.core.getVersionAPI() + "planos/planos-cct", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
        }
        )
            .then(res => self.core.responseConvert(res))
            .then((response) => {

                ///mapeia o valores vindo de planos e faz um sort para ordenação de texto
                let planos = response.content.filter((data, index) => {
                    if (data.id_tr_plno !== 8888 && data.id_tr_plno !== 9999) {
                        return true
                    }
                }).map((data, index) => {

                    let tela_rosa_id = data.id_tr_plno || 0
                    return {
                        value: data.id_plno,
                        label: tela_rosa_id + " - " + data.dscr_plno,
                        id: tela_rosa_id
                    }
                }).sort(function (a, b) {
                    return parseInt(a.id) - parseInt(b.id)
                });

                //adicionando todos os planos como opção
                planos.splice(0, 0, {
                    value: "all",
                    label: "TODOS OS PLANOS",
                    id: 0
                })

                this.setState({
                    planos: planos
                });
                self.props.action.loadedStatus();
            })
            .then(res => {

                fetch(self.core.getUrlConecta() + "emails/exceto-planos", {
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                    }
                })
                    .then(res => self.core.responseConvert(res))
                    .then(res => {
                        // console.log(res.content[0].lsta_exto_plno)
                        let exceto = res.content[0].lsta_exto_plno
                        let object = exceto.split(",").map(Number)

                        // console.log(object)
                        let lista = this.state.planos.filter(function (e) {
                            return object.includes(e.value)
                        })
                        // console.log(lista)
                        this.setState({
                            excecoes: lista
                        })
                    })
            })
            .catch((err) => {
                self.core.catchFecth(err);
                self.props.action.loadedStatus();
            });




    }



    /**
     * Faz o options de anos para o Fromulário
     * @param  Number ano_inicio 
     * @return HTML
     */
    rangeAno(ano_inicio) {
        var anoAtual = new Date().getFullYear(), anos = [];
        ano_inicio = ano_inicio || 2017;

        while (ano_inicio <= anoAtual) {
            let ano = ano_inicio++;
            anos.push(ano)
        }

        anos.sort((a, b) => {
            return b - a
        })

        anos = anos.map((ano, index) => {
            return <option key={index} value={ano}>{ano}</option>
        })

        return anos;
    }

    handleClose() {
        this.setState({
            modal: false
        })
    }

    getModalInfoSend() {
        let modelo = this.state.modelosRaw.filter((val, index) => {
            return val.id === parseInt(this.state.dados.modelo_id)
        })[0] || {}

        let tipoEnvio = ""
        switch (this.state.dados.tipo) {
            case 'disponibilidade':
                tipoEnvio = "TODAS AS EMPRESAS DOS PLANOS SELECIONADOS"
                break;
            case 'inadimplentes':
                tipoEnvio = "TODAS AS EMPRESAS INADINPLENTES DOS PLANOS SELECIONADOS"
                break;
            case 'nao-impressos':
                tipoEnvio = "TODAS AS EMPRESAS SEM BOLETO IMPRESSO DOS PLANOS SELECIONADOS"
        }

        let planos = []
        if (this.state.dados.planos) {
            planos = this.state.dados.planos.map((val, index) => {
                let indexOf = this.state.planos.map(e => e.value).indexOf(val)
                let data = this.state.planos[indexOf]
                return <li key={index}>{data.label}</li>
            }) || []
        }

        return <div>
            {
                this.state.modal &&
                <ModalContainer onClose={this.handleClose} style={{ zIndex: 99999 }}>
                    <ModalDialog onClose={this.handleClose}>
                        <div style={{ maxWidth: 800 }}>
                            <h3>Analise para envio de Email</h3>
                            <p>Confirme os dados para envio do email</p>

                            <p>
                                <b>PERIODO: {this.state.dados.mes}/{this.state.dados.ano}</b> <br />
                                <b>MODELO: {modelo.id} - {modelo.assunto}</b> <br />
                                <b>TIPO DE ENVIO: {tipoEnvio}</b>
                                <br /><br />
                            </p>
                            <b>PLANOS:</b>
                            <ul className="view-box-planos">
                                {planos}
                            </ul>

                            <p>Para confirmar o envio coloque seu usuário e senha</p>
                            <form onSubmit={this.ModalInfosend}>
                                <div className="form-group">
                                    <input type="email" name="email" required className="form-control" />
                                </div>
                                <div className="form-group">
                                    <input type="password" name="senha" required className="form-control" />
                                </div>
                                <div className="form-group">
                                    <button className="btn" type="submit">ENVIAR EMAILS</button>
                                </div>

                            </form>
                        </div>
                    </ModalDialog>
                </ModalContainer>
            }
        </div>
    }

    ModalInfosend(event) {
        event.preventDefault()
        let self = this

        let send = this.state.dados
        let user = base64.encode(event.target.email.value + ":" + event.target.senha.value)

        this.props.action.loadingStatus();
        fetch(self.core.getUrlConecta() + 'emails/register-send', {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + user
            },
            method: 'POST',
            body: JSON.stringify(send)
        }
        )
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                Alert.success("Lista de emails gerada com sucesso!\nAguarde o envio.", {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 8000
                });

                self.props.action.loadedStatus();

                self.setState({
                    redirect: "/manager/envio-emails"
                });
            })
            .catch((err) => {
                self.core.catchFecth(err);
                self.props.action.loadedStatus();
            });
    }

    /**
     * Pega o eventode envio dos emails de cobranca
     * @param  Obejec event 
     * @return null
     */
    async handlerSubmit(event) {
        event.preventDefault();

        let tipo = event.target.TIPO_ENVIO.value || null

        const self = this

        if (tipo === 'TESTE') {
            let bodyData = {
                emails_teste: event.target.emails_teste.value,
                modelo_id: event.target.modelo_id.value,
                cnpj_teste: event.target.cnpj_teste.value,
                mes: event.target.mes.value,
                ano: event.target.ano.value
            }

            fetch(self.core.getUrlConecta() + 'emails/send-teste', {
                headers: self.core.getHeadersFetch(),
                method: 'POST',
                body: JSON.stringify(bodyData)
            }).then(res => self.core.responseConvert(res))
                .then(response => {
                    Alert.success("Emails Teste enviado com sucesso!", {
                        position: 'bottom-right',
                        effect: 'slide',
                        timeout: 8000
                    });

                    self.props.action.loadedStatus();
                }).catch(e => {
                    self.core.catchFecth(e)
                    self.props.action.loadedStatus()
                })
        } else if (tipo === 'PRODUCAO') {
            let planos = this.state.selecteds.map((data, index) => {
                return data.value;
            })
            let excecoes = this.state.excecoes.map((data, index) => {
                return data.value;
            })

            if (!planos.length) {
                this.setState({
                    show: true
                })
                return false
            }

            self.setState({
                dados: Object.assign({}, {
                    modelo_id: event.target.modelo_id.value,
                    planos: planos,
                    tipo: event.target.tipo.value,
                    mes: event.target.mes.value,
                    ano: event.target.ano.value,
                    exceto: excecoes
                })
            })

            this.setState({
                modal: true
            })
        } else if (tipo === 'LISTA') {
            let send = new FormData()
            let input = document.querySelector('.upload-lista')
            //dados do usuario logado 
            let getUserToken = JSON.parse(base64.decode(window.sessionStorage.getItem('manager_log')))

            let usuario = {
                nome_user: getUserToken.nome,
                email_user: getUserToken.email
            }



            send.append('arquivo', input.files['0'])
            send.append('modelo_id', event.target.modelo_id.value)
            send.append('usuario', JSON.stringify(usuario))


            fetch(self.core.getUrlConecta() + 'emails/register-excel', {
                headers: {
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: send
            }
            )
                .then(res => self.core.responseConvert(res))
                .then((response) => {
                    Alert.success("Lista de emails gerada com sucesso!\nAguarde o envio.", {
                        position: 'bottom-right',
                        effect: 'slide',
                        timeout: 'none'
                    });

                    self.props.action.loadedStatus();

                    self.setState({
                        redirect: "/manager/envio-emails"
                    });
                })
                .catch((err) => {
                    self.core.catchFecth(err);
                    self.props.action.loadedStatus();
                });
        }
    }

    handlerSelect(valor) {
        this.setState({
            selecteds: valor
        });
    }

    handlerSelectExcecoes(valor) {
        const self = this
        this.setState({
            excecoes: valor
        });


        let lista = this.state.excecoes.map(e => e.value);
        // console.log(this.state.excecoes)
        //Atualizando Banco de dados
        fetch(self.core.getUrlConecta() + "emails/exceto-planos", {
            method: 'PUT',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            body: JSON.stringify({ planos: lista })
        })

    }

    /**
     * Altera a tela de envio para teste ou envio para produção
     * @param  Object event 
     */
    handleTypeEmail(event) {
        if (event.target.value === 'TESTE') {
            $('.teste-box').slideDown()
            $('.empresa-box').slideUp()
            $('.lista-box').slideUp()

            $('.empresas-required').removeAttr("required")
            $('.teste-required').attr("required", "")
            $('.upload-lista').removeAttr("required")
        } else if (event.target.value === 'PRODUCAO') {
            $('.teste-box').slideUp()
            $('.lista-box').slideUp()
            $('.empresa-box').slideDown()

            $('.empresas-required').attr("required", "")
            $('.teste-required').removeAttr("required")
            $('.upload-lista').removeAttr("required")
        } else if (event.target.value === 'LISTA') {
            $('.teste-box').slideUp()
            $('.empresa-box').slideUp()
            $('.lista-box').slideDown()

            $('.upload-lista').attr("required", "")
            $('.empresas-required').removeAttr("required")
            $('.teste-required').removeAttr("required")
        }
    }

    /**
     * pega o tipo do select que o usuario deseja
     */
    typeSelect(e) {
        // console.log(e.target)
        if (e.target.value === 'lista') {
            this.setState({
                selectType: Object.assign({}, {
                    select: false,
                    lista: true
                })
            })
            $('.form-select-select').slideUp()
            $('.form-select-lista').slideDown()
        } else if (e.target.value === 'select') {
            this.setState({
                selectType: Object.assign({}, {
                    select: true,
                    lista: false
                })
            })
            $('.form-select-select').slideDown()
            $('.form-select-lista').slideUp()
        }
    }

    /**
     * GET RANGE
     */
    formatRanger() {
        // console.log(this.state.planos)
        if (this.state.range.num1 > this.state.range.num2) {
            Alert.error("Numero Início não pode ser maior que Numero Fim", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 8000
            });
            return false
        }
        let selectsObject = {}
        let selecteds = []


        //paga os selecionados do range
        this.state.planos.forEach((val, index) => {
            if (val.id >= this.state.range.num1 && val.id <= this.state.range.num2) {
                selectsObject[val.id] = val
            }
        })

        //combina o selecionado do range com os ja selecionados
        this.state.selecteds.forEach((val, index) => {
            selectsObject[val.id] = val
        })

        //converte para array os selecionados
        selecteds = Object.keys(selectsObject).map((key, index) => {
            return selectsObject[key]
        })

        this.setState({
            selecteds: selecteds
        })
    }

    removeSelectedLista(e, val) {
        // console.warn(val)
        let arr = this.state.selecteds.slice(0)
        let index = arr.indexOf(val)
        arr.splice(index, 1)

        this.setState({
            selecteds: arr
        })
    }

    removeSelectedListaExcecoes(e, val) {
        let arr = this.state.excecoes.slice(0)
        let index = arr.indexOf(val)
        arr.splice(index, 1)

        this.setState({
            excecoes: arr
        })
    }

    renderRangeList() {
        return this.state.selecteds.map((val, index) => {
            return <div key={index} className="list-item-planos">
                <span className="remove-planos" onClick={e => this.removeSelectedLista(e, val)}><i className="fa fa-times" aria-hidden="true"></i></span> {val.label}
            </div>
        })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        if (this.props.redirect.url) {
            return <Redirect to={this.props.redirect.url} />;
        }
        // console.warn(this.state)
        //return render
        return <div className="page">
            {this.getModalInfoSend()}

            <SweetAlert
                show={this.state.show}
                title="AVISO"
                type={'info'}
                text="Selecione um plano para envio!"
                onConfirm={() => this.setState({ show: false })}
            />
            <div className="page-content">
                <h4>ENVIAR LISTA DE EMAILS COBRANÇA</h4>
                {/* exibe  */}
                <div className="panel">
                    <div className="panel-body container-fluid">
                        <form action="#" onSubmit={this.handlerSubmit}>
                            <div className={'row'}>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <select name="TIPO_ENVIO" id="tipo-envio" className="form-control" onChange={this.handleTypeEmail} required>
                                                    {/* <option value="">Selecione uma forma de envio</option> */}
                                                    <option checked={true} value="TESTE">ENVIO TESTE</option>
                                                    {/* <option value="PRODUCAO">ENVIO PRODUÇÃO</option> */}
                                                    {/* <option value="LISTA">ENVIO LISTA</option> */}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 teste-box">
                                            <div className="form-group">
                                                <label htmlFor="">Emails para envio teste:</label>
                                                <input type="text" name="emails_teste" className="form-control teste-required" placeholder="Exempo: teste@gmail.com, teste2@hotmail.com" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="empresa-box">
                                        <div className="row">
                                            <div className="col-sm-7">
                                                <div className="form-group">
                                                    <label>Selecione o Mês</label>
                                                    <select name="mes" className="form-control" required>
                                                        <option value="01">Janeiro</option>
                                                        <option value="02">Fevereiro</option>
                                                        <option value="03">Março</option>
                                                        <option value="04">Abril</option>
                                                        <option value="05">Maio</option>
                                                        <option value="06">Junho</option>
                                                        <option value="07">Julho</option>
                                                        <option value="08">Agosto</option>
                                                        <option value="09">Setembro</option>
                                                        <option value="10">Outubro</option>
                                                        <option value="11">Novembro</option>
                                                        <option value="12">Desembro</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-sm-5">
                                                <label>Selecione o Ano</label>
                                                <select name="ano" className="form-control" required>
                                                    {this.rangeAno()}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>Selecione um Modelo</label>
                                        <select name="modelo_id" className="form-control" required>
                                            {this.state.modelos}
                                        </select>
                                    </div>
                                    <div className="lista-box">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <a href="/files/modelo_envio.xlsx" download className="btn btn-info">Baixar modelo excel</a>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <input type="file" className="form-control upload-lista" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="empresa-box">
                                        <div className="form-group">
                                            <label>Selecione um grupo de empresas</label>
                                            <select name="tipo" className="form-control empresas-required">
                                                <option value="">SELECIONE UM GRUPO PARA ENVÍO</option>
                                                <option value="disponibilidade">TODAS AS EMPRESAS</option>
                                                <option value="inadimplentes">EMPRESAS INADIMPLENTES</option>
                                                <option value="nao-impressos">EMPRESAS SEM IMPRESSÃO</option>
                                                <option value="sim-impressos">EMPRESAS COM IMPRESSÃO</option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="select-tipo-lista">Forma de Seleção</label>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <input type="radio" checked={this.state.selectType.select} name="forma_select" value="select" onChange={this.typeSelect} /> Seleção Específica
                                                </div>
                                                <div className="col-sm-6">
                                                    <input type="radio" checked={this.state.selectType.lista} name="forma_select" value="lista" onChange={this.typeSelect} /> Seleção por Lista
                                                </div>
                                            </div>
                                        </div>


                                        <div className="form-select-select">
                                            <div className="form-group">
                                                <label>Selecione os Planos</label>
                                                <Select
                                                    name="planos"
                                                    multi={true}
                                                    value={this.state.selecteds}
                                                    options={this.state.planos}
                                                    onChange={this.handlerSelect}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-select-select">
                                            <div className="form-group">
                                                <label>Exceções de planos</label>
                                                <Select
                                                    name="excecoes"
                                                    multi={true}
                                                    value={this.state.excecoes}
                                                    options={this.state.planos}
                                                    onChange={this.handlerSelectExcecoes}
                                                // value ={this.state.exceto}
                                                />
                                            </div>
                                        </div>


                                        <div className="form-select-lista">

                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <input
                                                            type="number"
                                                            name="range1"
                                                            value={this.state.range.num1}
                                                            onChange={e => this.setState({
                                                                range: Object.assign(this.state.range, {
                                                                    num1: parseInt(e.target.value)
                                                                })
                                                            })}
                                                            placeholder="Num. Início"
                                                            className="form-control" />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <input
                                                            type="number"
                                                            name="range2"
                                                            className="form-control"
                                                            value={this.state.range.num2}
                                                            onChange={e => this.setState({
                                                                range: Object.assign(this.state.range, {
                                                                    num2: parseInt(e.target.value)
                                                                })
                                                            })}
                                                            placeholder="Num. Fim" />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <button className="btn btn-primary" type="button" onClick={this.formatRanger}>ADICIONAR LISTA</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="lista-selects">
                                                    {this.renderRangeList()}
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="teste-box">
                                        <div className="form-group">
                                            <label htmlFor="">Informe o CNPJ para simulação de dados do Envio de Teste</label>
                                            <input type="text" name="cnpj_teste" className="form-control teste-required" placeholder="Adicione um CNPJ" />
                                        </div>
                                    </div>
                                </div> {/* fim coluna 1*/}

                                <div className="col-sm-6">

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <button type="submit" className="btn btn-success">Enviar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {/*fim*/}
            </div>
        </div>;
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}

export default withRouter(connect(mapStateProps, mapDispatchProps)(CreateEnvioEmails))
