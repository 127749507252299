import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import moment from 'moment';
import InputMask from 'react-input-mask';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class ImpressoesPersonal extends React.Component{
    constructor(props){
        super(props);

        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;

        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            paramters: null,
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            trabs: [],
            qtd_total: 0,
            vlr_total: 0,
            qtd_trabalhadores: 0,
            empresa: null,
            nnumero: null,
            excel: null
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.showTrabs = this.showTrabs.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        data.empresa_pesquisa   = event.target.empresa_pesquisa.value
        data.plano              =  event.target.plano.value
        data.date_ini           =  event.target.date_ini.value
        data.date_fim           =  event.target.date_fim.value
        data.mesano_vecto       =  event.target.mesano_vecto.value
        data.nnumero_pesquisa   =  event.target.nnumero_pesquisa.value

        this.state.paramters = "?";
        this.state.paramters += 'empresa_pesquisa=' + data.empresa_pesquisa;
        this.state.paramters += '&plano=' + data.plano;
        this.state.paramters += '&date_ini=' + data.date_ini;
        this.state.paramters += '&date_fim=' + data.date_fim;
        this.state.paramters += '&mesano_vecto=' + data.mesano_vecto;
        this.state.paramters += '&nnumero_pesquisa='+ data.nnumero_pesquisa;

        this.props.action.loadingStatus();

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/personal/lista-empresa-impressoes"+this.state.paramters, {
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'Authorization': self.core.formatAutorizationToken(self.core.getToken())
        },
        method: 'GET'
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            let vlr_impresso = 0
            let qtd_tbldr = 0
            response.content.map ((val, index) => {
                vlr_impresso += parseFloat(val.vlr_boleto)
                qtd_tbldr += parseFloat(val.trabalhadores_declarados)
            })

            self.setState({
                relatorio: response.content,
                vlr_total: vlr_impresso,
                qtd_trabalhadores: qtd_tbldr,
                qtd_total: response.content.length
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    showTrabs(id_empr_blto_impr, razao_social, nosso_numero){
        this.empresa = razao_social;
        this.nnumero = nosso_numero;
        this.setState({
            trabs: []
        })
        let self = this;
        this.props.action.loadingStatus();

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/personal/trabalhadores-sd-impressao?id_empr_blto_impr=" + id_empr_blto_impr, {
        headers: {
            'Authorization': self.core.formatAutorizationToken(self.core.getToken())
        },
        method: 'GET'
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            let trabsres = response.content.map((trab, index) => {
                    return <tr key={index} >
                        <td>{trab.nome}</td>
                        <td>{trab.cpf}</td>
                        <td>{trab.funcao_ocupacional}</td>
                        <td>{trab.tipo}</td>
                        <td>{trab.n_cbo}</td>
                        <td>{trab.local_prestacao_servico}</td>
                        <td>{trab.sindicato}</td>
                    </tr>
                });

            self.setState({
                trabs: trabsres
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        })
    }

    showExcel(){
        console.log("showExcel1");
        this.state.excel = 'sim'
    }
 
    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            let botao1 = null;
            let botao2 = null;
            //console.log(data);
                if (data.cnpj !== ' ') {
                    botao1 = 
                        <td>
                            <button className="btn btn-info" onClick={event => this.showTrabs(data.id_empr_blto_impr, data.razao_social, data.nosso_numero)} data-toggle="modal" data-target="#trabModal">Trabalhadores</button>
                        </td>
                } else {
                    botao1 = 
                        <td>
                        </td>
                }
    
            return <tr key={index} >
                <td>{data.cnpj}</td>
                <td>{data.razao_social}</td>
                <td>{data.placa}</td>
                <td>{data.vencimento_original}</td>
                <td>{data.impressao}</td>
                <td>{data.vlr_boleto}</td>
                <td>{data.nosso_numero}</td>
                <td>{data.codigo_caged}</td>
                <td>{data.data_caged}</td>
                <td>{data.trabalhadores_plano}</td>
                <td>{data.ativos}</td>
                <td>{data.apos12m}</td>
                <td>{data.semdireito}</td>
                <td>{data.total_trabalhadores}</td>
                {botao1}
            </tr>
        });
    }

    render(){
        let tableContent;
        let tableTrabalhadores;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.trabs.length){
            tableTrabalhadores = this.state.trabs;
        }
        else {
            tableTrabalhadores = <tr>
                        <td colSpan={5}>
                            <b> Nenhum Trabalhadore vinculado a esse boleto.</b>
                        </td>
                        </tr>
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={15}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page personal">
            <div className="page-content">
                <h2>Impressões Personal</h2>
                <p>Consulte os boletos impressos pelos empregadores no Sistema de Cobrança Personal</p>

                <div className="modal fade" id="trabModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Trabalhadores do Empregador {this.empresa}, boleto N.Número {this.nnumero}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <table style={{width: 100 + '%'}}>
                                <thead>
                                <tr>
                                    <td>Nome</td>
                                    <td>CPF</td>
                                    <td>Função</td>
                                    <td>Tipo</td>
                                    <td>N. CBO</td>
                                    <td>Local Prestação Serviço</td>
                                    <td>Sindicato</td>
                                </tr>
                                </thead>
                                <tbody>
                                    {tableTrabalhadores}
                                </tbody>
                            </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">FECHAR</button>
                            </div>
                            {/*}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                            </div>
                            */}
                        </div>
                    </div>
                </div>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Selecione o Empregador para a pesquisa das impressões dos boletos de pagamento.
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-1">
                                        <div className="form-group">
                                            <label><small>Plano</small></label>
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label><small>Impressão</small></label>
                                            <InputMask name="date_ini" id="data_ini2" placeholder="Data início" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label><small>Impressão</small></label>
                                            <InputMask name="date_fim" id="data_fim2" placeholder="Data Fim" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label><small>Mes/Ano Vecto</small></label>
                                            <InputMask name="mesano_vecto" id="mesano_vecto" placeholder="Mês/Ano Vencimento" className="form-control" mask="99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label><small>Empresa</small></label>
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Empregador" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label><small>Nosso Número</small></label>
                                            <input name="nnumero_pesquisa" id="nnumero_pesquisa" placeholder="Nosso Número" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <label></label>
                                        <tr>
                                            <button type="Submit" className="btn btn-primary">pesquisar</button>
                                        </tr>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>CNPJ/CPF</td>
                                    <td>Empregador</td>
                                    <td>Plano</td>
                                    <td>Vcto Original</td>
                                    <td>Data Impressao</td>
                                    <td>Vlr Boleto</td>
                                    <td>Nosso Numero</td>
                                    <td>Caged</td>
                                    <td>Dt Caged</td>
                                    <td>Trabalhadores Segmento</td>
                                    <td>Trabalhadores Declarados</td>
                                    <td>Afastados</td>
                                    <td>Sem Direito</td>
                                    <td>Total Trabalhadores</td>
                                    <td>Trabalhadores</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(ImpressoesPersonal))
