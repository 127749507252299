import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class EmpresaBoletosAPOIAR extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            trabs: [],
            qtd_total: 0,
            vlr_total: 0,
            auth: this.core.getAuth(),
            div_permitida: <div></div>,
            empresa: null,
            nnumero: null
            
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.showTrabs = this.showTrabs.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    generateRangeDate(){
        let range = [];

        let now = moment().date(1);
        range.push(moment(now));

        for(let i = 0; i < 5; i++){
            let mes = now.month() -1;
            now.month(mes);

            let date = moment(now);
            range.push(date);
        }

        range = range.map((data, index) => {
            return <option key={index + 1} value={data.format("YYYY-MM-DD")}>
                {data.format("MMMM")}
            </option>
        });

        //adiciona index 0 frase de seleção
        range.splice(0,0, <option key={0} checked value="">Selecione um mês</option>)
        return range;
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }
        data.plano              = null;
        data.empresa_pesquisa   = event.target.empresa_pesquisa.value;
        data.recnum_pesquisa    = event.target.recnum_pesquisa.value;
        data.pesquisa_parcial   = 'nao';
        //data.parceiro_pesquisa  = '14';
        data.parceiro_pesquisa  = '32';

        this.props.action.loadingStatus();

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-boletos-parceiro", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            let vlr_pago = 0
            response.content.map ((val, index) => {
                vlr_pago += parseFloat(val.vlr_pago)
            })
            self.setState({
                relatorio: response.content, 
                qtd_total: response.content.length,
                vlr_total: vlr_pago,
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    showTrabs(id_empr_blto, razao_social, nosso_numero){
        this.empresa = razao_social;
        this.nnumero = nosso_numero;
        this.setState({
            trabs: []
        })
        let self = this;
        this.props.action.loadingStatus();

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/empresas-trabalhadores-boleto-pago?id_empr_blto=" + id_empr_blto
        ,{method: 'GET'}
        )
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            let trabsres = response.content.map((trab, index) => {
                    return <tr key={index} >
                        <td>{trab.cpf}</td>
                        <td>{trab.nome}</td>
                        <td>{trab.sexo}</td>
                        <td>{trab.nascimento}</td>
                        <td>{trab.admissao}</td>
                        <td>{trab.status}</td>
                    </tr>
                });


            self.setState({
                trabs: trabsres
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        })

    }

    showBoleto(token_empr_blto_impr, id_empr_blto_impr){
        let parametros = "invoice/" + token_empr_blto_impr + "?id=" + id_empr_blto_impr;
        window.open(this.core.getUrl() + parametros, 'newwindow', 'width=' + this.width + ',height=' + this.height);
    }


    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            let botao1 = null;
            let botao2 = null;
            let vlr_pago_x = null;
            if (data.cobranca === 'BSFOnline') {
                botao1 = 
                    <td>
                        <button className="btn btn-info" onClick={event => this.showTrabs(data.id_empr_blto, data.razao_social, data.nosso_numero)} data-toggle="modal" data-target="#trabModal">Trabalhadores</button>
                    </td>
                botao2 = 
                    <td>
                        <button className="btn btn-info" onClick={event => this.showBoleto(data.token_impr, data.id_empr_blto_impr)} data-toggle="modal" data-target="#boletoModal">Boleto</button>
                    </td>
            } else {
                botao1 = 
                    <td>
                        ----------x----------
                    </td>
                botao2 = 
                    <td>
                        ----x----
                    </td>
            }
            if (data.vlr_pago === 0) {
                vlr_pago_x = '  '
            } else {
                vlr_pago_x = "R$ " + parseFloat(data.vlr_pago).toFixed(2)
            }

   
            return <tr key={index} >
                <td>{data.id_empr_blto}</td>
                <td>{data.cnpj}</td>
                <td>{data.razao_social}</td>
                <td>{data.placa}</td>
                <td>{data.id_empr_plno}</td>
                <td>{data.vencimento_original}</td>
                <td>{data.pagamento}</td>
                <td style={{textAlign:"right"}}>{vlr_pago_x}</td>
                <td>{data.nosso_numero}</td>
                <td>{data.impressao}</td>
                <td>{data.ativos}</td>
                <td>{data.ate12m}</td>
                <td>{data.apos12m}</td>
                <td>{data.semdireito}</td>
                <td>{data.opositor}</td>
                <td>{data.idademaxima}</td>
                {botao1}
                {botao2}
                <td>{data.complemento}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        let tableTrabalhadores;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.trabs.length){
            tableTrabalhadores = this.state.trabs;
        }
        else {
            tableTrabalhadores = <tr>
                <td colSpan={5}>
                    <b> Nenhum Trabalhador vinculado a esse boleto.</b>
                </td>
                </tr>
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={19}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa.</b>
                    </center>
                </td>
            </tr>
        }
        if(this.state.auth.config.grupo.nome === "administrador") {
            this.state.div_permitida = 
            <table className="table table-striped table-doc">
            <tr>
                <thead>
                    <td>Totais</td>
                    <td>Qtd Pagamentos</td>
                    <td>Valor Total Pago</td>
                </thead>
                <tbody>
                    <td></td>
                    <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                    <td style={{textAlign:"right"}}>R$ {this.state.vlr_total.toLocaleString('de-DE')}</td>
                </tbody>
            </tr>
            </table>
        } else {
            this.state.div_permitida = <div></div>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Boletos Gerados - APOIAR</h2>
                <p>Consulte os Boletos Gerados para a Empresas no Sistema de Cobrança BSFonline</p>

                <div className="modal fade" id="trabModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Trabalhadores da empresa {this.empresa}, boleto N.Número {this.nnumero}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <table style={{width: 100 + '%'}}>
                                <thead>
                                <tr>
                                    <td>CPF</td>
                                    <td>Nome</td>
                                    <td>Sexo</td>
                                    <td>Nascimento</td>
                                    <td>Admissao</td>
                                    <td>Status</td>
                                </tr>
                                </thead>  
                                <tbody>
                                    {tableTrabalhadores}
                                </tbody>
                            </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">FECHAR</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe a Empresa para a Pesquisa.
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Informe a Razao Social ou CNPJ" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="recnum_pesquisa" id="recnum_pesquisa" placeholder="Recnum" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary">pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">
                        {this.state.div_permitida}

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>ID</td>
                                    <td>CNPJ</td>
                                    <td>Empresa</td>
                                    <td>Plano</td>
                                    <td>Empr/Plno</td>
                                    <td>Vecto Original</td>
                                    <td>Data Pagamento</td>
                                    <td style={{textAlign:"right"}}>Vlr Pago</td>
                                    <td>Nosso Numero</td>
                                    <td>Impressao</td>
                                    <td>Ativos</td>
                                    <td>Ate12m</td>
                                    <td>Apos12m</td>
                                    <td>Sem Direito</td>
                                    <td>Opositor</td>
                                    <td>Idade Maxima</td>
                                    <td>Trabalhadores</td>
                                    <td>Boleto</td>
                                    <td>Complemento</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(EmpresaBoletosAPOIAR))
