import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Alert from 'react-s-alert';
import DatePickerTime from 'react-datetime';
import Select from 'react-select';
import $ from 'jquery';

//class
import Core from '../../../class/Core';

//CSS
//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';


class CreateCampanhas extends React.Component {
    constructor(props) {
        super(props)
        this.core = new Core();
        this.core.isAuth();

        this.state = {
            modelos: [],
            planos: [],
            redirect: null,
            selecteds: [],
            selectType: {
                select: true,
                lista: false,
                arquivo: false
            },
            range: {
                num1: 0,
                num2: 0
            }
        };

        this.handlerSubmit = this.handlerSubmit.bind(this);
        this.formatRanger = this.formatRanger.bind(this)
        this.removeSelectedLista = this.removeSelectedLista.bind(this)
        this.typeSelect = this.typeSelect.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this);


    }

    componentDidMount() {
        $('.form-select-lista').hide()
        $('.form-upload-xls').hide()
    }

    componentWillMount() {
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();
        this.props.action.loadingStatus();





        fetch(self.core.getUrl() + self.core.getVersionAPI() + "email/modelos", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            }
        })
            .then(resp => self.core.responseConvert(resp))
            .then((response) => {
                let data = response.content.map((data, index) => {
                    return <option key={index + 1} value={data.id}>{data.assunto}</option>
                });

                data.splice(0, 0, <option key={0} value="">Selecione uma opção</option>);

                self.setState({
                    modelos: data
                });



                self.props.action.loadedStatus();

            });


        fetch(self.core.getUrl() + self.core.getVersionAPI() + "planos", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
        }
        )
            .then(res => self.core.responseConvert(res))
            .then((response) => {

                ///mapeia o valores vindo de planos e faz um sort para ordenaÃ§Ã£o de texto
                let planos = response.content.filter((data, index) => {
                    if (data.id_tr_plno !== 8888 && data.id_tr_plno !== 9999) {
                        return true
                    }
                }).map((data, index) => {

                    let tela_rosa_id = data.id_tr_plno || 0
                    return {
                        value: data.id_plno,
                        label: tela_rosa_id + " - " + data.dscr_plno,
                        id: tela_rosa_id
                    }
                }).sort(function (a, b) {
                    return parseInt(a.id) - parseInt(b.id)
                });

                //adicionando todos os planos como opÃ§Ã£o
                planos.splice(0, 0, {
                    value: "all",
                    label: "TODOS OS PLANOS",
                    id: 0
                })

                this.setState({
                    planos: planos
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err);
                self.props.action.loadedStatus();
            });



    }



    handlerSubmit(event) {
        event.preventDefault();

        let self = this;

        let tipo = event.target.forma_select.value || null

        console.log(tipo);

        if (tipo === 'arquivo') {
            let input = document.querySelector('input[type="file"]');
            let formData = new FormData();

            formData.append('nome_cmpnha', event.target.nome_campanha.value);
            formData.append('fk_eml_modl', event.target.modelo_id.value);
            formData.append('desc_cmpnha', event.target.descricao.value);
            formData.append('arquivo', input.files['0']);
            formData.append('data_envio', event.target.data_envio.value);



            this.props.action.loadingStatus();

            fetch(self.core.getUrl() + self.core.getVersionAPI() + "email/email-campanha", {
                method: 'post',
                headers: {

                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                body: formData

            }
            )
                .then(res => self.core.responseConvert(res))
                .then((response) => {

                    Alert.success("Registro inserido com sucesso!", {
                        position: 'bottom-right',
                        effect: 'slide',
                        timeout: 8000
                    });
                })
                .then(resp => {
                    self.setState({
                        redirect: '/manager/campanhas'
                    });
                })
                .catch((err) => {
                    console.log(err);
                    //  self.core.catchFetch(err);
                    self.props.action.loadedStatus();
                });
        } else {

            /**
             * POR LISTA OU SELECT
             */

            let planos = this.state.selecteds.map((data, index) => {
                return data.value;
            });
            let bodyData = {
                nome_cmpnha: event.target.nome_campanha.value,
                fk_eml_modl: event.target.modelo_id.value,
                desc_cmpnha: event.target.descricao.value,
                planos: planos,
                data_envio: event.target.data_envio.value,
                type_file: 'list-plano'
            }

          
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "email/email-campanha", {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                body: JSON.stringify(bodyData)

            }
            )
                .then(res => self.core.responseConvert(res))
                .then((response) => {

                    Alert.success("Campanha inserida com sucesso!", {
                        position: 'bottom-right',
                        effect: 'slide',
                        timeout: 8000
                    });
                })
                .then(resp => {
                    self.setState({
                        redirect: '/manager/campanhas'
                    });
                })
                .catch((err) => {
                    console.log(err);
                    //  self.core.catchFetch(err);
                    self.props.action.loadedStatus();
                });
        }

    }


    /**
     * pega o tipo do select que o usuario deseja
     */
    typeSelect(e) {
        console.log(e.target)
        if (e.target.value === 'lista') {
            this.setState({
                selectType: Object.assign({}, {
                    select: false,
                    lista: true
                })
            })
            $('.form-select-select').slideUp()
            $('.form-select-lista').slideDown()
            $('.form-upload-xls').slideUp()
        } else if (e.target.value === 'select') {
            this.setState({
                selectType: Object.assign({}, {
                    select: true,
                    lista: false
                })
            })
            $('.form-select-select').slideDown()
            $('.form-select-lista').slideUp()
            $('.form-upload-xls').slideUp()
        } else if (e.target.value === 'arquivo') {
            this.setState({
                selectType: Object.assign({}, {
                    select: false,
                    lista: false,
                    arquivo: true
                })
            })
            $('.form-select-select').slideUp()
            $('.form-select-lista').slideUp()
            $('.form-upload-xls').slideDown()
        }
    }

    /**
     * GET RANGE
     */
    formatRanger() {
        // console.log(this.state.planos)
        if (this.state.range.num1 > this.state.range.num2) {
            Alert.error("Numero Início não pode ser maior que Numero Fim", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 8000
            });
            return false
        }
        let selectsObject = {}
        let selecteds = []


        //paga os selecionados do range
        this.state.planos.forEach((val, index) => {
            if (val.id >= this.state.range.num1 && val.id <= this.state.range.num2) {
                selectsObject[val.id] = val
            }
        })

        //combina o selecionado do range com os ja selecionados
        this.state.selecteds.forEach((val, index) => {
            selectsObject[val.id] = val
        })

        //converte para array os selecionados
        selecteds = Object.keys(selectsObject).map((key, index) => {
            return selectsObject[key]
        })

        this.setState({
            selecteds: selecteds
        })
    }

    removeSelectedLista(e, val) {
        console.warn(val)
        let arr = this.state.selecteds.slice(0)
        let index = arr.indexOf(val)
        arr.splice(index, 1)

        this.setState({
            selecteds: arr
        })
    }

    renderRangeList() {
        return this.state.selecteds.map((val, index) => {
            return <div key={index} className="list-item-planos">
                <span className="remove-planos" onClick={e => this.removeSelectedLista(e, val)}><i className="fa fa-times" aria-hidden="true"></i></span> {val.label}
            </div>
        })
    }


    handlerSelect(valor) {
        this.setState({
            selecteds: valor
        });
    }

    render() {
        if (this.state.redirect !== null) {
            return <Redirect to={this.state.redirect} />;
        }

        if (this.props.redirect.url) {
            return <Redirect to={this.props.redirect.url} />;
        }


        return <div className="page">
            <div className="page-content">
                <h4>Criar campanha</h4>
                {/* exibe  */}
                <div className="panel">
                    <div className="panel-body container-fluid">
                        <form action="#" onSubmit={this.handlerSubmit}>
                            <div className={'col-md-6 row'}>
                                <div className="col-sm-6">

                                    <div className="form-group">
                                        <label>Nome</label>
                                        <input type="text" className='form-control' name="nome_campanha" required />
                                    </div>

                                </div> {/* fim coluna 1*/}


                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Modelo</label>
                                        <select name="modelo_id" className='form-control' required>
                                            {this.state.modelos}
                                        </select>
                                    </div>


                                </div>



                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>Descrição</label>
                                        <textarea name="descricao" cols="30" rows="5" className='form-control'></textarea>
                                    </div>
                                </div>



                                <div className="form-group col-md-12">
                                    <label htmlFor="select-tipo-lista">Forma de Seleção</label>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <input type="radio" checked={this.state.selectType.select} name="forma_select" value="select" onChange={this.typeSelect} /> Seleção Específica
                                                </div>
                                        <div className="col-sm-4">
                                            <input type="radio" checked={this.state.selectType.lista} name="forma_select" value="lista" onChange={this.typeSelect} /> Seleção por Lista
                                                </div>

                                        <div className="col-sm-4">
                                            <input type="radio" checked={this.state.selectType.arquivo} name="forma_select" value="arquivo" onChange={this.typeSelect} /> Seleção arquivo XLS
                                                </div>
                                    </div>
                                </div>


                                <div className="form-select-select col-sm-6">
                                    <div className="form-group">
                                        <label>Selecione os Planos</label>
                                        <Select
                                            name="planos"
                                            multi={true}
                                            value={this.state.selecteds}
                                            options={this.state.planos}
                                            onChange={this.handlerSelect}
                                        />
                                    </div>
                                </div>

                                <div className="form-select-lista col-sm-12">

                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <input
                                                    type="number"
                                                    name="range1"
                                                    value={this.state.range.num1}
                                                    onChange={e => this.setState({
                                                        range: Object.assign(this.state.range, {
                                                            num1: parseInt(e.target.value)
                                                        })
                                                    })}
                                                    placeholder="Num. Início"
                                                    className="form-control" />
                                            </div>
                                            <div className="col-sm-4">
                                                <input
                                                    type="number"
                                                    name="range2"
                                                    className="form-control"
                                                    value={this.state.range.num2}
                                                    onChange={e => this.setState({
                                                        range: Object.assign(this.state.range, {
                                                            num2: parseInt(e.target.value)
                                                        })
                                                    })}
                                                    placeholder="Num. Fim" />
                                            </div>
                                            <div className="col-sm-4">
                                                <button className="btn btn-primary" type="button" onClick={this.formatRanger}>ADICIONAR LISTA</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="lista-selects">
                                            {this.renderRangeList()}
                                        </div>
                                    </div>
                                </div>


                            </div>









                            <div className="form-upload-xls col-sm-12">
                                <h3>Arquivo (xls)</h3>


                                <div className="row">
                              

                                    <div className="col-sm-5">
                                        <label>Arquivo</label>
                                        <div>
                                            <input type="file" placeholder="Arquivo xls" />
                                        </div>
                                    </div>
                                </div>


                            </div>

                          
                                <div className="col-sm-1">
                                    <label>Data de envio</label>
                                    <DatePickerTime inputProps={{ name: 'data_envio' }} />
                                </div>
                            




                            <br />


















                            <div>
                                <div className="col-sm-12">
                                    <button type="submit" className="btn btn-success">salvar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {/*fim*/}
            </div>
        </div>;

    }
}



function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}

export default withRouter(connect(mapStateProps, mapDispatchProps)(CreateCampanhas))