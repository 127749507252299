import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import Alert from 'react-s-alert';
import { isNull } from 'util';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';


class ExcelTrabalhadorPagamento extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();

        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let paramters = "?";
        let plano = event.target.plano.value;
        let empresa_pesquisa = event.target.empresa_pesquisa.value;
        
        let url             = event.currentTarget.getAttribute("action");
        let mesano_vecto    = moment(event.target.mesano_vecto.value, "MM/YYYY");
        let ano_vecto       = moment(event.target.ano_vecto.value, "YYYY");
        let date_ini        = event.target.date_ini.value;
        let date_fim        = event.target.date_fim.value;

        paramters += "pesquisa_parcial=nao";
        paramters += '&token=' + this.core.getToken();

        console.log("Plano:", plano);
        if ( (isNull(plano) || (plano === "")) && (isNull(empresa_pesquisa) || (empresa_pesquisa === "")) ) {
            Alert.error("Plano ou Empresa. devem ser Informados.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 8000
            });
            return false;
        } 

        if(event.target.plano.value !== undefined){
            paramters += "&plano=" + plano;
        } else {
            paramters += "&plano=";
        }
        if(event.target.empresa_pesquisa.value !== undefined){
            paramters += "&empresa_pesquisa=" + empresa_pesquisa;
        } else {
            paramters += "&empresa_pesquisa=";
        }

        if(url === null){
            Alert.error("Problemas de configuração.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 8000
            });
            return false;
        }

        if(event.target.mesano_vecto.value !== undefined && mesano_vecto.isValid()){
            paramters += "&mesano_vecto=" + mesano_vecto.format("MM/YYYY");
        } else {
            paramters += "&mesano_vecto=";
        }

        if(event.target.ano_vecto.value !== undefined && ano_vecto.isValid()){
            paramters += "&ano_vecto=" + ano_vecto.format("YYYY");
        } else {
            paramters += "&ano_vecto=";
        }

        if(event.target.date_ini.value !== undefined){
            paramters += "&date_ini=" + date_ini;
        } else {
            paramters += "&date_ini=";
        }

        if(event.target.date_fim.value !== undefined){
            paramters += "&date_fim=" + date_fim;
        } else {
            paramters += "&date_fim=";
        }

        console.log("Parametros=>", paramters);

        //baixa o relatório
        if(paramters !== "?"){
            window.location.href = url + paramters;
        }else{
            window.location.href = url;
        }

        event.currentTarget.reset();
        return true;
    }
    
    render(){
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        //url dos relatórios
        let urlTrabalhadoresVidaEmGrupo = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-trabalhador-pagamento";

        //return render
        return <div className="page">
            <div className="page-content">
                <h2>Lista de Trabalhadores - Pagamentos (Excel)</h2>
                <p>Gerar em Excel a lista dos trabalhadores relacionados nos pagamentos das Empresas</p>

                {/*relatorio de planos/trabalhadores*/}
                <div className="panel">
                    <h3 className='panel-title'>Lista Trabalhadores Por Pagamento</h3>
                    <div className="panel-body container-fluid">
                        {/* formulario envia para handlerSubmitPlanoTrabalhadores*/}
                        <form action={urlTrabalhadoresVidaEmGrupo} onSubmit={this.handlerSubmit}>
                            <div className="row">
                                <div className="col-sm-1">
                                        <div className="form-group">
                                            <label htmlFor="plano">Plano</label>
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                </div>
                                <div className="col-sm-2">
                                        <label htmlFor="date_ini">Pagamento:<small>(DD/MM/AAAA)</small></label>
                                        <div className="form-group">
                                            <InputMask name="date_ini" id="data_ini" placeholder="Data início" className="form-control" mask="99/99/9999" />
                                        </div>
                                </div>
                                <div className="col-sm-2">
                                        <label htmlFor="date_fim">Pagamento:<small>(DD/MM/AAAA)</small></label>
                                        <div className="form-group">
                                            <InputMask name="date_fim" id="data_fim" placeholder="Data fim" className="form-control" mask="99/99/9999" />
                                        </div>
                                </div>
                                <div className="col-sm-2">
                                        <label htmlFor="ano_vecto">Ano:<small>(AAAA)</small></label>
                                        <div className="form-group">
                                            <InputMask name="ano_vecto" id="ano_vecto" placeholder="Ano Vencimento" className="form-control" mask="9999" />
                                        </div>
                                </div>
                                <div className="col-sm-2">
                                        <label htmlFor="mesano_vecto">Vencimento:<small>(MM/AAAA)</small></label>
                                        <div className="form-group">
                                            <InputMask name="mesano_vecto" id="mesano_vecto" placeholder="Mês/Ano Vencimento" className="form-control" mask="99/9999" />
                                        </div>
                                </div>
                                <div className="col-sm-2">
                                        <div className="form-group">
                                            <label htmlFor="empresa_pesquisa">Empresa</label>
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Todas as Empresas" className="form-control" />
                                        </div>
                                    </div>
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <button className="btn btn-primary margin-btn">GERAR</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> 
                {/*fim*/}
            </div>
        </div>;
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(ExcelTrabalhadorPagamento))