import React from 'react';
import $ from 'jquery';
$.DataTable = require( 'datatables.net' );

class Table extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            head: this.props.head,
            columns: this.props.columns,
            thead: [],
            tbody: [],
            datatable: undefined,
             
        }
    }

    componentWillMount(){
        /**
         * Monta o conteudo do thead
         * @type {Array}
         */
        let title = this.state.head.map((data, index) => {
            return <td key={index}>
                {data}
            </td>;
        });
        let thead = <tr>{title}</tr>;
        let columns = this.state.columns.sort((a, b) => {
            return b.id - a.id
        })

        let tbody = columns.map((data, index) => {
            let td = Object.keys(data).map((t_data, t_index) => {
                return <td key={t_index}>{data[t_data]}</td>;
            });

            return <tr key={index}>{td}</tr>;
        });

        this.setState({
            thead: thead,
            tbody: tbody
        });

    }

    componentDidMount(){
        $('.datatables').DataTable({
            createdRow: this.props.createdRow,
            "ordering": false,
             "iDisplayLength": 100,
            "language": {
                "sEmptyTable": "Nenhum registro encontrado",
                "sInfo": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
                "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
                "sInfoFiltered": "(Filtrados de _MAX_ registros)",
                "sInfoPostFix": "",
                "sInfoThousands": ".",
                "sLengthMenu": "_MENU_ resultados por página",
                "sLoadingRecords": "Carregando...",
                "sProcessing": "Processando...",
                "sZeroRecords": "Nenhum registro encontrado",
                "sSearch": "Pesquisar",
                "oPaginate": {
                    "sNext": "Próximo",
                    "sPrevious": "Anterior",
                    "sFirst": "Primeiro",
                    "sLast": "Último"
                },
                "oAria": {
                    "sSortAscending": ": Ordenar colunas de forma ascendente",
                    "sSortDescending": ": Ordenar colunas de forma descendente"
                }
            }
        });
    }

    updateTable(columns){
        columns = columns.sort((a, b) => {
            return b.id - a.id
        })

        let tbody = columns.map((data, index) => {
            let td = Object.keys(data).map((t_data, t_index) => {
                return data[t_data];
            });
            
            return td;
        });

        return tbody;
    }

    componentWillUpdate(nextProps){

        let rows = this.updateTable(nextProps.columns);
        $('.datatables').DataTable().clear().rows.add(rows).draw();
    }

    render(){
        return <div className={"table-content"}>
            <table className={"table table-striped datatables"}>
                <thead>
                    {this.state.thead}
                </thead>
                <tbody>
                    {this.state.tbody}
                </tbody>
            </table>
        </div>
    }
}

export default Table;
