import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import TinyMCE from "tinymce-react";
import Alert from "react-s-alert";

import DatePicker from "react-datepicker";

//class
import Core from "../../../class/Core";

//actions
import * as UserActions from "../../../actions/UserActions";
import * as ManagerStatusActions from "../../../actions/StatusManagerActions.js";
import * as RedirectActions from "../../../actions/RedirectActions";
import * as StatusActions from "../../../actions/StatusActions";

class CreateEmailModelos extends React.Component {
    constructor(props) {
        super(props);
        this.core = new Core();
        this.core.isAuth();

        this.state = {
            tipos: [],
            contas: [],
            rawContas: [],
            replay: "",
            html_content: null,
            html_content_ghost: null,
            html_notification: null,
            redirect: null,
            noReplay: null,
        };

        this.handlerSubmit = this.handlerSubmit.bind(this);
        this.handlerPreview = this.handlerPreview.bind(this);
        this.showreplay = this.showReplay.bind(this);
        this.handleFileSelect = this.handleFileSelect.bind(this);
        this.exportHTMLContent = this.exportHTMLContent.bind(this);
        this.checkboxReplay = this.checkboxReplay.bind(this);
    }

    componentWillMount() {
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();

        this.props.action.loadingStatus();

        new Promise((resolve, reject) => {
            fetch(
                self.core.getUrl() +
                    self.core.getVersionAPI() +
                    "email/email-tipo",
                {
                    headers: {
                        Accept: "application/json, text/plain, */*",
                        "Content-Type": "application/json",
                        Authorization: self.core.formatAutorizationToken(
                            self.core.getToken()
                        ),
                    },
                }
            )
                .then((res) => self.core.responseConvert(res))
                .then((response) => {
                    let data = response.content.map((data, index) => {
                        return (
                            <option key={index + 1} value={data.id_eml_tipo}>
                                {data.dscr_eml_tipo}
                            </option>
                        );
                    });

                    data.splice(
                        0,
                        0,
                        <option key={0} value="">
                            Selecione uma opção
                        </option>
                    );
                    self.setState({
                        tipos: data,
                    });
                    resolve(true);
                })
                .catch((err) => {
                    self.core.catchFecth(err);
                    self.props.action.loadedStatus();
                });
        }).then((result) => {
            fetch(
                self.core.getUrl() +
                    self.core.getVersionAPI() +
                    "email/email-conta",
                {
                    headers: {
                        Accept: "application/json, text/plain, */*",
                        "Content-Type": "application/json",
                        Authorization: self.core.formatAutorizationToken(
                            self.core.getToken()
                        ),
                    },
                }
            )
                .then((res) => self.core.responseConvert(res))
                .then((response) => {
                    let data = response.content.map((data, index) => {
                        return (
                            <option key={index + 1} value={data.id_cnta_eml}>
                                {data.eml_cnta_eml}
                            </option>
                        );
                    });

                    data.splice(
                        0,
                        0,
                        <option key={0} value="">
                            Selecione uma opção
                        </option>
                    );
                    self.setState({
                        contas: data,
                        rawContas: response.content,
                    });
                    self.props.action.loadedStatus();
                })
                .catch((err) => {
                    self.core.catchFecth(err);
                    self.props.action.loadedStatus();
                });
        });
    }

    handlerSubmit(event) {
        event.preventDefault();
        let self = this;

        let send = {
            fk_eml_tipo: event.target.tipo_email.value,
            fk_cnta_eml: event.target.tipo_conta.value,
            assunto_eml_modl: event.target.assunto.value,
            html_eml_modl: this.state.html_content,
            htmlnot_eml_modl: this.state.html_notification,
            to_eml_modl: event.target.to.value,
            cc_eml_modl: event.target.cc.value,
            cco_eml_modl: event.target.cco.value,
            obs_eml_modl: event.target.obs_eml_modl.value,
            no_reply: this.state.noReplay,
        };

        this.props.action.loadingStatus();
        fetch(
            self.core.getUrl() +
                self.core.getVersionAPI() +
                "email/email-modelo",
            {
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    Authorization: self.core.formatAutorizationToken(
                        self.core.getToken()
                    ),
                },
                method: "POST",
                body: JSON.stringify(send),
            }
        )
            .then((res) => self.core.responseConvert(res))
            .then((response) => {
                Alert.success("Modelo inserido com sucesso!", {
                    position: "bottom-right",
                    effect: "slide",
                    timeout: 8000,
                });
            })
            .then((res) => {
                self.setState({
                    redirect: "/manager/modelos-email",
                });
            })
            .catch((err) => {
                self.core.catchFecth(err);
                self.props.action.loadedStatus();
            });
    }

    handlerPreview(event) {
        event.preventDefault();
        const html = this.state.html_content;

        if (html) {
            const myWindow = window.open(
                "",
                "MsgWindow",
                "width=700,height=600"
            );
            myWindow.document.write(html);
        } else {
            Alert.error("Conteúdo do modelo está vazio!", {
                position: "bottom-right",
                effect: "slide",
                timeout: 8000,
            });
        }
    }

    showReplay(e) {
        if (e.target.value === "" || e.target.value === null) {
            this.setState({
                replay: "",
            });
            return false;
        } else {
            let select = this.state.rawContas.filter((val, index) => {
                return parseInt(e.target.value) === val.id_cnta_eml
                    ? true
                    : false;
            });

            if (select.length === 0) {
                this.setState({
                    replay: "",
                });

                return false;
            }

            this.setState({
                replay: "EMAIL RESPOSTA: " + select[0].replay_cnta_eml,
            });

            return true;
        }
    }

    checkboxReplay(noReplay) {
        let emailResposta = document.getElementsByClassName("replay");

        if (noReplay) {
            emailResposta[0].style.display = "none";
        } else {
            emailResposta[0].style.display = "unset";
        }
        this.setState({
            noReplay: noReplay,
        });
    }

    handleFileSelect(evt) {
        const self = this;
        let files = evt.target.files; // FileList object

        // use the 1st file from the list
        let f = files[0];

        const reader = new FileReader();

        // Closure to capture the file information.
        reader.onload = (function (theFile) {
            return (e) => {
                self.setState({
                    html_content_ghost: e.target.result,
                    html_content: e.target.result,
                });
            };
        })(f);

        // Read in the image file as a data URL.
        reader.readAsText(f);
    }

    exportHTMLContent(e) {
        let html = this.state.html_content;
        let link = document.createElement("a");
        let mimeType = "text/html";

        link.setAttribute("download", "modelo.html");
        link.setAttribute(
            "href",
            "data:" + mimeType + ";charset=utf-8," + encodeURIComponent(html)
        );
        link.click();
    }
    render() {
        if (this.state.redirect !== null) {
            return <Redirect to={this.state.redirect} />;
        }

        if (this.props.redirect.url) {
            return <Redirect to={this.props.redirect.url} />;
        }

        //return render
        return (
            <div className="page">
                <div className="page-content">
                    <h4>Criar Modelo de Email</h4>
                    {/* exibe  */}
                    <div className="panel">
                        <div className="panel-body container-fluid">
                            <form action="#" onSubmit={this.handlerSubmit}>
                                <div className={"row"}>
                                    <div className="col-sm-6">
                                        <div className="internos">
                                            <div className="form-group">
                                                <label>Assunto</label>
                                                <input
                                                    type="text"
                                                    className={"form-control"}
                                                    name="assunto"
                                                    placeholder={"ASSUNTO"}
                                                    required
                                                    maxLength={150}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <div className="internos">
                                            <h5>Configurações</h5>
                                            <div className="form-group">
                                                <label>Tipo de Modelo</label>
                                                <select
                                                    name="tipo_email"
                                                    className="form-control"
                                                    required
                                                >
                                                    {this.state.tipos}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Conta</label>
                                                <select
                                                    name="tipo_conta"
                                                    className="form-control"
                                                    onChange={this.showreplay}
                                                >
                                                    {this.state.contas}
                                                </select>
                                                <div className="replay">
                                                    {this.state.replay}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="checkbox-replay">
                                                    <p>
                                                        Permitir e-mail de
                                                        resposta:
                                                    </p>
                                                    <label>
                                                        Sim
                                                        <input
                                                            type="radio"
                                                            name="permitir-replay"
                                                            value="Sim"
                                                            onClick={(e) =>
                                                                this.checkboxReplay(
                                                                    false
                                                                )
                                                            }
                                                        />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <label>
                                                        Não
                                                        <input
                                                            type="radio"
                                                            name="permitir-replay"
                                                            value="Não"
                                                            onClick={(e) =>
                                                                this.checkboxReplay(
                                                                    true
                                                                )
                                                            }
                                                            checked
                                                        />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <br></br>
                                            </div>
                                        </div>
                                    </div>{" "}
                                    {/* fim coluna 1*/}
                                    <div className="col-sm-6">
                                        <div className="internos">
                                            <h5>Envios Internos</h5>
                                            <div className="form-group">
                                                <label>Para (to)</label>
                                                <input
                                                    type="text"
                                                    className={"form-control"}
                                                    name="to"
                                                    placeholder={"Para"}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Copias (cc)</label>
                                                <input
                                                    type="text"
                                                    className={"form-control"}
                                                    name="cc"
                                                    placeholder={"Copias"}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Copias (cco)</label>
                                                <input
                                                    type="text"
                                                    className={"form-control"}
                                                    name="cco"
                                                    placeholder={"Copias"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>Modelo</label>
                                            <span
                                                style={{
                                                    float: "right",
                                                    marginTop: "-10px",
                                                    marginLeft: 30,
                                                }}
                                            >
                                                <button
                                                    onClick={
                                                        this.exportHTMLContent
                                                    }
                                                    className="btn btn-info"
                                                    type="button"
                                                >
                                                    <i
                                                        className="fa fa-cloud-download"
                                                        aria-hidden="true"
                                                    ></i>{" "}
                                                    Baixar modelo
                                                </button>
                                            </span>

                                            <span
                                                style={{
                                                    float: "right",
                                                    marginTop: "-10px",
                                                }}
                                            >
                                                <button
                                                    onClick={
                                                        this.handlerPreview
                                                    }
                                                    className="btn btn-info"
                                                >
                                                    <i
                                                        className="fa fa-envelope-o"
                                                        aria-hidden="true"
                                                    ></i>{" "}
                                                    visualização prévia
                                                </button>
                                            </span>

                                            <span
                                                style={{
                                                    float: "right",
                                                    marginTop: "-10px",
                                                    marginRight: 30,
                                                }}
                                            >
                                                <span>
                                                    CARREGAR ARQUIVO HTML:
                                                </span>{" "}
                                                <input
                                                    type="file"
                                                    onChange={
                                                        this.handleFileSelect
                                                    }
                                                />
                                            </span>
                                            <TinyMCE
                                                id="value_html"
                                                name="editor"
                                                content={
                                                    this.state
                                                        .html_content_ghost
                                                }
                                                config={{
                                                    height: 500,
                                                    plugins:
                                                        "image imagetools table code link wordcount media paste fullpage",
                                                    image_advtab: true,
                                                    image_caption: true,
                                                    images_upload_url:
                                                        this.core.getUrl() +
                                                        this.core.getVersionAPI() +
                                                        "uploads/images",
                                                    automatic_uploads: false,
                                                    paste_auto_cleanup_on_paste: false,
                                                    file_browser_callback_types:
                                                        "file image media ",
                                                    imagetools_toolbar:
                                                        "rotateleft rotateright | flipv fliph | editimage imageoptions",
                                                    toolbar1:
                                                        "formatselect image link code fullpage | bold italic  strikethrough  forecolor backcolor  | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat",
                                                }}
                                                onContentChanged={(content) =>
                                                    this.setState({
                                                        html_content: content,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <label>Mensagem de notificação</label>
                                        <div className="form-group">
                                            <TinyMCE
                                                id="value_notification"
                                                name="editor"
                                                // apiKey={YOUR_TINYMCE_API_KEY}
                                                config={{
                                                    height: 100,
                                                    plugins:
                                                        "image imagetools table code wordcount media paste",
                                                    image_advtab: true,
                                                    image_caption: true,
                                                    images_upload_url:
                                                        this.core.getUrl() +
                                                        this.core.getVersionAPI() +
                                                        "uploads/images",
                                                    automatic_uploads: false,
                                                    paste_auto_cleanup_on_paste: false,
                                                    file_browser_callback_types:
                                                        "file image media",
                                                    imagetools_toolbar:
                                                        "rotateleft rotateright | flipv fliph | editimage imageoptions",
                                                    toolbar1:
                                                        "formatselect image code | bold italic  strikethrough  forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat",
                                                }}
                                                onContentChanged={(content) =>
                                                    this.setState({
                                                        html_notification:
                                                            content,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <label>Observações</label>
                                        <div className="form-group">
                                            <textarea
                                                name="obs_eml_modl"
                                                className={"form-control"}
                                                id=""
                                                cols="30"
                                                rows="10"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <button
                                            type="submit"
                                            className="btn btn-success"
                                        >
                                            <i
                                                className="fa fa-plus-circle"
                                                aria-hidden="true"
                                            ></i>{" "}
                                            CRIAR MODELO
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/*fim*/}
                </div>
            </div>
        );
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer,
    };
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators(
            {
                ...UserActions,
                ...ManagerStatusActions,
                ...RedirectActions,
                ...StatusActions,
            },
            dispatch
        ),
    };
}

export default withRouter(
    connect(mapStateProps, mapDispatchProps)(CreateEmailModelos)
);
