export const insertEnviosEmails = (data) => {
    return {
        type: 'INSERT_LISTA_ENVIO_EMAILS',
        data: data
    }
}

export const clearEnvioEmails = () => {
    return {
        type: 'CLEAR_LISTA_ENVIO_EMAILS',
    }
}
