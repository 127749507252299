import React,{useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { withRouter, Redirect, Link } from 'react-router-dom';
import moment from 'moment'
import { FaChartLine, FaMapMarkedAlt } from 'react-icons/fa'
import  Table from '../../geral/Table';
import { ModalDialog, ModalContainer } from 'react-modal-dialog-react16'
import Alert from 'react-s-alert';
import ReactSpinner from 'react16-spinjs';
//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';
import Core from 'class/Core';

 

function Reportses(){

    const [modelo, setModelo] = useState([])
    const [email, setEmail] = useState([])
    const [city, setCity] = useState([])
    const [modal, setModal] = useState(false)
    const core = new Core()

    const dispatch = useDispatch()

    useEffect(() => {
        const core = new Core()
        core.isAuth()
        dispatch(ManagerStatusActions.showManager())
        dispatch(RedirectActions.redirectClear())
        dispatch(StatusActions.loadingStatus())

        const response = async() => {
         let result = await   fetch(core.getUrlConecta() + "emails/modelo", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': core.formatAutorizationToken(core.getToken())
                },
            }
        )

        .then(res => core.responseConvert(res))
        .then((response) => {
            let data = response.content.sort( (a,b) => a.id_eml_modl - b.id_eml_modl)
            setModelo(data);
            dispatch(StatusActions.loadedStatus())
        })
        }
        response()

    },[dispatch])


    const handleSubmit  = (e) => {
        e.preventDefault()
       dispatch(StatusActions.loadingStatus())
        fetch(`https://ses.bsfonline.com.br/report/get?date_ini=${e.target.data.value}&modelo=${e.target.modelo.value}`)
       // fetch(`https://ses.bsfonline.com.br/report/get?date_ini=${e.target.data.value}&modelo=${e.target.modelo.value}`)
        .then(res => res.json())
        .then(response =>{
            if(response.content.length > 0){
                setEmail(response.content)
                
            } else {
                Alert.error("Nenhum resultado encontrado.", {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 8000
                });
            }
            dispatch(StatusActions.loadedStatus())
            
        }).catch((err) => {
            core.catchFecth(err);
            dispatch(StatusActions.loadedStatus())
        });
    }

    const handleDownload = (uid, status, name) => {
        dispatch(StatusActions.loadingStatus())
        fetch(`https://ses.bsfonline.com.br/report/download?grupo=${uid}&status=${status}`)
        .then(response => response.blob())
        .then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = name +"_"+ uid + ".csv";
            dispatch(StatusActions.loadedStatus())
            a.click();
        }).catch(err => {
            core.catchFecth(err);
            dispatch(StatusActions.loadedStatus())
        })

    
    }

    const handleRecebidos = (uid) => {
        console.log(uid)
        dispatch(StatusActions.loadingStatus())
        fetch(`https://ses.bsfonline.com.br/report/recebidos?grupo=${uid}`)
        .then(response => response.blob())
        .then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download =  "Recebidos_"+ uid + ".csv";
            dispatch(StatusActions.loadedStatus())
            a.click();
            
        }).catch(err => {
            core.catchFecth(err);
            dispatch(StatusActions.loadedStatus())
        })

    
    }

    const tableCityReport  = (uid) => {
        setCity([])
        setModal(true)
        fetch(`https://ses.bsfonline.com.br/report/city?ses=${uid}`)
          .then(response => response.json())
          .then(res => {
              let sort = res.content.sort((a,b) =>{
                if(a.total > b.total) return -1;
                if(a.total < b.total) return 1;
              })
              setCity(sort)
          }).catch(err => {
            core.catchFecth(err);
            setModal(false)
            StatusActions.loadedStatus();
            core.catchFecth(err);
          })
        
    }
    
    return <>
        <div className="page">
            <div className="page-content">
                <h2>Relatório SES(Amazon)</h2>
                <p></p>

                <div className="panel">
                    <div className='panel-title'>
                    </div>

                    <div className="panel-body container-fluid">
                            <form onSubmit={handleSubmit}>
                        <div className="row">
                         
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Data</label>
                                        <input type="date" name="data" className="form-control" required/>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                        <div className="form-group">
                                            <label>Modelo Email</label> <br/>
                                            <select required name="modelo" id=""
                                                className="form-control">
                                                <option key={0} value="">SELECIONE UM MODELO</option>
                                                {modelo.map((v,i) => (
                                                    <option key={i}  value={v.id_eml_modl}>{v.id_eml_modl} - {v.assunto_eml_modl}</option>
                                                ))}
                                                
                                            </select>
                                        </div>
                                </div>
                                <div className="col-sm-2">
                                    <label style={{ opacity: 0 }}>envio</label> <br/>
                                    <button type="submit" className="btn btn-primary">BUSCAR</button>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped table-doc" style={{ width:'100%' }}>
                                            <thead>
                                            <tr>
                                                <td  align='center'>Modelo</td>
                                                <td  align='center'>Assunto</td>
                                                <td  align='center'>Data</td>
                                                <td  align='center'>Total envios</td>
                                                <td  align='center'>Recebidos</td>
                                                <td  align='center'>Abertos</td>
                                                <td  align='center'>Abertos<br/>(Cidades)</td>
                                                <td  align='center'>Clicados</td>
                                                <td  align='center'>Devoluções</td>
                                                <td  align='center'>Reclamações</td>
                                            </tr>
                                            </thead>
                                                
                                            <tbody>
                                                {!email.length && (
                                                    <tr>
                                                        <td colSpan='10' align="center">Nenhum resultado encontrado</td>
                                                    </tr>
                                                )}
                                                {email.map((v,i) => (
                                                    
                                                    <>
                                                         <tr key={i}>
                                                     <td align='center' style={{verticalAlign:'middle'}}>{v.fk_eml_modl}</td>
                                                     <td align='center' style={{verticalAlign:'middle'}}>{v.assunto_eml_envo}</td>
                                                     <td align='center' style={{verticalAlign:'middle'}}>{moment(v.created_at).format('DD/MM/YYYY')}</td>
                                                     <td align='center' style={{verticalAlign:'middle'}}>{v.total_envios}</td>
                                                     <td align='center' style={{verticalAlign:'middle'}}>{v.total_recebidos} <br/><button className="btn btn-xs btn-info" type="button" onClick={() => handleRecebidos(v.group_uuid_eml_envo)}><FaChartLine/></button></td>
                                                     <td align='center' style={{verticalAlign:'middle'}}>{v.total_open} <br/>
                                                        <button className="btn btn-xs btn-info" type="button" onClick={() => handleDownload(v.group_uuid_eml_envo, 1, 'abertos')}><FaChartLine/></button>
                                                      </td>
                                                      <td align='center' style={{verticalAlign:'middle'}}>{v.total_open_city} <br/><button className="btn btn-xs btn-info" type="button" onClick={() => tableCityReport(v.group_uuid_eml_envo)}><FaMapMarkedAlt/></button></td>
                                                     <td align='center' style={{verticalAlign:'middle'}}>{v.total_click} <br/><button className="btn btn-xs btn-info" type="button" onClick={() => handleDownload(v.group_uuid_eml_envo, 2, 'click')}><FaChartLine/></button></td>
                                                     <td align='center' style={{verticalAlign:'middle'}}>{v.total_bounce} <br/><button className="btn btn-xs btn-info" type="button" onClick={() => handleDownload(v.group_uuid_eml_envo, 0, 'devolucao')}><FaChartLine/></button></td>
                                                     <td align='center' style={{verticalAlign:'middle'}}>{v.total_reclamacao} <br/><button className="btn btn-xs btn-info" type="button" onClick={() => handleDownload(v.group_uuid_eml_envo, 3,'reclamacao')}><FaChartLine/></button></td>
                                                 </tr>

                                               

                                                    </>
                                                ))}
                                                                       
                                            </tbody>
                                        </table>
                                    
                                                  {modal && (
                                                        <ModalContainer>
                                                        <ModalDialog onClose={() => setModal(false)}>
                                                            {city.length > 0 ? (
                                                                <table className="table table-striped">
                                                                <thead>
                                                                    <th>Cidade</th>
                                                                    <th>Região</th>
                                                                    <th>País</th>
                                                                    <th>Total</th>
                                                                </thead>
                                                                <tbody>
                                                                    {city.map((v,i) => {
                                                                        if(v._id !=''){
                                                                            return (
                                                                                <tr key={i}>
                                                                                <td>{v._id}</td>
                                                                                <td>{v.region}</td>
                                                                                <td>{v.country}</td>
                                                                                <td>{v.total}</td>
                                                                            </tr>
                                                                            )
                                                                        }
                                                                    })}
                                                                    
                                                                </tbody>
                                                            </table>
                                                            ): 
                                                                <ReactSpinner color="black"/>
                                                            }
                                                            
                                                        </ModalDialog>
                                                        </ModalContainer>
                                                  )}
                                                    
                                    </div>
                                </div>
                          
                        </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Reportses

