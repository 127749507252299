import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import $ from 'jquery';

import * as StatusActions from '../actions/StatusActions';

import loading from '../images/loading.gif';

class StatusApp extends React.Component {
    constructor(props){
        super(props);
        this.props.action.loadedStatus();
    }

    componentDidUpdate(){
        if(this.props.statusApp){
            $(this.refs.modalSatus).addClass('show-animate animated bounceIn');
        }else{
            $(this.refs.modalSatus).removeClass('show-animate animated bounceIn');
        }
    }

    //loading content
    getContentLoanding(){
        return (<div className='status-loading'>
            <div ref="modalSatus" className="modal-loading">
                <div className="content">
                    <img src={loading} alt="Carregando"/>
                    <h3>Carregando ...</h3>
                </div>
            </div>
        </div>);
    }

    render(){
        let content;
        if(this.props.statusApp){
            content = this.getContentLoanding();
        }else{
            content = <div></div>;
        }
        return content;
    }
}

function mapStateProps(state, props ){
    return {
        statusApp: state.status
    }
}

function mapDispatchProps(dispatch){
    return {
        action: bindActionCreators(StatusActions, dispatch )
    }
}
export default connect(mapStateProps, mapDispatchProps) (StatusApp);
