import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as UserActions from '../../actions/UserActions';
import * as RedirectActions from '../../actions/RedirectActions';
import * as EnvioEmailsActions from '../../actions/EnvioEmailsActions';

class Menu extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            redirect: false
        }

        this.props.action.isAuth();
        this.redirectTo = this.redirectTo.bind(this);
        this.getMenuItem = this.getMenuItem.bind(this);
        this.logoutUser = this.logoutUser.bind(this);
    }

    componentWillMount(){
        this.setState({
            redirect: false
        });
    }

    redirectTo(path){
        console.log(path, window.location.pathname)
        if(window.location.pathname !== path){
            console.log('diferente', this.props.action)
            this.props.action.redirectClear()
            this.props.action.clearEnvioEmails()
            this.props.action.redirectTo(path)
        }
    }

    getMenuItem(){
        let self = this;
        if(this.props.user.config === undefined){
            return <div></div>;
        }
        let dataMenu = this.props.user.config.menu || null;

        //caso não tenha relacionamento para menu retorna vazio para o menu.
        if(dataMenu === null){
            return <div></div>;
        }

        /**
         * Percorre a lista de menu verifica e possui
         * submenu, e faz um novo loop para o sub menu.
         */
        let retorno = dataMenu.map((data, index) =>{
            // sub inicia com false
            let subClass = (data.subMenu)? "has-sub" : "";
            //submenu caso exista
            let sub = <div></div>;
            //seta de mais menu caso tenha
            let arrow = (subClass === "has-sub")? <span className="site-menu-arrow"></span> : <span></span>;

            if(subClass === "has-sub"){
                /**
                 * percore o submenu(valor API) e monta o sub-menu ;)
                 */
                let subResult = data.subMenu.map((subData, subIndex) => {
                    return <li key={ subIndex } className="site-menu-item" onMouseDown={(event) => {self.redirectTo(subData.url)}}>
                        <a className="animsition-link" >
                            <span className="site-menu-title">{ subData.nome }</span>
                        </a>
                    </li>;
                });

                sub = <ul className="site-menu-sub"> { subResult } </ul>;
            }

            //retorna o item para a classe requisitante.
            return <li key={index} className={ "site-menu-item " + subClass } onMouseDown={ (event) => {if(subClass !== "has-sub"){ self.redirectTo(data.url) } } }>
                            <a className="animsition-link">
                                <i className={ "site-menu-icon fa " +  data.icone } aria-hidden="true"></i>
                                <span className="site-menu-title"><div dangerouslySetInnerHTML={{ __html: data.nome }}/></span>
                                { arrow }
                            </a>
                            { sub }
                        </li>;
        });

        return retorno;
    }

    logoutUser(event){
        this.props.action.logoutUser();
        window.location.href = "/";
    }

    getContent(){
        return <div className="site-menubar">
                <div className="site-menubar-body">
                    <div>
                        <div>
                            <ul className="site-menu" data-plugin="menu">
                                <li className="site-menu-category"><br/></li>
                                <li className="site-menu-item" onMouseDown={(event) => this.redirectTo('/manager/dashboard')}>
                                    <a className="animsition-link">
                                        <i className="site-menu-icon md-view-dashboard" aria-hidden="true"></i>
                                        <span className="site-menu-title">Tela Inicial</span>
                                    </a>
                                </li>
                                {this.getMenuItem()}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>;
    }

    render(){
        if(this.props.managerStatus){
            return this.getContent();
        }else{
            return <div></div>;
        }
    }
}

function mapStateToProps(state, props){
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    };
}

function mapDispatchProps(dispatch){
    return {
        action: bindActionCreators({
            ...UserActions,
            ...RedirectActions,
            ...EnvioEmailsActions
        }, dispatch )
    }
}

export default connect(mapStateToProps, mapDispatchProps)(Menu);
