import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';
import  Table from '../../geral/Table';

//class
import Core from '../../../class/Core';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';


class VisualizarEnvioEmails extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.state = {
            head: ['CNPJ', 'RAZÃO SOCIAL', 'ASSUNTO', 'EMAIL', 'STATUS'],
            columns: []
        }

        this.updateData = this.updateData.bind(this);
    }


    componentWillMount(){
        let self = this;
        let uuid = this.props.match.params.uuid;
        this.props.action.showManager();
        this.props.action.redirectClear();

        self.props.action.loadingStatus();

        fetch(self.core.getUrlConecta() + 'emails/grupos/' + uuid,
            {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
            }
        )
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            console.log(response)
            let colunas = response.content.map((data, index) => {

                let status = "";
                if(data.status === "pendente"){
                    status = '<label class="badge badge-default">PENDENTE</label>';
                }else if(data.status === 'falho'){
                    status = '<label class="badge badge-danger">FALHO</label>';
                }else{
                    status = '<label class="badge badge-success">ENVIADO</label>';
                }

                let retorno = {

                    cnpj: data.cnpj,
                    razao: data.razao_social,
                    assunto: data.assunto,
                    email: data.email,
                    status: status
                };

                return retorno;
            });

            self.setState({
                columns: colunas
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    updateData(){

    }

    componentDidMount(){

    }

    render(){
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        //return render
        return <div className="page">
            <div className="page-content">
                <h2>Visualização Lista de Envios</h2>
                <p>Chave: {this.props.match.params.uuid}</p>

                {/* exibe  */}
                <div className="panel">
                    <div className="panel-title">
                        <Link className="btn btn-dark is_link" to={'/manager/envio-emails/create'}>
                            <i className="fa fa-plus-circle" aria-hidden="true"></i> ENVIAR EMAILS
                        </Link>
                        <Link className="btn btn-warning is_link pull-right" to={'/manager/envio-emails'}>
                            <i className="fa fa-undo" aria-hidden="true"></i> VOLTAR
                        </Link>
                    </div>
                    <div className="panel-body container-fluid">
                        <Table
                            head={this.state.head}
                            columns={this.state.columns}
                        />
                    </div>
                </div>
                {/*fim*/}
            </div>
        </div>;
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}

export default withRouter(connect(mapStateProps, mapDispatchProps)(VisualizarEnvioEmails))
