import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class CancelarBoletosPagos extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio_boletos: [],
            relatorio_impressoes: [],
            relatorio_planos: [],
            renderModal: <div></div>,
            auth: this.core.getAuth(),
            div_permitida: <div></div>,
            empresa: null,
            nnumero: null
            
        }

        this.getTableShowBoletos = this.getTableShowBoletos.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        data.plano =  event.target.plano.value;
        data.mesano_vcto = event.target.mesano_vcto.value;
        data.empresa_pesquisa = event.target.empresa_pesquisa.value;
        data.id_boleto_pesquisa = event.target.id_boleto_pesquisa.value;
        data.recnum = event.target.recnum.value;
        data.pesquisa_parcial =  'sim';


        this.props.action.loadingStatus();

        //boletos
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-boletos-estorno", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            let vlr_pago = 0
            response.content.map ((val, index) => {
                vlr_pago += parseFloat(val.vlr_pago)
            })
            self.setState({
                relatorio_boletos: response.content
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });

        //impressoes
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-impressoes-estorno", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.setState({
                relatorio_impressoes: response.content
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });

        {/* planos da empresa */}
        console.log("RAZAO SOCIAL EMPRESA:",this.state.dados_razao_empr);
        console.log("Fetch Plano");
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-planos", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            console.log("Planos=>response.content:", response.content)
            this.state.qtd_planos = 0
            this.state.qtd_planos_ativos = 0
            this.state.qtd_planos_inativos = 0
            response.content.map ((val, index) => {            
                this.state.qtd_planos+=1 
                switch(val.situ_plno){
                    case true:
                        this.state.qtd_planos_ativos+=1;
                        break;
                    case false:
                        this.state.qtd_planos_inativos+=1;
                        break;
                    default:
                        break;
                }
            })
        self.setState({
            relatorio_planos: response.content, 
            qtd_total_planos: response.content.length
        });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });    

    }

    estornarBoleto(prm_id_empr_blto){
        let self=this;
        this.state.paramters = '?id_empr_blto=' + prm_id_empr_blto;
        let urlDesativarBoleto = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/empresa-boleto-desativar"+this.state.paramters;
        fetch(urlDesativarBoleto, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(this.state.paramters)})

        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.refs.formulario.click()
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    ativarBoleto(prm_id_empr_blto){
        let self=this;
        this.state.paramters = '?id_empr_blto=' + prm_id_empr_blto;
        let urlAtivarBoleto = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/empresa-boleto-ativar"+this.state.paramters;
        fetch(urlAtivarBoleto, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(this.state.paramters)})

        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.refs.formulario.click()
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }


    getTableShowBoletos(){
        return this.state.relatorio_boletos.map((data, index) => {
            let botao1 = null;
            let botao2 = null;
            if (data.situacao === 'ativo') {
                botao1 = 
                <td>
                    <button className="btn btn-danger" onClick={event => this.estornarBoleto(data.id_empr_blto)} data-toggle="modal" data-target="#estornarbltoModal">Estornar</button>
                </td>    
            } else {
                botao1 = 
                <td>
                    <button className="btn btn-info" onClick={event => this.ativarBoleto(data.id_empr_blto)} data-toggle="modal" data-target="#ativarbltoModal">Ativar</button>
                </td>    
                
            }

            return <tr key={index} >
                <td>{data.id_empr_blto}</td>
                <td>{data.cnpj}</td>
                <td>{data.razao_social}</td>
                <td>{data.placa}</td>
                <td>{data.id_empr_plno}</td>
                <td>{data.vencimento_original}</td>
                <td>{data.vencimento}</td>
                <td>{data.pagamento}</td>
                <td style={{textAlign:"right"}}>R$ {parseFloat(data.vlr_pago).toFixed(2)}</td>
                <td>{data.nosso_numero}</td>
                <td>{data.complemento}</td>
                <td>{data.situacao}</td>
                {botao1}
            </tr>
        });
    }

    getTableShowImpressoes(){
        return this.state.relatorio_impressoes.map((data, index) => {
            let botao1 = null;
            let botao2 = null;
            //console.log(data);
    
            return <tr key={index} >
                <td>{data.cnpj}</td>
                <td>{data.razao_social}</td>
                <td>{data.placa}</td>
                <td>{data.id_empr_plno}</td>
                <td>{data.vencimento_original}</td>
                <td>{data.impressao}</td>
                <td>{data.vencimento}</td>
                <td>{data.vlr_boleto}</td>
                <td>{data.nosso_numero}</td>
                <td>{data.pagamento}</td>
                <td>{data.vlr_pago}</td>
                <td>{data.id_empr_blto_impr}</td>
                <td>{data.id_empr_blto}</td>
                <td>{data.complemento}</td>
                <td>{data.situacao}</td>
            </tr>
        });
    }
    getTableShowPlanos(){
        //console.log("Planos=>", this.state.relatorio_planos);
        console.log("getTableShowPlanos=>");
        return this.state.relatorio_planos.map((data, index) => {
            let situacao = null;
            let status_tr = null;
            let cobranca_bsfonline = null;
            let oper_senha_tr = null;
            if (data.situ_plno) {
                situacao = "ativo";
            } else {
                situacao = "inativo";
            }
            if (data.emcv_fl_status === 0) {
                status_tr = "ativo";
            } else {
                status_tr = "inativo";
            }
            if (data.conv_fl_cobranca_personal === 0) {
                cobranca_bsfonline = "sim";
            } else {
                if (data.emcv_fl_cobranca_personal === 0) {
                    cobranca_bsfonline = "nao";
                } else {
                    cobranca_bsfonline = "migrada";
                }    
            }
            if (data.emcv_fl_oper_senha === 0) {
                oper_senha_tr = "nao";
            } else {
                oper_senha_tr = "sim";
            }

            return <tr key={index} >
                <td>{String(data.id_plno).padStart(3,"0")}</td>
                <td>{String(data.id_tr_plno).padStart(3,"0")}</td>
                <td>{data.id_empr_plno}</td>
                <td>{data.emrn_cd_recnum}</td>
                <td>{data.dscr_plno}</td>
                <td>{situacao}</td>
                <td>{status_tr}</td>
                <td>{oper_senha_tr}</td>
                <td>{cobranca_bsfonline}</td>
            </tr>
        });

    }


    render(){
        let tableContentBoletos;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio_boletos.length){
            tableContentBoletos = this.getTableShowBoletos();
        }else{
            tableContentBoletos = <tr>
                <td colSpan={12}>
                </td>
            </tr>
        }

        let tableContentImpressoes;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio_impressoes.length){
            tableContentImpressoes = this.getTableShowImpressoes();
        }else{
            tableContentImpressoes = <tr>
                <td colSpan={15}>
                </td>
            </tr>
        }

        let tableContentPlanos;
        if(this.state.relatorio_planos.length){
            console.log('Planos 2=>');
            tableContentPlanos = this.getTableShowPlanos();
        }else{
            console.log('Planos 3=>');
            tableContentPlanos = <tr>
                <td colSpan={9}>  </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Pagamentos - Cancelamento de Boletos Pagos</h2>
                <p>Cancele os boletos pagos pelas empresas (Duplicidade no Plano, Plano Indevido, Estornos</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe o Intervalo de Datas para Pesquisa, demais campos são opcionais.
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="mesano_vcto" id="mesano_vcto" placeholder="Mes/Ano Vencimento" className="form-control" mask="99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Todas as Empresas" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="id_boleto_pesquisa" id="id_boleto_pesquisa" placeholder="Id Boleto" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="recnum" id="recnum" placeholder="Recnum" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" ref="formulario" className="btn btn-primary">pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <h2> Planos </h2>
                                </tr>

                                <tr>
                                    <td>Id</td>
                                    <td>Plano</td>
                                    <td>Empr/Plano</td>
                                    <td>Recnum</td>
                                    <td>Descrição</td>
                                    <td>Situação</td>
                                    <td>Status TR</td>
                                    <td>Operador</td>
                                    <td>BSFOnline</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContentPlanos}
                            </tbody>
                        </table>
                    </div>

                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <h2> Boletos </h2>
                                </tr>
                            </thead>

                            <thead>
                                <tr>
                                    <td>ID</td>
                                    <td>CNPJ</td>
                                    <td>Empresa</td>
                                    <td>Plano</td>
                                    <td>Empr/Plno</td>
                                    <td>Vecto Original</td>
                                    <td>Data Vencimento</td>
                                    <td>Data Pagamento</td>
                                    <td style={{textAlign:"right"}}>Vlr Pago</td>
                                    <td>Nosso Numero</td>
                                    <td>Complemento</td>
                                    <td>Situação</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContentBoletos}
                            </tbody>
                        </table>
                    </div>

                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <h2> Impressões </h2>
                                </tr>
                            </thead>

                            <thead>
                                <tr>
                                    <td>CNPJ</td>
                                    <td>Empresa</td>
                                    <td>Plano</td>
                                    <td>Empr/Plno</td>
                                    <td>Vcto Original</td>
                                    <td>Data Impressao</td>
                                    <td>Data Vencimento</td>
                                    <td>Vlr Boleto</td>
                                    <td>Nosso Numero</td>
                                    <td>Data Pagamento</td>
                                    <td>Vlr Pago</td>
                                    <td>Id Impressao</td>
                                    <td>Id Boleto</td>
                                    <td>Complemento</td>
                                    <td>Situação</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContentImpressoes}
                            </tbody>
                        </table>
                    </div>

                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(CancelarBoletosPagos))
