export const showManager = () => {
    return {
        type: 'STATUS_MANAGER_SHOW',
    }
}

export const hideManager = () => {
    return {
        type: 'STATUS_MANAGER_HIDE',
    }
}
