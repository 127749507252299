import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import moment from 'moment';
import InputMask from 'react-input-mask';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class ComparativoPRAGAS extends React.Component{
    constructor(props){
        super(props);

        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;

        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            trabs: [],
            qtd_total: 0,
            vlr_total: 0,
            qtd_trabalhadores: 0,
            empresa: null,
            nnumero: null,
            contaColuna:0

        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        data.mesano_vecto = event.target.mesano_vecto.value;

        this.state.paramters = "?";
        this.state.paramters += '&tipo=sindpragas';
        this.state.paramters += '&mes=' + data.mesano_vecto;        

        if (this.state.excel != 'sim') {
            this.props.action.loadingStatus();

            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/comparativo"+this.state.paramters, {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'GET',
                //body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                
                self.setState({
                    relatorio: response.content.linhas,
                    qtd_total: response.content.linhas.length,
                    contaColuna:response.content.colunas
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });   
        } else {
            console.log("Chamando Excel!", this.state.paramters);
            if (this.consisteParametros()) {
                console.log("Chamando Excel OK");
                let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-empresa-impressoes-pas"+this.state.paramters;
                window.location.href = urlExcel+'&token='+self.core.getToken();     
            }
        }
    }

    showLista(){
        console.log("showLista1");
        this.state.excel = 'nao'
    }

    showExcel(){
        console.log("showExcel1");
        this.state.excel = 'sim'
    }

    consisteParametros(){
        console.log("consisteParametros");
        //nao tenenhuma regra para essa geracao de excel
        return true;
    }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            let botao1 = null;
            let botao2 = null;
            let contaColuna = 0;
            //console.log(data);
                let row = [];
                data.planos.map((plano) => {
                    //console.log(index);
                    row.push(<td>{plano.cobranca}</td>)
                    row.push(<td>{plano.emprplno}</td>)
                    row.push(<td style={{textAlign:"center"}}>{plano.dtpago}</td>)
                    row.push(<td style={{textAlign:"center"}}>{plano.valorpago}</td>)
                    //row.push(<td>{plano.pago}</td>)
                    contaColuna++;


                        //console.log(plano.pago);
                })

                for(var e=1; e<=(this.state.contaColuna-contaColuna); e++){
                    row.push(<td colSpan={4} style={{textAlign:"center"}}> - X -</td>)
                }

            
                if(contaColuna>this.state.contaColuna){
                    //this.setState({'contaColuna':contaColuna});
                }

            return <tr key={index} >
                <td>{data.cnpj}</td>
                <td>{data.razao}</td>
                <td>{data.id}</td>
                {row}
               
                
            </tr>
        });
    }

    render(){
        let tableContent;
        let tableTrabalhadores;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.trabs.length){
            tableTrabalhadores = this.state.trabs;
        }
        else {
            tableTrabalhadores = <tr>
                        <td colSpan={5}>
                            <b> Nenhum Trabalhador vinculado a esse boleto.</b>
                        </td>
                        </tr>
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={15}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa</b>
                    </center>
                </td>
            </tr>
        }


        let colunaHeader=[];
        for (var i = 1; i <= this.state.contaColuna; i++) {
            colunaHeader.push(<td>Cob</td>)
            colunaHeader.push(<td>Empr/Plno</td>)
            colunaHeader.push(<td>Dt. Pgto</td>)
            colunaHeader.push(<td>Vlr. Pago</td>)

        };

        return <div className="page">
            <div className="page-content">
                <h2>Comparativo de Boletos - SINDPRAG</h2>
                <p>Consulte os boletos em relação BSF</p>

                <div className="modal fade" id="trabModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Trabalhadores da Empresa {this.empresa}, boleto N.Número {this.nnumero}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <table style={{width: 100 + '%'}}>
                                <thead>
                                <tr>
                                    <td>CPF</td>
                                    <td>Nome</td>
                                    <td>Sexo</td>
                                    <td>Nascimento</td>
                                    <td>Admissao</td>
                                    <td>Status</td>
                                </tr>
                                </thead>
                                <tbody>
                                    {tableTrabalhadores}
                                </tbody>
                            </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">FECHAR</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Selecione a empresa para a pesquisa das impressões dos boletos de pagamento.
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                   
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="mesano_vecto" id="mesano_vecto" placeholder="Mes/Ano Vencimento" className="form-control" mask="99/9999" />
                                        </div>
                                    </div>
                                    
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    {/*}
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>
                                    */}
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">
                        {/* formulario envia para handlerSubmitPlanoTrabalhadores*/}
                        <h4>Totais</h4>
                        <table className="table table-striped table-doc">
                            <tr>
                            <thead>                                   
                                    <td>Qtd Diferenças</td>
                                </thead>
                                <tbody>                                    
                                    <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                </tbody>
                            </tr>
                        </table>

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>CNPJ</td>
                                    <td>Empresa</td>
                                    <td>Empr</td>
                                    {colunaHeader}
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(ComparativoPRAGAS))
