import React from 'react';
import Alert from "react-s-alert";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class TrabalhadorEmpresaSemDireito extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            trabs: [],
            qtd_total: 0,
            qtd_sem_direito: 0,
        }
        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        data.empresa_pesquisa = event.target.empresa_pesquisa.value;
        data.trabalhador_pesquisa = event.target.trabalhador_pesquisa.value;
        data.pesquisa_parcial =  'nao';

        this.props.action.loadingStatus();

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-trabalhador-empresa-semdireito", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            console.log("Planos=>response.content:", response.content)
        self.setState({
            relatorio: response.content, 
            qtd_total: response.content.length
        });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            let dt_afastamento = null;
            if (data.fk_tipo_tbldr === 2) {
                dt_afastamento = data.dt_tipo_tbldr;
            }
            return <tr key={index} >
                <td>{String(data.id_tbldr).padStart(7,"0")}</td>
                <td>{data.nome_tbldr}</td>
                <td>{data.cpf_tbldr}</td>
                <td>{data.dt_nasc_tbldr}</td>
                <td>{data.razao_empr}</td>
                <td>{data.cnpj_empr}</td>
                <td>{data.dt_admi_tbldr}</td>
                <td>{data.created_at}</td>
                <td>{data.updated_at}</td>
                <td>{dt_afastamento}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.qtd_total > 0){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={10}>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Trabalhador Sem Direito - Empregadores</h2>
                <p>Consulte os Trabalhadores SEM DIREITO Cadastrados nos Empregadores no Sistema BSFOnline</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                        Informe os parametros necessários para a pesquisa
                        </h3>
                        <div  className="seleciona-trabalhador" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="trabalhador_pesquisa" id="trabalhador_pesquisa" placeholder="Todos os Trabalhadores" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Todos os Empregadores" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-sm-1">
                                        <button type="Submit" ref="formulario" className="btn btn-primary">pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                        
                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                    <td>Totais</td>
                                    <td>Trabalhadores</td>
                                </thead>
                                <tbody>
                                    <td></td>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                </tbody>
                            </tr>
                        </table>

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>Id</td>
                                    <td>Nome</td>
                                    <td>CPF</td>
                                    <td>Dt Nasc</td>
                                    <td>Empregador</td>
                                    <td>CNPJ/CPF</td>
                                    <td>Dt Admissao</td>
                                    <td>Dt Inclusão</td>
                                    <td>Dt Alteração</td>
                                    <td>Dt Afastamento</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(TrabalhadorEmpresaSemDireito))
