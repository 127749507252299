import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';
import  Table from '../../geral/Table';
import moment from 'moment';
import { ModalDialog, ModalContainer } from 'react-modal-dialog-react16'
import io from 'socket.io-client';


//class
import Core from '../../../class/Core';
import * as Helpers from '../../../class/Helpers';
import $ from 'jquery';
import ReactSpinner from 'react16-spinjs';
//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';
import * as EnvioEmailsActions from '../../../actions/EnvioEmailsActions';


class IndexFilasEmails extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.state = {
            head: ['CÓDIGO GRUPO', 'ASSUNTO','DESCRIÇÃO','INFO', 'QTD. PENDENTE',  'DATA'],
            columns: [],
            modelos: [],
            planos:[],
            logs:[],
            relatorio:[],
            isShowingModal: false,
            isShowModalRelatorio: false,
            isLoading: true,
            isLoadingRelatorio: false,
            uuidGrupo: "",
            pesquisa: {
                date_ini: "",
                date_fim: "",
                modelo_id: ""
            }
        }


        this.inputHandleChange = Helpers.inputHandleChange.bind(this)
        this.handleInvalido = this.handleInvalido.bind(this)
        this.handleRead = this.handleRead.bind(this)
        this.handleError = this.handleError.bind(this)
    }


    handleInvalido(e){
        let self = this;
        let uuid = e
        fetch(self.core.getUrlConecta() + 'emails/relatorio/invalidos/' + uuid,{
            headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                    }
        }).then(response => response.blob())
        .then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = uuid + "-invalidos.xlsx";
            a.click();
        })
    }

    handleRead(e){
        let self = this;
        let uuid = e
        fetch(self.core.getUrlConecta() + 'emails/relatorio/emailread/' + uuid,{
            headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                    }
        }).then(response => response.blob())
        .then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = uuid + "-read.xlsx";
            a.click();
        })
    }

    handleError(e){
         let self = this;
        let uuid = e
        fetch(self.core.getUrlConecta() + 'emails/relatorio/errorsend/' + uuid,{
            headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                    }
        }).then(response => response.blob())
        .then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = uuid + "-invalidos.xlsx";
            a.click();
        })
    }



    componentDidMount(){
        let self = this
        let data = window.sessionStorage.getItem("lista_envios")
        if(data){
            data = JSON.parse(data)
            self.props.action.insertEnviosEmails(data.arr)
            this.setState({
                pesquisa: {
                    date_ini: data.date_ini,
                    date_fim: data.date_fim,
                    modelo_id: data.modelo_id
                }
            })
        }
        console.log(self.core.getUrlConecta())
        const socket = io(self.core.getUrlConecta()+'email-queue',{
        	forceNew: true
        });
       	socket.emit('queue-get', true) // chamar ao iniciar
        socket.on('list', response => {
        	   let colunas = response.result.map((data, index) => {

                let per_recebidos = (parseInt(data.total_recebidos, 10)/parseInt(data.total_envios, 10)) * 100;
                let per_falhos = (parseInt(data.total_falhos, 10)/parseInt(data.total_envios, 10)) * 100;
                console.log(data.group_uuid_eml_envo, per_recebidos, per_falhos)

                let progress_bar = `<div class="progressbar-content">
                    <small>Total: `+data.total_envios+`</small>
                </div>`;

                let retorno = {
                    codigo: data.group_uuid_eml_envo,
                    assunto: data.assunto_eml_envo,
                    descricao: data.obs_eml_modl || '',
                    info: `<button class='btn btn-default not-infoEmail'  data-logEmail='${data.group_uuid_eml_envo}'><i class='fa fa-info-circle' aria-hidden='true' data-logEmail='${data.group_uuid_eml_envo}'></i></button>`,
                    status: progress_bar,
                    data: moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format("DD/MM/YYYY")

                };
                return retorno;
            });
        	   this.setState({
        	   	columns: colunas
        	   })

        })
        //Atualizar a cada 5 min
        setInterval(()=>{
        	socket.emit('queue-get', true)
        },300000)


        //log info
        $('body').on('click', '.not-infoEmail', async(e) => {
            //self.props.action.loadingStatus();
            this.setState({
                    isShowingModal: true,
                    isLoading: true
            })
           // let uuid = '3f462be0-ca14-4102-9edc-7b9cb2f8d11d';
            let uuid = $(e.target).attr('data-logEmail');
             fetch(self.core.getUrl() + self.core.getVersionAPI() + 'email/logs/' + uuid, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
        })
            .then(res => res.json())
            .then((response) => {
                this.setState({
                    logs: response.content,
                    isLoading: false
                })
                console.log(this.state)
                 return response;

            })
            .then( resp => {
                self.props.action.loadedStatus();
            })
            .catch(err => {
                   this.setState({
                    isShowingModal: false
                    })
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            })
        })


        $('body').on('click','.not-infoSend', (e) => {
               let uuid = $(e.target).attr('data-SendEmail');
               this.props.action.loadingStatus();
               this.setState({
                   isLoadingRelatorio: true,
                   uuidGrupo: uuid
               })



              fetch(self.core.getUrlConecta() + 'emails/relatorio/error/' + uuid,{
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                    }
              })
              .then(response => response.json())
              .then(resp => {
                 this.setState({
                     relatorio: resp.content,
                     isLoadingRelatorio: false,
                     isShowModalRelatorio: true
                 })
                  this.props.action.loadedStatus();
              })
        })
    }

    componentWillMount(){
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handleClose = () => this.setState({isShowingModal: false})
    handleCloseRelatorio = () => this.setState({isShowModalRelatorio: false})

    render(){

        // console.log("ENVIO LISTA>>", this.props)
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        //return render
        return <div className="page">
            <div className="page-content">
                <table style={{"width":"100%"}} >
                    <tr>
                        <td>
                            <h2>FILA DE E-MAILS</h2>
                            <p>Lista em tempo real.</p>
                        </td>
                        <td style={{textAlign: "right"}}>
                           <Link to="/manager/filas-notificacoes"> <button className="btn btn-info">FILA DE NOTICAÇÕES</button></Link>
                        </td>
                    </tr>
                </table>


                {/* exibe  */}
                <div className="panel">
                    {/*<div className="panel-title"> */}
                       {/* <Link className="btn btn-dark is_link" to={'/manager/envio-emails/create'}>
                            <i className="fa fa-plus-circle" aria-hidden="true"></i> FILAS DE DISPARO
                        </Link>*/}
                        {/*<Link className="btn btn-info" to={'/manager/envio-emails/create'} style={{float: 'right', color: 'white'}}>
                            <i className="fa fa-plus-circle" aria-hidden="true"></i> ENVIAR LISTA EMAILS
                        </Link>*/}
                   {/* </div> */}

                    <div className="panel-body container-fluid">
                        <Table
                            head={this.state.head}
                            columns={this.state.columns}
                        />
                    </div>
                </div>
                {/*fim*/}
            </div>



            <div className="modal fade" id="myModalLog" role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                           <div className="col-sm-12">
                                <button type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title" id="myModalLabel">Responsável: </h4>
                           </div>
                        </div>

                        <div className="modal-body" id="Lista">
                            <div className="col-sm-12">





                            </div>

                        </div>

                    </div>
                </div>
            </div>
            {this.state.isShowModalRelatorio &&
            <ModalContainer>
                { this.state.isLoadingRelatorio ?
                 <ReactSpinner color="white"/> :
                    <ModalDialog onClose={this.handleCloseRelatorio}>
                    <ReactSpinner color="white"/>
                    <h3 style={{textAlign:'center'}}>Relatório de E-mail</h3>
                     <h4 style={{textAlign:'center'}}>Código grupo</h4>
                     <p style={{textAlign:'center'}}>{this.state.uuidGrupo}</p>
                    <hr/>
                    <table>
                    	<tr>
                    		<td>Total</td>
                    		<td>{this.state.relatorio.totalSend}</td>
                    		<td></td>
                    	</tr>

                        <tr>
                            <td style={{paddingRight:50}}>Erro de envio:</td>
                            <td>{this.state.relatorio.totalError}</td>
                            <td style={{paddingLeft:50}}>
                                {(this.state.relatorio.totalError > 0) ?
                                <button onClick={() => this.handleError(this.state.uuidGrupo)}  className='btn btn-success btn-sm'>Download Excel</button>
                                   : ''
                                }
                            </td>
                        </tr>

                        <tr>
                            <td>E-mail inválidos:</td>
                            <td>{this.state.relatorio.emailsInvalid}</td>
                            <td style={{paddingLeft:50}}>
                            {(this.state.relatorio.emailsInvalid > 0) ?
                            <button onClick={() => this.handleInvalido(this.state.uuidGrupo)} className='btn btn-success btn-sm'>Download Excel</button>
                            : ''
                            }

                            </td>
                        </tr>

                          <tr>
                            <td>E-mail lidos:</td>
                            <td>{this.state.relatorio.emailsRead}</td>
                            <td style={{paddingLeft:50}}>
                            {(this.state.relatorio.emailsRead > 0) ?
                            <button onClick={() => this.handleRead(this.state.uuidGrupo)} className='btn btn-success btn-sm'>Download Excel</button>
                            : ''
                            }

                            </td>
                        </tr>
                    </table>

                </ModalDialog>
            }
            </ModalContainer>
            }




            { this.state.isShowingModal &&
        <ModalContainer onClose={this.handleClose}>
          {
             this.state.isLoading ?
             <ReactSpinner color="white"/> :
            <ModalDialog onClose={this.handleClose}>



               { this.state.logs &&

                                    this.state.logs.map(value => {
                                       let user = JSON.parse(value.usuario)
                                       let dados = JSON.parse(value.dados)

                                       let dadosUsuario = (dados.usuario) ? JSON.parse(dados.usuario) : user

                                       let planosObjeto = JSON.stringify(dados.planos)
                                       let planosArray = (planosObjeto)? planosObjeto.replace(/[\[\]]/g,'').split(',') : []
                                       let item =  planosArray.map( val => {
                                                   return  this.state.planos.map(v => {
                                                      if(v.value == Number(val)){
                                                          return <li key={v.id}>{v.label}</li>
                                                      }
                                                   })
                                               })
                                        return (
                                            <div key={value.uuid}>
                                                <h3>LOG DE ENVIO</h3>
                                                    <hr/>
                                                    <p><b>Usuário: </b> {dadosUsuario.nome_user} </p>
                                                    <p><b>Horario disparo: </b> {value.hora} </p>
                                                    <p><b>Código do grupo: </b> {value.uuid} </p>
                                                    {(planosArray.length)? (<div><hr/>
                                                    <h3>DADOS INSERIDOS</h3>
                                                    <p><b>MODELO SELECIONADO: </b> {dados.modelo_id} </p>
                                                    <p><b>TIPO SELECIONADO: </b> {dados.tipo} </p>
                                                    <p><b>PLANOS SELECIONADOS:</b> </p>
                                                    <ul>
                                                        {item}
                                                    </ul></div>) : ''}



                                            </div>
                                            )
                                })}

            </ModalDialog>
          }
        </ModalContainer>}


        </div>;
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer,
        envioEmails: state.envioEmails
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions,
            ...EnvioEmailsActions
        }, dispatch)
    }
}

export default withRouter(connect(mapStateProps, mapDispatchProps)(IndexFilasEmails))
