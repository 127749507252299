import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class TrabalhadorConecta
 extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            qtd_total: 0,
            paramters: null,
            critica: 'nao',            
            excel: 'nao',            
            exceltipo: '0',            
            auth: this.core.getAuth()            
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        data.pesquisa_parcial       = 'sim';
        data.plano                  = event.target.plano.value;
        data.empresa_pesquisa       = event.target.empresa_pesquisa.value;
        data.trabalhador_pesquisa   = event.target.trabalhador_pesquisa.value;
        data.telefone_pesquisa      = event.target.telefone_pesquisa.value;
   
        this.state.paramters = "?";
        this.state.paramters += 'pesquisa_parcial=' + data.pesquisa_parcial;
        this.state.paramters += '&empresa_pesquisa=' + data.empresa_pesquisa;
        this.state.paramters += '&trabalhador_pesquisa=' + data.trabalhador_pesquisa;
        this.state.paramters += '&plano=' + data.plano;
        this.state.paramters += '&telefone_pesquisa=' + data.telefone_pesquisa;
        this.state.paramters += '&token=' + this.core.getToken();

        console.log("Fetch");
        if (this.state.excel != 'sim') {
            this.props.action.loadingStatus();
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-trabalhador-plataforma", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                console.log("Planos=>response.content:", response.content)
                response.content.map ((val, index) => {
                })
        
            self.setState({
                relatorio: response.content,
                qtd_total: response.content.length
            });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });   
        } 
        if (this.state.excel === 'sim') {
            let urlExcel = null;
            switch (this.state.exceltipo) {
                case "1":
                    console.log("Chamando Excel1:");
                    urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-trabalhador-plataforma"+this.state.paramters;
                    window.location.href = urlExcel   
                    break;     

                case "2":
                    console.log("Chamando Excel22:");
                    urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-trabalhador-plataforma2"+this.state.paramters;
                    window.location.href = urlExcel   
                    break;     
    
                case "3":
                    console.log("Chamando Excel3:");
                    urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-trabalhador-plataforma3"+this.state.paramters;
                    window.location.href = urlExcel     
                    break;          
            } 
        }
    }

    showLista(){
        console.log("showLista1");
        this.setState({
             excel: 'nao'
        });
    }

    showExcel(){
        console.log("showExcel1");
        this.setState({
             excel: 'sim'
            ,exceltipo: '1'
        });
    }

    showExcel2(){
        console.log("showExcel2");
        this.setState({
             excel: 'sim'
            ,exceltipo: '2'
        });
    }

    showExcel3(){
        console.log("showExcel3");
        this.setState({
            excel: 'sim'
            ,exceltipo: '3'
        });
    }

    getTableShow(){
        console.log("getTableShow=>");
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.id_tbldr}</td>
                <td>{data.nome_tbldr}</td>
                <td>{data.tel}</td>
                <td>{data.eml}</td>
                <td>{data.doc}</td>
                <td>{data.code_sms}</td>
                <td>{data.dtt_vldt}</td>
                <td>{data.plano}</td>
                <td>{data.razao_empr}</td>
                <td>{data.cnpj_empr}</td>
                <td>{data.uf}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        let botao2 = null;  
        let botao3 = null;  
        if(this.state.auth.email === "d.sis@beneficiosocial.com.br") {
            botao2 =    
            <div className="col-sm-1">
                <button type="submit" className="btn btn-warning" onClick={event =>{  this.showExcel2();}} data-toggle="modal" data-target="#excelModal">Excel</button>
            </div>

            botao3 =    
            <div className="col-sm-1">
                <button type="submit" className="btn btn-danger" onClick={event =>{  this.showExcel3();}} data-toggle="modal" data-target="#excelModal">Excel</button>
            </div>

        } else {
            botao2 = <td></td>
            botao3 = <td></td>
        }


        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={11}>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Conecta - Trabalhador</h2>
                <p>Trabalhadores que Instalaram e Validaram o Aplicativo BSF Online</p>

                <div className="panel">
                    <div className='panel-title'>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="trabalhador_pesquisa" id="trabalhador_pesquisa" placeholder="Todos os Trabalhadores" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Todas as Empresas" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="telefone_pesquisa" id="telefone_pesquisa" placeholder="Telefone" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>
                                    {botao2}
                                    {botao3}
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panel-body container-fluid">

                            <table className="table table-striped table-doc">
                                <tr>
                                    <thead>
                                        <td>Totais</td>
                                        <td>Trabalhadores</td>
                                    </thead>
                                    <tbody>
                                        <td></td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                    </tbody>
                                </tr>
                            </table>

                            <table className="table table-striped table-doc">
                                <thead>
                                    <tr>
                                        <td>ID</td>
                                        <td>Nome</td>
                                        <td>Telefone</td>
                                        <td>Email</td>
                                        <td>Documento</td>
                                        <td>Code SMS</td>
                                        <td>Data Validacao</td>
                                        <td>Plano</td>
                                        <td>Razao Social</td>
                                        <td>CNPJ</td>
                                        <td>UF</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableContent}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(TrabalhadorConecta
))
