import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';
import Table from '../../geral/Table';
import $ from 'jquery';


//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';
import Core from '../../../class/Core';

class IndexCampanhas extends React.Component {
    constructor(props) {
        super(props)
        this.core = new Core();
        this.core.isAuth();
        this.state = {
            head: ['ID', "NOME", 'MODELO', 'DATA', 'AÇÃO'],
            columns: [],
            redirect: null
        }
    }

    //Carrega antes do render
    componentWillMount() {
        // let self = this;
        // this.props.action.showManager();
        // this.props.action.redirectClear();
        // this.props.action.loadingStatus();
        // self.props.action.loadedStatus();

        this.props.action.loadingStatus();
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();




        fetch(self.core.getUrl() + self.core.getVersionAPI() + "email/email-campanha", {
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
        }
        )
            .then(res => self.core.responseConvert(res))
            .then(response => {
                let data = response.content.map((data, index) => {

                    return {
                        id: data.id_cmpnha,
                        nome: data.nome_cmpnha,
                        modelo: data.assunto_eml_modl,
                        data: data.created_at,
                        acao: "<a href='/manager/campanhas/edit/" + data.id_cmpnha + "' class='btn btn-sm btn-info'>Editar</a> <button class='btn btn-sm btn-danger btn-remove-item' data-id='" + data.id_cmpnha + "'>Remover</button>"
                    }

                });




                self.setState({
                    columns: data

                });
            })
            .catch((err) => {
                self.core.catchFetch(err);
                self.props.action.loadedStatus();
            });

    }



    changeContent() {
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();
        this.props.action.loadingStatus();


        // self.props.action.loadedStatus();




        fetch(self.core.getUrl() + self.core.getVersionAPI() + "email/email-campanha", {
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
        }
        )
            .then(res => self.core.responseConvert(res))
            .then(response => {
                let data = response.content.map((data, index) => {

                    return {
                        id: data.id_cmpnha,
                        nome: data.nome_cmpnha,
                        modelo: data.assunto_eml_modl,
                        data: data.created_at,
                        acao: "<a href='/manager/campanhas/edit/" + data.id_cmpnha + "' class='btn btn-sm btn-info '>Editar</a> <button class='btn btn-sm btn-danger btn-remove-item' data-id='" + data.id_cmpnha + "'>Remover</button>"
                    }

                });

                self.setState({
                    columns: data
                });
            })
            .catch((err) => {
                self.core.catchFetch(err);
                self.props.action.loadedStatus();
            });
    }


    // Deletar

    componentDidMount() {
        let self = this;
        $('body').on('click', '.btn-remove-item', (e) => {
            let id = $(e.target).attr('data-id');
            let result = window.confirm("Deseja remover esse registro?");
            if (result) {
                fetch(self.core.getUrl() + self.core.getVersionAPI() + "email/email-campanha/" + id, {
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                    },
                    method: 'DELETE'
                }
                )
                    .then(res => self.core.responseConvert(res))
                    .then((response) => {
                        self.changeContent();
                        self.props.action.loadedStatus();
                    })
                    .catch((err) => {
                        self.core.catchFecth(err)
                        self.props.action.loadedStatus();
                    });
            }
        });


    }



    render() {


        if (this.props.redirect.url) {
            return <Redirect to={this.props.redirect.url} />;
        }



        //return render
        return <div className="page">
            <div className="page-content">
                <h2>Campanhas</h2>
                <p>Gerêncie e crie campanhas.</p>

                {/* exibe  */}
                <div className="panel">
                    <div className="panel-title">
                        <Link className="btn btn-dark is_link" to={'/manager/campanhas/create'}>
                            <i className="fa fa-plus-circle" aria-hidden="true"></i> CRIAR CAMPANHA
                        </Link>
                    </div>
                    <div className="panel-body container-fluid">
                        <Table
                            head={this.state.head}
                            columns={this.state.columns}
                        />
                    </div>
                </div>
                {/*fim*/}
            </div>
        </div>;
    }

}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}

export default withRouter(connect(mapStateProps, mapDispatchProps)(IndexCampanhas));