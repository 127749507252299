import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class Analise3Plano extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            diferencas_tr: [],
            diferencas_bsf: [],
            duplicidades_bsf: [],
            qtd_empresas_tr: 0,
            qtd_empresas_bsf: 0
        }

        this.getTableShow1 = this.getTableShow1.bind(this);
        this.getTableShow2 = this.getTableShow2.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
            plano_pesquisa : event.target.plano.value
        }
    
        this.props.action.loadingStatus();

        console.log("Fetch");
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-planos-analise3", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            console.log("Planos=>response.content:", response.content)
    
        self.setState({
            relatorio: response.content,
            qtd_empresas_tr: response.content[0].qtd_empresas_tr,
            qtd_empresas_bsf: response.content[0].qtd_empresas_bsf,
            diferencas_tr: response.content[0].diferencas_tr,
            diferencas_bsf: response.content[0].diferencas_bsf,
            duplicidades_bsf: response.content[0].duplicidades_bsf
        });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        }); 
    }

    getTableShow1(){
        console.log("getTableShow1=>");
        return <tr>
            <td>{String(this.state.qtd_empresas_tr).padStart(5,"0")}</td>
            <td>{String(this.state.qtd_empresas_bsf).padStart(5,"0")}</td>
        </tr>
    }

    getTableShow2(){
        console.log("getTableShow2=>");
        console.log("this.state.diferencas_tr=>", this.state.diferencas_tr);
        return this.state.diferencas_tr.map((data, index) => {
            return <tr key={index} >
                <td>{String(data.empresa).padStart(14,"0")}</td>
            </tr>
        });
    }

    getTableShow3(){
        console.log("getTableShow3=>");
        console.log("this.state.diferencas_bsf=>", this.state.diferencas_bsf);
        return this.state.diferencas_bsf.map((data, index) => {
            return <tr key={index} >
                <td>{String(data.empresa).padStart(14,"0")}</td>
            </tr>
        });
    }

    getTableShow4(){
        console.log("getTableShow4=>");
        console.log("this.state.duplicidades_bsf=>", this.state.duplicidades_bsf);
        return this.state.duplicidades_bsf.map((data, index) => {
            return <tr key={index} >
                <td>{String(data.empresa).padStart(14,"0")}</td>
            </tr>
        });
    }

    render(){
        let tableContent1;
        let tableContent2;
        let tableContent3;
        let tableContent4;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent1 = this.getTableShow1();
            tableContent2 = this.getTableShow2();
            tableContent3 = this.getTableShow3();
            tableContent4 = this.getTableShow4();
        }else{
            tableContent1 = <tr>
                <td colSpan={6}> </td>
            </tr>
            tableContent2 = <tr>                
                <td colSpan={1}> </td>
            </tr>
            tableContent3 = <tr>                
            <td colSpan={1}> </td>
        </tr>
            tableContent4 = <tr>                
            <td colSpan={1}> </td>
        </tr>
}

        return <div className="page">
            <div className="page-content">
                <h2>Planos - Análise Individual de Plano - Comparativo de Empresas</h2>
                <p>Compare as Empresas Ativas nos Planos entre os Sistemas Tela Rosa e BSFOnline</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe o Plano para a comparação
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary" >pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panel-body container-fluid">
                            <table className="table table-striped table-doc">
                                <thead>
                                    <tr>
                                        <h3> Empresas do Plano </h3>
                                    </tr>
                                    <tr>
                                        <td>Empresas Tela Rosa</td>
                                        <td>Empresas BSFOnline</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableContent1}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panel-body container-fluid">
                            <table className="table table-striped table-doc">
                                <thead>
                                    <tr>
                                        <h3> Diferenças Encontradas Tela Rosa -> BSF </h3>
                                    </tr>
                                    <tr>
                                        <td>CNPJ</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableContent2}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panel-body container-fluid">

                            <table className="table table-striped table-doc">
                                <thead>
                                    <tr>
                                        <h3> Diferenças Encontradas BSF -> Tela Rosa </h3>
                                    </tr>
                                    <tr>
                                        <td>CNPJ</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableContent3}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panel-body container-fluid">

                            <table className="table table-striped table-doc">
                                <thead>
                                    <tr>
                                        <h3> Duplicidades BSF </h3>
                                    </tr>
                                    <tr>
                                        <td>CNPJ</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableContent4}
                                </tbody>
                            </table>

                        </div>
                    </div>

                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(Analise3Plano))
