import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as StatusActions from '../../actions/StatusActions';


class Footer extends React.Component{
    getContent(){
        return <footer className="site-footer">
            <div className="site-footer-legal">© 2017 BENEFÍCIO SOCIAL FAMILIAR</div>
        </footer>;
    }

    render(){
        if(this.props.managerStatus){
            return this.getContent();
        }else{
            return <div></div>;
        }
    }
}

function mapStateProps(state, props ){
    return {
        statusApp: state.status,
        managerStatus: state.managerStatus
    }
}

function mapDispatchProps(dispatch){
    return {
        action: bindActionCreators(StatusActions, dispatch )
    }
}
export default connect(mapStateProps, mapDispatchProps)(Footer);