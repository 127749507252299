import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';
import { exists } from 'fs';
import { isNull } from 'util';

class ConsultaEmpresa extends React.Component{
    constructor(props){
        super(props);

        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;

        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio_empresa: [],
            relatorio_planos: [],
            relatorio_operadores: [],
            relatorio_impressoes: [],
            relatorio_trabalhadores: [],
            renderModal: <div></div>,
            selecao: {},
            trabs: [],
            empresa: null,
            nnumero: null,
            /* totais trabalhadores */
            qtd_trabalhadores: 0,
            qtd_ativos: 0,
            qtd_demitidos: 0,
            qtd_afastados: 0,
            qtd_opositores: 0,
            /* totais planos */
            qtd_planos: 0,
            qtd_planos_ativos: 0,
            qtd_planos_inativos: 0,
            /* totais operadores */
            qtd_operadores: 0,
            qtd_operadores_ativos: 0,
            qtd_operadores_pendentes: 0,
            /* dados empresa */
            dados_cnpj_empr_data: null,
            dados_cnpj_empr: null,
            dados_razao_empr: null,
            dados_nome_fantasia: null,
            dados_id_empr: null,
            dados_eml_cobr_empr: null,
            dados_endereco_empr: null,
            dados_bairro_empr: null,
            dados_cidade_empr: null,
            dados_uf_empr: null,
            dados_cep_empr: null
        }

        this.getTableShowEmpresa1 = this.getTableShowEmpresa1.bind(this);
        this.getTableShowEmpresa2 = this.getTableShowEmpresa2.bind(this);
        this.getTableShowPlanos = this.getTableShowPlanos.bind(this);
        this.getTableShowOperadores = this.getTableShowOperadores.bind(this);
        this.getTableShowBoletos = this.getTableShowBoletos.bind(this);
        this.getTableShowTabalhadores = this.getTableShowTrabalhadores.bind(this);
        this.showTrabs = this.showTrabs.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        console.log("handlerSubmit=>");
        event.preventDefault();
        let self = this;

        let data = {
            empresa_pesquisa: event.target.empresa_pesquisa.value,
            pesquisa_parcial: 'nao',
            recnum: null,
            recnum_pesquisa: null
        }

        this.props.action.loadingStatus();

        {/* dados empresa */}
        console.log("Fetch Empresa");
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/empresa-dados-cadastrais", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            console.log("Empresas=>response.content1:", response.content)
            this.state.dados_cnpj_empr = response.content.cnpj_empr
            this.state.dados_razao_empr = response.content.razao_empr
            this.state.dados_nome_fantasia = response.content.fant_empr
            this.state.dados_id_empr = response.content.id_empr
            this.state.dados_eml_cobr_empr = response.content.eml_cobr_empr
            this.state.dados_endereco_empr = response.content.endr_empr
            this.state.dados_bairro_empr = response.content.brro_empr
            this.state.dados_cidade_empr = response.content.cide_empr
            this.state.dados_uf_empr = response.content.uf_empr
            this.state.dados_cep_empr = response.content.cep_empr

        self.setState({
        });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });    
        console.log("Empresas=>Razao:", this.state.dados_razao_empr);

        {/* planos da empresa */}
        console.log("RAZAO SOCIAL EMPRESA:",this.state.dados_razao_empr);
        console.log("Fetch Plano");
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-planos", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            console.log("Planos=>response.content:", response.content)
            this.state.qtd_planos = 0
            this.state.qtd_planos_ativos = 0
            this.state.qtd_planos_inativos = 0
            response.content.map ((val, index) => {            
                this.state.qtd_planos+=1 
                switch(val.situ_plno){
                    case true:
                        this.state.qtd_planos_ativos+=1;
                        break;
                    case false:
                        this.state.qtd_planos_inativos+=1;
                        break;
                    default:
                        break;
                }
            })
        self.setState({
            relatorio_planos: response.content, 
            qtd_total_planos: response.content.length
        });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });    
        console.log("Planos=>relatorio_planos:", this.state.relatorio_planos);    
        
        {/* operadores da empresa */}
        console.log("Fetch Operadores");
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-operadores", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            this.state.qtd_operadores = 0
            this.state.qtd_operadores_ativos = 0
            this.state.qtd_operadores_pendentes = 0
            response.content.map ((val, index) => {
                this.state.qtd_operadores+=1 
                switch(val.situacao_operador){
                    case "ativo":
                        this.state.qtd_operadores_ativos+=1;
                        break;
                    case "pendente":
                        this.state.qtd_operadores_pendentes+=1;
                        break;
                    default:
                        break;
                }
            })
            
            self.setState({
                relatorio_operadores: response.content
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });        

        {/* boletos da empresa */}
        console.log("Fetch Boletos");

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-boletos", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.setState({
                relatorio_impressoes: response.content
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });

        {/* Trabalhadores Empresa */}
        console.log("Fetch Trabalhadores");
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-trabalhador-empresa", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            let qtd_trabalhadores1 = 0
            let qtd_trabalhadores2 = 0
            this.state.qtd_ativos = 0
            this.state.qtd_demitidos = 0
            this.state.qtd_afastados = 0
            this.state.qtd_opositores = 0
            response.content[0].lista_trabalhadores.map ((val, index) => {
                qtd_trabalhadores1+=1 
                switch(val.fk_tipo_tbldr){
                    case 1:
                        this.state.qtd_ativos+=1;
                        break;
                    case 2:
                        this.state.qtd_afastados+=1;
                        break;
                    case 3:
                        this.state.qtd_demitidos+=1;
                        break;
                    default:
                        break;
                }
                if (val.opst_tbldr) {
                    this.state.qtd_opositores+=1;                    
                }
            })
            self.setState({
                relatorio_trabalhadores: response.content[0].lista_trabalhadores, 
                qtd_trabalhadores2: response.content.length,
                qtd_trabalhadores: qtd_trabalhadores1
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    showTrabs(id_empr_blto_impr, razao_social, nosso_numero){
        console.log("showTrabs=>");
        this.empresa = razao_social;
        this.nnumero = nosso_numero;
        this.setState({
            trabs: []
        })
        let self = this;
        this.props.action.loadingStatus();

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/empresas-trabalhadores-boleto-impresso?id_empr_blto_impr=" + id_empr_blto_impr
        ,{method: 'GET'}
        )
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            let trabsres = response.content.map((trab, index) => {
                    return <tr key={index} >
                        <td>{trab.cpf}</td>
                        <td>{trab.nome}</td>
                        <td>{trab.sexo}</td>
                        <td>{trab.nascimento}</td>
                        <td>{trab.admissao}</td>
                        <td>{trab.status}</td>
                    </tr>
                });

            self.setState({
                trabs: trabsres
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        })
    }

    showBoleto(token_empr_blto_impr, id_empr_blto_impr){
        console.log("showBoleto=>");
        let parametros = "invoice/" + token_empr_blto_impr + "?id=" + id_empr_blto_impr;
        window.open(this.core.getUrl() + parametros, 'newwindow', 'width=' + this.width + ',height=' + this.height);
    }

    getTableShowEmpresa1(){
        console.log('getTableShowEmpresa1=>');
        return  <tr>
                <td>{String(this.state.dados_id_empr).padStart(5,"0")}</td>
                <td>{String(this.state.dados_cnpj_empr).padStart(14,"0")}</td>
                <td>{this.state.dados_nome_fantasia}</td>
                <td>{this.state.dados_razao_empr}</td>
                <td>{this.state.dados_eml_cobr_empr}</td>
        </tr> 
    }

    getTableShowEmpresa2(){
        console.log('getTableShowEmpresa2=>');
        return  <tr>
                <td>{this.state.dados_endereco_empr}</td>
                <td>{this.state.dados_bairro_empr}</td>
                <td>{this.state.dados_cep_empr}</td>
                <td>{this.state.dados_cidade_empr}</td>
                <td>{this.state.dados_uf_empr}</td>
        </tr> 
    }

    getTableShowPlanos(){
        //console.log("Planos=>", this.state.relatorio_planos);
        console.log("getTableShowPlanos=>");
        return this.state.relatorio_planos.map((data, index) => {
            let situacao = null;
            let status_tr = null;
            let cobranca_bsfonline = null;
            let oper_senha_tr = null;
            if (data.situ_plno) {
                situacao = "ativo";
            } else {
                situacao = "inativo";
            }
            if (data.emcv_fl_status === 0) {
                status_tr = "ativo";
            } else {
                status_tr = "inativo";
            }
            if (data.conv_fl_cobranca_personal === 0) {
                cobranca_bsfonline = "sim";
            } else {
                if (data.emcv_fl_cobranca_personal === 0) {
                    cobranca_bsfonline = "nao";
                } else {
                    cobranca_bsfonline = "migrada";
                }    
            }
            if (data.emcv_fl_oper_senha === 0) {
                oper_senha_tr = "nao";
            } else {
                oper_senha_tr = "sim";
            }

            return <tr key={index} >
                <td>{String(data.id_plno).padStart(3,"0")}</td>
                <td>{String(data.id_tr_plno).padStart(3,"0")}</td>
                <td>{data.id_empr_plno}</td>
                <td>{data.dscr_plno}</td>
                <td>{situacao}</td>
                <td>{status_tr}</td>
                <td>{oper_senha_tr}</td>
                <td>{cobranca_bsfonline}</td>
                <td>{data.data_migracao}</td>
                <td>{data.usuario}</td>
            </tr>
        });

    }

    getTableShowOperadores(){
        console.log("getTableShowOperadores=>");
        return this.state.relatorio_operadores.map((data, index) => {
            let botao1 = null;
            if (data.situacao_operador === 'ativo') {
                botao1 =                    
                    <button type="button" className="btn btn-danger"  onClick={event =>{  this.desativarOperador(data.cnpj, data.cpf_operador, data.id_empr, data.id_empr_oprdr)}} data-toggle="modal" data-target="#btn1Modal">Desativar</button>
            } else {
                botao1 = 
                    <button type="button" className="btn btn-info"  onClick={event =>{  this.ativarOperador(data.cnpj, data.cpf_operador, data.id_empr, data.id_empr_oprdr)}} data-toggle="modal" >Ativar</button>
            }

            return <tr key={index} >
                <td>{data.nome_operador}</td>
                <td>{data.cpf_operador}</td>
                <td>{data.email_operador}</td>
                <td>{data.situacao_operador}</td>
            </tr>
        });

    }

    getTableShowBoletos(){
        console.log("getTableShowBoletos=>");
        return this.state.relatorio_impressoes.map((data, index) => {
            let botao1 = null;
            let botao2 = null;
            let vlr_pago_x = null;
                if (data.cobranca === 'BSFOnline') {
                    botao1 = 
                        <td>
                            <button className="btn btn-info" onClick={event => this.showTrabs(data.id_empr_blto_impr, data.razao_social, data.nosso_numero)} data-toggle="modal" data-target="#trabModal">Trabalhadores</button>
                        </td>
                    botao2 = 
                        <td>
                            <button className="btn btn-info" onClick={event => this.showBoleto(data.token_impr, data.id_empr_blto_impr)} data-toggle="modal" data-target="#boletoModal">Boleto</button>
                        </td>
                } else {
                    botao1 = 
                        <td>
                            ----------x----------
                        </td>
                    botao2 = 
                        <td>
                            ----x----
                        </td>
                }
            if (data.vlr_pago === 0) {
                vlr_pago_x = '  '
            } else {
                vlr_pago_x = "R$" + parseFloat(data.vlr_pago).toFixed(2)
            }
    
            return <tr key={index} >
                <td>{data.id_empr_blto}</td>
                <td>{data.cnpj}</td>
                <td>{data.razao_social}</td>
                <td>{data.placa}</td>
                <td>{data.id_empr_plno}</td>
                <td>{data.vencimento_original}</td>
                <td>{data.vencimento}</td>
                <td>{data.pagamento}</td>
                <td style={{textAlign:"right"}}>{vlr_pago_x}</td>
                <td>{data.nosso_numero}</td>
                <td>{data.impressao}</td>
                {botao1}
                {botao2}
            </tr>
        });
    }

    getTableShowTrabalhadores(){
        console.log("getTableShowTrabalhadores=>");
        return this.state.relatorio_trabalhadores.map((data, index) => {
            return <tr key={index} >
                <td>{String(data.id_tr_plno).padStart(3,"0")}</td>
                <td>{String(data.id_empr_plno).padStart(6,"0")}</td>
                <td>{String(data.id_tbldr).padStart(7,"0")}</td>
                <td>{data.nome_tbldr}</td>
                <td>{data.cpf_tbldr}</td>
                <td>{data.dscr_tipo_tbldr}</td>
                <td>{data.dt_admi_tbldr}</td>
                <td>{data.dt_demi_tbldr}</td>
            </tr>
        });
    }

    render(){
        console.log("render=>");
        let tableContentEmpresa1;
        let tableContentEmpresa2;
        let tableContentPlanos;
        let tableContentOperador;
        let tableContentBoletos;
        let tableContentTrabalhadores;
        let tableTrabalhadores;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.trabs.length){
            tableTrabalhadores = this.state.trabs;
        }
        else {
            tableTrabalhadores = <tr>
                <td colSpan={5}>
                    <b> Nenhum Trabalhador vinculado a esse boleto.</b>
                </td>
            </tr>
        }

        //dados empresas
        if (!isNull(this.state.dados_razao_empr)) {
            tableContentEmpresa1 = this.getTableShowEmpresa1();
            tableContentEmpresa2 = this.getTableShowEmpresa2();
        }else {
            tableContentEmpresa1 = <tr>
                <td colSpan={5}>  </td>
            </tr>           
            tableContentEmpresa2 = <tr>
            <td colSpan={5}>  </td>
            </tr>           
        }

        if(this.state.relatorio_planos.length){
            console.log('Planos 2=>');
            tableContentPlanos = this.getTableShowPlanos();
        }else{
            console.log('Planos 3=>');
            tableContentPlanos = <tr>
                <td colSpan={10}>  </td>
            </tr>
        }

        if(this.state.relatorio_operadores.length){
            //console.log('Operador 2=>');
            tableContentOperador = this.getTableShowOperadores();
        }else{
            //console.log('Operador 3=>');
            tableContentOperador = <tr>
                <td colSpan={4}> </td>
            </tr>
        }


        if(this.state.relatorio_impressoes.length){
            //console.log('Boletos 2=>');
            tableContentBoletos = this.getTableShowBoletos();
        }else{
            //console.log('Boletos 3=>');
            tableContentBoletos = <tr>
                <td colSpan={13}></td>
            </tr>
        }

        if(this.state.relatorio_trabalhadores.length){
            //console.log('Trabalhadores 2=>');
            tableContentTrabalhadores = this.getTableShowTrabalhadores();
        }else{
            //console.log('Trabalhadores 3=>');
            tableContentTrabalhadores = <tr>
                <td colSpan={8}></td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Dados Empresa</h2>
                <p>Consulta Dados das Empresas do Sistema de Cobrança BSFonline</p>

                <div className="modal fade" id="trabModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Trabalhadores da Empresa {this.empresa}, boleto N.Número {this.nnumero}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <table style={{width: 100 + '%'}}>
                                <thead>
                                <tr>
                                    <td>CPF</td>
                                    <td>Nome</td>
                                    <td>Sexo</td>
                                    <td>Nascimento</td>
                                    <td>Admissao</td>
                                    <td>Status</td>
                                </tr>
                                </thead>
                                <tbody>
                                    {tableTrabalhadores}
                                </tbody>
                            </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">FECHAR</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe a Razao Social/Nome ou CNPJ/CPF para a pesquisa (exatos)
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Informe a Razao Social/Nome ou CNPJ/CPF (exatos)" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary">pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="panel">
                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <h2> Cadastro </h2>
                                </tr>
                            </thead>
                            <thead>
                                <tr>
                                    <td>Id</td>
                                    <td>CNPJ</td>
                                    <td>Razao Social</td>
                                    <td>Nome Fantasia</td>
                                    <td>Email Responsavel</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContentEmpresa1}
                            </tbody>
                        </table>

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <h2> Endereço </h2>
                                </tr>
                            </thead>
                            <thead>
                                <tr>
                                    <td>Rua</td>
                                    <td>Bairro</td>
                                    <td>CEP</td>
                                    <td>Cidade</td>
                                    <td>UF</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContentEmpresa2}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="panel">
                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <h2> Operadores </h2>
                                </tr>
                                <tr>
                                    <thead>
                                        <td>Total</td>
                                        <td>Ativos</td>
                                        <td>Pendentes</td>
                                    </thead>
                                    <tbody>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_operadores.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_operadores_ativos.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_operadores_pendentes.toLocaleString('de-DE')}</td>
                                    </tbody>
                                </tr>

                                <tr>
                                    <td>Nome Operador</td>
                                    <td>CPF</td>
                                    <td>Email</td>
                                    <td>Situação</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContentOperador}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="panel">
                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <h2> Planos </h2>
                                </tr>

                                <tr>
                                    <thead>
                                        <td>Total</td>
                                        <td>Ativos</td>
                                        <td>Inativos</td>
                                    </thead>
                                    <tbody>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_planos.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_planos_ativos.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_planos_inativos.toLocaleString('de-DE')}</td>
                                    </tbody>
                                </tr>


                                <tr>
                                    <td>Id</td>
                                    <td>Plano</td>
                                    <td>Id Empr/Plano</td>
                                    <td>Descrição</td>
                                    <td>Situação</td>
                                    <td>Status TR</td>
                                    <td>Operador</td>
                                    <td>BSFOnline</td>
                                    <td>Data Migração</td>
                                    <td>Usuario</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContentPlanos}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="panel">
                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <h2> Boletos Gerados</h2>
                                </tr>

                                <tr>
                                <td>ID</td>
                                    <td>CNPJ</td>
                                    <td>Empresa</td>
                                    <td>Plano</td>
                                    <td>Empr_Plno</td>
                                    <td>Vecto Original</td>
                                    <td>Data Vencimento</td>
                                    <td>Data Pagamento</td>
                                    <td style={{textAlign:"right"}}>Vlr Pago</td>
                                    <td>Nosso Numero</td>
                                    <td>Impressao</td>
                                    <td>Trabalhadores</td>
                                    <td>Boleto</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContentBoletos}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="panel">
                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <h2> Trabalhadores </h2>
                                </tr>

                                <tr>
                                    <thead>
                                        <td>Total</td>
                                        <td>Ativos</td>
                                        <td>Afastados</td>
                                        <td>Demitidos</td>
                                        <td>Opositores</td>
                                    </thead>
                                    <tbody>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_trabalhadores.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_ativos.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_afastados.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_demitidos.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_opositores.toLocaleString('de-DE')}</td>
                                    </tbody>
                                </tr>

                                <tr>
                                    <td>Plano</td>
                                    <td>Empr/Plno</td>
                                    <td>Id trabalhador</td>
                                    <td>Nome trabalhador</td>
                                    <td>CPF</td>
                                    <td>Situação</td>
                                    <td>Dt Admissao</td>
                                    <td>Dt Demissao</td>
                                </tr>
                            </thead>
                            {<tbody>
                                {tableContentTrabalhadores}
                            </tbody>}
                        </table>
                    </div>
                </div>

            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(ConsultaEmpresa))
