import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class TrabalhadorSorteio
 extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            qtd_total: 0,
            paramters: null,
            critica: 'nao',            
            excel: 'nao',
            sorteioChecked:[], 
            array_sorteios:[]           
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    async  onChange(e){
        let self = this;
        let inputChecked = document.querySelectorAll('input[type=checkbox]')
        let checkboxes =  Array.from(inputChecked)
        let radioChecked =  await checkboxes.filter((checkbox) => checkbox.checked)
            .map((checkbox) => checkbox.value);
        if(radioChecked.length){
            self.setState({
                id_sorteioChecked : radioChecked
            })
        } else {
            self.setState({
                id_sorteioChecked : []
            })
        }
    }

    componentWillMount(){
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();

        let sorteios = [];

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/sorteio/lista-sorteios", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            console.log("Sorteios=>response.content:", response.content)
            let i = 0;
            response.content.map ((val, index) => {
                sorteios[i++] = {id_sort: val.id_sort, dt_sort: val.dt_sort};
            })
    
        self.setState({
            array_sorteios: sorteios
        });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });   
    }

    handlerSubmit(event){
        console.log('Handler Submit')
        event.preventDefault();
        let self = this;

        let data = {
        }

        data.pesquisa_parcial = 'sim';
        data.plano = event.target.plano.value;
        data.date_ini = event.target.date_ini.value;
        data.date_fim = event.target.date_fim.value;
        data.empresa_pesquisa = event.target.empresa_pesquisa.value;
        data.trabalhador_pesquisa = event.target.trabalhador_pesquisa.value;
        switch(event.target.empresas_bsf.value){
            case "1":
                data.empresas_bsf = "sim";
                break;
            case "2":
                data.empresas_bsf = "nao";
                break;
            default:
                data.empresas_bsf = "sim";
                break;
        }

        data.id_sorteio = this.state.id_sorteioChecked
        console.log("Sorteio=>", data.id_sorteio, "<=");

        this.state.paramters = "?";
        this.state.paramters += 'pesquisa_parcial=' + data.pesquisa_parcial;
        this.state.paramters += '&empresa_pesquisa=' + data.empresa_pesquisa;
        this.state.paramters += '&trabalhador_pesquisa=' + data.trabalhador_pesquisa;
        this.state.paramters += '&plano=' + data.plano;
        this.state.paramters += '&date_ini=' + data.date_ini;
        this.state.paramters += '&date_fim=' + data.date_fim;
        this.state.paramters += '&empresas_bsf=' + data.empresas_bsf;
        this.state.paramters += '&id_sorteio=' + data.id_sorteio;
        this.state.paramters += '&token=' + this.core.getToken();

        console.log("Fetch");
        if (this.state.excel != 'sim') {
            this.props.action.loadingStatus();
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/sorteio/lista-trabalhador-sorteio", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                response.content.map ((val, index) => {
                })
        
            self.setState({
                relatorio: response.content,
                qtd_total: response.content.length
            });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });   
        } 
        if (this.state.excel === 'sim') {
            console.log("Chamando Excel:");
            let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/sorteio/excel-trabalhador-sorteio"+this.state.paramters;
            window.location.href = urlExcel        
        } 
    }

    showLista(){
        console.log("showLista1");
        this.setState({
            excel: 'nao'
        });
    }

    showExcel(){
        console.log("showExcel1");
        this.setState({
            excel: 'sim'
        });
    }


    getTableShow(){
        console.log("getTableShow=>");
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.id_tbldr}</td>
                <td>{data.nome_tbldr}</td>
                <td>{data.cpf_tbldr}</td>
                <td>{data.situacao_tbldr}</td>
                <td>{data.num_sort_num}</td>
                <td>{data.dt_inscricao}</td>
                <td>{data.plano}</td>
                <td>{data.razao_empr}</td>
                <td>{data.cnpj_empr}</td>
                <td>{data.uf}</td>
                <td>{data.situacao}</td>
            </tr>
        });
    }

    render(){
        console.log('render')
        console.log('Array_Sorteios=>', this.state.array_sorteios)

        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={11}>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Sorteio APP Premiável - Trabalhadores Inscritos no Sorteio</h2>
                <p>Consulte os Trabalhadores Inscritos nos Sorteios</p>


                <div className="panel">
                    <div className='panel-title'>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-1">
                                        <div className="form-group">
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="date_ini" id="data_ini2" placeholder="Data início" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="date_fim" id="data_fim2" placeholder="Data fim" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="trabalhador_pesquisa" id="trabalhador_pesquisa" placeholder="Todos os Trabalhadores" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Todas as Empresas" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <div className="form-group">
                                            <label> <b>Empresas BSF</b> </label> <br/>
                                                <input type="radio" name="empresas_bsf" value="1" /> Sim <br/>
                                                <input type="radio" name="empresas_bsf" value="2"/> Não <br/>
                                        </div>
                                    </div>
                                    {/*
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                        <label> <b>Sorteio</b> </label> <br/>
                                            <input type="checkbox" name="id_sorteio" value="2" onChange={this.onChange}/> 09/Março/2019 <br/>
                                            <input type="checkbox" name="id_sorteio" value="3" onChange={this.onChange}/> 13/Abril/2019 <br/>
                                            <input type="checkbox" name="id_sorteio" value="4" onChange={this.onChange}/> 11/Maio/2019 <br/>
                                            <input type="checkbox" name="id_sorteio" value="5" onChange={this.onChange}/> 08/Junho/2019 <br/>
                                            <input type="checkbox" name="id_sorteio" value="6" onChange={this.onChange}/> 13/Julho/2019 <br/>
                                        </div>
                                    </div>
                                    */}
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                        <label> <b>Sorteio</b> </label> <br/>
                                            {this.state.array_sorteios.map(item => (
                                                <tr>                                                                                                      
                                                    <input type="checkbox" name={item.id_sort} value={item.id_sort} onChange={this.onChange}/> {item.dt_sort} <br/>
                                                </tr>                    
                                            ))} 
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panel-body container-fluid">

                            <table className="table table-striped table-doc">
                                <tr>
                                    <thead>
                                        <td>Totais</td>
                                        <td>Trabalhadores</td>
                                    </thead>
                                    <tbody>
                                        <td></td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                    </tbody>
                                </tr>
                            </table>

                            <table className="table table-striped table-doc">
                                <thead>
                                    <tr>
                                        <td>ID</td>
                                        <td>Nome</td>
                                        <td>CPF</td>
                                        <td>Situação</td>
                                        <td>Número</td>
                                        <td>Data Inscrição</td>
                                        <td>Plano</td>
                                        <td>Empregador</td>
                                        <td>CNPJ/CPF</td>
                                        <td>UF</td>
                                        <td>Situação</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableContent}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(TrabalhadorSorteio
))
