import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class ResumoPagamentos extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            qtd_total: 0,
            vlr_total: 0,
            excel: 'nao'
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {}

        data.date_ini = event.target.date_ini.value;
        data.date_fim = event.target.date_fim.value;
        data.mesano_vecto = event.target.mesano_vecto.value;
        data.pesquisa_parcial =  'sim';

        switch(event.target.baixas_sync.value){
            case "1":
                data.baixas_sync = 'sim';
                break;
            case "2":
                data.baixas_sync = 'nao';
                break;
            default:
                data.baixas_sync = 'nao';
                break;
        }

        var hoje = new Date();
        var ontem =  moment(hoje.toLocaleDateString(), "DD/MM/YYYY").subtract(1, 'days');
        if (data.date_ini === "") {
            data.date_ini = ontem.format('DD/MM/YYYY');
        }
        if (data.date_fim === "") {
            data.date_fim = ontem.format('DD/MM/YYYY');
        }

        let paramters = "?";
        paramters += 'date_ini=' + data.date_ini;
        paramters += '&date_fim=' + data.date_fim;
        paramters += '&mesano_vecto=' + data.mesano_vecto;
        paramters += '&pesquisa_parcial=' + data.pesquisa_parcial;
        paramters += '&baixas_sync=' + data.baixas_sync;
        paramters += '&excel=' + this.state.excel;
        paramters += '&token=' + this.core.getToken();


        if (this.state.excel != 'sim') {
            this.props.action.loadingStatus();
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/resumopagamentos" + paramters, {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'get'
                //,body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                self.setState({
                    relatorio: response.content, 
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });    
        } else {
            let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/resumopagamentos" + paramters;
            window.location.href = urlExcel        
        }
    }

    showLista(){
        console.log("showLista1");
        this.setState({
            excel: 'nao'
        });
    }

    showExcel(){
        console.log("showExcel1");
        this.setState({
            excel: 'sim'
        });
    }


    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            let total               = null;
            let qtd                 = null;
            let ativo               = null;
            let afastadoate12meses  = null;
            let afastadoapos12meses = null;
            let semdireito          = null;
            let opositor            = null;
            let idademaxima         = null;

            if (data.vlr_total === '-'){
                total               = data.vlr_total;   
                qtd                 = data.qtd_total; 
                ativo               = data.qtd_ativo;
                afastadoate12meses  = data.qtd_afastadoate12meses;
                afastadoapos12meses = data.qtd_afastadoapos12meses;
                semdireito          = data.qtd_semdireito;
                opositor            = data.qtd_opositor;
                idademaxima         = data.qtd_idademaxima;  
            } else {
                total               = parseFloat(data.vlr_total).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
                qtd                 = parseFloat(data.qtd_total).toLocaleString('pt-br');
                ativo               = parseFloat(data.qtd_ativo).toLocaleString('pt-br');
                afastadoate12meses  = parseFloat(data.qtd_afastadoate12meses).toLocaleString('pt-br');
                afastadoapos12meses = parseFloat(data.qtd_afastadoapos12meses).toLocaleString('pt-br');
                semdireito          = parseFloat(data.qtd_semdireito).toLocaleString('pt-br');
                opositor            = parseFloat(data.qtd_opositor).toLocaleString('pt-br');
                idademaxima         = parseFloat(data.qtd_idademaxima).toLocaleString('pt-br');
            }
            return <tr key={index} >
                <td>{data.dt_pagamento}</td>
                <td>{data.cobranca}</td>
                <td>{qtd}</td>
                <td>{total}</td>
                <td>{ativo}</td>
                <td>{afastadoate12meses}</td>
                <td>{afastadoapos12meses}</td>
                <td>{opositor}</td>
                <td>{idademaxima}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={9}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Resumo Pagamentos</h2>
                <p>Consulte o resumo dos Pagamentos</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe o Intervalo de Datas para Pesquisa.
                        </h3>
                        {/*<div  className="seleciona-mes-doc" >*/}
                        <div className="panel-body container-fluid">
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label>Data Inicio:<small>(Data Pagamento)</small></label>
                                            <InputMask name="date_ini" id="data_ini2" placeholder="Data início" className="form-control" mask="99/99/9999"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label>Data Fim:<small>(Data Pagamento)</small></label>
                                            <InputMask name="date_fim" id="data_fim2" placeholder="Data fim" className="form-control" mask="99/99/9999"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label>Vencimento:<small>(MM/AAAA)</small></label>
                                            <InputMask name="mesano_vecto" id="mesano_vecto" placeholder="Mês/Ano Vencimento" className="form-control" mask="99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label> <b>Baixas Sincronismo</b> </label> <br/>
                                                <input type="radio" name="baixas_sync" value="1" /> Sim <br/>
                                                <input type="radio" name="baixas_sync" value="2" /> Não <br/>
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <div className="form-group">
    
                                            <div className="form-group">
                                                <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className={"btn btn-info ${this.state.processado}"} onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal"> <i className="fa fa-download"></i>Excel</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>Dt Pagamento</td>
                                    <td>Cobrança</td>
                                    <td>Quantidade</td>
                                    <td>Valor</td>
                                    <td>Ativos</td>
                                    <td>-12m</td>
                                    <td>+12m</td>
                                    <td>Opositor</td>
                                    <td>Idade Maxima</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(ResumoPagamentos))
