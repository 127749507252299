import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core.js';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions.js';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions.js';
import * as StatusActions from '../../../actions/StatusActions.js';

class PagamentoSegProprio extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            trabs: [],
            empresa: null,
            dt_vencimento: null,
            qtd_total: 0,
            vlr_total: 0,
            qtd_ativos: 0,
            qtd_ate12m: 0,
            qtd_apos12m: 0,
            qtd_semdireito: 0,
            qtd_opositor: 0,
            qtd_idademaxima: 0,
            auth: this.core.getAuth(),
            totais: <div></div>,       
            colunas: <div></div>        
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.showFiles = this.showFiles.bind(this);
        this.showTrabs = this.showTrabs.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    generateRangeDate(){
        let range = [];
        
        let now = moment().date(1);
        range.push(moment(now));
        
        for(let i = 0; i < 5; i++){
            let mes = now.month() -1;
            now.month(mes);
            
            let date = moment(now);
            range.push(date);
        }

        range = range.map((data, index) => {
            return <option key={index + 1} value={data.format("YYYY-MM-DD")}>
                {data.format("MMMM")}
            </option>
        });

        //adiciona index 0 frase de seleção
        range.splice(0,0, <option key={0} checked value="">Selecione um mês</option>)
        return range;
    }

    generateplanosSelect(){
        let user =  this.props.user;

        let opt = [];
        if(user.segmentos !== undefined){
            opt = user.segmentos.map((data, index) => {
                return <option key={index + 1} value={data.id}>
                    {data.nome}
                </option>
            });
        }
    
        //adiciona index 0 frase de seleção
        opt.splice(0,0, <option key={0} checked value="">Todos os Planos</option>);

        return opt;
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;
        let planos = [];

        /*
        if(event.target.plano.value !== ""){
            planos.push(event.target.plano.value);
        }else{
            planos =  this.core.getPlanosCorretora();
        }

        let data = {
            planos: planos
        }
        */
                
       let data = {
        }

        data.planos                 = event.target.plano.value; 
        
        data.date_ini               = event.target.date_ini.value;
        data.date_fim               = event.target.date_fim.value;
        data.trabalhador_pesquisa   = event.target.trabalhador_pesquisa.value;
        data.empresa_pesquisa       = event.target.empresa_pesquisa.value;
        data.pesquisa_parcial       = 'sim';
        data.mesano_vecto           = event.target.mesano_vecto.value;


        this.props.action.loadingStatus();

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-segproprio2", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            let vlr_pago = 0
            let ativos = 0
            let ate12m = 0
            let apos12m = 0
            let semdireito = 0
            let opositor = 0
            let idademaxima = 0
            response.content.map ((val, index) => {
                vlr_pago += parseFloat(val.vlr_pago)
                ativos += parseFloat(val.ativos)
                ate12m += parseFloat(val.ate12m)
                apos12m += parseFloat(val.apos12m)
                semdireito += parseFloat(val.semdireito)
                opositor += parseFloat(val.opositor)
                idademaxima += parseFloat(val.idademaxima)
            })

            self.setState({
                relatorio: response.content,
                qtd_total: response.content.length,
                vlr_total: vlr_pago,
                qtd_ativos: ativos,
                qtd_ate12m: ate12m,
                qtd_apos12m: apos12m,
                qtd_semdireito: semdireito,
                qtd_opositor: opositor,
                qtd_idademaxima: idademaxima
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    showFiles(files, selecao){
        let self = this;
        let data =  files.map((data, index) => {
            
            //coreção para novos formatos vindo
            let path = data;
            if(typeof path !== 'string'){
                path = data.path;
            }
            
            return <div key={index} className="form-group box-btn">
                <a className="btn btn-success" href={self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/arquivo?arquivo=" + path+"&token="+self.core.getToken() } target="_blank">Documento {index + 1}</a>
            </div>
        });
        this.setState({
            renderModal: data,
            selecao: selecao
        });
    }
    showTrabs(id_empr_blto_impr, razao_social, vencimento, title){
        this.empresa = razao_social;
        this.dt_vencimento = vencimento;
        this.setState({
            trabs: []
        })
               
        let self = this;
        this.props.action.loadingStatus();
        
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/empresas-trabalhadores-boleto-pago?id_empr_blto=" + id_empr_blto_impr
        ,{method: 'GET'}
        )
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            let trabsres = response.content.map((trab, index) => {
                    return <tr key={index} >
                        <td>{trab.cpf}</td>
                        <td>{trab.nome}</td>
                        <td>{trab.sexo}</td>
                        <td>{trab.nascimento}</td>
                        <td>{trab.admissao}</td>
                    </tr>
    
                });
                    
            self.setState({
                trabs: trabsres
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        })
                            
    }

    getTableShow(){
        if(this.state.auth.config.grupo.nome === "administrador") {
            return this.state.relatorio.map((data, index) => {
                let files = [];
                if(data.parametros.pmtr_impr.files !== undefined){
                    files = data.parametros.pmtr_impr.files;
                }
                return  <tr key={index} >
                    <td>{data.id_empr_blto}</td>
                    <td>{data.cnpj}</td>
                    <td>{data.razao_social}</td>
                    <td>{data.placa}</td>
                    <td>{data.vencimento_original}</td>
                    <td>{data.pagamento}</td>
                    <td style={{textAlign:"right"}}>R$ {parseFloat(data.vlr_pago).toFixed(2)}</td>
                    <td>{data.nosso_numero}</td>
                    <td>{data.ativos}</td>
                    <td>{data.ate12m}</td>
                    <td>{data.apos12m}</td>
                    <td>{data.semdireito}</td>
                    <td>{data.opositor}</td>
                    <td>{data.idademaxima}</td>
                    <td>
                        <button className="btn btn-info" onClick={event => this.showFiles(files, data)} data-toggle="modal" data-target="#exampleModal">Documentos</button>
                    </td>
                    <td>
                        <button className="btn btn-success" onClick={event => this.showTrabs(data.id_empr_blto, data.razao_social, data.vencimento, false)} data-toggle="modal" data-target="#trabModal">Trabalhadores</button>
                    </td>
                </tr>
            });
        }
        else {
            return this.state.relatorio.map((data, index) => {
                let files = [];
                if(data.parametros.pmtr_impr.files !== undefined){
                    files = data.parametros.pmtr_impr.files;
                }
                return  <tr key={index} >
                    <td>{data.cnpj}</td>
                    <td>{data.razao_social}</td>
                    <td>{data.plano}</td>
                    <td>{data.vencimento}</td>
                    <td>{data.pagamento}</td>
                    <td>
                        <button className="btn btn-info" onClick={event => this.showFiles(files, data)} data-toggle="modal" data-target="#exampleModal">Documentos</button>
                    </td>
                    <td>
                        <button className="btn btn-success" onClick={event => this.showTrabs(data.id_empr_blto, data.razao_social, data.vencimento, false)} data-toggle="modal" data-target="#trabModal">Trabalhadores</button>
                    </td>
                </tr>
            });    
        }
    }
    
    render(){
        let tableContent;
        let title = <span> Odontologico </span>;
        
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }
        if(this.state.auth.config.grupo.nome === "administrador") {
            if(this.state.relatorio.length){
                tableContent = this.getTableShow();
            }else{
                tableContent = <tr>
                    <td colSpan={16}>
                        <center>
                            <b>Selecione um período para exibição do conteúdo</b>
                        </center>
                    </td>
                </tr>
            }
        }
        else {
            if(this.state.relatorio.length){
                tableContent = this.getTableShow();
            }else{
                tableContent = <tr>
                    <td colSpan={7}>
                        <center>
                            <b>Selecione um período para exibição do conteúdo</b>
                        </center>
                    </td>
                </tr>
            }

        }

        // if(this.state.auth.config.grupo.nome === "administrador") {
        //     this.state.totais = 
        //     <table className="table table-striped table-doc">
        //     <tr>
        //         <thead>
        //             <td>Totais</td>
        //             <td>Qtd Pagamentos</td>
        //             <td>Valor Total Pago</td>
        //             <td>Ativos</td>
        //             <td>Ate12m</td>
        //             <td>Apos12m</td>
        //             <td>Sem Direito</td>
        //             <td>Opositor</td>
        //             <td>Idade Maxima</td>
        //         </thead>
        //         <tbody>
        //             <td></td>
        //             <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('pr-br')}</td>
        //             <td style={{textAlign:"right"}}>R$ {this.state.vlr_total.toFixed(2).toLocaleString('pr-br')}</td>
        //             <td style={{textAlign:"right"}}>{this.state.qtd_ativos.toLocaleString('pt-br')}</td>
        //             <td style={{textAlign:"right"}}>{this.state.qtd_ate12m.toLocaleString('pt-br')}</td>
        //             <td style={{textAlign:"right"}}>{this.state.qtd_apos12m.toLocaleString('pt-br')}</td>
        //             <td style={{textAlign:"right"}}>{this.state.qtd_semdireito.toLocaleString('pt-br')}</td>
        //             <td style={{textAlign:"right"}}>{this.state.qtd_opositor.toLocaleString('pt-br')}</td>
        //             <td style={{textAlign:"right"}}>{this.state.qtd_idademaxima.toLocaleString('pt-br')}</td>
        //         </tbody>
        //     </tr>
        //     </table>

        //     this.state.colunas = 
        //         <thead>
        //             <tr>
        //                 <td>ID</td>
        //                 <td>CNPJ</td>
        //                 <td>Empresa</td>
        //                 <td>Plano</td>
        //                 <td>Vecto Original</td>
        //                 <td>Data Pagamento</td>
        //                 <td style={{textAlign:"right"}}>Vlr Pago</td>
        //                 <td>Nosso Numero</td>
        //                 <td>Ativos</td>
        //                 <td>-12m</td>
        //                 <td>+12m</td>
        //                 <td>Sem Direito</td>
        //                 <td>Opositor</td>
        //                 <td>Idade Maxima</td>
        //                 <td>Documentos</td>
        //                 <td>Trabalhadores</td>
        //             </tr>
        //         </thead>


        // } else {
            this.state.colunas = 
                <thead>
                    <tr>
                        <td>CNPJ</td>
                        <td>Empresa</td>
                        <td>Plano</td>
                        <td>Data Vencimento</td>
                        <td>Data Pagamento</td>
                        <td>Documentos</td>
                        <td>Trabalhadores</td>
                    </tr>
                </thead>
        // }

        return <div className="page">
            <div className="page-content">
                <h2>Pagamentos de Empresas com {title}</h2>
                <p>Consulte os documentos enviados e a relação de trabalhadores das empresas não optantes pelo Benefício Vida em Grupo</p>

                <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Documentos enviados - {this.state.selecao.razao_social}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.state.renderModal}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">FECHAR</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="trabModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Trabalhadores da Empresa {this.empresa}, Mes Ref: {this.dt_vencimento} - Com {title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <table style={{width: 100 + '%'}}>
                                <thead>
                                <tr>
                                    <td>CPF</td>
                                    <td>Nome</td>
                                    <td>Sexo</td>
                                    <td>Nascimento</td>
                                    <td>admissao</td>
                                </tr>
                                </thead>  
                                <tbody>
                                    {this.state.trabs}
                                </tbody>
                            </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">FECHAR</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel">
                    <div  className='panel-title'>
                        <h3>
                            Selecione o plano e o período para a pesquisa
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    {/*}
                                    <div className="col-sm-2">
                                        <select name="plano" id="plano" className="form-control">
                                            {this.generateplanosSelect()}
                                        </select>
                                    </div>
                                    */}
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="date_ini" id="data_ini2" placeholder="Data início" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="date_fim" id="data_fim2" placeholder="Data fim" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="mesano_vecto" id="mesano_vecto" placeholder="Mês/Ano Vencimento" className="form-control" mask="99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="trabalhador_pesquisa" id="trabalhador_pesquisa" placeholder="Todos os Trabalhadores" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Todas as Empresas" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary">pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">
                        {this.state.totais}

                        <table className="table table-striped table-doc">
                            {this.state.colunas}

                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(PagamentoSegProprio))