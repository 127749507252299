export default (state = false, payload) => {
    switch(payload.type){
        case 'STATUS_MANAGER_SHOW':
            state = true;
            return state;
       case 'STATUS_MANAGER_HIDE':
            state = false;
            return state;
        default:
            return state;
    }
}
