import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class EmpresasPersonal extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            qtd_total: 0,
            auth: this.core.getAuth(),
            empresa: null,
            paramters: null,
            critica: 'nao',            
            excel: 'nao'            
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        switch(event.target.cobranca.value){
            case "1":
                data.cobranca = "1";
                break;
            case "2":
                data.cobranca = "2";
                break;
            default:
                data.cobranca = "9";
                break;
        }

        this.state.paramters = "?";
        this.state.paramters += 'cobranca=' + data.cobranca;
        this.state.paramters += '&token=' + this.core.getToken();

        if (this.state.excel != 'sim') {
            this.props.action.loadingStatus();

            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-personal", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                self.setState({
                    relatorio: response.content, 
                    qtd_total: response.content.length
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });
        }
        if (this.state.excel === 'sim') {
            console.log("Chamando Excel=>", data.mesano_vecto, "!");
            let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-empresa-personal"+this.state.paramters;
            window.location.href = urlExcel        
        }
    }

    showLista(){
        console.log("showLista1");
        this.state.excel = 'nao'
    }

    showExcel(){
        console.log("showExcel1");
        this.state.excel = 'sim'
    }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.empr_cd_cnpj}</td>
                <td>{data.empr_ds_razao_social}</td>
                <td>{String(data.conv_cd_nseq).padStart(3,"0")}</td>
                <td>{String(data.recnum).padStart(6,"0")}</td>
                <td>{data.ult_recolhimento}</td>
                <td>{data.qtd_trabalhadores}</td>
                <td>{data.situacao_cobranca}</td>
                <td>{data.responsavel_cobranca}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={8}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa.</b>
                    </center>
                </td>
            </tr>
        }

        this.state.div_totais = 
        <table className="table table-striped table-doc">
        <tr>
            <thead>
                <td>Totais</td>
                <td>Qtd Empresas</td>
            </thead>
            <tbody>
                <td></td>
                <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
            </tbody>
        </tr>
        </table>

        return <div className="page">
            <div className="page-content">
                <h2>Empresas Personal</h2>
                <p>Consulte as empresas que Imprimem Boletos p/ Sistema Personal</p>

                <div className="panel">
                    <div className='panel-title'>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                        <label> <b>Situação Cobrança</b> </label> <br/>
                                            <input type="radio" name="cobranca" value="1" /> Em Cobrança <br/>
                                            <input type="radio" name="cobranca" value="2" /> Sem Cobrança <br/>
                                            <input type="radio" name="cobranca" value="9" /> Todas <br/>
                                        </div>
                                    </div>

                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">
                        {this.state.div_totais}

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>CNPJ</td>
                                    <td>Empresa</td>
                                    <td>Plano</td>
                                    <td>Recnum</td>
                                    <td>Ult Recolhimento</td>
                                    <td>Qtd Trab.</td>
                                    <td>Cobrança</td>
                                    <td>Responsável</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(EmpresasPersonal))
