import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class EmpresasMigradasPlano extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            qtd_total: 0,
            auth: this.core.getAuth(),
            empresa: null
            
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        this.props.action.loadingStatus();

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-migradas-plano", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.setState({
                relatorio: response.content, 
                qtd_total: response.content.length
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.empr_cd_cnpj}</td>
                <td>{data.empr_ds_razao_social}</td>
                <td>{String(data.conv_cd_nseq).padStart(3,"0")}</td>
                <td>{data.data_migracao}</td>
                <td>{data.usuario}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        let tableTrabalhadores;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={5}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa.</b>
                    </center>
                </td>
            </tr>
        }

        this.state.div_totais = 
        <table className="table table-striped table-doc">
        <tr>
            <thead>
                <td>Totais</td>
                <td>Qtd Empresas</td>
            </thead>
            <tbody>
                <td></td>
                <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
            </tbody>
        </tr>
        </table>

        return <div className="page">
            <div className="page-content">
                <h2>Empresas Migradas - Plano</h2>
                <p>Consulte as empresas que foram migradas para o Sistema BSFOnline</p>

                <div className="panel">
                    <div className='panel-title'>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary">pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">
                        {this.state.div_totais}

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>CNPJ</td>
                                    <td>Empresa</td>
                                    <td>Plano</td>
                                    <td>Dt Migracao</td>
                                    <td>Usuario</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(EmpresasMigradasPlano))
