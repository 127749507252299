import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect} from 'react-router-dom';
import Core from '../../../class/Core';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class EstornoBanco extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            qtd_total: 0,
            paramters: null,
            excel: 'nao',
            processado: "invisible"  
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let form = new FormData();
        let self = this;

        let data = {
        }

        data.nome_arquivo = event.target.arquivo.files[0];

        switch(event.target.banco.value){
            case "1":
                data.banco = "itau";
                break;
            case "2":
                data.banco = "santander";
                 break;
            case "3":
                data.banco = "sicoob";
                break;
            default:
                data.banco = "itau";
                break;
        }

        data.pesquisa_parcial =  'nao';

        if (this.state.excel != 'sim') {
            form.append("nome_arquivo", data.nome_arquivo);
            form.append("banco", data.banco);
            form.append("pesquisa_parcial", data.pesquisa_parcial);
            
            this.props.action.loadingStatus();
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-estorno-banco", {
                headers: {
                 
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: form
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                self.setState({
                    relatorio: response.content, 
                    qtd_total: response.content.length,
                    processado: "visible" 
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            }); 
        } 
        if (this.state.excel === 'sim') {
            this.state.paramters = '?pesquisa_parcial=' + data.pesquisa_parcial;
            this.state.paramters += '&banco=' + data.banco;
            this.state.paramters += '&nome_arquivo=' + data.nome_arquivo.name;  
            this.state.paramters += '&token=' + self.core.getToken();   
            let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-estorno-banco"+this.state.paramters;
            window.location.href = urlExcel        
        }           
    }

    showLista(){
        console.log("showLista1");
        this.setState({
            excel: 'nao'
        });
    }

    showExcel(){
        console.log("showExcel1");
        this.setState({
            excel: 'sim'
        });
    }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.nnum}</td>
                <td>{data.fk_situ_empr}</td>
                <td>{data.id_empr}</td>
                <td>{data.cnpj}</td>
                <td>{data.razao_social}</td>
                <td>{data.plano}</td>
                <td>{data.dt_vcto}</td>
                <td>{data.dt_pgto}</td>
                <td>{data.vlr_pago}</td>
                <td>{data.nnum_pago}</td>
                <td>{data._data_pagto}</td>
                <td>{data._valor}</td>
                <td>{data._banco_voltou}</td>
                <td>{data._agencia_voltou}</td>
                <td>{data._agencia_voltou_outro}</td>
            </tr>
        });
    }

    render(){
        let tableContent;

        if (this.props.redirect.url) {
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={15}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
        <form  onSubmit={this.handlerSubmit}>
            <div className="page-content">
                <h2>Análise dos Títulos Devolvidos - Cobranca BSFOnline</h2>

                <div className="panel">
                    <div className='panel-title'>
                        <h4>
                            Certifique-se que a planilha a ser importada tenha a coluna NOSSO NUMERO.
                        </h4>
                        <h4>
                            Certifique-se que a 1a linha da planilha tenha os nomes das Colunas!
                        </h4>
                        
                        <div  className="seleciona-mes-doc" >
                            
                                <div className="row">
                                    <div className="form-group">
                                        <input className="form-control" type="file" name="arquivo" placeholder="Nome" required onChange={(event)=> { 
                                            this.setState({
                                                processado: 'invisible'
                                            });
                                            return false
                                        }}/>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                        <label> <b>Banco</b> </label> <br/>
                                            <input type="radio" name="banco" value="1" /> Itau <br/>
                                            <input type="radio" name="banco" value="2" /> Santander <br/>
                                            <input type="radio" name="banco" value="3" /> SICOOB <br/>
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>

                                    <div className="col-sm-1">
                                        
                                    </div>
                                </div>
                            
                        </div>
                    </div>

                    <div className="panel-body container-fluid">

                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                    <td>Qtd Títulos: {this.state.qtd_total.toLocaleString('de-DE')}</td>
                                </thead>
                                <tbody>
                                    <td><button type="submit" className={`btn btn-info ${this.state.processado}`} onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal"> <i className="fa fa-download"></i> Gerar em Excel</button></td>
                                </tbody>
                            </tr>
                        </table>
                        

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>N.Número</td>
                                    <td>Situação</td>
                                    <td>Id_Empr</td>
                                    <td>CNPJ</td>
                                    <td>Empresa</td>
                                    <td>Plano</td>
                                    <td>Dt Vecto</td>
                                    <td>Dt Pagto</td>
                                    <td>Vlr Pago</td>
                                    <td>N.Numero Pagto</td>
                                    <td>#data_pagto</td>
                                    <td>#valor</td>
                                    <td>#banco voltou</td>
                                    <td>#ag voltou</td>
                                    <td>#ag voltou outro</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
            </form>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(EstornoBanco))
