import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect} from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';
import Alert from 'react-s-alert';


//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';
import { isNull } from 'util';
import { isNullOrUndefined } from 'util';


class ConsultarBlackList extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            qtd_total: 0,
            paramters: null,
            excel: 'nao'   
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        console.log("handlerSubmit");
        event.preventDefault();
        let self = this;

        let data = {
        }


        data.empresa_pesquisa = event.target.empresa_pesquisa.value;
        data.email_pesquisa = event.target.email_pesquisa.value;
        data.pesquisa_parcial =  'nao';
        data.date_ini = event.target.date_ini.value;
        data.date_fim = event.target.date_fim.value;

        if ( (data.empresa_pesquisa == "" && data.email_pesquisa == "") && (data.date_ini == "" && data.date_fim == "")) {
            Alert.error('por favor, preencha pelo menos um dos campos.', {
                position: 'bottom-right',
                html: false,
                effect: 'scale',
                beep: false,
                timeout: 'none',
                offset: 100
            });
            return false
        }

        this.state.paramters = "?";
        this.state.paramters += 'empresa_pesquisa=' + data.empresa_pesquisa;
        this.state.paramters += '&email_pesquisa=' + data.email_pesquisa;
        this.state.paramters += '&pesquisa_parcial=' + data.pesquisa_parcial;
        this.state.paramters += '&date_ini=' + data.date_ini;
        this.state.paramters += '&date_fim=' + data.date_fim;

        this.critica = 'ok';

        if (this.state.excel != 'sim' && (this.critica === 'ok')) {
            this.props.action.loadingStatus();
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/lista-emails-invalidos", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken()),
                    'mode': 'cors',
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                if(response.content.length){
                    self.setState({
                        relatorio: response.content, 
                        qtd_total: response.content.length
                    });
                } else {
                    Alert.error('Nenhum resultado encontrado!', {
                        position: 'top-right',
                        html: true,
                        effect: 'scale',
                        onShow: function () {
                            console.log('aye!')
                        },
                        beep: false,
                        timeout: 'none',
                        offset: 100
                    });
                }
               
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });
        }
        if (this.state.excel === 'sim' && (this.critica === 'ok')) {
            if (this.consisteParametros(data)) {
                let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/excel-emails-invalidos"+this.state.paramters;
                window.location.href = urlExcel        
            }
        }

    }

    showLista(){
        this.state.excel = 'nao'
    }

    showExcel(){
        this.state.excel = 'sim'
    }

    consisteParametros(prm_data){        
        return true;
    }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.email}</td>
                <td>{data.dt_inclusao}</td>
                <td>{data.cnpj}</td>
                <td>{data.razao_social}</td>
                <td>{data.modelo_email}</td>
                <td>{data.assunto_email}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        this.state.excel = 'nao'
        if (this.props.redirect.url) {
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={6}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa.</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Emails BSFOnline - Consulta Emails Inválidos</h2>
                <p>Consulte a lista de Emails Inválidos Permanentemente</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Opcionalmente Informe a Empresa e/ou Email Destinatário.
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="date_ini" id="data_ini" placeholder="Data início" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="date_fim" id="data_fim" placeholder="Data Fim" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Todas as Empresas" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="email_pesquisa" id="email_pesquisa" placeholder="Todos os Emails Destino" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                        
                    <div className="panel-body container-fluid">

                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                    <td>Qtd Emails: {this.state.qtd_total.toLocaleString('de-DE')}</td>
                                </thead>
                                <tbody>
                                    <td></td>
                                </tbody>
                            </tr>
                        </table>
                        
                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>Email</td>
                                    <td>Inclusão</td>
                                    <td>CNPJ</td>
                                    <td>Empresa</td>
                                    <td>Modelo</td>
                                    <td>Assunto</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}

export default withRouter(connect(mapStateProps, mapDispatchProps)(ConsultarBlackList))
