import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import moment from 'moment';
import 'moment/locale/pt-br';
import { isNull } from 'util';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class ConsultaReceitaCNPJ extends React.Component{
    constructor(props){
        super(props);

        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;

        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio_empresa: [],
            qtd_total: 0,
            /* dados empresa */
            dados_cpf_cnpj: null,
            dados_razao_social: null,
            dados_nome: null,
            dados_tipo: null,
            dados_situacao: null,
            dados_data_situacao: null,
            dados_status: null,
            dados_data_abertura: null,
            dados_natureza_juridica: null,
            dados_telefone: null,
            dados_email: null,
            dados_logradouro: null,
            dados_cep: null,
            dados_numero: null,
            dados_complemento: null,
            dados_bairro: null,
            dados_municipio: null,
            dados_uf: null,
            dados_capital_social: null,
            dados_socios: null,
            dados_atividades: null,
            dados_ultima_atualizacao: null
        }

        this.getTableShowEmpresa1 = this.getTableShowEmpresa1.bind(this);
        this.getTableShowEmpresa2 = this.getTableShowEmpresa2.bind(this);
        this.getTableShowEmpresa3 = this.getTableShowEmpresa3.bind(this);
        this.getTableShowEmpresa4 = this.getTableShowEmpresa4.bind(this);
        this.getTableShowEmpresa5 = this.getTableShowEmpresa5.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
            empresa_pesquisa: event.target.empresa_pesquisa.value,
            pesquisa_parcial: 'nao',
        }

        let paramters = "?";
        paramters += 'empresa_pesquisa=' + data.empresa_pesquisa;
        paramters += '&pesquisa_parcial=' + data.pesquisa_parcial;
        //paramters += '&token=' + this.core.getToken();

        this.props.action.loadingStatus();
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/empresa-consulta-receitaCNPJ" + paramters, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'GET'
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            console.log("Content=>response.content:", response.content[0])
            self.setState({
                dados_cpf_cnpj: response.content[0].cpf_cnpj,
                dados_razao_social: response.content[0].razao_social,
                dados_nome: response.content[0].nome,
                dados_tipo: response.content[0].tipo,
                dados_situacao: response.content[0].situacao,
                dados_data_situacao: response.content[0].data_situacao,
                dados_status: response.content[0].status,
                dados_data_abertura: response.content[0].data_abertura,
                dados_natureza_juridica: response.content[0].natureza_juridica,
                dados_telefone: response.content[0].telefone,
                dados_email: response.content[0].email,
                dados_logradouro: response.content[0].logradouro,
                dados_cep: response.content[0].cep,
                dados_numero: response.content[0].numero,
                dados_complemento: response.content[0].complemento,
                dados_bairro: response.content[0].bairro,
                dados_municipio: response.content[0].municipio,
                dados_uf: response.content[0].uf,   
                dados_ultima_atualizacao: response.content[0].ultima_atualizacao,   
                dados_capital_social: response.content[0].capital_social,   
                dados_socios: response.socios,   
                dados_atividades: response.atividades,   
                qtd_total: 0
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    getTableShowEmpresa1(){
        return <tr>
            <td>{this.state.dados_cpf_cnpj}</td>
            <td>{this.state.dados_razao_social}</td>
            <td>{this.state.dados_nome}</td>
            <td>{this.state.dados_tipo}</td>
            <td>{this.state.dados_capital_social}</td>
        </tr>
    }

    getTableShowEmpresa2(){
        return <tr>
            <td>{this.state.dados_logradouro}</td>
            <td>{this.state.dados_numero}</td>
            <td>{this.state.dados_complemento}</td>
            <td>{this.state.dados_cep}</td>
            <td>{this.state.dados_municipio}</td>
            <td>{this.state.dados_uf}</td>
            <td>{this.state.dados_telefone}</td>
            <td>{this.state.dados_email}</td>
        </tr>
    }

    getTableShowEmpresa3(){
        return <tr>
            <td>{this.state.dados_natureza_juridica}</td>
            <td>{this.state.dados_situacao}</td>
            <td>{this.state.dados_data_situacao}</td>
            <td>{this.state.dados_status}</td>
            <td>{this.state.dados_data_abertura}</td>
            <td>{this.state.dados_ultima_atualizacao}</td>
        </tr>
    }

    getTableShowEmpresa4(){
        console.log("getTableShowSocios=>");
        return this.state.dados_socios.map((data, index) => {
            return <tr key={index} >
                <td>{data.nome}</td>
                <td>{data.qualificacao}</td>
            </tr>
        });
    }

    getTableShowEmpresa5(){
        console.log("getTableShowAtividades=>");
        return this.state.dados_atividades.map((data, index) => {
            return <tr key={index} >
                <td>{data.codigo}</td>
                <td>{data.descricao}</td>
                <td>{data.tipo}</td>
            </tr>
        });
    }


    render(){
        console.log("render=>");
        let tableContentEmpresa1;
        let tableContentEmpresa2;
        let tableContentEmpresa3;
        let tableContentEmpresa4;
        let tableContentEmpresa5;

        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        //dados empresas
        if (!isNull(this.state.dados_razao_empr)) {
            tableContentEmpresa1 = this.getTableShowEmpresa1();
        } else {
            tableContentEmpresa1 = <tr>
                <td colSpan={5}>  </td>
            </tr>     
        }         

        if (!isNull(this.state.dados_razao_empr)) {
            tableContentEmpresa2 = this.getTableShowEmpresa2();
        } else {
            tableContentEmpresa2 = <tr>
                <td colSpan={8}>  </td>
            </tr>     
        }         

        if (!isNull(this.state.dados_razao_empr)) {
            tableContentEmpresa3 = this.getTableShowEmpresa3();
        } else {
            tableContentEmpresa3 = <tr>
                <td colSpan={6}>  </td>
            </tr>     
        }         

        if (!isNull(this.state.dados_socios)) {
            tableContentEmpresa4 = this.getTableShowEmpresa4();
        } else {
            tableContentEmpresa4 = <tr>
                <td colSpan={2}>  </td>
            </tr>     
        }    
        console.log("tableContentEmpresa4=>",tableContentEmpresa4);
     
        if (!isNull(this.state.dados_atividades)) {
            tableContentEmpresa5 = this.getTableShowEmpresa5();
        } else {
            tableContentEmpresa5 = <tr>
                <td colSpan={3}>  </td>
            </tr>     
        }         
        console.log("tableContentEmpresa5=>",tableContentEmpresa5);

        return <div className="page">
            <div className="page-content">
                <h2>Empresa - Consulta Base de Dados Receita Federal</h2>
                <p>Consulte os dados das empresas na base de dados da Receita Federal</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe a os parametros necessários para a pesquisa.
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Razao Social/Nome ou CNPJ/CPF" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary">pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="panel">
                    <div className="panel-body container-fluid">

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <h2> Cadastro </h2>
                                </tr>
                            </thead>

                            <thead>
                                <tr>
                                    <td>CNPJ / CPF</td>
                                    <td>Razao Social</td>
                                    <td>Nome</td>
                                    <td>Tipo</td>
                                    <td>Capital Social</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContentEmpresa1}
                            </tbody>
                        </table>

                    </div>
                </div>
                
                <div className="panel">
                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">

                            <thead>
                                <tr>
                                    <h2> Endereço </h2>
                                </tr>
                            </thead>

                            <thead>
                                <tr>
                                    <td>Logradouro</td>
                                    <td>Número</td>
                                    <td>Complemento</td>
                                    <td>CEP</td>
                                    <td>Municipio</td>
                                    <td>UF</td>
                                    <td>Telefone</td>
                                    <td>Email</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContentEmpresa2}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="panel">
                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">

                            <thead>
                                <tr>
                                    <h2> Situação </h2>
                                </tr>
                            </thead>

                            <thead>
                                <tr>
                                    <td>Natureza Jurídica</td>
                                    <td>Situação</td>
                                    <td>Data Situação</td>
                                    <td>Status</td>
                                    <td>Data Abertura</td>
                                    <td>Data Ult. Atualizacao</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContentEmpresa3}
                            </tbody>
                        </table>


                    </div>
                </div>

                <div className="panel">
                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">

                            <thead>
                                <tr>
                                    <h2> Sócios </h2>
                                </tr>
                            </thead>

                            <thead>
                                <tr>
                                    <td>Nome</td>
                                    <td>Qualificação</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContentEmpresa4}
                            </tbody>
                        </table>


                    </div>
                </div>

                <div className="panel">
                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">

                            <thead>
                                <tr>
                                    <h2> Atividades </h2>
                                </tr>
                            </thead>

                            <thead>
                                <tr>
                                    <td>Código</td>
                                    <td>Descrição</td>
                                    <td>Tipo</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContentEmpresa5}
                            </tbody>
                        </table>


                    </div>
                </div>


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(ConsultaReceitaCNPJ))
