import React from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';
import moment from 'moment';
import '../../../assets/css/sweetalert.css'
//class
import Table from '../../geral/Table';
import io from 'socket.io-client';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';
import Core from '../../../class/Core';


class IndexFilasNotificacoes extends React.Component {

    constructor(props) {
        super(props)

        this.core = new Core();
        this.core.isAuth();
        this.state = {
            head: ['ID', "NOME", 'DATA ENVIO','INFORMAÇÕES'],
            columns: [],
            redirect: null 
        }

    }

       

    componentWillMount() {
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();
        this.props.action.loadingStatus();
        self.props.action.loadedStatus();


        const socket = io(self.core.getUrlConecta()+'notification-queue',{
        	forceNew: true
        });
           //socket.emit('notification-get', true)
           
           socket.on('list', response => {  
            let socketResult = response.content.map(dados => {
                    return{
                        id: dados.fk_notfo_sts,
                        nome: dados.nome_notfo,
                        data_envio: moment(dados.date).format('DD/MM/YYYY'),
                        informacoes: ` <b>Pendentes:</b> ${dados.total_pendentes}<br/> <b>Enviados:</b> ${dados.total_enviados}<br/>
                            <b>Lidos:</b> ${dados.total_lidos}`, 
                    }
                })
                self.setState({
                    columns: socketResult
                });
           }) 
    }


    render() {
       
        if (this.props.redirect.url) {
            return <Redirect to={this.props.redirect.url} />;
        }

        return <div className="page">    
            <div className="page-content">
            <table style={{"width":"100%"}} >
                    <tr>
                        <td>  
                            <h2>FILA DE NOTIFIÇÕES</h2>
                            <p>Lista em tempo real.</p>
                        </td>
                        <td style={{textAlign: "right"}}>
                           <Link to="/manager/filas-emails"> <button className="btn btn-info">FILA DE E-MAILS</button></Link>
                        </td>
                    </tr>
                </table>

                {/* exibe  */}
                <div className="panel">
                    <div className="panel-body container-fluid">
                        <Table
                            head={this.state.head}
                            columns={this.state.columns}
                        />
                    </div>
                </div>
                {/*fim*/}
            </div>
        </div>;
    }

}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}

export default withRouter(connect(mapStateProps, mapDispatchProps)(IndexFilasNotificacoes));