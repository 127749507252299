import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class EmpresasMigradasPlanoPagamentos extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            qtd_total: 0,
            auth: this.core.getAuth(),
            empresa: null,
            excel: 'nao'
            
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        if (this.state.excel != 'sim') {
            this.props.action.loadingStatus();
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-migradas-plano-pagamentos", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                self.setState({
                    relatorio: response.content, 
                    qtd_total: response.content.length
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });    
        } else {
            console.log("Chamando Excel:", data.date_ini, "!");
            if (this.consisteParametros(data.date_ini, data.date_fim)) {
                console.log("Chamando Excel OK");
                let urlMigradas = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-empresa-migradas-plano-pagamentos";
                window.location.href = urlMigradas
            }

        }
    }

    showLista(){
        console.log("showLista1");
        this.state.excel = 'nao'
    }
 
    showExcel(){
        console.log("showExcel1");
        this.state.excel = 'sim'
    }
 
    consisteParametros(prm_date_ini, prm_date_fim){
        console.log("consisteParametros");
        return true;
    }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            let pagto1 = null;
            let pagto2 = null;
            let pagto3 = null;
            let tbldr1 = null;
            let tbldr2 = null;
            let tbldr3 = null;
            
            if (data.pagto1_mes === 'n/d') {
                pagto1 = '-x-x-x-'
                tbldr1 = '-x-x-x-'
            }
            else {
                pagto1 = data.pagto1_mes+"/"+data.pagto1_ano
                tbldr1 = String(data.pagto1_qt_tbldr).padStart(5,"0")
            }

            if (data.pagto2_mes === 'n/d') {
                pagto2 = '-x-x-x-'
                tbldr2 = '-x-x-x-'
            }
            else {
                pagto2 = data.pagto2_mes+"/"+data.pagto2_ano
                tbldr2 = String(data.pagto1_qt_tbldr).padStart(5,"0")
            }

            if (data.pagto3_mes === 'n/d') {
                pagto3 = '-x-x-x-'
                tbldr3 = '-x-x-x-'
            }
            else {
                pagto3 = data.pagto3_mes+"/"+data.pagto3_ano
                tbldr3 = String(data.pagto1_qt_tbldr).padStart(5,"0")
            }

            return <tr key={index} >
                <td>{data.empr_cd_cnpj}</td>
                <td>{data.empr_ds_razao_social}</td>
                <td>{String(data.conv_cd_nseq).padStart(3,"0")}</td>
                <td>{pagto1}</td>
                <td>{tbldr1}</td>
                <td>{pagto2}</td>
                <td>{tbldr2}</td>
                <td>{pagto3}</td>
                <td>{tbldr3}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        let tableTrabalhadores;
        this.state.excel = 'nao'
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={9}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa.</b>
                    </center>
                </td>
            </tr>
        }

        this.state.div_totais = 
        <table className="table table-striped table-doc">
        <tr>
            <thead>
                <td>Totais</td>
                <td>Qtd Empresas</td>
            </thead>
            <tbody>
                <td></td>
                <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
            </tbody>
        </tr>
        </table>

        return <div className="page">
            <div className="page-content">
                <h2>Empresas Migradas - Plano - Pagamentos</h2>
                <p>Consulte as empresas que foram migradas para o Sistema BSFOnline</p>

                <div className="panel">
                    <div className='panel-title'>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">
                        {this.state.div_totais}

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>CNPJ</td>
                                    <td>Empresa</td>
                                    <td>Plano</td>
                                    <td>Vencimento</td>
                                    <td>Qtd Trabalhadores</td>
                                    <td>Vencimento</td>
                                    <td>Qtd Trabalhadores</td>
                                    <td>Vencimento</td>
                                    <td>Qtd Trabalhadores</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(EmpresasMigradasPlanoPagamentos))
