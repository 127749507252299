export const redirectTo = (path) => {
    return {
        type: 'REDIRECT_TO',
        data: path
    }
}

export const redirectClear = () => {
    return {
        type: 'REDIRECT_CLEAR'
    }
}
