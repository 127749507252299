import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class Contador
 extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            qtd_total: 0,
            paramters: null,
            critica: 'nao',            
            excel: 'nao'            
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        data.pesquisa_parcial = 'sim';
        data.contador_pesquisa = event.target.contador_pesquisa.value;
   
        this.state.paramters = "?";
        this.state.paramters += 'pesquisa_parcial=' + data.pesquisa_parcial;
        this.state.paramters += '&contador_pesquisa=' + data.contador_pesquisa;
        this.state.paramters += '&token=' + this.core.getToken();

        console.log("Fetch");
        if (this.state.excel != 'sim') {
            this.props.action.loadingStatus();
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-contador-cadastrado", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                response.content.map ((val, index) => {
                })
        
            self.setState({
                relatorio: response.content,
                qtd_total: response.content.length
            });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });   
        } 
        if (this.state.excel === 'sim') {
            console.log("Chamando Excel:");
            let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-contador-cadastrado"+this.state.paramters;
            window.location.href = urlExcel        
        } 
    }

    showLista(){
        console.log("showLista1");
        this.setState({
            excel: 'nao'
        });
    }

    showExcel(){
        console.log("showExcel1");
        this.setState({
            excel: 'sim'
        });
    }


    getTableShow(){
        console.log("getTableShow=>");
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.id_admin}</td>
                <td>{data.cnpj_admin}</td>
                <td>{data.razao_admin}</td>
                <td>{data.uf_admin}</td>
                <td>{data.eml_dirt}</td>
                <td>{data.eml_cobr}</td>
                <td>{data.situacao}</td>
                <td>{data.created_at}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={8}>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Contador</h2>
                <p>Contadores Cadastrados no Sistemas BSFOnline</p>

                <div className="panel">
                    <div className='panel-title'>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="contador_pesquisa" id="contador_pesquisa" placeholder="Todos os Contadores" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panel-body container-fluid">

                            <table className="table table-striped table-doc">
                                <tr>
                                    <thead>
                                        <td>Totais</td>
                                        <td>Contadores</td>
                                    </thead>
                                    <tbody>
                                        <td></td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                    </tbody>
                                </tr>
                            </table>

                            <table className="table table-striped table-doc">
                                <thead>
                                    <tr>
                                        <td>ID</td>
                                        <td>CNPJ Contabilidade</td>
                                        <td>Contabilidade</td>
                                        <td>UF</td>
                                        <td>Email Diretoria</td>
                                        <td>Email Cobrança</td>
                                        <td>Situação</td>
                                        <td>Data Cadastro</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableContent}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(Contador
))
