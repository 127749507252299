import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import Alert from "react-s-alert";
import Select from "react-select";
import TinyMCE from "tinymce-react";

//class
import Core from "../../../class/Core";

//actions
import * as UserActions from "../../../actions/UserActions";
import * as ManagerStatusActions from "../../../actions/StatusManagerActions.js";
import * as RedirectActions from "../../../actions/RedirectActions";
import * as StatusActions from "../../../actions/StatusActions";

class EditNotificacoes extends React.Component {
    constructor(props) {
        super(props);
        this.core = new Core();
        this.core.isAuth();

        this.state = {
            modelos: [],
            planos: [],
            selecteds: [],
            html_content: null,
            redirect: null,
            data: {},
        };

        this.handlerSubmit = this.handlerSubmit.bind(this);
        this.handlerSelect = this.handlerSelect.bind(this);
    }

    async componentWillMount() {
        let self = this;
        let id = this.props.match.params.id;
        this.props.action.showManager();
        this.props.action.redirectClear();
        this.props.action.loadingStatus();
        self.props.action.loadedStatus();

        fetch(
            self.core.getUrl() +
                self.core.getVersionAPI() +
                "email/notificacoes/" +
                id,
            {
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    Authorization: self.core.formatAutorizationToken(
                        self.core.getToken()
                    ),
                },
            }
        )
            .then((res) => self.core.responseConvert(res))
            .then((response) => {
                self.setState({
                    html_content: response.content.desc_notfo,
                    data: response.content,
                    selecteds: response.content.plno_notfo.split(","),
                });

                //Carregando tipos
                document.getElementById("tipo").value =
                    this.state.data.tpo_notfo;

                console.log(this.state.data.tpo_notfo);
            })

            .catch((err) => {
                self.core.catchFecth(err);
                self.props.action.loadedStatus();
            });

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "email/modelo", {
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: self.core.formatAutorizationToken(
                    self.core.getToken()
                ),
            },
        })
            .then((res) => self.core.responseConvert(res))
            .then((response) => {
                let data = response.content.map((data, index) => {
                    return (
                        <option key={index + 1} value={data.id_eml_modl}>
                            {data.id_eml_modl} - {data.obs_eml_modl}
                        </option>
                    );
                });

                data.splice(
                    0,
                    0,
                    <option key={0} value="">
                        SELECIONE UM MODELO
                    </option>
                );
                self.setState({
                    modelos: data,
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err);
                self.props.action.loadedStatus();
            });

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "planos", {
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: self.core.formatAutorizationToken(
                    self.core.getToken()
                ),
            },
        })
            .then((res) => self.core.responseConvert(res))
            .then((response) => {
                ///mapeia o valores vindo de planos e faz um sort para ordenação de texto
                let planos = response.content
                    .map((data, index) => {
                        let tela_rosa_id = data.id_tr_plno || 0;
                        return {
                            value: data.id_plno,
                            label: tela_rosa_id + " - " + data.dscr_plno,
                            id: tela_rosa_id,
                        };
                    })
                    .sort(function (a, b) {
                        return parseInt(a.id) - parseInt(b.id);
                    });

                //adicionando todos os planos como opção
                planos.splice(0, 0, {
                    value: "all",
                    label: "TODOS OS PLANOS",
                    id: 0,
                });

                this.setState({
                    planos: planos,
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err);
                self.props.action.loadedStatus();
            });
    }

    handlerSelect(valor) {
        this.setState({
            selecteds: valor,
        });
    }

    async handlerSubmit(e) {
        e.preventDefault();

        const self = this;
        let id = this.props.match.params.id;
        let planos = this.state.selecteds.map((data, index) => {
            return data.value;
        });

        let send = {
            nome_notfo: e.target.titulo.value,
            tpo_notfo: e.target.tipo.value,
            plno_notfo: planos,
            desc_notfo: this.state.html_content,
        };
        console.log(send);
        fetch(
            self.core.getUrl() +
                self.core.getVersionAPI() +
                "email/notificacoes/" +
                id,
            {
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    Authorization: self.core.formatAutorizationToken(
                        self.core.getToken()
                    ),
                },
                method: "PUT",
                body: JSON.stringify(send),
            }
        )
            .then((res) => self.core.responseConvert(res))
            .then((response) => {
                Alert.success("Registro inserido com sucesso!", {
                    position: "bottom-right",
                    effect: "slide",
                    timeout: 8000,
                });

                self.props.action.loadedStatus();

                self.setState({
                    redirect: "/manager/notificacoes",
                });
            })
            .catch((err) => {
                self.core.catchFecth(err);
                self.props.action.loadedStatus();
            });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }

        if (this.props.redirect.url) {
            return <Redirect to={this.props.redirect.url} />;
        }
        return (
            <div className="page">
                <div className="page-content">
                    <h4>CRIAR NOTIFICAÇÃO</h4>

                    <div className="panel">
                        <div className="panel-body container-fluid">
                            <form action="#" onSubmit={this.handlerSubmit}>
                                <div className={"row"}>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Título</label>
                                            <input
                                                type="text"
                                                name="titulo"
                                                className="form-control"
                                                value={
                                                    this.state.data.nome_notfo
                                                }
                                                onChange={(e) => {
                                                    let data = this.state.data;
                                                    data.nome_notfo =
                                                        e.target.value;
                                                    this.setState({
                                                        data: data,
                                                    });
                                                }}
                                                required
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label>Mensagem</label>
                                            <TinyMCE
                                                id="value_html"
                                                name="editor"
                                                // apiKey={YOUR_TINYMCE_API_KEY}

                                                config={{
                                                    height: 200,
                                                    plugins:
                                                        "image imagetools table code wordcount media paste",
                                                    image_advtab: true,
                                                    image_caption: true,
                                                    images_upload_url:
                                                        this.core.getUrl() +
                                                        this.core.getVersionAPI() +
                                                        "uploads/images",
                                                    automatic_uploads: false,
                                                    file_browser_callback_types:
                                                        "file image media",
                                                    imagetools_toolbar:
                                                        "rotateleft rotateright | flipv fliph | editimage imageoptions",
                                                    toolbar1:
                                                        "formatselect image code | bold italic  strikethrough  forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat",
                                                }}
                                                setContent={
                                                    this.state.data.desc_notfo
                                                }
                                                content={
                                                    this.state.data.desc_notfo
                                                }
                                                onContentChanged={(content) =>
                                                    this.setState({
                                                        html_content: content,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Tipos de empresas
                                                    </label>
                                                    <select
                                                        name="tipo"
                                                        id="tipo"
                                                        className="form-control empresas-required"
                                                    >
                                                        <option value="">
                                                            SELECIONE UM PERFIL
                                                            PARA ENVÍO
                                                        </option>
                                                        <option
                                                            value="disponibilidade"
                                                            selected={
                                                                this.state.data
                                                                    .tpo_notfo ==
                                                                "disponibilidade"
                                                                    ? "selected"
                                                                    : ""
                                                            }
                                                        >
                                                            TODAS AS EMPRESAS
                                                        </option>
                                                        <option
                                                            value="inadimplentes"
                                                            selected={
                                                                this.state.data
                                                                    .tpo_notfo ==
                                                                "inadimplentes"
                                                                    ? "selected"
                                                                    : ""
                                                            }
                                                        >
                                                            EMPRESAS
                                                            INADIMPLENTES
                                                        </option>
                                                        <option
                                                            value="nao-impressos"
                                                            selected={
                                                                this.state.data
                                                                    .tpo_notfo ==
                                                                "nao-impressos"
                                                                    ? "selected"
                                                                    : ""
                                                            }
                                                        >
                                                            EMPRESAS SEM
                                                            IMPRESSÃO
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Selecione os Planos
                                                    </label>
                                                    <Select
                                                        name="planos"
                                                        multi={true}
                                                        value={
                                                            this.state.selecteds
                                                        }
                                                        options={
                                                            this.state.planos
                                                        }
                                                        onChange={
                                                            this.handlerSelect
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <button
                                                    type="submit"
                                                    className="btn btn-success"
                                                >
                                                    Enviar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer,
    };
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators(
            {
                ...UserActions,
                ...ManagerStatusActions,
                ...RedirectActions,
                ...StatusActions,
            },
            dispatch
        ),
    };
}

export default withRouter(
    connect(mapStateProps, mapDispatchProps)(EditNotificacoes)
);
