import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class EmpresaTrabalhadorPlanilha extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            trabalhador_res: [],
            qtd_total: 0,
            auth: this.core.getAuth(),
            com_direito: null,
            curl_lista: null,
            curl_excel1: null,
            curl_excel2: null
        }
        this.getTableShow       = this.getTableShow.bind(this);
        this.handlerSubmit      = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
        console.log(this.state.auth);
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        data.plano = event.target.plano.value;
        data.empresa_pesquisa = event.target.empresa_pesquisa.value;
        data.pesquisa_parcial =  'nao';
        switch(event.target.tbldr.value){
            case "1":
                this.state.curl = "empresa/relatorios/lista-empresa-trabalhador-planilha";
                this.state.com_direito = 1;
                break;
            case "2":
                this.state.curl = "empresa/relatorios/lista-empresa-trabalhador-planilha-sem";
                this.state.com_direito = 2;
                break;
            default:
                this.state.curl = "empresa/relatorios/lista-empresa-trabalhador-planilha";
                break;
        }

        this.props.action.loadingStatus();

        fetch(self.core.getUrl() + self.core.getVersionAPI() + this.state.curl, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.setState({
                relatorio: response.content,
                qtd_total: response.content.length
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    getTableShow(){
        let self  = this;
        if (this.state.com_direito == 1) {
            this.state.curl_excel1  = "empresa/relatorios/download-empresa-trabalhador-planilha?file_path=";
            this.state.curl_excel2  = "empresa/relatorios/download-empresa-trabalhador-planilha";    
        } else {
            this.state.curl_excel1  = "empresa/relatorios/download-empresa-trabalhador-planilha?file_path=";
            this.state.curl_excel2  = "empresa/trabalhador/uploadmassatotal/download/";    
        }
        let token = '&token=' + this.core.getToken();

        return this.state.relatorio.map((data, index) => {
            let botao_download = null;
            let botao_analise  = null;
            if (data.upload === 'original') {
                botao_download = 
                <td>
                    <a className="btn btn-info" href={self.core.getUrl() + self.core.getVersionAPI() + this.state.curl_excel1 + data.file_path + "&nome_arquivo=" + data.nome_arquivo + token } target="_blank"><i className="fa fa-download"></i>Download</a>
                </td>
                botao_analise = <td> </td> 
            } else {
                botao_download = 
                <td>
                    <a className="btn btn-danger" href={self.core.getUrl() + self.core.getVersionAPI() + this.state.curl_excel1 + data.file_path + "&nome_arquivo=" + data.nome_arquivo + token } target="_blank"><i className="fa fa-download"></i>Download M.Total</a>
                </td>
                botao_analise = <td> </td> 
                
                botao_analise = <td> 
                    <a className="btn btn-danger" href={self.core.getUrl() + self.core.getVersionAPI() + this.state.curl_excel2 + data.id_empr_arqv } target="_blank"><i className="fa fa-download"></i>Análise</a>
                </td> 
                
            }
        return <tr key={index} >
                <td>{data.data_upload}</td>
                <td>{data.hora_upload}</td>
                {botao_download}
                {botao_analise}
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.qtd_total > 0){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={4}>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Trabalhador - Empregador - Planilha</h2>
                <h3>Consulte as Planilhas que os Empregadores usaram para o atualizar o cadastro de trabalhadores</h3>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                        Informe os parametros necessários para a pesquisa
                        </h3>
                        <div  className="seleciona-trabalhador" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Empregador" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label> <b>Trabalhador</b> </label> <br/>
                                                <input type="radio" name="tbldr" value="1" /> Com Direito <br/>
                                                <input type="radio" name="tbldr" value="2" /> Sem Direito <br/>
                                        </div>
                                    </div>

                                    <div className="col-sm-1">
                                        <button type="Submit" ref="formulario" className="btn btn-primary">pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                        
                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                    <td>Totais</td>
                                    <td>Arquivos</td>
                                </thead>
                                <tbody>
                                    <td></td>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                </tbody>
                            </tr>
                        </table>

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>Data Upload</td>
                                    <td>Hora Upload</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(EmpresaTrabalhadorPlanilha))
