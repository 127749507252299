import React, { Component } from 'react';
import Alert from 'react-s-alert';
import './assets/sass/style.scss';

// import User from './components/User';
import Routes from './Routes';
import StatusApp from './components/StatusApp';
import smartlookClient from 'smartlook-client';

class App extends Component {
    componentWillMount() {
        let scripts = [
            '/global-theme/vendor/tether/tether.js',
            '/global-theme/vendor/bootstrap/bootstrap.js',
            // '/global-theme/vendor/animsition/animsition.js',
            '/global-theme/vendor/mousewheel/jquery.mousewheel.js',
            '/global-theme/vendor/asscrollbar/jquery-asScrollbar.js',
            '/global-theme/vendor/asscrollable/jquery-asScrollable.js',
            '/global-theme/vendor/ashoverscroll/jquery-asHoverScroll.js',
            '/global-theme/vendor/waves/waves.js',

            '/global-theme/vendor/switchery/switchery.min.js',
            '/global-theme/vendor/intro-js/intro.js',
            '/global-theme/vendor/screenfull/screenfull.js',
            '/global-theme/vendor/slidepanel/jquery-slidePanel.js',
            '/global-theme/vendor/jquery-placeholder/jquery.placeholder.js',
            '/global-theme/vendor/bootstrap-datepicker/bootstrap-datepicker.min.js',
            '/global-theme/vendor/bootstrap-datepicker/bootstrap-datepicker.pt-BR.min.js',

            '/global-theme/js/State.js',
            '/global-theme/js/Component.js',
            '/global-theme/js/Plugin.js',
            '/global-theme/js/Base.js',
            '/global-theme/js/Config.js',
            '/assets/js/Section/Menubar.js',
            '/assets/js/Section/GridMenu.js',
            '/assets/js/Section/Sidebar.js',
            '/assets/js/Section/PageAside.js',
            '/assets/js/Plugin/menu.js',
            '/global-theme/js/config/colors.js',
            '/assets/js/config/tour.js',

            '/assets/js/Site.js',
            '/global-theme/js/Plugin/asscrollable.js',
            '/global-theme/js/Plugin/slidepanel.js',
            '/global-theme/js/Plugin/switchery.js',
            '/global-theme/js/Plugin/jquery-placeholder.js',
            '/global-theme/js/Plugin/material.js',
        ];

        for(let i = 0; i < scripts.length; i++ ){
            let script = document.createElement("script");
            script.type = 'text/javascript';
            script.src = scripts[i];
            script.async = false;

            if(scripts[i] === '/global-theme/js/Config.js'){
                script.onload = window.configdone;
            }

            if(scripts[i] === '/assets/js/Site.js'){
                script.onload = window.sitedone;
            }
            document.body.appendChild(script);
        }
    }

    render() {
        return (
            <div className="App">
                <StatusApp />
                <Alert stack={{limit: 3}} />
                <Routes />
            </div>
        );
    }
    componentDidMount() {
        smartlookClient.init('10926a3480d08fd6df50b308253f2ab8f530c735')
    }
}

export default App;
