import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';

//images
import PictureNull  from '../../../images/avatar_null.png';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as StatusActions from '../../../actions/StatusActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';

class Perfil extends React.Component {
    constructor(props){
        super(props);
        this.core = new Core();
        
        this.core.isAuth();
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    getPicture(){
        let data = this.props.user.foto;

        if(data !== "" && data !== null){
            return data;
        }else{
            return PictureNull;
        }
    }

    render(){
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        return <div className="page">
            <div className="page-content">
                <h2>Perfil</h2>
                <p>Visualize as informações referente ao seu Perfil</p>

                <div className="row">
                    <div className="col-sm-3">
                        <div className="panel">
                            <div className="panel-body">
                                <div className="image-profile" style={ { background: 'url(' + this.getPicture()+')' } }>
                                    
                                </div>
                                <h3 className="center light-font title-perfil">{this.props.user.nome}</h3>
                                <small className="center light-font">{this.props.user.config.grupo.nome}</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-9">
                        <div className="panel">
                            <div className="panel-body">
                                <form action="" className="perfil">
                                    
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="nome">Nome</label>
                                                <h5>{this.props.user.nome}</h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="nome">E-mail</label>
                                                <h5>{this.props.user.email}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="nome">CPF</label>
                                                <h5>{this.props.user.doc}</h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="nome">Cargo</label>
                                                <h5>{this.props.user.cargo}</h5>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}

export default withRouter(connect(mapStateProps, mapDispatchProps)(Perfil));
