import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as UserActions from '../../../actions/UserActions';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusManagerActions from '../../../actions/StatusManagerActions';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';


class Index extends React.Component {
    constructor(props){
        super(props);
        let core = new Core();
        core.isAuth();
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    render() {
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }
        return <div className="page">
            <div className="page-content">
                {/* <h2>BEM VINDO {this.props.user.nome}</h2> */}
                <p>BEM VINDO {this.props.user.nome}</p>
            </div>
      </div>
    }
}

function mapStateProps(state, props ){
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch){
    return {
        action: bindActionCreators({
                ...UserActions,
                ...StatusManagerActions,
                ...RedirectActions
            }, dispatch )
        }
}

export default withRouter(connect(mapStateProps, mapDispatchProps)(Index));
