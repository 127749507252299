import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as StatusActions from '../../../actions/StatusActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';

class Index extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        
        this.core.isAuth();
    }

    componentWillMount(){
        let self = this;
        this.props.action.showManager();
        console.log(this.core.formatAutorizationToken(this.props.user.token));
        this.props.action.loadingStatus();
        // TODO: fazer chamada
        fetch(this.core.getUrl() + this.core.getVersionAPI() + "usuario/all",{
            method: "POST",
            headers: {
                'Authorization': this.core.formatAutorizationToken(this.props.user.token)
            }
        })
        .then(res => this.core.responseConvert(res))
        .then((res) => {
            self.props.action.loadedStatus();
            console.log("usuarios",res);
        })
        .catch(err => {
            this.core.catchFecth(err);
            self.props.action.loadedStatus();
        });
    }

    componentWillUnmount(){
        this.props.action.redirectClear();
    }

    render(){
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        return <div className="page">
            <div className="page-content">
                <h2>USUÁRIOS</h2>
                <p>Lista de usuários no sistema administrativo.</p>
                <div className="panel">
                    <div className="panel-title">

                    </div>
                    <div className="panel-content">
                        
                    </div>
                </div>
            </div>
        </div>
    }
}


function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}

export default withRouter(connect(mapStateProps, mapDispatchProps)(Index));
