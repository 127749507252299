import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import Alert from "react-s-alert";
import Select from "react-select";
import TinyMCE from "tinymce-react";
import { ModalDialog, ModalContainer } from "react-modal-dialog-react16";
import $ from "jquery";
import base64 from "base-64";

//class
import Core from "../../../class/Core";

//actions
import * as UserActions from "../../../actions/UserActions";
import * as ManagerStatusActions from "../../../actions/StatusManagerActions.js";
import * as RedirectActions from "../../../actions/RedirectActions";
import * as StatusActions from "../../../actions/StatusActions";
import { CLIENT_RENEG_LIMIT } from "tls";
import Index from "../dashboard/Index";

class CreateNotificacoes extends React.Component {
    constructor(props) {
        super(props);
        this.core = new Core();
        this.core.isAuth();

        this.state = {
            modelos: [],
            planos: [],
            selecteds: [],
            html_content: null,
            html_content_ghost: null,
            redirect: null,
            tipo: "NORMAL",
            dados: {},
            modal: false,
            allModels: [],
        };

        this.handlerSubmit = this.handlerSubmit.bind(this);
        this.handleChangeModel = this.handleChangeModel.bind(this);
        this.handlerSelect = this.handlerSelect.bind(this);
        this.changeType = this.changeType.bind(this);
        this.handleFileSelect = this.handleFileSelect.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.ModalInfosend = this.ModalInfosend.bind(this);
    }

    componentWillMount() {
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();
        this.props.action.loadingStatus();
        self.props.action.loadedStatus();

        fetch(self.core.getUrlConecta() + "emails/modelo", {
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: self.core.formatAutorizationToken(
                    self.core.getToken()
                ),
            },
        })
            .then((res) => self.core.responseConvert(res))
            .then((response) => {
                self.setState({
                    allModels: response,
                });
                let data = response.content.map((data, index) => {
                    return (
                        <option key={index + 1} value={data.id_eml_modl}>
                            {" "}
                            {data.id_eml_modl} - {data.obs_eml_modl}{" "}
                        </option>
                    );
                });

                data.splice(
                    0,
                    0,
                    <option key={0} value="">
                        SELECIONE UM MODELO
                    </option>
                );
                self.setState({
                    modelos: data,
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err);
                self.props.action.loadedStatus();
            });

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "planos", {
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: self.core.formatAutorizationToken(
                    self.core.getToken()
                ),
            },
        })
            .then((res) => self.core.responseConvert(res))
            .then((response) => {
                ///mapeia o valores vindo de planos e faz um sort para ordenação de texto
                let planos = response.content
                    .map((data, index) => {
                        let tela_rosa_id = data.id_tr_plno || 0;
                        return {
                            value: data.id_plno,
                            label: tela_rosa_id + " - " + data.dscr_plno,
                            id: tela_rosa_id,
                        };
                    })
                    .sort(function (a, b) {
                        return parseInt(a.id) - parseInt(b.id);
                    });

                //adicionando todos os planos como opção
                planos.splice(0, 0, {
                    value: "all",
                    label: "TODOS OS PLANOS",
                    id: 0,
                });

                this.setState({
                    planos: planos,
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err);
                self.props.action.loadedStatus();
            });

        // Verificando as condições do formulario TIPO

        // fetch(self.core.getUrl() + self.core.getVersionAPI() + "email/modelos", {
        //     headers: {
        //         'Accept': 'application/json, text/plain, */*',
        //         'Content-Type': 'application/json',
        //         'Authorization': self.core.formatAutorizationToken(self.core.getToken())
        //     }
        // })
        //     .then(resp => self.core.responseConvert(resp))
        //     .then((response) => {
        //         let data = response.content.map((data, index) => {
        //             return <option key={index + 1} value={data.id}>{data.assunto}</option>
        //         });

        //         data.splice(0, 0, <option key={0} value="">Selecione uma opção</option>);

        //         self.setState({
        //             modelos: data
        //         });
        //         self.props.action.loadedStatus();
        //     });
    }

    handlerSelect(valor) {
        this.setState({
            selecteds: valor,
        });
    }

    handleChangeModel(e) {
        let id_model = e.target.value;
        let self = this;

        fetch(
            self.core.getUrl() +
                self.core.getVersionAPI() +
                "email/email-modelo/" +
                id_model,
            {
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    Authorization: self.core.formatAutorizationToken(
                        self.core.getToken()
                    ),
                },
            }
        )
            .then((res) => self.core.responseConvert(res))
            .then((response) => {
                this.setState({
                    html_content: response.content.html_eml_modl,
                    html_content_ghost: response.content.html_eml_modl,
                });
            });
    }

    async handlerSubmit(e) {
        e.preventDefault();

        const self = this;
        const TIPO = this.state.tipo;
        let planos = this.state.selecteds.map((data, index) => {
            return data.value;
        });

        if (planos.length === 0 && e.target.tipoEnvio.value === "NORMAL") {
            Alert.warning("O campo Planos é obrigatório", {
                position: "bottom-right",
                effect: "slide",
                timeout: 5000,
            });

            return false;
        }

        if (
            e.target.tipo.value === "" &&
            e.target.tipoEnvio.value === "NORMAL"
        ) {
            Alert.warning("Selecione o tipo de empresa", {
                position: "bottom-right",
                effect: "slide",
                timeout: 5000,
            });
            return false;
        }

        // this.props.action.loadingStatus();
        let send = {
            tipo_notfo: e.target.tipoEnvio.value,
            nome_notfo: e.target.titulo.value,
            tpo_notfo: e.target.tipo.value,
            plno_notfo: planos,
            cnpj_notfo: e.target.cnpj.value,
            desc_notfo: this.state.html_content,
            model_notfo: e.target.modelo.value,
        };

        let sendArquivo = new FormData();
        let input = document.querySelector(".upload-lista");

        sendArquivo.append("arquivo", input.files[0]);
        sendArquivo.append("tipo_notfo", e.target.tipoEnvio.value);
        sendArquivo.append("nome_notfo", e.target.titulo.value);
        sendArquivo.append("tpo_notfo", e.target.tipo.value);
        sendArquivo.append("plno_notfo", planos);
        sendArquivo.append("cnpj_notfo", e.target.cnpj.value);
        sendArquivo.append("desc_notfo", this.state.html_content);
        sendArquivo.append("model_notfo", e.target.modelo.value);

        switch (e.target.tipoEnvio.value) {
            case "ARQUIVO":
                this.setState({
                    dados: sendArquivo,
                    modal: true,
                });
                break;

            default:
                this.setState({
                    dados: send,
                    modal: true,
                });

                break;
        }

        // console.log(this.state.dados)

        //FIXME: ALterar para Node as noticações

        // fetch(self.core.getUrl() + self.core.getVersionAPI() + "email/notificacoes", {
        //     headers: {
        //         'Accept': 'application/json, text/plain, */*',
        //         'Content-Type': 'application/json',
        //         'Authorization': self.core.formatAutorizationToken(self.core.getToken())
        //     },
        //     method: 'POST',
        //     body: JSON.stringify(send)
        // })
        //     .then(res => self.core.responseConvert(res))
        //     .then((response) => {
        //         Alert.success("Registro inserido com sucesso!", {
        //             position: 'bottom-right',
        //             effect: 'slide',
        //             timeout: 8000
        //         });

        //         self.props.action.loadedStatus();

        //         self.setState({
        //             redirect: "/manager/notificacoes"
        //         });
        //     })
        //     .catch((err) => {
        //         self.core.catchFecth(err);
        //         self.props.action.loadedStatus();
        //     });
    }

    changeType(e) {
        const TIPO = e.target.value;

        switch (TIPO) {
            case "INDIVIDUAL":
                $(".normal").hide("slow");
                $(".arquivo").hide("slow");
                $(".cnpj").show("slow");
                this.setState({
                    tipo: "INDIVIDUAL",
                });
                break;
            case "ARQUIVO":
                $(".normal").hide("slow");
                $(".cnpj").hide("slow");
                $(".arquivo").show("slow");
                this.setState({
                    tipo: "ARQUIVO",
                });
                break;
            default:
                $(".normal").show("fast");
                $(".cnpj").hide("slow");
                $(".arquivo").hide("slow");
                this.setState({
                    tipo: "NORMAL",
                });
                break;
        }
    }

    handleFileSelect(evt) {
        const self = this;
        let files = evt.target.files; // FileList object

        // use the 1st file from the list
        let f = files[0];

        const reader = new FileReader();
        // Closure to capture the file information.
        reader.onload = (function (theFile) {
            return (e) => {
                console.log(e.target.result);
                self.setState({
                    html_content: e.target.result,
                });
            };
        })(f);

        // Read in the image file as a data URL.
        reader.readAsText(f);
    }

    handleClose() {
        this.setState({
            modal: false,
        });
    }

    ModalInfosend(event) {
        event.preventDefault();
        let self = this;

        let user = {
            usuario: base64.encode(
                event.target.email.value + ":" + event.target.senha.value
            ),
        };
        let send = this.state.dados;
        //Apenas quando foi arquivo
        if (this.state.dados instanceof FormData) {
            send.append(
                "usuario",
                base64.encode(
                    event.target.email.value + ":" + event.target.senha.value
                )
            );
        }

        let dados =
            this.state.dados instanceof FormData
                ? send
                : JSON.stringify(Object.assign({}, send, user));

        this.props.action.loadingStatus();

        if (this.state.dados instanceof FormData) {
            console.log("Foi como formDATA");
            fetch(
                self.core.getUrl() +
                    self.core.getVersionAPI() +
                    "email/notificacoes",
                {
                    headers: {
                        Authorization: self.core.formatAutorizationToken(
                            self.core.getToken()
                        ),
                    },
                    method: "POST",
                    body: dados,
                }
            )
                .then((res) => self.core.responseConvert(res))
                .then((response) => {
                    Alert.success(
                        "Lista de notificações gerada com sucesso!\nAguarde o envio.",
                        {
                            position: "bottom-right",
                            effect: "slide",
                            timeout: 8000,
                        }
                    );

                    self.props.action.loadedStatus();

                    self.setState({
                        redirect: "/manager/notificacoes",
                    });
                })
                .catch((err) => {
                    self.core.catchFecth(err);
                    self.props.action.loadedStatus();
                });
        } else {
            console.log("FOI NORMAL");
            fetch(
                self.core.getUrl() +
                    self.core.getVersionAPI() +
                    "email/notificacoes",
                {
                    headers: {
                        Accept: "application/json, text/plain, */*",
                        "Content-Type": "application/json",
                        Authorization: self.core.formatAutorizationToken(
                            self.core.getToken()
                        ),
                    },
                    method: "POST",
                    body: dados,
                }
            )
                .then((res) => self.core.responseConvert(res))
                .then((response) => {
                    Alert.success("Notificação enviada com sucesso", {
                        position: "bottom-right",
                        effect: "slide",
                        timeout: 8000,
                    });

                    self.props.action.loadedStatus();

                    self.setState({
                        redirect: "/manager/notificacoes",
                    });
                })
                .catch((err) => {
                    self.core.catchFecth(err);
                    self.props.action.loadedStatus();
                });
        }
    }

    modalLoginConfirm() {
        let tipoEnvio = "";
        switch (this.state.dados.tpo_notfo) {
            case "disponibilidade":
                tipoEnvio = "TODAS AS EMPRESAS DOS PLANOS SELECIONADOS";
                break;
            case "inadimplentes":
                tipoEnvio =
                    "TODAS AS EMPRESAS INADINPLENTES DOS PLANOS SELECIONADOS";
                break;
            case "nao-impressos":
                tipoEnvio =
                    "TODAS AS EMPRESAS SEM BOLETO IMPRESSO DOS PLANOS SELECIONADOS";
        }
        let planos = [];
        if (this.state.dados.plno_notfo) {
            planos =
                this.state.dados.plno_notfo.map((val, index) => {
                    let indexOf = this.state.planos
                        .map((e) => e.value)
                        .indexOf(val);
                    let data = this.state.planos[indexOf];
                    return <li key={index}>{data.label}</li>;
                }) || [];
        }
        let modelo = [];
        if (this.state.dados.model_notfo) {
            this.state.allModels.content.filter((data) => {
                let modelType = this.state.dados.model_notfo;
                if (data.id == modelType) {
                    modelo.push(data.assunto);
                }
            });
        }
        console.log(this.state.dados);

        return (
            <div>
                {this.state.modal && (
                    <ModalContainer
                        onClose={this.handleClose}
                        style={{ zIndex: 99999 }}
                    >
                        <ModalDialog onClose={this.handleClose}>
                            <div style={{ maxWidth: 900 }}>
                                <h3>Analise para envio da notificação</h3>
                                <p>Confirme os dados para envio</p>

                                <p>
                                    <b>ASSUNTO:</b>{" "}
                                    {this.state.dados.nome_notfo ||
                                        this.state.dados.get("nome_notfo")}
                                </p>
                                <p>
                                    <b>MODELO:</b> {modelo}
                                </p>
                                <p>
                                    <b>TIPO DE EMPRESAS:</b> {tipoEnvio}
                                </p>

                                <b>PLANOS:</b>
                                <ul className="view-box-planos">{planos}</ul>

                                <p>
                                    Para confirmar o envio coloque seu usuário e
                                    senha
                                </p>
                                <form onSubmit={this.ModalInfosend}>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            required
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            name="senha"
                                            required
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button className="btn" type="submit">
                                            ENVIAR NOTIFICAÇÃO
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </ModalDialog>
                    </ModalContainer>
                )}{" "}
            </div>
        );
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }

        if (this.props.redirect.url) {
            return <Redirect to={this.props.redirect.url} />;
        }

        return (
            <div className="page">
                {this.modalLoginConfirm()}
                <div className="page-content">
                    <h4>CRIAR NOTIFICAÇÃO</h4>

                    <div className="panel">
                        <div className="panel-body container-fluid">
                            <form action="#" onSubmit={this.handlerSubmit}>
                                <div className={"row"}>
                                    <div className="col-sm-8">
                                        <div className="form-group">
                                            <label htmlFor="select">
                                                Tipo de envio
                                            </label>
                                            <select
                                                className="form-control"
                                                name="tipoEnvio"
                                                onChange={this.changeType}
                                            >
                                                <option value="NORMAL">
                                                    Normal
                                                </option>
                                                <option value="INDIVIDUAL">
                                                    Individual
                                                </option>
                                                <option value="ARQUIVO">
                                                    Arquivo
                                                </option>
                                            </select>
                                        </div>

                                        <div className="form-group ">
                                            <label>Título</label>
                                            <input
                                                type="text"
                                                name="titulo"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <label>Modelos</label>
                                                <select
                                                    className="form-control"
                                                    onChange={
                                                        this.handleChangeModel
                                                    }
                                                    name="modelo"
                                                    showSearch={true}
                                                >
                                                    {this.state.modelos}
                                                </select>
                                            </div>
                                            <div className="col-sm-6">
                                                <label>Carregar HTML</label>
                                                <input
                                                    id="upload"
                                                    type="file"
                                                    className="form-control"
                                                    accept="text/html"
                                                    onChange={
                                                        this.handleFileSelect
                                                    }
                                                    name="files[]"
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>Mensagem</label>
                                            <TinyMCE
                                                id="value_html"
                                                name="editor"
                                                // apiKey={YOUR_TINYMCE_API_KEY}
                                                config={{
                                                    height: 200,
                                                    plugins:
                                                        "image imagetools table code wordcount media paste fullpage",
                                                    image_advtab: true,
                                                    image_caption: true,
                                                    images_upload_url:
                                                        this.core.getUrl() +
                                                        this.core.getVersionAPI() +
                                                        "uploads/images",
                                                    automatic_uploads: false,
                                                    file_browser_callback_types:
                                                        "file image media",
                                                    imagetools_toolbar:
                                                        "rotateleft rotateright | flipv fliph | editimage imageoptions",
                                                    toolbar1:
                                                        "formatselect image code fullpage | bold italic  strikethrough  forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat",
                                                }}
                                                content={
                                                    this.state
                                                        .html_content_ghost
                                                }
                                                SetContent={
                                                    this.state.html_content
                                                }
                                                onContentChanged={(content) =>
                                                    this.setState({
                                                        html_content: content,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="row normal">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Tipos de empresas
                                                    </label>
                                                    <select
                                                        name="tipo"
                                                        className="form-control empresas-required"
                                                    >
                                                        <option value="">
                                                            SELECIONE UM PERFIL
                                                            PARA ENVÍO
                                                        </option>
                                                        <option value="disponibilidade">
                                                            TODAS AS EMPRESAS
                                                        </option>
                                                        <option value="inadimplentes">
                                                            EMPRESAS
                                                            INADIMPLENTES
                                                        </option>
                                                        <option value="nao-impressos">
                                                            EMPRESAS SEM
                                                            IMPRESSÃO
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Selecione os Planos
                                                    </label>
                                                    <Select
                                                        name="planos"
                                                        multi={true}
                                                        value={
                                                            this.state.selecteds
                                                        }
                                                        options={
                                                            this.state.planos
                                                        }
                                                        onChange={
                                                            this.handlerSelect
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="row">
                                                <div
                                                    className="col-sm-12 arquivo"
                                                    style={{ display: "none" }}
                                                >
                                                    <label htmlFor="input">
                                                        Arquivo Excel
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control upload-lista"
                                                        name="arquivo"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="row cnpj"
                                            style={{ display: "none" }}
                                        >
                                            <div className="form-group">
                                                <div className="col-sm-12 ">
                                                    <label htmlFor="">
                                                        CNPJ
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="cnpj"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <br />
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success"
                                                    >
                                                        Enviar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer,
    };
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators(
            {
                ...UserActions,
                ...ManagerStatusActions,
                ...RedirectActions,
                ...StatusActions,
            },
            dispatch
        ),
    };
}

export default withRouter(
    connect(mapStateProps, mapDispatchProps)(CreateNotificacoes)
);
