import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect} from 'react-router-dom';
import Core from '../../../class/Core';
import moment from 'moment';
import InputMask from 'react-input-mask';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class UltimoRecxPagto extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            qtd_total: 0,
            paramters: null,
            excel: 'nao',
            processado: "invisible"  
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let form = new FormData();
        let self = this;

        let data = {
        }

        data.nome_arquivo = event.target.arquivo.files[0];

        data.pesquisa_parcial =  'nao';

        switch(event.target.col_chave.value){
            case "1":
                data.coluna_chave = "CNPJ_RECNUM";
                break;
            case "2":
                data.coluna_chave = "CNPJ";
                break;
            case "3":
                data.coluna_chave = "EMPR_CD_NSEQ";
                break;
            case "4":
                data.coluna_chave = "EMCV_CD_NSEQ";
                break;
            default:
                data.coluna_chave = "CNPJ_RECNUM";
                break;
        }

        data.data_comunicado = event.target.comunicados_apos.value;

        if (this.state.excel != 'sim') {
            form.append("nome_arquivo", data.nome_arquivo);
            form.append("pesquisa_parcial", data.pesquisa_parcial);
            form.append("coluna_chave", data.coluna_chave);
            form.append("data_comunicado", data.data_comunicado);
            
            this.props.action.loadingStatus();
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-ultimo-recxpagtoall", {
                headers: {
                 
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: form
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                self.setState({
                    relatorio: response.content, 
                    qtd_total: response.content.length,
                    processado: "visible" 
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            }); 
        } 
        if (this.state.excel === 'sim') {
            this.state.paramters = '?pesquisa_parcial=' + data.pesquisa_parcial;
            this.state.paramters += '&nome_arquivo=' + data.nome_arquivo.name;  
            this.state.paramters += '&coluna_chave=' + data.coluna_chave;  
            this.state.paramters += '&data_comunicado=' + data.data_comunicado;  
            this.state.paramters += '&token=' + self.core.getToken();   
            let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-ultimo-recxpagtoall"+this.state.paramters;
            window.location.href = urlExcel        
        }           
    }

    showLista(){
        console.log("showLista1");
        this.setState({
            excel: 'nao'
        });
    }

    showExcel(){
        console.log("showExcel1");
        this.setState({
            excel: 'sim'
        });
    }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.cnpj}</td>
                <td>{data.razao_social}</td>
                <td>{data.recnum}</td>
                <td>{data.plano}</td>
                <td>{data.situacao_cadastro}</td>
                <td>{data.vcto_ult_recolhimento}</td>
                <td>{data.ult_recolhimento}</td>
                <td>{data.qt_empregados}</td>
                <td>{data.pri_vecto_aberto}</td>
                <td>{data.ult_vecto_aberto}</td>
                <td>{data.qtd_vecto_aberto}</td>
                <td>{parseFloat(data.vl_apropriacoes).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                <td>{data.situacao_cobranca}</td>
                <td>{data.responsavel_cobranca}</td>
            </tr>
        });
    }

    render(){
        let tableContent;

        if (this.props.redirect.url) {
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={14}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
        <form  onSubmit={this.handlerSubmit}>
            <div className="page-content">
                <h2>Análise de Pagamentos das Empresas - Ultimo Recolhimento x Meses em Aberto</h2>

                <div className="panel">
                    <div className='panel-title'>
                        <h4>
                            Certifique-se que a planilha a ser importada tenha as colunas chaves: [CNPJ e RECNUM] ou [ID ou EMPR_CD_NSEQ] ou [CNPJ] ou [EMCV_CD_NSEQ].
                        </h4>
                        <h4>
                            Certifique-se que a 1a linha da planilha tenha os nomes das Colunas!
                        </h4>
                        
                        <div  className="seleciona-mes-doc" >
                            
                            <div className="row">
                                <div className="form-group">
                                    <input className="form-control" type="file" name="arquivo" placeholder="Nome" required onChange={(event)=> { 
                                        this.setState({
                                            processado: 'invisible'
                                        });
                                        return false
                                    }}/>
                                </div>

                                <div className="col-sm-1">
                                </div>

                                <div className="col-sm-2">
                                        <div className="form-group">
                                        <label> <b>Colunas Chave </b> </label> <br/>
                                            <input type="radio" name="col_chave" value="1" />CNPJ e RECNUM <br/>
                                            <input type="radio" name="col_chave" value="2" />CNPJ <br/>
                                            <input type="radio" name="col_chave" value="3" />ID Empresa (T.Rosa)<br/>
                                            <input type="radio" name="col_chave" value="4" />EMCV_CD_NSEQ (T.Rosa) <br/>
                                        </div>
                                </div>

                                <div className="col-sm-2">
                                        <div className="form-group">
                                            <label><b>Comunicados Apos:<small>(MM/AAAA)</small></b></label>
                                            <InputMask name="comunicados_apos" id="comunicados_apos" placeholder="Mês/Ano Inicio Comunicados" className="form-control" mask="99/9999" />
                                        </div>
                                </div>


                                <div className="col-sm-1">
                                    <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div className="panel-body container-fluid">

                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                    <td>Qtd Registros: {this.state.qtd_total.toLocaleString('de-DE')}</td>
                                </thead>
                                <tbody>
                                    <td><button type="submit" className={`btn btn-info ${this.state.processado}`} onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal"> <i className="fa fa-download"></i> Gerar em Excel</button></td>
                                </tbody>
                            </tr>
                        </table>
                        

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>CNPJ</td>
                                    <td>Empresa</td>
                                    <td>Recnum</td>
                                    <td>Plano</td>
                                    <td>Sit.Cadastro</td>
                                    <td>Vcto Ult Recolhimento</td>
                                    <td>Ult Recolhimento</td>
                                    <td>Qtd Trabalhadores</td>
                                    <td>1o Mes em Aberto</td>
                                    <td>Último Mes em Aberto</td>
                                    <td>Qtd Meses em Aberto</td>
                                    <td>Vl Apropriações</td>
                                    <td>Cobrança</td>
                                    <td>Responsável</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
            </form>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(UltimoRecxPagto))
