import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
//import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class NetPointsEmpresas extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            trabs: [],
            qtd_total: 0
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        data.empresa_pesquisa = event.target.empresa_pesquisa.value;
        switch(event.target.cadastro_cpf.value){
            case "1":
                data.empresas_cadastradas = "sim";
                break;
            case "2":
                data.empresas_cadastradas = "nao";
                break;
            default:
                data.empresas_cadastradas = "";
                break;
        }

        this.props.action.loadingStatus();

            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-netpoints-empresas", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                self.setState({
                    relatorio: response.content,
                    qtd_total: response.content.length
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });
    }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.cnpj_empr}</td>
                <td>{data.razao_social}</td>
                <td>{data.dt_cadastro}</td>
                <td>{data.operador}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={4}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa.</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Empresas -  Clube de Vantagens</h2>
                <p>Consulte as Empresas participantes do Clube de Vantagens</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Selecione o tipo de pesquisa, Empresas com cadastro de CPF ou Empresas sem o cadastro de CPF
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Todas as Empresas" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                        <label> <b>Cadastro NetPoints</b> </label> <br/>
                                            <input type="radio" name="cadastro_cpf" value="1" /> Com Vínculo de CPF <br/>
                                            <input type="radio" name="cadastro_cpf" value="2" /> Sem Vínculo de CPF <br/>
                                            <input type="radio" name="cadastro_cpf" value="9" /> Ambos <br/>
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-danger">pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                        
                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                    <td>Qtd Empresas: {this.state.qtd_total.toLocaleString('de-DE')}</td>
                                </thead>
                                <tbody>
                                    <td></td>
                                </tbody>
                            </tr>
                        </table>
                        
                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>CNPJ</td>
                                    <td>Empresa</td>
                                    <td>Dt Vínculo</td>
                                    <td>Operador</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(NetPointsEmpresas))
