import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import Core from "../../../class/Core";
import InputMask from "react-input-mask";
import moment from "moment";
import "moment/locale/pt-br";

//actions
import * as UserActions from "../../../actions/UserActions";
import * as ManagerStatusActions from "../../../actions/StatusManagerActions.js";
import * as RedirectActions from "../../../actions/RedirectActions";
import * as StatusActions from "../../../actions/StatusActions";

class Pagamentos extends React.Component {
    constructor(props) {
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66;
        this.height = (this.width * window.innerHeight) / window.innerWidth;
        //define locale moment
        moment.locale("pt-BR");
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            trabs: [],
            qtd_total: 0,
            total: 0,
            excel: "nao",
            paramters: null,
        };

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    callentidades = async () => {
        const self = this;
        let arr = [];
        try {
            let res = await fetch(
                self.core.getUrl() +
                    self.core.getVersionAPI() +
                    "empresa/relatorios/relacao-adicional",
                {
                    headers: {
                        Accept: "application/json, text/plain, */*",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + self.core.getToken(),
                    },
                }
            );

            if (!res.ok) {
                throw res.json();
            }

            res = await res.json();

            arr = res.content.map((el, ind) => {
                return (
                    <option key={"ent__" + ind} value={el.indice}>
                        {el.entidade}
                    </option>
                );
            });

            this.setState({
                listEntidades: arr,
            });

            return true;
        } catch (error) {
            // console.log("erro", error)
            return false;
        }
    };

    componentWillMount() {
        this.props.action.showManager();
        this.props.action.redirectClear();
        this.callentidades();
    }

    handlerSubmit(event) {
        event.preventDefault();
        let self = this;

        let data = {};

        //data.plano = event.target.plano.value;
        data.date_ini = event.target.date_ini.value;
        data.date_fim = event.target.date_fim.value;
        //data.favorecido = '1543';
        data.entidade = event.target.entidade.value;
        data.excel = "nao";
        data.cnpj =
            event.target.cnpj.value != ""
                ? `&cnpj=${event.target.cnpj.value}`
                : "";

        this.state.paramters = "?";
        this.state.paramters += "inicio=" + data.date_ini;
        this.state.paramters += "&final=" + data.date_fim;
        this.state.paramters += "&entidade=" + data.entidade;
        // this.state.paramters += "&cnpj=" + data.cnpj;
        if (data.cnpj) {
            this.state.paramters += data.cnpj;
        }

        //this.state.paramters += '&token=' + this.core.getToken();

        console.log(this.state.paramters);
        if (this.state.excel != "sim") {
            data.excel = "nao";
            this.props.action.loadingStatus();
            fetch(
                self.core.getUrl() +
                    self.core.getVersionAPI() +
                    "empresa/relatorios/cobranca-adicional" +
                    this.state.paramters,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + this.core.getToken(),
                    },
                    method: "GET",
                }
            )
                .then((res) => self.core.responseConvert(res))
                .then((response) => {
                    let qtd_trabalhadores1 = 0;
                    this.setState.qtd_total = 0;

                    response.content.map((val, index) => {
                        qtd_trabalhadores1 += 1;
                    });
                    self.setState({
                        relatorio: response.content,
                        qtd_total: response.resumo.qtde,
                        total: response.resumo.valor,
                    });
                    self.props.action.loadedStatus();
                })
                .catch((err) => {
                    self.core.catchFecth(err);
                    self.props.action.loadedStatus();
                });
        }
        if (this.state.excel === "sim") {
            data.excel = "sim";
            this.state.paramters += "&excel=" + data.excel;
            this.state.paramters += "&token=" + this.core.getToken();
            let urlExcel = null;
            urlExcel =
                this.core.getUrl() +
                this.core.getVersionAPI() +
                "empresa/relatorios/cobranca-adicional" +
                this.state.paramters;
            window.location.href = urlExcel;
        }
    }

    showLista() {
        console.log("showLista1");
        this.setState({
            excel: "nao",
        });
    }

    showExcel() {
        console.log("showExcel1");
        this.setState({
            excel: "sim",
        });
    }

    getTableShow() {
        return this.state.relatorio.map((data, index) => {
            return (
                <tr key={index}>
                    <td>{data.CNPJ}</td>
                    <td>{data.Empregador}</td>
                    <td>{data.Plano}</td>
                    <td>{data.UF}</td>
                    <td>{data.Nosso_Numero}</td>
                    <td>
                        {data.Parcela}/{data.Forma_Pagamento}
                    </td>
                    <td>R$ {parseFloat(data.Valor_Adicional).toFixed(2)}</td>
                    <td>{data.Vencimento}</td>
                    <td>{data.Pagamento}</td>
                </tr>
            );
        });
    }
    clearMask(e) {
        console.log(e.target.value);
        let a = document.getElementById("cnpj").value;
        a = a.replace(/[^\d]+/g, "");

        document.getElementById("cnpj").value = "";
        document.getElementById("cnpj").value = a;
    }

    render() {
        let tableContent;
        if (this.props.redirect.url) {
            return <Redirect to={this.props.redirect.url} />;
        }

        if (this.state.relatorio.length) {
            tableContent = this.getTableShow();
        } else {
            tableContent = (
                <tr>
                    <td colSpan={9}>
                        <center>
                            <b>
                                Informe os parametros necessários para a
                                pesquisa.
                            </b>
                        </center>
                    </td>
                </tr>
            );
        }

        return (
            <div className="page">
                <div className="page-content">
                    <h2>Contribuição Assistencial</h2>
                    <p>
                        Consulte as contribuições realizadas pelas empresas que
                        recolhem pelo Beneficio Social Familiar
                    </p>

                    <div className="panel">
                        <div className="panel-title">
                            <h3>
                                Informe o intervalo de pagamento para a consulta
                            </h3>
                            <div className="seleciona-trabalhador">
                                <form onSubmit={this.handlerSubmit}>
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <InputMask
                                                    name="date_ini"
                                                    id="data_ini2"
                                                    placeholder="Data início Pagto"
                                                    className="form-control"
                                                    mask="99/99/9999"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <InputMask
                                                    name="date_fim"
                                                    id="data_fim2"
                                                    placeholder="Data fim Pagto"
                                                    className="form-control"
                                                    mask="99/99/9999"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <select
                                                    name="entidade"
                                                    id="entidade_val"
                                                    className="form-control"
                                                    required
                                                >
                                                    <option selected value={""}>
                                                        SELECIONE A ENTIDADE
                                                    </option>
                                                    {this.state.listEntidades}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <input
                                                    name="cnpj"
                                                    id="cnpj"
                                                    placeholder="CNPJ"
                                                    onChange={(e) =>
                                                        this.clearMask(e)
                                                    }
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-1">
                                            <button
                                                type="Submit"
                                                className="btn btn-primary"
                                                onClick={(event) => {
                                                    this.showLista();
                                                }}
                                            >
                                                pesquisar
                                            </button>
                                        </div>
                                        <div className="col-sm-1">
                                            <button
                                                type="submit"
                                                className="btn btn-info"
                                                onClick={(event) => {
                                                    this.showExcel();
                                                }}
                                                data-toggle="modal"
                                                data-target="#excelModal"
                                            >
                                                Excel
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="panel-body container-fluid">
                            <table className="table table-striped table-doc">
                                <tr>
                                    <thead>
                                        <td>Qtde</td>
                                        <td>Arrecadado</td>
                                    </thead>
                                    <tbody>
                                        <td style={{ textAlign: "right" }}>
                                            {this.state.qtd_total.toLocaleString(
                                                "de-DE"
                                            )}
                                        </td>
                                        <td>
                                            R${" "}
                                            {parseFloat(
                                                this.state.total
                                            ).toFixed(2)}
                                        </td>
                                    </tbody>
                                </tr>
                            </table>
                            <table className="table table-striped table-doc">
                                <thead>
                                    <tr>
                                        <td>CNPJ</td>
                                        <td>Razão Social</td>
                                        <td>Plano</td>
                                        <td>UF</td>
                                        <td>Nosso numero</td>
                                        <td>Parcela</td>
                                        <td>Valor</td>
                                        <td>Dt. Vencimento</td>
                                        <td>Dt. Pagamento</td>
                                    </tr>
                                </thead>
                                <tbody>{tableContent}</tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer,
    };
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators(
            {
                ...UserActions,
                ...ManagerStatusActions,
                ...RedirectActions,
                ...StatusActions,
            },
            dispatch
        ),
    };
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(Pagamentos));
