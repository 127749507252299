export const loadingStatus = () => {
    return {
        type: 'STATUS_LOADING',
    }
}

export const loadedStatus = () => {
    return {
        type: 'STATUS_LOADED',
    }
}
