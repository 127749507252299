import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as StatusActions from '../../actions/StatusActions';
import * as UserActions from '../../actions/UserActions';

import Logo from '../../images/logo.png';
import Avatar from '../../images/avatar_null.png';

class TopBar extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            avatar: Avatar
        }

        this.logoutUser = this.logoutUser.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.user.foto !== "" && nextProps.user.foto !== null){
            this.setState({
                avatar: nextProps.user.foto_user
            });
        }else{
            this.setState({
                avatar: Avatar
            });
        }
   }

   logoutUser(event){
        if(this.props.user.tipo === "user"){
            this.props.action.logoutUser()
            window.location.href = "/login"
        }else{
           this.props.action.logoutUser();
            window.location.href = "/";
        }
    }

    getContent(){
        let dev = "";
        if(process.env.NODE_ENV && process.env.NODE_ENV === "development"){
            dev = "delevopment_url"
        }
        return <nav className="site-navbar navbar navbar-default navbar-fixed-top navbar-mega">
            <div className={"navbar-header "+ dev}>
                <button type="button" className="navbar-toggler hamburger hamburger-close navbar-toggler-left hided" data-toggle="menubar">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="hamburger-bar"></span>
                </button>
                <button type="button" className="navbar-toggler collapsed" data-target="#site-navbar-collapse" data-toggle="collapse">
                    <i className="icon md-more" aria-hidden="true"></i>
                </button>
                <div className="navbar-brand navbar-brand-center site-gridmenu-toggle" data-toggle="gridmenu">
                    <img className="navbar-brand-logo" src={Logo} title="Manager" alt="Benefício Social Familiar"/>
                </div>
                <button type="button" className="navbar-toggler collapsed" data-target="#site-navbar-search" data-toggle="collapse">
                    <span className="sr-only">Toggle Search</span>
                    <i className="icon md-search" aria-hidden="true"></i>
                </button>
            </div>
            <div className="navbar-container container-fluid">
                <div className="collapse navbar-collapse navbar-collapse-toolbar" id="site-navbar-collapse">
                    <ul className="nav navbar-toolbar">
                        {/* <li className="nav-item hidden-float" id="toggleMenubar">
                            <a className="nav-link" data-toggle="menubar" href="#" role="button">
                                <i className="icon hamburger hamburger-arrow-left">
                                        <span className="sr-only">Toggle menubar</span>
                                        <span className="hamburger-bar"></span>
                                    </i>
                            </a>
                        </li> */}
                        <li className="nav-item hidden-sm-down" id="toggleFullscreen">
                            <a className="nav-link icon icon-fullscreen" data-toggle="fullscreen" role="button">
                                <span className="sr-only">Toggle fullscreen</span>
                            </a>
                        </li>
                    </ul>
                    <ul className="nav navbar-toolbar navbar-right navbar-toolbar-right">
                        <li className="nav-item dropdown">
                            <a className="nav-link navbar-avatar" data-toggle="dropdown" aria-expanded="false" data-animation="scale-up" role="button">
                                <span className="avatar avatar-online">
                                    <img src={this.state.avatar} alt="AVATAR"/>
                                    <i></i>
                                </span>
                            </a>
                            <div className="dropdown-menu" role="menu">
                                {/* <a className="dropdown-item" href="javascript:void(0)" role="menuitem"><i className="icon md-account" aria-hidden="true"></i> Profile</a> */}
                                {/* <a className="dropdown-item" href="javascript:void(0)" role="menuitem"><i className="icon md-card" aria-hidden="true"></i> Billing</a> */}
                                {/* <a className="dropdown-item" href="javascript:void(0)" role="menuitem"><i className="icon md-settings" aria-hidden="true"></i> Settings</a> */}
                                {/* <div className="dropdown-divider"></div> */}
                                <a className="dropdown-item" role="menuitem" onClick={this.logoutUser}><i className="icon md-power" aria-hidden="true" ></i> Sair</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>;
    }

    render(){
        if(this.props.managerStatus){
            return this.getContent();
        }else{
            return <div></div>;
        }
    }
}


function mapStateProps(state, props ){
    return {
        user: state.user,
        statusApp: state.status,
        managerStatus: state.managerStatus
    }
}

function mapDispatchProps(dispatch){
    return {
        action: bindActionCreators({
            ...StatusActions,
            ...UserActions
        }, dispatch )
    }
}
export default connect(mapStateProps, mapDispatchProps)(TopBar);
