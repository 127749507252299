import User from './UserReducer';
import Status from './StatusReducer';
import StatusManager from './StatusManagerReducer';
import RedirectReducer from './RedirectReducer';
import EnvioEmails from './EnvioEmailsReducer'
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    user: User,
    status: Status,
    managerStatus: StatusManager,
    redirectReducer: RedirectReducer,
    envioEmails: EnvioEmails
});

export default rootReducer;
