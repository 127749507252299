import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect} from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';
import Alert from 'react-s-alert';


//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';
import { isNull } from 'util';
import { isNullOrUndefined } from 'util';


class Emails extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            trabs: [],
            qtd_total: 0,
            paramters: null,
            excel: 'nao',
            tipoEmailChecked:[]
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

   async  onChange(e){
        let self = this;
        let inputChecked = document.querySelectorAll('input[type=checkbox]')
        let checkboxes =  Array.from(inputChecked)
        let radioChecked =  await checkboxes.filter((checkbox) => checkbox.checked)
            .map((checkbox) => checkbox.value);
        if(radioChecked.length){
            self.setState({
                tipoEmailChecked : radioChecked
            })
        } else {
            self.setState({
                tipoEmailChecked : []
            })
        }
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        console.log("handlerSubmit");

        event.preventDefault();

        let self = this;

        let data = {
        }

        data.plano = event.target.plano.value;

        data.empresa_pesquisa = event.target.empresa_pesquisa.value;

        data.email_pesquisa = event.target.email_pesquisa.value;

        data.date_ini = event.target.date_ini.value;

        data.date_fim = event.target.date_fim.value;

       // data.tipo_email = event.target.tipo_email.value;

        //Checked
        data.tipo_email = this.state.tipoEmailChecked

        data.email_ok = event.target.email_ok.value

        // switch(event.target.email_ok.value){
        //     case "1":
        //         data.email_ok = "sim";
        //         data.email_nok = "";
        //         break;
        //     case "2":
        //         data.email_ok = "";
        //         data.email_nok = "sim";
        //         break;
        //     case "3":
        //         data.email_ok = "";
        //         data.email_nok = "";
        //         break;
        //     default:
        //         data.email_ok = "";
        //         data.email_nok = "";
        //         break;
        // }

        data.pesquisa_parcial =  'nao';

        this.state.paramters = "?";
        this.state.paramters += 'plano=' + data.plano;
        this.state.paramters += '&empresa_pesquisa=' + data.empresa_pesquisa;
        this.state.paramters += '&email_pesquisa=' + data.email_pesquisa;
        this.state.paramters += '&date_ini=' + data.date_ini;
        this.state.paramters += '&date_fim=' + data.date_fim;
        this.state.paramters += '&tipo_email=' + data.tipo_email;
        this.state.paramters += '&email_ok=' + data.email_ok;
        this.state.paramters += '&email_nok=' + data.email_nok;
        this.state.paramters += '&pesquisa_parcial=' + data.pesquisa_parcial;

        this.critica = 'ok';

        console.log("this.state.excel=>", this.state.excel);
        console.log("this.critica=>", this.state.critica);
        console.log("this.critica=>", this.state.critica);
        if (this.state.excel != 'sim' && (this.critica === 'ok')) {
            this.props.action.loadingStatus();
            fetch(self.core.getUrlConecta() + "emails/relatorios/lista-empresa-emails", {
            //fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-emailsErro", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                self.setState({
                    relatorio: response.content,
                    qtd_total: response.content.length
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });
        }
        if (this.state.excel === 'sim' && (this.critica === 'ok')) {
            if (this.consisteParametros(data)) {
                console.log("Chamando Excel OK");
                this.props.action.loadingStatus();
               	 fetch(self.core.getUrlConecta() + "emails/relatorios/excel-empresa-emails", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: JSON.stringify(data)
            }).then(response => response.blob())
        .then(blob => {
        	 this.props.action.loadedStatus();
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = "empresa-emails.csv";
            a.click();
        })

                // let urlExcel = self.core.getUrlConecta()  + "emails/relatorios/excel-empresa-emails"+this.state.paramters;
                // window.location.href = urlExcel
            }
        }

    }

    showLista(){
        this.state.excel = 'nao'
    }

    showExcel(){
        this.state.excel = 'sim'
    }

    consisteParametros(prm_data){
        if (((prm_data.empresa_pesquisa === '') || isNullOrUndefined(prm_data.empresa_pesquisa))) {

            if (((prm_data.date_ini === '') || isNullOrUndefined(prm_data.date_ini))) {
                Alert.error("Data Inicial deve ser informado.", {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 3000
                });

                this.state.excel = 'nao'
                return false;
            }
            if (((prm_data.date_fim === '') || isNullOrUndefined(prm_data.date_fim))) {
                Alert.error("Data Final deve ser informado.", {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 3000
                });

                this.state.excel = 'nao'
                return false;
            }
        }

        return true;
    }


    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.plano}</td>
                <td>{data.cnpj}</td>
                <td>{data.razao_social}</td>
                <td>{data.assunto_email}</td>
                <td>{data.modelo_email}</td>
                <td>{data.tipo_email}</td>
                <td>{data.data_envio}</td>
                <td>{data.destinatario_email}</td>
                <td>{data.situacao_email}</td>
                <td>{data.bouncetype}</td>
                <td>{data.bouncesubtype}</td>
                <td>{data.message_status}</td>
                <td>{data.message_diagnostic_code}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        this.state.excel = 'nao'
        if (this.props.redirect.url) {
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={13}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa.</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Disparo de Emails BSFOnline</h2>
                <p>Consulte os Emails Enviados para os Empregadores</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe o Empregador, Email Destinatário, Intervalo de Datas, Situacao e Tipo de Email para a pesquisa de emails enviados
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-1">
                                        <div className="form-group">
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Todos os Empregadores" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="email_pesquisa" id="email_pesquisa" placeholder="Todos os Emails Destino" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="date_ini" id="data_ini" placeholder="Data início" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="date_fim" id="data_fim" placeholder="Data fim" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                        <label> <b>Situação</b> </label> <br/>
                                            <input type="radio" name="email_ok" value="1" /> Email OK <br/>
                                            <input type="radio" name="email_ok" value="2" /> Email Invalido <br/>
                                            <input type="radio" name="email_ok" value="3" /> Todos <br/>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                        <label> <b>Tipo Email</b> </label> <br/>
                                            <input type="checkbox" name="tipo_email" value="1" onChange={this.onChange}/> Boleto Disponível <br/>
                                            <input type="checkbox" name="tipo_email" value="2" onChange={this.onChange}/> Inadimplência <br/>
                                            <input type="checkbox" name="tipo_email" value="3" onChange={this.onChange}/> Pagamento <br/>
                                            <input type="checkbox" name="tipo_email" value="4" onChange={this.onChange}/> Operador <br/>
                                            <input type="checkbox" name="tipo_email" value="5" onChange={this.onChange}/> Cadastro Empresa <br/>
                                            <input type="checkbox" name="tipo_email" value="6" onChange={this.onChange}/> Divulgação <br/>
                                            <input type="checkbox" name="tipo_email" value="7" onChange={this.onChange}/> Todos <br/>
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">

                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                    <td>Qtd Emails: {this.state.qtd_total}</td>
                                </thead>
                                <tbody>
                                    <td></td>
                                </tbody>
                            </tr>
                        </table>

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>Plano</td>
                                    <td>CNPJ/CPF</td>
                                    <td>Empregador</td>
                                    <td>Assunto</td>
                                    <td>Modelo</td>
                                    <td>Tipo Email</td>
                                    <td>Data Envio</td>
                                    <td>Destinatário</td>
                                    <td>Situacao</td>
                                    <td>Tipo</td>
                                    <td>SubTipo</td>
                                    <td>Status SES</td>
                                    <td>Diagnostico SES</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}

export default withRouter(connect(mapStateProps, mapDispatchProps)(Emails))
