import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import moment from 'moment';
import InputMask from 'react-input-mask';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';
import { isNullOrUndefined } from 'util';
//import { exists } from 'fs';
//import { isNull, isNullOrUndefined } from 'util';

class ResyncBoletos extends React.Component{
    constructor(props){
        super(props);

        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;

        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio_parametros: [],
            relatorio_boletos: [],
            relatorio_atualizacao: [],
            qtd_total: 0,
            atualiza: null,
            botao_atualiza: null
        }

        this.getTableShowConsulta = this.getTableShowConsulta.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        console.log("handlerSubmit=>");
        event.preventDefault();
        let self = this;

        let data = {
            empresa_pesquisa: event.target.empresa_pesquisa.value,
            plano: event.target.plano.value,
            ano_vecto: event.target.ano_vecto.value,
            mes_vecto: event.target.mes_vecto.value,
            pesquisa_parcial: 'nao',
            atualiza: this.state.atualiza,
        }

        this.props.action.loadingStatus();
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/empresa-resync-boletos", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.setState({
                relatorio_parametros: response.content[0].consulta,
                relatorio_boletos: response.content[0].boletos,
                relatorio_atualizacao: response.content[0].atualizacao
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    consultarBoletos(){
        this.state.atualiza = 'nao'
    }

    atualizarBoletos(){
        this.state.atualiza = 'sim'
    }

    getTableShowConsulta(){

        return this.state.relatorio_parametros.map((data, index) => {
            return <tr key={index} >
                <td>{data}</td>
            </tr>
        });
    }

    getTableShowBoletos(){

        return this.state.relatorio_boletos.map((data, index) => {
            return <tr key={index} >
                <td>{data.emcv_cd_nseq}</td>
                <td>{data.ano_mes}</td>
                <td>{data.dt_pagto}</td>
                <td>{data.vl_pagto}</td>
            </tr>
        });
    }

    getTableShowAtualizacao(){

        return this.state.relatorio_atualizacao.map((data, index) => {
            return <tr key={index} >
                <td>{data}</td>
            </tr>
        });
    }

    render(){
        console.log("render=>");
        let tableContentConsulta;
        let tableContentBoletos;
        let tableContentAtualizacao;
        //let botao_atualiza;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio_parametros.length){
            tableContentConsulta = this.getTableShowConsulta();
        }else{
            tableContentConsulta = <tr>
                <td colSpan={1}>  </td>
            </tr>
        }

        if(this.state.relatorio_boletos.length){
            tableContentBoletos = this.getTableShowBoletos();
        }else{
            tableContentBoletos = <tr>
                <td colSpan={4}>  </td>
            </tr>
        }
        if(this.state.relatorio_atualizacao.length){
            tableContentAtualizacao = this.getTableShowAtualizacao();
        }else{
            tableContentAtualizacao = <tr>
                <td colSpan={1}>  </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Empresa - Sincronismo de Boletos (Tela Rosa -> BSF)</h2>
                <p>Refazer o sincronismo de Boletos entre os Sistemas Tela Rosa e BSF Online</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe o Plano, Empregador e Ano para Sincronismo.
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                <div className="col-sm-1">
                                        <div className="form-group">
                                            {/*<label><small>Plano</small></label>*/}
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            {/*<label><small>Empresa</small></label>*/}
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Informe a Razao Social/Nome ou CNPJ/CPF (exatos)" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            {/*<label><small>Ano</small></label>*/}
                                            <InputMask name="ano_vecto" id="ano_vecto" placeholder="Ano Vencimento" className="form-control" mask="9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            {/*<label><small>Mês</small></label>*/}
                                            <InputMask name="mes_vecto" id="mes_vecto" placeholder="Mês Vencimento" className="form-control" mask="99" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" ref="formulario1" className="btn btn-primary"  onClick={event =>{  this.consultarBoletos();}}>pesquisar</button>
                                    </div>
                                    {this.state.botao_atualiza}
                                    <div className="col-sm-1">
                                        <button type="Submit" ref="formulario2" className="btn btn-danger"  onClick={event =>{  this.atualizarBoletos();}}>sincronizar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="panel">
                    <div className="panel-body container-fluid">

                        <table className="table table-striped table-doc">
                            <thead>
                                <td><h3>Parametros Informados</h3></td>
                            </thead>
                            <tbody>
                                {tableContentConsulta}
                            </tbody>
                        </table>

                        <table className="table table-striped table-doc">
                            <thead>
                                <td><h3>Boletos Selecionados</h3></td>
                            </thead>
                        </table>
                        
                        <table className="table table-striped table-doc">
                            <thead>
                                <td>emcv_cd_nseq</td>
                                <td>Vencimento</td>
                                <td>Data Pagamento</td>
                                <td>Valor Pago</td>
                            </thead>
                            <tbody>
                                {tableContentBoletos}
                            </tbody>
                        </table>

                        <table className="table table-striped table-doc">
                            <thead>
                                <td><h3>Atualizações</h3></td>
                            </thead>
                            <tbody>
                                {tableContentAtualizacao}
                            </tbody>

                        </table>
                        


                    </div>
                </div>


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(ResyncBoletos))
