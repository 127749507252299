import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import Alert from 'react-s-alert';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';


class ExcelTrabalhador extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();

        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        console.log(event.target.date_ini.value);
        let paramters = "?";
        let date_ini = moment(event.target.date_ini.value, "DD/MM/YYYY");
        let date_ini_cp = moment(event.target.date_ini.value, "DD/MM/YYYY").add(30, 'days');;
        let date_fim = moment(event.target.date_fim.value, "DD/MM/YYYY");
        let now = moment();
        let url = event.currentTarget.getAttribute("action");
        let mesano_vecto = moment(event.target.mesano_vecto.value, "MM/YYYY");

        if(url === null){
            Alert.error("Problemas de configuração.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 8000
            });
            return false;
        }

        if(event.target.date_ini.value !== "" && event.target.date_fim.value !== ""){
            if(date_fim.isBefore(date_ini.format("YYYY-MM-DD"))){
                console.log("maior");
                Alert.error("Data Fim não pode ser maior que data inicio.", {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 8000
                });
                return false;
            }
    
            if(date_fim.isAfter(now) || date_ini.isAfter(now)){
                Alert.error("Datas não podem ser futuras.", {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 8000
                });
                return false;
            }
            
            if(date_fim.isAfter(date_ini_cp)){
                Alert.error("Período não pode ser maior que 30 dias.", {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 8000
                });
                return false;
            }
    
            //valida a data e o formato da data_ini
            if(event.target.date_ini.value !== undefined && date_ini.isValid()){
                if(paramters !== "?")
                    paramters += "&";
                paramters += "date_ini=" + date_ini.format("DD/MM/YYYY");
            }
            
            //valida a data e o formato da data_fim
            if(event.target.date_fim.value !== undefined && date_fim.isValid()){
                if(paramters !== "?")
                    paramters += "&";
                paramters += "date_fim=" + date_fim.format("DD/MM/YYYY");
            }

            if(event.target.mesano_vecto.value !== undefined && mesano_vecto.isValid()){
                if(paramters !== "?")
                    paramters += "&";
                paramters += "mesano_vecto=" + mesano_vecto.format("MM/YYYY");
            }
            console.log("Parametros=>", paramters);

        }else{
            Alert.error("Por favor coloque um periódo para pesquisa.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 8000
            });
            return false;
        }

        // if(event.target.grupo && event.target.grupo.value === "true"){
        //    if(paramters !== "?")
        //             paramters += "&";
        //     paramters += "grupo=true";
        // }
        
        //verifca segvida
        switch(event.target.segvida.value){
            case "true":
                if(paramters !== "?")
                    paramters += "&";
                paramters += "segvida=true";
                break;
            case "false":
                if(paramters !== "?")
                    paramters += "&";
                paramters += "segvida=false";
                break;
            default:
                break;
        }
        
        //baixa o relatório
        if(paramters !== "?"){
            window.location.href = url + paramters + '&token=' + this.core.getToken();
        }else{
            window.location.href = url;
        }


        event.currentTarget.reset();
        return true;
    }
    
    render(){
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        //url dos relatórios
        let urlTrabalhadoresVidaEmGrupo = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-trabalhadores-vidaemgrupo";

        //return render
        return <div className="page">
            <div className="page-content">
                <h2>Lista de Trabalhadores Vida em Grupo</h2>
                <p>Gere os Relatórios de Trabalhadores em excel</p>

                {/*relatorio de planos/trabalhadores*/}
                <div className="panel">
                    <h3 className='panel-title'>Lista Trabalhadores</h3>
                    <div className="panel-body container-fluid">
                        {/* formulario envia para handlerSubmitPlanoTrabalhadores*/}
                        <form action={urlTrabalhadoresVidaEmGrupo} onSubmit={this.handlerSubmit}>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Vida em Grupo:</label> <br/>
                                        <input type="radio" name="segvida" value="false" /> <b>Com</b> Direito ao Seguro Sindical <br/>
                                        <input type="radio" name="segvida" value="true" /> <b>Sem</b> Direito ao Seguro Sindical
                                    </div>
                                </div>
                                {/* <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="grupo2">Grupo por Pagamento:</label>
                                        <select name="grupo" id="grupo2" className="form-control">
                                            <option value="false">NÃO</option>
                                            <option value="true">SIM</option>
                                        </select>
                                    </div>
                                </div> */}
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <label htmlFor="data_ini2">Data Inicio:<small>(Data pagamento)</small></label>
                                        <InputMask name="date_ini" id="data_ini2" className="form-control" mask="99/99/9999" />
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <label htmlFor="data_fim2">Data Fim:<small>(Data pagamento)</small></label>
                                        <InputMask name="date_fim" id="data_fim2" className="form-control" mask="99/99/9999" />
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                        <label htmlFor="mesano_vecto">Vencimento:<small>(MM/AAAA)</small></label>
                                        <div className="form-group">
                                            <InputMask name="mesano_vecto" id="mesano_vecto" placeholder="Mês/Ano Vencimento" className="form-control" mask="99/9999" />
                                        </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <button className="btn btn-primary margin-btn">GERAR</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> 
                {/*fim*/}
            </div>
        </div>;
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(ExcelTrabalhador))