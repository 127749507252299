export default (state = {}, payload) => {
    switch(payload.type){
        case 'REDIRECT_TO':
            state = Object.assign({
                last: state.url,
                url: payload.data
            })

            return state
       case 'REDIRECT_CLEAR':
            state = Object.assign({
                last: state.last,
                url: null
            })

            return state
        default:
            state = Object.assign({
                last: null,
                url: null
            })

            return state;
    }
}
