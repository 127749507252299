import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';


class EmpresaTransacoes extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            paramters: null,
            renderModal: <div></div>,
            selecao: {},
            trabs: [],
            qtd_total: 0,
            vlr_total: 0,
            auth: this.core.getAuth(),
            div_permitida: <div></div>,
            empresa: null,
            nnumero: null,
            excel: 'nao',
            upload: 'nao',
            urlTransacao : null,
            processado: "invisible"             
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;
        let form = new FormData();

        let data = {
        }
        data.plano =  event.target.plano.value;
        data.empresa_pesquisa = event.target.empresa_pesquisa.value;
        data.nsu_pesquisa = event.target.nsu_pesquisa.value;
        data.pesquisa_parcial =  'sim';
        if (event.target.arquivo.files[0] === undefined || event.target.arquivo.files[0] === null) {
            data.nome_arquivo =  '';
        } else {
            data.nome_arquivo = event.target.arquivo.files[0];
        }

        this.state.paramters = "?";
        this.state.paramters += 'pesquisa_parcial=' + data.pesquisa_parcial;
        this.state.paramters += '&empresa_pesquisa=' + data.empresa_pesquisa;
        this.state.paramters += '&nsu_pesquisa=' + data.nsu_pesquisa;
        this.state.paramters += '&plano=' + data.plano;
        this.state.paramters += '&excel=' + this.state.excel;
        this.state.paramters += '&token=' + this.core.getToken();

        if (this.state.excel != 'sim') {
            if (this.state.upload != 'sim') {
                //this.state.urlTransacao = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/gateway/lista-transacoes" + this.state.paramters
                this.props.action.loadingStatus();
                fetch(this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/gateway/lista-transacoes" + this.state.paramters, {
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                    },
                    method: 'GET',
                })
                .then(res => self.core.responseConvert(res))
                .then((response) => {
                    let vlr_pago = 0
                    response.content.map ((val, index) => {
                        vlr_pago += parseFloat(val.vlr_pago)
                    })
                    self.setState({
                        relatorio: response.content, 
                        qtd_total: response.content.length,
                        vlr_total: vlr_pago,
                    });
                    self.props.action.loadedStatus();
                })
                .catch((err) => {
                    self.core.catchFecth(err)
                    self.props.action.loadedStatus();
                });    
            } else {
                form.append("nome_arquivo", data.nome_arquivo);
                this.props.action.loadingStatus();
                fetch(this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/gateway/verifica-planilha", {
                    headers: {
                        'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                    },
                    method: 'POST',
                    body: form
                })
                .then(res => self.core.responseConvert(res))
                .then((response) => {
                    let vlr_pago = 0
                    response.content.map ((val, index) => {
                        vlr_pago += parseFloat(val.vlr_pago)
                    })
                    self.setState({
                        relatorio: response.content, 
                        qtd_total: response.content.length,
                        vlr_total: vlr_pago,
                    });
                    self.props.action.loadedStatus();
                })
                .catch((err) => {
                    self.core.catchFecth(err)
                    self.props.action.loadedStatus();
                });

            }
        }
        if (this.state.excel === 'sim') {
            console.log("Chamando Excel:");
            let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/gateway/lista-transacoes" + this.state.paramters;
            window.location.href = urlExcel        
        } 
    }

    showLista(){
        console.log("showLista1");
        this.setState({
            excel: 'nao',
            upload: 'nao'
        });
    }

    showPlanilha(){
        console.log("showPlanilha1");
        this.setState({
            excel: 'nao',
            upload: 'sim'
        });
    }

    showExcel(){
        console.log("showExcel1");
        this.setState({
            excel: 'sim',
            upload: 'nao'
        });
    }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            let vlr_pago_x = null;
            if (data.vlr_pago === 0) {
                vlr_pago_x = '  '
            } else {
                vlr_pago_x = "R$ " + parseFloat(data.vlr_pago).toFixed(2)
            }
   
            return <tr key={index} >
                <td>{data.id_empr_blto}</td>
                <td>{data.cnpj}</td>
                <td>{data.razao_social}</td>
                <td>{data.placa}</td>
                <td>{data.id_empr_plno}</td>
                <td>{data.vencimento_original}</td>
                <td>{data.pagamento}</td>
                <td style={{textAlign:"right"}}>{vlr_pago_x}</td>
                <td>{data.nosso_numero}</td>
                <td>{data.nsu}</td>
                <td>{data.tipo}</td>
                <td>{data.status}</td>
                <td>{data.statuscielo}</td>
                <td>{data.lote_cd_nseq}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={14}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa.</b>
                    </center>
                </td>
            </tr>
        }
        if(this.state.auth.config.grupo.nome === "administrador") {
            this.state.div_permitida = 
            <table className="table table-striped table-doc">
            <tr>
                <thead>
                    <td>Totais</td>
                    <td>Qtd Pagamentos</td>
                    <td>Valor Total Pago</td>
                </thead>
                <tbody>
                    <td></td>
                    <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                    <td style={{textAlign:"right"}}>R$ {this.state.vlr_total.toLocaleString('de-DE')}</td>
                </tbody>
            </tr>
            </table>
        } else {
            this.state.div_permitida = <div></div>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Transações com Cartão Crédito / Débito - CIELO</h2>
                <p>Consulte as Transações em Cartão realizadas pelos Empregadores no Sistema de Cobrança BSFonline</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Opcionalmente, informe os parametros para uma pesquisa específica.
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Razao Social/Nome ou CNPJ/CPF" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="nsu_pesquisa" id="nsu_pesquisa" placeholder="NSU" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>

                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal"><i className="fa fa-download"></i>Excel</button>
                                    </div>

                                </div>
                                <div>
                                    <h3>
                                        Informe o Arquivo Obtido no site de Cielo para identificar as Transações
                                    </h3>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <input className="form-control" type="file" name="arquivo" placeholder="Nome" onChange={(event)=> { 
                                                this.setState({
                                                    processado: 'invisible'
                                                });
                                                return false
                                            }}/>
                                        </div>

                                        <div className="form-group">
                                        <div className="col-sm-3">
                                            <button type="submit" className="btn btn-primary"  onClick={event =>{  this.showPlanilha();}}><i className="fa fa-upload"></i>upload</button>
                                        </div>
                                        </div>

                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">
                        {this.state.div_permitida}

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>Boleto</td>
                                    <td>CNPJ/CPF</td>
                                    <td>Empregador</td>
                                    <td>Plano</td>
                                    <td>Empr/Plno</td>
                                    <td>Vecto Original</td>
                                    <td>Data Pagamento</td>
                                    <td style={{textAlign:"right"}}>Vlr Pago</td>
                                    <td>Nosso Numero</td>
                                    <td>NSU</td>
                                    <td>Tipo</td>
                                    <td>Status Cobrança</td>
                                    <td>Status CIELO</td>
                                    <td>Tela Rosa</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(EmpresaTransacoes))
