import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class TrabalhadorSegSindical extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            trabs: [],
            qtd_total: 0,
            qtd_ativos: 0,
            qtd_afastadosate12m: 0,
            qtd_afastadosapos12m: 0,
            qtd_acimalimiteidade: 0,
            qtd_opositores: 0

        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        data.plano = event.target.plano.value;
        data.date_ini = event.target.date_ini.value;
        data.date_fim = event.target.date_fim.value;
        data.empresa_pesquisa = event.target.empresa_pesquisa.value;
        data.trabalhador_pesquisa = event.target.trabalhador_pesquisa.value;
        switch(event.target.grupo_tbldr.value){
            case "1":
                data.grupo_trabalhador_pesquisa = 1;
                break;
            case "2":
                data.grupo_trabalhador_pesquisa = 2;
                break;
            case "3":
                data.grupo_trabalhador_pesquisa = 3;
                break;
            case "4":
                data.grupo_trabalhador_pesquisa = 4;
                break;
            case "5":
                data.grupo_trabalhador_pesquisa = 5;
                break;
            default:
                data.grupo_trabalhador_pesquisa = 9;
                break;
        }

        data.vidaemgrupo = 'sim';
        data.segvida = 'sindical';
        data.pesquisa_parcial = 'sim';

        this.props.action.loadingStatus();

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-trabalhador-pagamento", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            let qtd_trabalhadores1 = 0
            this.state.qtd_total = 0
            this.state.qtd_ativos = 0
            this.state.qtd_afastadosate12m = 0
            this.state.qtd_afastadosapos12m = 0
            this.state.qtd_acimalimiteidade = 0
            this.state.qtd_opositores = 0

            response.content.map ((val, index) => {
                qtd_trabalhadores1+=1 
                switch(val.grpo_empr_blto_tbldr){
                    case "ativo":
                        this.state.qtd_ativos+=1;
                        break;
                    case "AfastadoAte12Meses":
                        this.state.qtd_afastadosate12m+=1;
                        break;
                    case "AfastadoApos12Meses":
                        this.state.qtd_afastadosapos12m+=1;
                        break;
                    case "idadeMaxima":
                        this.state.qtd_acimalimiteidade+=1;
                        break;
                    case "opositor":
                        this.state.qtd_opositores+=1;
                        break;
                    default:
                        break;
                }
            })
            self.setState({
                relatorio: response.content, 
                qtd_trabalhadores2: response.content.length,
                qtd_total: qtd_trabalhadores1
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.nome_tbldr}</td>
                <td>{data.cpf_tbldr}</td>
                <td>{data.razao_empr}</td>
                <td>{data.cnpj_empr}</td>
                <td>{data.grpo_empr_blto_tbldr}</td>
                <td>{data.id_tr_plno}</td>
                <td>{data.dt_vcto_empr_blto}</td>
                <td>{data.dt_pgto_empr_blto}</td>
                <td style={{textAlign:"right"}}>R$ {parseFloat(data.vlr_pago_empr_blto).toFixed(2)}</td>
                <td>{data.nosso_numero}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={10}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Trabalhador - Pagamento - Seguro Sindical</h2>
                <p>Consulte os Trabalhadores das Empresas dos Planos com Vida em Grupo nos boletos pagos pelas empresas</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                        Informe os parametros necessários para a pesquisa
                        </h3>
                        <div  className="seleciona-trabalhador" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-1">
                                        <div className="form-group">
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="date_ini" id="data_ini2" placeholder="Data início" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="date_fim" id="data_fim2" placeholder="Data fim" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="trabalhador_pesquisa" id="trabalhador_pesquisa" placeholder="Todos os Trabalhadores" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Todas as Empresas" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label> <b>Grupo Trabalhador</b> </label> <br/>
                                                <input type="radio" name="grupo_tbldr" value="1" /> Ativos <br/>
                                                <input type="radio" name="grupo_tbldr" value="2" /> Afastados até 12m <br/>
                                                <input type="radio" name="grupo_tbldr" value="3" /> Afastados após 12m <br/>
                                                <input type="radio" name="grupo_tbldr" value="4" /> Acima Limite Idade <br/>
                                                <input type="radio" name="grupo_tbldr" value="5" /> Opositores <br/>
                                                <input type="radio" name="grupo_tbldr" value="9" /> Todos <br/>
                                        </div>
                                    </div>

                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary">pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                        
                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                <td>Totais</td>
                                <td>Trabalhadores</td>
                                <td>Ativos</td>
                                <td>Afastados até 12m</td>
                                <td>Afastados após 12m</td>
                                <td>Acima Limite Idade</td>
                                <td>Opositores</td>
                                </thead>
                                <tbody>
                                <td></td>
                                <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                <td style={{textAlign:"right"}}>{this.state.qtd_ativos.toLocaleString('de-DE')}</td>
                                <td style={{textAlign:"right"}}>{this.state.qtd_afastadosate12m.toLocaleString('de-DE')}</td>
                                <td style={{textAlign:"right"}}>{this.state.qtd_afastadosapos12m.toLocaleString('de-DE')}</td>
                                <td style={{textAlign:"right"}}>{this.state.qtd_acimalimiteidade.toLocaleString('de-DE')}</td>
                                <td style={{textAlign:"right"}}>{this.state.qtd_opositores.toLocaleString('de-DE')}</td>

                                </tbody>
                            </tr>
                        </table>

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>Nome</td>
                                    <td>CPF</td>
                                    <td>Empresa</td>
                                    <td>CNPJ</td>
                                    <td>Situacao</td>
                                    <td>Plano</td>
                                    <td>Dt Vcto</td>
                                    <td>Dt Pgto</td>
                                    <td>Vlr Pago</td>
                                    <td>N.Numero</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(TrabalhadorSegSindical))
