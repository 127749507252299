import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';


class CartoesFuncional extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            paramters: null,
            renderModal: <div></div>,
            selecao: {},
            qtd_total: 0,
            qtd_tcel_OK: 0,
            qtd_tcel_NOK: 0,
            qtd_erros_planilha: 0,
            sms_texto: null,
            auth: this.core.getAuth(),
            upload: 'nao',
            processado: "invisible"             
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();

        let texto_nao_validados = null;

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/sms/cartoesfuncionaltexto", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            texto_nao_validados = response.content;
    
            self.setState({
                sms_texto: texto_nao_validados
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });   
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;
        let form = new FormData();

        let data = {
        }
        if (event.target.arquivo.files[0] === undefined || event.target.arquivo.files[0] === null) {
            data.nome_arquivo =  '';
        } else {
            data.nome_arquivo = event.target.arquivo.files[0];
        }
        data.enviarSMS = this.state.enviarSMS;

        form.append("nome_arquivo", data.nome_arquivo);
        form.append("enviar_sms", data.enviarSMS);
        
        this.props.action.loadingStatus();
        fetch(this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/sms/cartoesfuncional", {
            headers: {
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: form
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.setState({
                qtd_erros_planilha: response.qtd_erros_planilha, 
                qtd_tcel_OK: response.qtd_tcel_OK, 
                qtd_tcel_NOK: response.qtd_tcel_NOK, 
                relatorio: response.content, 
                qtd_total: response.content.length
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    verificaPlanilha(){
        console.log("verificaPlanilha");
        this.setState({
            enviarSMS: 'nao'
        });
    }

    enviaSMS(){
        console.log("enviaSMS");
        this.setState({
            enviarSMS: 'sim'
        });
    }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.evbe_cd_nseq}</td>
                {/*<td>{data.cpf}</td>*/}
                <td>{data.nome}</td>
                <td>{data.telefone}</td>
                <td>{data.even_cd_nseq}</td>
                <td>{data.even_ds_protocolo}</td>
                <td>{data.even_cd_webben}</td>
                <td>{data.status_atualizacao}</td>
                <td>{data.status_envioSMS}</td>
                <td>{data.texto_sms}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={9}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa</b>
                    </center>
                </td>
            </tr>
        }
        return <div className="page">
            <div className="page-content">
                <h2>Funcional - Envio de SMS</h2>
                <p>Envie o SMS para os Beneficários que possuem o Cartão Virtual Funcional</p>
                <div className="panel">
                    <div className='panel-title'>
                        <div  className="seleciona-parametros" >
                            <form onSubmit={this.handlerSubmit}>
                                <div>
                                    <h3>
                                        Texto: "{this.state.sms_texto}"
                                    </h3>

                                    <h3>
                                        Informe a Planilha com as Colunas: Protocolo e Telefone (formato Excel)
                                    </h3>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <input className="form-control" type="file" name="arquivo" placeholder="Nome" onChange={(event)=> { 
                                                this.setState({
                                                    processado: 'invisible'
                                                });
                                                return false
                                            }}/>
                                        </div>

                                        <div className="col-sm-2">
                                            <button type="submit" className="btn btn-primary"  onClick={event =>{  this.verificaPlanilha();}}><i className="fa fa-upload"></i>verificar</button>
                                        </div>
                                        <div>
                                        </div>
                                        <div className="col-sm-2">
                                            <button type="submit" className="btn btn-info"  onClick={event =>{  this.enviaSMS();}}><i className="fa fa-upload"></i>enviar</button>
                                        </div>

                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">

                        <table className="table table-striped table-doc">
                                <tr>
                                    <thead>
                                        <td>Totais:</td>
                                        <td>Registros</td>
                                        <td>Telefones Válidos</td>
                                        <td>Telefones Inválidos</td>
                                        <td>Qtd Erros Planilha</td>
                                    </thead>
                                    <tbody>
                                        <td></td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_tcel_OK.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_tcel_NOK.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_erros_planilha.toLocaleString('de-DE')}</td>
                                    </tbody>
                                </tr>
                        </table>

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>Id</td>
                                    {/*<td>CPF</td>*/}
                                    <td>Beneficiário(a)</td>
                                    <td>Telefone</td>
                                    <td>Evento</td>
                                    <td>Protocolo</td>
                                    <td>Cod Webben</td>
                                    <td>Atualização</td>
                                    <td>Envio SMS</td>
                                    <td>Status</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(CartoesFuncional))
