import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class Analise1Plano extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            paramters: null,
            excel: 'nao'            
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
            plano_pesquisa : event.target.plano.value,
            ano_vecto : event.target.ano_vecto.value,
            mes_vecto : event.target.mes_vecto.value
        }

        this.state.paramters = "?";
        this.state.paramters += 'plano_pesquisa=' + data.plano_pesquisa;
        this.state.paramters += '&mes_vecto=' + data.mes_vecto;
        this.state.paramters += '&ano_vecto=' + data.ano_vecto;
        this.state.paramters += '&token=' + this.core.getToken();

        if (this.state.excel != 'sim') {
            this.props.action.loadingStatus();

            console.log("Fetch");
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-planos-analise4", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                console.log("Planos=>response.content:", response.content)
                response.content.map ((val, index) => {
                })
        
            self.setState({
                relatorio: response.content 
            });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });  
        }
        if (this.state.excel === 'sim') {
            console.log("Chamando Excel:");
            let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-planos-analise1"+this.state.paramters;
            window.location.href = urlExcel        
        } 
  
    }

    showLista(){
        console.log("showLista1");
        this.setState({
            excel: 'nao'
        });
    }

    showExcel(){
        console.log("showExcel1");
        this.setState({
            excel: 'sim'
        });
    }


    getTableShow(){
        console.log("getTableShow=>");
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{String(data.qtd_empresas_tr).padStart(5,"0")}</td>
                <td>{String(data.qtd_empresas_bsf).padStart(5,"0")}</td>
                <td>{String(data.qtd_boletos_tr).padStart(5,"0")}</td>
                <td>{String(data.qtd_boletos_bsf).padStart(5,"0")}</td>
                <td>{String(data.qtd_boletos_impressos).padStart(5,"0")}</td>
                <td>{String(data.tbldr_ativo_impressos).padStart(5,"0")}</td>
                <td>{String(data.tbldr_ate12m_impressos).padStart(5,"0")}</td>
                <td>{String(data.tbldr_apos12m_impressos).padStart(5,"0")}</td>
                <td>{String(data.tbldr_opositor_impressos).padStart(5,"0")}</td>
                <td>{String(data.tbldr_idade_impressos).padStart(5,"0")}</td>
                <td>{String(data.qtd_boletos_pagos).padStart(5,"0")}</td>
                <td>{String(data.tbldr_ativo_pagos).padStart(5,"0")}</td>
                <td>{String(data.tbldr_ate12m_pagos).padStart(5,"0")}</td>
                <td>{String(data.tbldr_apos12m_pagos).padStart(5,"0")}</td>
                <td>{String(data.tbldr_opositor_pagos).padStart(5,"0")}</td>
                <td>{String(data.tbldr_idade_pagos).padStart(5,"0")}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={16}>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Planos - Análise Individual de Plano</h2>
                <p>Consulte a Análise do Plano, Total de Empresas, Qtd de Boletos Gerados, Boletos Impressos e Boletos Pagos</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe o Plano e Mês/Ano de Vencimento para Pesquisa
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="mes_vecto" id="mes_vecto" placeholder="Mes Vencimento" className="form-control" mask="99" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="ano_vecto" id="ano_vecto" placeholder="Ano Vencimento" className="form-control" mask="9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panel-body container-fluid">
                            <table className="table table-striped table-doc">
                                <thead>
                                    <tr>
                                        <h2> Análise Plano </h2>
                                    </tr>
                                    <tr>
                                        <td>Empresas Tela Rosa</td>
                                        <td>Empresas BSFOnline</td>
                                        <td>Boletos Tela Rosa</td>
                                        <td>Boletos BSFOnline</td>
                                        <td>Boletos Impressos</td>
                                        <td>Ativos</td>
                                        <td>Até12m</td>
                                        <td>Após12m</td>
                                        <td>Opositor</td>
                                        <td>Idade</td>
                                        <td>Boletos Pagos</td>
                                        <td>Ativos</td>
                                        <td>Até12m</td>
                                        <td>Após12m</td>
                                        <td>Opositor</td>
                                        <td>Idade</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableContent}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(Analise1Plano))
