import React from 'react'
import Core from '../../../class/Core';
import { withRouter, Redirect, Link } from 'react-router-dom';
import Alert from 'react-s-alert';
//actios
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class SmsPushConecta extends React.Component {
	constructor(props){
		super(props)
		this.core = new Core()
        this.core.isAuth()
        this.state = {
        	dados : {}
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.loadTable = this.loadTable.bind(this)
        this.tipoEnvio = this.tipoEnvio.bind(this)
	}

	handleSubmit(e){
		e.preventDefault();
		let self = this
		let sendFormData = new FormData()
		let inputFile = document.querySelector('.lista')
        sendFormData.append('arquivo', inputFile.files[0]) //pegando arquvico
        sendFormData.append('smspsh_tipo_envo', e.target.tipoenvio.value)
        sendFormData.append('smspsh_assunto', e.target.assunto.value)
        sendFormData.append('smspsh_msg',e.target.mensagem.value)


        self.props.action.loadingStatus();

        fetch(self.core.getUrlConecta() + 'conecta/smspush', {
                    headers: {
                        'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                    },
                    method: 'POST',
                    body: sendFormData
                }
            )
            .then(res => self.core.responseConvert(res))
            .then((response) => {
               Alert.success("Lista de notificações gerada com sucesso!\nAguarde o envio.", {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 8000
                });

                self.props.action.loadedStatus();

               self.setState({
                    redirect: "/conecta/smspush"
               });

              window.location.reload(false);
            })
            .catch((err) => {
                self.core.catchFecth(err);
                self.props.action.loadedStatus();
            });


	}

	componentWillMount(){
		this.props.action.showManager();
        this.props.action.redirectClear();

	}

    componentDidMount(){
          fetch(this.core.getUrlConecta() + 'conecta/lista/enviados', {
                    headers: {
                        'Authorization': this.core.formatAutorizationToken(this.core.getToken())
                    },
                    method: 'GET'
                }
            )
            .then(res => this.core.responseConvert(res))
            .then(resp => {

			let sort = resp.content.sort(function(a,b){
				return new Date(b.createdAt) - new Date(a.createdAt);
			});


                this.setState({
                    dados: sort
                })
            })

    }
     tipoEnvio(e){
         console.log(e)
        switch (e) {
            case 1:
               return 'SMS'
                break;
             case 2:
               return 'PUSH'
                break;

            default:
               return 'SMS/PUSH'
                break;
        }
    }

    loadTable(){
        let result = this.state.dados
        if(result.length){
            return result.map((val, index) => {

                let tipo = this.tipoEnvio(val.smspsh_tipo_envo)
                return <tr  key={index} >
                           <td> {moment(val.createdAt).format('lll')}</td>
                           <td> {tipo}</td>
                           <td> {val.smspsh_assunto}</td>
                           <td> <a target="_blank" href={val.smspsh_arquivo}>{val.smspsh_arquivo}</a></td>
                       </tr>
                })
        }

    }



	render(){
        let dadosTabela = this.loadTable()
	 	if(this.props.redirect.url){
        	return <Redirect to={this.props.redirect.url} />;
    	}
		return <div className="page">
            <div className="page-content">
                <h2>SMS/PUSH</h2>
                <p>Envio de mensagens para os trabalhadores conecta</p>
                <div className="row">
                <div className="col-sm-8">
                	<form onSubmit={this.handleSubmit}>
                		<div className="form-smspush row">
                		<div className="col-sm-6">
                			<div className="form-group">
               					<label>Tipo de envio:</label>
               					<select name="tipoenvio" id="tipo-envio" className="form-control"  required>
                            		<option value="">Selecione</option>
		                            <option value="1">SMS</option>
		                            <option value="2">PUSH</option>
		                            <option value="3">SMS/PUSH</option>
                          		</select>
               				</div>

               				<div className="form-group">
               					<label>Assunto:</label>
               					<input type="text" name="assunto" className="form-control" required/>
               				</div>

               				<div className="form-group">
		               			<label>Arquivo Excel: </label>
		               				<div className="form-group">
		               					<input type="file" name="arquivo" placeholder="Excel" className="lista form-control" required/>
		               				</div>
		               			</div>

                			</div>
                			<div className="col-sm-6">
               					<div className="form-group">
									<label>Mensagem</label>
									<textarea className="form-control" name="mensagem" rows="8" required></textarea>
               					</div>

               					<div className="form-group text-right">
               						<button type="submit" className="btn btn-success">Enviar mensagem</button>
               					</div>
                			</div>
                		</div>
                	</form>
                </div>

                </div>
                <hr/>
                {/* exibe  */}
                <div className="panel">
                    <div className="panel-title">
                    <h2>Histórico de envio</h2>
                    </div>
                    <div className="panel-body container-fluid">

                    	<table className="table table-striped table-doc">
                    		<thead>
                    		<tr>
                    			<th>Data de envio</th>
                    			<th>Tipo de envio</th>
                                <th>Assunto</th>
                    			<th>Arquivo</th>
                    		</tr>
                    		</thead>
                    		<tbody>
                    			{dadosTabela}
                    		</tbody>
                    	</table>

                    </div>
                </div>
                {/*fim*/}
            </div>
        </div>;
	}
}
function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(SmsPushConecta))