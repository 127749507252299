import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';
import Alert from 'react-s-alert';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';
import { isNull } from 'util';
import { isNullOrUndefined } from 'util';

class Pagamentos extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            trabs: [],
            qtd_total: 0,
            vlr_total: 0,
            qtd_ativos: 0,
            qtd_ate12m: 0,
            qtd_apos12m: 0,
            qtd_semdireito: 0,
            qtd_opositor: 0,
            qtd_idademaxima: 0,
            auth: this.core.getAuth(),
            div_permitida: <div></div>,
            empresa: null,
            nnumero: null,
            excel: 'nao'            
            
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.showTrabs = this.showTrabs.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        data.plano =  event.target.plano.value;
        data.date_ini = event.target.date_ini.value;
        data.date_fim = event.target.date_fim.value;
        data.mesano_vecto = event.target.mesano_vecto.value;
        data.trabalhador_pesquisa = event.target.trabalhador_pesquisa.value;
        data.empresa_pesquisa = event.target.empresa_pesquisa.value;
        data.recnum_pesquisa = event.target.recnum_pesquisa.value;
        data.pesquisa_parcial =  'nao';

        this.state.paramters = "?";
        this.state.paramters += 'plano=' + data.plano;
        this.state.paramters += '&date_ini=' + data.date_ini;
        this.state.paramters += '&date_fim=' + data.date_fim;
        this.state.paramters += '&mesano_vecto=' + data.mesano_vecto;
        this.state.paramters += '&trabalhador_pesquisa=' + data.trabalhador_pesquisa;
        this.state.paramters += '&empresa_pesquisa=' + data.empresa_pesquisa;
        this.state.paramters += '&recnum_pesquisa=' + data.recnum_pesquisa;
        this.state.paramters += '&pesquisa_parcial=' + data.pesquisa_parcial;
        this.state.paramters += '&token=' + this.core.getToken();

        if (this.state.excel != 'sim') {
            this.props.action.loadingStatus();
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-pagamentos", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                self.setState({
                    relatorio:       response.content, 
                    qtd_total:       response.qtd_pagamentos,
                    vlr_total:       parseFloat(response.vlr_total_pago),
                    qtd_ativos:      parseFloat(response.qtd_ativos),
                    qtd_ate12m:      parseFloat(response.qtd_afastadosate12m),
                    qtd_apos12m:     parseFloat(response.qtd_afastadosapos12m),
                    qtd_semdireito:  parseFloat(response.qtd_semdireito),
                    qtd_opositor:    parseFloat(response.qtd_opositores),
                    qtd_idademaxima: parseFloat(response.qtd_idademaxima)
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });    
        } else {
            console.log("Chamando Excel:", data.date_ini, "!");
            if (this.consisteParametros(data.date_ini, data.date_fim, data.empresa_pesquisa, data.recnum_pesquisa)) {
                console.log("Chamando Excel OK");
                let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-empresa-pagamentos"+this.state.paramters;
                window.location.href = urlExcel        
            }
        }

    }

    showTrabs(id_empr_blto, razao_social, nosso_numero){
        this.empresa = razao_social;
        this.nnumero = nosso_numero;
        this.setState({
            trabs: []
        })
        let self = this;
        this.props.action.loadingStatus();

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/empresas-trabalhadores-boleto-pago?id_empr_blto=" + id_empr_blto
        ,{method: 'GET'}
        )
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            let trabsres = response.content.map((trab, index) => {
                    return <tr key={index} >
                        <td>{trab.cpf}</td>
                        <td>{trab.nome}</td>
                        <td>{trab.sexo}</td>
                        <td>{trab.nascimento}</td>
                        <td>{trab.admissao}</td>
                        <td>{trab.status}</td>
                    </tr>
                });


            self.setState({
                trabs: trabsres
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        })

    }

    showBoleto(token_empr_blto_impr, id_empr_blto_impr){
        let parametros = "invoice/" + token_empr_blto_impr + "?id=" + id_empr_blto_impr;
        window.open(this.core.getUrl() + parametros, 'newwindow', 'width=' + this.width + ',height=' + this.height);
    }

    showLista(){
        console.log("showLista1");
        this.state.excel = 'nao'
    }

    showExcel(){
        console.log("showExcel1");
        this.state.excel = 'sim'
    }

    consisteParametros(prm_date_ini, prm_date_fim, prm_empresa_pesquisa, prm_recnum_pesquisa){
        console.log("consisteParametros");
        if (((prm_empresa_pesquisa !== ''))) {
            this.state.excel = 'sim'
            return true;    
        }
        if (((prm_recnum_pesquisa !== ''))) {
            this.state.excel = 'sim'
            return true;    
        }
        if (((prm_date_ini === '') || isNullOrUndefined(prm_date_ini))) {
            Alert.error("Data Inicial deve ser Informada.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 3000
            });

            this.state.excel = 'nao'
            return false;    
        }
        if (((prm_date_fim === '') || isNullOrUndefined(prm_date_fim))) {
            Alert.error("Data Final deve ser Informada.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 3000
            });

            this.state.excel = 'nao'
            return false;    
        }
        return true;
    }


    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            let botao1 = null;
            let botao2 = null;
            if (data.cobranca === 'BSFOnline') {
                botao1 = 
                    <td>
                        <button className="btn btn-info" onClick={event => this.showTrabs(data.id_empr_blto, data.razao_social, data.nosso_numero)} data-toggle="modal" data-target="#trabModal">Trabalhadores</button>
                    </td>
                botao2 = 
                    <td>
                        <button className="btn btn-info" onClick={event => this.showBoleto(data.token_impr, data.id_empr_blto_impr)} data-toggle="modal" data-target="#boletoModal">Boleto</button>
                    </td>
            } else {
                botao1 = 
                    <td>
                        ----------x----------
                    </td>
                botao2 = 
                    <td>
                        ----x----
                    </td>
            }
   
            return <tr key={index} >
                <td>{data.id_empr_blto}</td>
                <td>{data.cnpj}</td>
                <td>{data.razao_social}</td>
                <td>{data.placa}</td>
                <td>{data.id_empr_plno}</td>
                <td>{data.vencimento_original}</td>
                <td>{data.pagamento}</td>
                <td>{data.dt_impr}</td>
                <td style={{textAlign:"right"}}>R$ {parseFloat(data.vlr_pago).toFixed(2)}</td>
                <td>{data.nosso_numero}</td>
                <td>{data.ativos}</td>
                <td>{data.ate12m}</td>
                <td>{data.apos12m}</td>
                <td>{data.semdireito}</td>
                <td>{data.opositor}</td>
                <td>{data.idademaxima}</td>
                <td>{data.caged}</td>
                {botao1}
                {botao2}
                <td>{data.complemento}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        let tableTrabalhadores;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.trabs.length){
            tableTrabalhadores = this.state.trabs;
        }
        else {
            tableTrabalhadores = <tr>
                <td colSpan={5}>
                    <b> Nenhum Trabalhadore vinculado a esse boleto.</b>
                </td>
                </tr>
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={20}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa</b>
                    </center>
                </td>
            </tr>
        }
        if( (this.state.auth.config.grupo.nome === "administrador") ||
            (this.state.auth.config.grupo.nome === "diretoria")) {
                this.state.div_permitida = 
            <table className="table table-striped table-doc">
            <tr>
                <thead>
                    <td>Totais</td>
                    <td>Qtd Pagamentos</td>
                    <td>Valor Total Pago</td>
                    <td>Ativos</td>
                    <td>Ate12m</td>
                    <td>Apos12m</td>
                    <td>Sem Direito</td>
                    <td>Opositor</td>
                    <td>Idade Maxima</td>
                </thead>
                <tbody>
                    <td></td>
                    <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('pr-br')}</td>
                    <td style={{textAlign:"right"}}>R$ {this.state.vlr_total.toFixed(2).toLocaleString('pr-br')}</td>
                    <td style={{textAlign:"right"}}>{this.state.qtd_ativos.toLocaleString('pt-br')}</td>
                    <td style={{textAlign:"right"}}>{this.state.qtd_ate12m.toLocaleString('pt-br')}</td>
                    <td style={{textAlign:"right"}}>{this.state.qtd_apos12m.toLocaleString('pt-br')}</td>
                    <td style={{textAlign:"right"}}>{this.state.qtd_semdireito.toLocaleString('pt-br')}</td>
                    <td style={{textAlign:"right"}}>{this.state.qtd_opositor.toLocaleString('pt-br')}</td>
                    <td style={{textAlign:"right"}}>{this.state.qtd_idademaxima.toLocaleString('pt-br')}</td>
                </tbody>
            </tr>
            </table>
        } else {
            this.state.div_permitida = <div></div>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Pagamentos</h2>
                <p>Consulte os pagamentos dos Empregadores feitas pelo Sistema de Cobrança BSFonline</p>

                <div className="modal fade" id="trabModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Trabalhadores da empresa {this.empresa}, boleto N.Número {this.nnumero}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <table style={{width: 100 + '%'}}>
                                <thead>
                                <tr>
                                    <td>CPF</td>
                                    <td>Nome</td>
                                    <td>Sexo</td>
                                    <td>Nascimento</td>
                                    <td>Admissao</td>
                                    <td>Status</td>
                                </tr>
                                </thead>  
                                <tbody>
                                    {tableTrabalhadores}
                                </tbody>
                            </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">FECHAR</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe o Intervalo de Datas para Pesquisa, demais campos são opcionais.
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-1">
                                        <div className="form-group">
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="date_ini" id="data_ini2" placeholder="Data início Pagto" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="date_fim" id="data_fim2" placeholder="Data Fim Pagto" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="mesano_vecto" id="mesano_vecto" placeholder="Mês/Ano Vencimento" className="form-control" mask="99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="trabalhador_pesquisa" id="trabalhador_pesquisa" placeholder="Todos os Trabalhadores" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Todos Empregadores" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <div className="form-group">
                                            <input name="recnum_pesquisa" id="recnum_pesquisa" placeholder="Recnum" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">
                        {this.state.div_permitida}

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>ID</td>
                                    <td>CNPJ/CPF</td>
                                    <td>Empregador</td>
                                    <td>Plano</td>
                                    <td>Empr/Plno</td>
                                    <td>Vecto Original</td>
                                    <td>Data Pagamento</td>
                                    <td>Data Impressao</td>
                                    <td style={{textAlign:"right"}}>Vlr Pago</td>
                                    <td>Nosso Numero</td>
                                    <td>Ativos</td>
                                    <td>-12m</td>
                                    <td>+12m</td>
                                    <td>Sem Direito</td>
                                    <td>Opositor</td>
                                    <td>Idade Maxima</td>
                                    <td>CAGED</td>
                                    <td>Trabalhadores</td>
                                    <td>Boleto</td>
                                    <td>Complemento</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(Pagamentos))
