import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import moment from 'moment';
import InputMask from 'react-input-mask';
import 'moment/locale/pt-br';
import Alert from 'react-s-alert';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class ListaEmpresa extends React.Component{
    constructor(props){
        super(props);

        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;

        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio_empresa: [],
            qtd_total: 0,
            empresa: null,
            nnumero: null
        }

        this.getTableShowEmpresa = this.getTableShowEmpresa.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
            empresa_pesquisa: event.target.empresa_pesquisa.value,
            recnum_pesquisa: event.target.recnum_pesquisa.value,
            email_pesquisa: event.target.email_pesquisa.value,
            pesquisa_parcial: 'sim',
        }

        if((data.empresa_pesquisa ===  "") && (data.recnum_pesquisa ===  "") && (data.email_pesquisa ===  "")){
            Alert.error("Empresa ou Recnum ou Email devem ser Informados", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 8000
            });
            return false;
        }

        this.props.action.loadingStatus();
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-cadastro", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.setState({
                relatorio_empresa: response.content,
                qtd_total: response.content.length
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    getTableShowEmpresa(){
        return this.state.relatorio_empresa.map((data, index) => {
            return <tr key={index} >
                <td>{String(data.id_empr).padStart(5,"0")}</td>
                <td>{data.cnpj_empr}</td>
                <td>{data.razao_empr}</td>
            </tr>
        });
    }

    render(){
        console.log("render=>");
        let tableContentEmpresa;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        //dados empresas
        if(this.state.relatorio_empresa.length){
            tableContentEmpresa = this.getTableShowEmpresa();
        } else {
            tableContentEmpresa = <tr>
                <td colSpan={3}>  </td>
            </tr>           
        }

        return <div className="page">
            <div className="page-content">
                <h2>Empregador - Lista</h2>
                <p>Consulta a lista de Empregadores Cadastrados no Sistema BSFOnline</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe a Razao Social/Nome ou CNPJ/CPF para a pesquisa (permite pesquisas parciais).
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Razao Social/Nome ou CNPJ/CPF" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="recnum_pesquisa" id="recnum_pesquisa" placeholder="Recnum" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="email_pesquisa" id="email_pesquisa" placeholder="Email" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary">pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="panel">
                    <div className="panel-body container-fluid">

                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                    <td>Quantidade de Empresas</td>
                                </thead>
                                <tbody>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                </tbody>
                            </tr>
                        </table>

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>Id</td>
                                    <td>CNPJ / CPF</td>
                                    <td>Razao Social / Nome</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContentEmpresa}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(ListaEmpresa))
