import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';
import  Table from '../../geral/Table';
import moment from 'moment';
import { ModalDialog, ModalContainer } from 'react-modal-dialog-react16'


//class
import Core from '../../../class/Core';
import * as Helpers from '../../../class/Helpers';
import $ from 'jquery';
import ReactSpinner from 'react16-spinjs';
//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';
import * as EnvioEmailsActions from '../../../actions/EnvioEmailsActions';


class IndexEnvioEmails extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.state = {
            head: ['CÓDIGO GRUPO', 'ASSUNTO','DESCRIÇÃO','INFO','RELATÓRIO', 'STATUS',  'DATA', 'AÇÕES'],
            columns: [],
            modelos: [],
            planos:[],
            logs:[],
            relatorio:[],
            isShowingModal: false,
            isShowModalRelatorio: false,
            isLoading: true,
            isLoadingRelatorio: false,
            uuidGrupo: "",
            pesquisa: {
                date_ini: "",
                date_fim: "",
                modelo_id: ""
            },
            bounceRate: []
        }

        this.handlerSearch =  this.handlerSearch.bind(this)
        this.inputHandleChange = Helpers.inputHandleChange.bind(this)
        this.handleInvalido = this.handleInvalido.bind(this)
        this.handleRead = this.handleRead.bind(this)
        this.handleError = this.handleError.bind(this)
        this.statusBounceEmail = this.statusBounceEmail.bind(this)
    }


    handleInvalido(e){
         let self = this;
        let uuid = e
        fetch(self.core.getUrlConecta() + 'emails/relatorio/invalidos/' + uuid,{
            headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                    }
        }).then(response => response.blob())
        .then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = uuid + "-invalidos.xlsx";
            a.click();
        })
    }

    handleRead(e){
         let self = this;
        let uuid = e
        fetch(self.core.getUrlConecta() + 'emails/relatorio/emailread/' + uuid,{
            headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                    }
        }).then(response => response.blob())
        .then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = uuid + "-read.xlsx";
            a.click();
        })
    }

    handleError(e){
         let self = this;
        let uuid = e
        fetch(self.core.getUrlConecta() + 'emails/relatorio/errorsend/' + uuid,{
            headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                    }
        }).then(response => response.blob())
        .then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = uuid + "-invalidos.xlsx";
            a.click();
        })
    }

    handlerSearch(e){
        e.preventDefault()
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();
        self.props.action.loadingStatus();

        //let url = this.core.getUrl() + this.core.getVersionAPI() + 'send-emails/grupos'
        let url = self.core.getUrlConecta() + 'emails/sended'

        let paramns = "?"

        if(e.target.date_ini.value){
            if(paramns === "?"){
                paramns += "date_ini=" + this.state.pesquisa.date_ini
            }else{
                paramns += "&date_ini=" + this.state.pesquisa.date_ini
            }
        }

        // if(e.target.date_fim.value){
        //     if(paramns === "?"){
        //         paramns += "date_fim=" + this.state.pesquisa.date_fim
        //     }else{
        //         paramns += "&date_fim=" + this.state.pesquisa.date_fim
        //     }
        // }

        if(e.target.modelo_id.value){
            if(paramns === "?"){
                paramns += "modelo_id=" + this.state.pesquisa.modelo_id
            }else{
                paramns += "&modelo_id=" + this.state.pesquisa.modelo_id
            }
        }

        fetch(url + paramns,
            {
                headers: {
                    'Accept': 'application/json, text/plain, */*',

                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                }
            }
        )
        .then(res => self.core.responseConvert(res))
        .then((response) => {

            self.props.action.loadedStatus()
            let colunas = response.content.map((data, index) => {

                let per_recebidos = (parseInt(data.total_recebidos, 10)/parseInt(data.total_envios, 10)) * 100;
                let per_falhos = (parseInt(data.total_falhos, 10)/parseInt(data.total_envios, 10)) * 100;
                console.log(data.group_uuid_eml_envo, per_recebidos, per_falhos)

                let progress_bar = `<div class="progressbar-content">
                    <small>Recebidos `+ data.total_recebidos + ` / Falhos ` + data.total_falhos + `</small>
                    <div class="progressbar">
                        <span class="success" style="width: `+ per_recebidos + `%;"></span>
                        <span class="erros" style="width: `+ per_falhos + `%;"></span>
                    </div>
                </div>`;

                let retorno = {
                    codigo: data.group_uuid_eml_envo,
                    assunto: data.assunto_eml_envo,
                    descricao: data.obs_eml_modl,
                    info: `<button class='btn btn-default not-infoEmail'  data-logEmail='${data.group_uuid_eml_envo}'><i class='fa fa-info-circle' aria-hidden='true' data-logEmail='${data.group_uuid_eml_envo}'></i></button>`,
                     relatorio: `<button class='btn btn-info not-infoSend'  data-SendEmail='${data.group_uuid_eml_envo}'><i class='fa fa-info-circle' aria-hidden='true' data-SendEmail='${data.group_uuid_eml_envo}'></i></button>`,
                    status: progress_bar,
                    data: moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format("DD/MM/YYYY"),
                    acao: "<a href='/manager/envio-emails/grupo/" + data.group_uuid_eml_envo + "' class='btn btn-sm btn-info'>Visualizar</a>"
                };

                return retorno;
            });

            window.sessionStorage.setItem("lista_envios", JSON.stringify({
                arr: colunas,
                date_ini: this.state.pesquisa.date_ini,
                date_fim: this.state.pesquisa.date_fim,
                modelo_id: this.state.pesquisa.modelo_id
            }))

            self.props.action.insertEnviosEmails(colunas)
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    statusBounceEmail(){
        let self  = this

        // setInterval(()=>{
        //     fetch(self.core.getUrlConecta() + 'emails/ses-relatorio',{
        //         headers: {
        //             'Accept': 'application/json, text/plain, */*',
        //             'Content-Type': 'application/json',
        //             'Authorization': self.core.formatAutorizationToken(self.core.getToken())
        //         }
        //     })
        //     .then(response => response.json())
        //     .then(resp => {
        //         this.setState({
        //             bounceRate: resp.content,
        //         })

        //     })
        // },35000)

    }


    componentDidMount(){
        let self = this
        let data = window.sessionStorage.getItem("lista_envios")

        if(data){
            data = JSON.parse(data)
            self.props.action.insertEnviosEmails(data.arr)
            this.setState({
                pesquisa: {
                    date_ini: data.date_ini,
                    date_fim: data.date_fim,
                    modelo_id: data.modelo_id
                }
            })
        }



        //log info
        $('body').on('click', '.not-infoEmail', async(e) => {
            //self.props.action.loadingStatus();
            this.setState({
                    isShowingModal: true,
                    isLoading: true
            })
           // let uuid = '3f462be0-ca14-4102-9edc-7b9cb2f8d11d';
            let uuid = $(e.target).attr('data-logEmail');
             fetch(self.core.getUrl() + self.core.getVersionAPI() + 'email/logs/' + uuid, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
        })
            .then(res => res.json())
            .then((response) => {
                this.setState({
                    logs: response.content,
                    isLoading: false
                })
                console.log(this.state)
                 return response;

            })
            .then( resp => {
                self.props.action.loadedStatus();
            })
            .catch(err => {
                   this.setState({
                    isShowingModal: false
                    })
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            })
        })


        $('body').on('click','.not-infoSend', (e) => {
               let uuid = $(e.target).attr('data-SendEmail');
               this.props.action.loadingStatus();
               this.setState({
                   isLoadingRelatorio: true,
                   uuidGrupo: uuid
               })



              fetch(self.core.getUrlConecta() + 'emails/relatorio/error/' + uuid,{
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                    }
              })
              .then(response => response.json())
              .then(resp => {
                 this.setState({
                     relatorio: resp.content,
                     isLoadingRelatorio: false,
                     isShowModalRelatorio: true
                 })
                  this.props.action.loadedStatus();
              })
        })
    }

    componentWillMount(){
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();

        fetch(self.core.getUrlConecta() + "emails/modelo", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
            }
        )
        .then(res => self.core.responseConvert(res))
        .then((response) => {

            let data = response.content.sort( (a,b) => a.id_eml_modl - b.id_eml_modl).map((data, index) => {
                return <option key={index + 1} value={data.id_eml_modl}> {data.id_eml_modl} - {data.assunto_eml_modl}</option>;
            });

            data.splice(0, 0, <option key={0} value="">SELECIONE UM MODELO</option>);
            self.setState({
                modelos: data
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err);
            self.props.action.loadedStatus();
        });


        //lista de planos
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "planos/all", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
            }
        )
        .then(res => self.core.responseConvert(res))
        .then((response) => {

            ///mapeia o valores vindo de planos e faz um sort para ordenação de texto
            let planos = response.content.filter((data, index)=>{
                if(data.id_tr_plno !== 8888 && data.id_tr_plno !== 9999){
                    return true
                }
            }).map((data, index) => {

                let tela_rosa_id = data.id_tr_plno || 0
                return {
                    value: data.id_plno,
                    label: tela_rosa_id + " - " +data.dscr_plno,
                    id: tela_rosa_id
                }
            }).sort(function(a, b){
                return parseInt(a.id) - parseInt(b.id)
            });

            //adicionando todos os planos como opção
            planos.splice(0,0, {
                value: "all",
                label: "TODOS OS PLANOS",
                id: 0
            })

            this.setState({
                planos: planos
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err);
            self.props.action.loadedStatus();
        });


    }

    handleClose = () => this.setState({isShowingModal: false})
    handleCloseRelatorio = () => this.setState({isShowModalRelatorio: false})

    render(){

        // console.log("ENVIO LISTA>>", this.props)
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        //return render
        return <div className="page">
            <div className="page-content">
                <h2>Envio de Emails Cobrança</h2>
                <p>Gerêncie os emails e status de envio da lista para Cobrança.</p>

                {/* exibe  */}
                <div className="panel">
                    <div className="panel-title">
                        <Link className="btn btn-dark is_link" to={'/manager/envio-emails/create'}>
                            <i className="fa fa-plus-circle" aria-hidden="true"></i> ENVIAR LISTA EMAILS COBRANÇA
                        </Link>
                        {/*<Link className="btn btn-info" to={'/manager/envio-emails/create'} style={{float: 'right', color: 'white'}}>
                            <i className="fa fa-plus-circle" aria-hidden="true"></i> ENVIAR LISTA EMAILS
                        </Link>*/}
                    </div>
                    <div className="painelsearch">
                        <form onSubmit={this.handlerSearch}>
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>Data do Envio</label> <br/>
                                        <input type="date" required name="date_ini"
                                            value={this.state.pesquisa.date_ini}
                                            onChange={e => this.inputHandleChange({ date_ini: e.target.value }, this, 'pesquisa')}
                                            className="form-control"
                                            placeholder="Data início"/>
                                    </div>
                                </div>
                                {/* <div className="col-sm-3">
                                    <div className="form-group">
                                        <input type="date" name="date_fim"
                                            value={this.state.pesquisa.date_fim}
                                            onChange={e => this.inputHandleChange({ date_fim: e.target.value }, this, 'pesquisa')}
                                            className="form-control"
                                            placeholder="Data fim"/>
                                    </div>
                                </div> */}
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Modelo Email</label> <br/>
                                        <select required name="modelo_id" id=""
                                            className="form-control"
                                            value={this.state.pesquisa.modelo_id}
                                            onChange={e => this.inputHandleChange({ modelo_id: e.target.value }, this, 'pesquisa')}>
                                            {this.state.modelos}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <label style={{ opacity: 0 }}>envio</label> <br/>
                                    <button type="submit" className="btn btn-primary">BUSCAR</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="panel-body container-fluid">
                        <Table
                            head={this.state.head}
                            columns={this.props.envioEmails}
                        />
                    </div>
                </div>
                {/*fim*/}
            </div>



            <div className="modal fade" id="myModalLog" role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                           <div className="col-sm-12">
                                <button type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title" id="myModalLabel">Responsável: </h4>
                           </div>
                        </div>

                        <div className="modal-body" id="Lista">
                            <div className="col-sm-12">





                            </div>

                        </div>

                    </div>
                </div>
            </div>
            {this.state.isShowModalRelatorio &&
            <ModalContainer>
                { this.state.isLoadingRelatorio ?
                 <ReactSpinner color="white"/> :
                    <ModalDialog onClose={this.handleCloseRelatorio}>
                    <ReactSpinner color="white"/>
                    <h3 style={{textAlign:'center'}}>Relatório de E-mail</h3>
                     <h4 style={{textAlign:'center'}}>Código grupo</h4>
                     <p style={{textAlign:'center'}}>{this.state.uuidGrupo}</p>
                    <hr/>
                    <table>
                    	<tr>
                    		<td>Total</td>
                    		<td>{this.state.relatorio.totalSend}</td>
                    		<td></td>
                    	</tr>

                        <tr>
                            <td style={{paddingRight:50}}>Erro de envio:</td>
                            <td>{this.state.relatorio.totalError}</td>
                            <td style={{paddingLeft:50}}>
                                {(this.state.relatorio.totalError > 0) ?
                                <button onClick={() => this.handleError(this.state.uuidGrupo)}  className='btn btn-success btn-sm'>Download Excel</button>
                                   : ''
                                }
                            </td>
                        </tr>

                        <tr>
                            <td>E-mail inválidos:</td>
                            <td>{this.state.relatorio.emailsInvalid}</td>
                            <td style={{paddingLeft:50}}>
                            {(this.state.relatorio.emailsInvalid > 0) ?
                            <button onClick={() => this.handleInvalido(this.state.uuidGrupo)} className='btn btn-success btn-sm'>Download Excel</button>
                            : ''
                            }

                            </td>
                        </tr>

                          <tr>
                            <td>E-mail lidos:</td>
                            <td>{this.state.relatorio.emailsRead}</td>
                            <td style={{paddingLeft:50}}>
                            {(this.state.relatorio.emailsRead > 0) ?
                            <button onClick={() => this.handleRead(this.state.uuidGrupo)} className='btn btn-success btn-sm'>Download Excel</button>
                            : ''
                            }

                            </td>
                        </tr>
                    </table>

                </ModalDialog>
            }
            </ModalContainer>
            }




            { this.state.isShowingModal &&
        <ModalContainer onClose={this.handleClose}>
          {
             this.state.isLoading ?
             <ReactSpinner color="white"/> :
            <ModalDialog onClose={this.handleClose}>



               { this.state.logs &&

                                    this.state.logs.map(value => {
                                       let user = JSON.parse(value.usuario)
                                       let dados = JSON.parse(value.dados)

                                       let dadosUsuario = (dados.usuario) ? JSON.parse(dados.usuario) : user

                                       let planosObjeto = JSON.stringify(dados.planos)
                                       let planosArray = (planosObjeto)? planosObjeto.replace(/[\[\]]/g,'').split(',') : []
                                       let item =  planosArray.map( val => {
                                                   return  this.state.planos.map(v => {
                                                      if(v.value == Number(val)){
                                                          return <li key={v.id}>{v.label}</li>
                                                      }
                                                   })
                                               })
                                        return (
                                            <div key={value.uuid}>
                                                <h3>LOG DE ENVIO</h3>
                                                    <hr/>
                                                    <p><b>Usuário: </b> {dadosUsuario.nome_user} </p>
                                                    <p><b>Horario disparo: </b> {value.hora} </p>
                                                    <p><b>Código do grupo: </b> {value.uuid} </p>
                                                    {(planosArray.length)? (<div><hr/>
                                                    <h3>DADOS INSERIDOS</h3>
                                                    <p><b>MODELO SELECIONADO: </b> {dados.modelo_id} </p>
                                                    <p><b>TIPO SELECIONADO: </b> {dados.tipo} </p>
                                                    <p><b>PLANOS SELECIONADOS:</b> </p>
                                                    <ul>
                                                        {item}
                                                    </ul></div>) : ''}



                                            </div>
                                            )
                                })}

            </ModalDialog>
          }
        </ModalContainer>}


        </div>;
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer,
        envioEmails: state.envioEmails
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions,
            ...EnvioEmailsActions
        }, dispatch)
    }
}

export default withRouter(connect(mapStateProps, mapDispatchProps)(IndexEnvioEmails))
