import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class CobrancaBSF extends React.Component{
    constructor(props){
        super(props);

        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;

        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio_empresa: [],
            qtd_total: 0,
            empresa: null,
            nnumero: null
        }

        this.getTableShowEmpresa = this.getTableShowEmpresa.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        console.log("handlerSubmit=>");
        event.preventDefault();
        let self = this;

        let data = {
            empresa_pesquisa: event.target.empresa_pesquisa.value,
            pesquisa_parcial: 'sim',
        }

        this.props.action.loadingStatus();
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-cadastro", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.setState({
                relatorio_empresa: response.content,
                qtd_total: response.content.length
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    EmpresaMigrarTotalCaged(prm_id_empr){
        let self=this;
        this.state.paramters = '?id_empr=' + prm_id_empr;
        let urlDesativarPlano = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/empresa-migrar-totalcaged"+this.state.paramters;
        fetch(urlDesativarPlano, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(this.state.paramters)})

        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.refs.formulario.click()
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    EmpresaMigrarListaNominal(prm_id_empr){
        let self=this;
        this.state.paramters = '?id_empr=' + prm_id_empr;
        let urlAtivarPlano = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/empresa-migrar-listanominal"+this.state.paramters;
        fetch(urlAtivarPlano, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(this.state.paramters)})

        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.refs.formulario.click()
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    EmpresaMigrarMassaTotal(prm_id_empr){
        let self=this;
        this.state.paramters = '?id_empr=' + prm_id_empr;
        let urlAtivarPlano = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/empresa-migrar-massatotal"+this.state.paramters;
        fetch(urlAtivarPlano, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(this.state.paramters)})

        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.refs.formulario.click()
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }


    getTableShowEmpresa(){
        return this.state.relatorio_empresa.map((data, index) => {
            let cobranca = null;
            let lista    = null;
            
            if (data.CAGED) {
                cobranca = 'Total Caged';
            } else {
                if (data.MassaTotal) { 
                    cobranca = 'Lista Massa Total';
                } else {
                    cobranca = 'Lista Simples';
                } 

            }
            let botao1 = null;
            let botao2 = null;
            if(cobranca !== "Total Caged") {
                botao1 = 
                <td>
                    <button className="btn btn-danger" onClick={event => this.EmpresaMigrarTotalCaged(data.id_empr)} data-toggle="modal" data-target="#migrarTotalCaged">->Total Caged</button>
                </td>   
                botao2 = <td></td>
                if (cobranca === 'Lista Simples') {
                    botao2 = 
                    <td>
                        <button className="btn btn-info" onClick={event => this.EmpresaMigrarMassaTotal(data.id_empr)} data-toggle="modal" data-target="#migrarMassaTotal">->Massa Total</button>
                    </td>       
                } else {
                    botao2 = 
                    <td>
                        <button className="btn btn-info" onClick={event => this.EmpresaMigrarListaNominal(data.id_empr)} data-toggle="modal" data-target="#migrarListaNominal">->Lista Nominal</button>
                    </td>       
                } 
            } else {
                botao1 = 
                <td>
                    <button className="btn btn-info" onClick={event => this.EmpresaMigrarListaNominal(data.id_empr)} data-toggle="modal" data-target="#migrarListaNominal">->Lista Nominal</button>
                </td>                        
                botao2 = 
                <td>
                    <button className="btn btn-info" onClick={event => this.EmpresaMigrarMassaTotal(data.id_empr)} data-toggle="modal" data-target="#migrarMassaTotal">->Massa Total</button>
                </td>       
            }

            console.log("pmtr_empr=>",data.pmtr_empr);
            return <tr key={index} >
                <td>{String(data.id_empr).padStart(5,"0")}</td>
                <td>{data.cnpj_empr}</td>
                <td>{data.razao_empr}</td>
                <td>{cobranca}</td>
                {botao1}
                {botao2}
            </tr>
        });
    }

    render(){
        console.log("render=>");
        let tableContentEmpresa;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        //dados empresas
        if(this.state.relatorio_empresa.length){
            tableContentEmpresa = this.getTableShowEmpresa();
        } else {
            tableContentEmpresa = <tr>
                <td colSpan={6}>  </td>
            </tr>           
        }

        return <div className="page">
            <div className="page-content">
                <h2>Empregador - Método de Cobranca</h2>
                <p>Consulte e Altere o metodo de cobranca dos Empregdores Cadastradas no Sistema BSFOnline</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe a Razao Social/Nome ou CNPJ/CPF para a pesquisa (permite pesquisas parciais).
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Informe a Razao Social/Nome ou CNPJ/CPF" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit"  ref="formulario" className="btn btn-primary">pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="panel">
                    <div className="panel-body container-fluid">

                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                    <td>Quantidade de Empresas</td>
                                </thead>
                                <tbody>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                </tbody>
                            </tr>
                        </table>

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>Id</td>
                                    <td>CNPJ/CPF</td>
                                    <td>Empregador</td>
                                    <td>Cobranca Atual</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContentEmpresa}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(CobrancaBSF))
