import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import Core from "../../../class/Core";
import moment from "moment";
import "moment/locale/pt-br";

//actions
import * as UserActions from "../../../actions/UserActions";
import * as ManagerStatusActions from "../../../actions/StatusManagerActions.js";
import * as RedirectActions from "../../../actions/RedirectActions";
import * as StatusActions from "../../../actions/StatusActions";
import Alert from "react-s-alert";

class EmpresaPlanos extends React.Component {
    constructor(props) {
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66;
        this.height = (this.width * window.innerHeight) / window.innerWidth;
        //define locale moment
        moment.locale("pt-BR");
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            /* totais planos */
            qtd_planos: 0,
            qtd_planos_ativos: 0,
            qtd_planos_inativos: 0,
            auth: this.core.getAuth(),
        };

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount() {
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event) {
        event.preventDefault();
        let self = this;

        let data = {
            empresa_pesquisa: event.target.empresa_pesquisa.value,
        };

        switch (event.target.planos_ativos.value) {
            case "1":
                data.planos_ativos = "sim";
                break;
            case "2":
                data.planos_ativos = "nao";
                break;
            default:
                data.planos_ativos = "todos";
                break;
        }

        data.recnum = null;
        data.pesquisa_parcial = "nao";

        if (data.empresa_pesquisa === "") {
            Alert.error("Empresa deve ser Informada", {
                position: "bottom-right",
                effect: "slide",
                timeout: 8000,
            });
            return false;
        }

        this.props.action.loadingStatus();

        console.log("Fetch Plano");
        fetch(
            self.core.getUrl() +
                self.core.getVersionAPI() +
                "empresa/relatorios/lista-empresa-planos",
            {
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    Authorization: self.core.formatAutorizationToken(
                        self.core.getToken()
                    ),
                },
                method: "POST",
                body: JSON.stringify(data),
            }
        )
            .then((res) => self.core.responseConvert(res))
            .then((response) => {
                console.log("Planos=>response.content:", response.content);
                this.state.qtd_planos = 0;
                this.state.qtd_planos_ativos = 0;
                this.state.qtd_planos_inativos = 0;
                response.content.map((val, index) => {
                    this.state.qtd_planos += 1;
                    switch (val.situ_plno) {
                        case true:
                            this.state.qtd_planos_ativos += 1;
                            break;
                        case false:
                            this.state.qtd_planos_inativos += 1;
                            break;
                        default:
                            break;
                    }
                });
                self.setState({
                    relatorio: response.content,
                    qtd_total: response.content.length,
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err);
                self.props.action.loadedStatus();
            });
    }

    desativarPlano(prm_id_empr_plno) {
        let self = this;
        this.state.paramters = "?id_empr_plno=" + prm_id_empr_plno;
        let urlDesativarPlano =
            this.core.getUrl() +
            this.core.getVersionAPI() +
            "empresa/relatorios/empresa-plano-desativar" +
            this.state.paramters;
        fetch(urlDesativarPlano, {
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: self.core.formatAutorizationToken(
                    self.core.getToken()
                ),
            },
            method: "POST",
            body: JSON.stringify(this.state.paramters),
        })
            .then((res) => self.core.responseConvert(res))
            .then((response) => {
                self.refs.formulario.click();
            })
            .catch((err) => {
                self.core.catchFecth(err);
                self.props.action.loadedStatus();
            });
    }

    ativarPlano(prm_id_empr_plno) {
        let self = this;
        this.state.paramters = "?id_empr_plno=" + prm_id_empr_plno;
        let urlAtivarPlano =
            this.core.getUrl() +
            this.core.getVersionAPI() +
            "empresa/relatorios/empresa-plano-ativar" +
            this.state.paramters;
        fetch(urlAtivarPlano, {
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: self.core.formatAutorizationToken(
                    self.core.getToken()
                ),
            },
            method: "POST",
            body: JSON.stringify(this.state.paramters),
        })
            .then((res) => self.core.responseConvert(res))
            .then((response) => {
                self.refs.formulario.click();
            })
            .catch((err) => {
                self.core.catchFecth(err);
                self.props.action.loadedStatus();
            });
    }

    getTableShow() {
        //console.log("Planos=>", this.state.relatorio_planos);
        console.log("getTableShow=>");
        return this.state.relatorio.map((data, index) => {
            let situacao = null;
            let status_tr = null;
            let cobranca_bsfonline = null;
            let oper_senha_tr = null;
            let botao1 = null;
            if (
                this.state.auth.email === "d.sis@beneficiosocial.com.br" ||
                this.state.auth.email === "cct01@beneficiosocial.com.br"
            ) {
                console.log("Situacao Plano:", data.situ_plno);
                if (data.situ_plno) {
                    console.log("Desativar");
                    botao1 = (
                        <td>
                            <button
                                className="btn btn-danger"
                                onClick={(event) =>
                                    this.desativarPlano(data.id_empr_plno)
                                }
                                data-toggle="modal"
                                data-target="#desativarplnoModal"
                            >
                                Desativar
                            </button>
                        </td>
                    );
                } else {
                    console.log("Ativar");
                    botao1 = (
                        <td>
                            <button
                                className="btn btn-info"
                                onClick={(event) =>
                                    this.ativarPlano(data.id_empr_plno)
                                }
                                data-toggle="modal"
                                data-target="#ativarplnoModal"
                            >
                                Ativar
                            </button>
                        </td>
                    );
                }
            } else {
                botao1 = <td></td>;
            }

            if (data.situ_plno) {
                situacao = "ativo";
            } else {
                situacao = "inativo";
            }
            if (data.emcv_fl_status === 0) {
                status_tr = "ativo";
            } else {
                status_tr = "inativo";
            }
            if (data.conv_fl_cobranca_personal === 0) {
                cobranca_bsfonline = "sim";
            } else {
                if (data.emcv_fl_cobranca_personal === 0) {
                    cobranca_bsfonline = "nao";
                } else {
                    cobranca_bsfonline = "migrada";
                }
            }
            if (data.emcv_fl_oper_senha === 0) {
                oper_senha_tr = "nao";
            } else {
                oper_senha_tr = "sim";
            }

            return (
                <tr key={index}>
                    <td>{String(data.id_plno).padStart(5, "0")}</td>
                    <td>{String(data.id_tr_plno).padStart(3, "0")}</td>
                    <td>{data.id_empr_plno}</td>
                    <td>{String(data.emrn_cd_recnum).padStart(7, "0")}</td>
                    <td>{data.dscr_plno}</td>
                    <td>{situacao}</td>
                    <td>{status_tr}</td>
                    <td>{oper_senha_tr}</td>
                    <td>{cobranca_bsfonline}</td>
                    {botao1}
                </tr>
            );
        });
    }

    render() {
        let tableContent;
        if (this.props.redirect.url) {
            return <Redirect to={this.props.redirect.url} />;
        }

        if (this.state.relatorio.length) {
            tableContent = this.getTableShow();
        } else {
            tableContent = (
                <tr>
                    <td colSpan={10}>
                        <center>
                            <b>
                                Informe os parametros necessários para a
                                pesquisa
                            </b>
                        </center>
                    </td>
                </tr>
            );
        }

        return (
            <div className="page">
                <div className="page-content">
                    <h2>Empregador x Planos</h2>
                    <p>Consulte os Planos de um Empregador</p>

                    <div className="panel">
                        <div className="panel-title">
                            <h3>
                                Informe o Empregador (CNPJ/CPF ou Nome) para
                                Pesquisa.
                            </h3>
                            <div className="seleciona-mes-doc">
                                <form onSubmit={this.handlerSubmit}>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <input
                                                    name="empresa_pesquisa"
                                                    id="empresa_pesquisa"
                                                    placeholder="Razao Social/Nome ou CNPJ/CPF"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <label>
                                                    {" "}
                                                    <b>Ativos/Inativos</b>{" "}
                                                </label>{" "}
                                                <br />
                                                <input
                                                    type="radio"
                                                    name="planos_ativos"
                                                    value="1"
                                                />{" "}
                                                Ativos <br />
                                                <input
                                                    type="radio"
                                                    name="planos_ativos"
                                                    value="2"
                                                />{" "}
                                                Inativos <br />
                                                <input
                                                    type="radio"
                                                    name="planos_ativos"
                                                    value="9"
                                                />{" "}
                                                Ambos <br />
                                            </div>
                                        </div>
                                        <div className="col-sm-1">
                                            <button
                                                type="Submit"
                                                ref="formulario"
                                                className="btn btn-primary"
                                            >
                                                pesquisar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panel-body container-fluid">
                                <table className="table table-striped table-doc">
                                    <thead>
                                        <tr>
                                            <h2> Planos </h2>
                                        </tr>

                                        <tr>
                                            <thead>
                                                <td>Total</td>
                                                <td>Ativos</td>
                                                <td>Inativos</td>
                                            </thead>
                                            <tbody>
                                                <td
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    {this.state.qtd_planos.toLocaleString(
                                                        "de-DE"
                                                    )}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    {this.state.qtd_planos_ativos.toLocaleString(
                                                        "de-DE"
                                                    )}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    {this.state.qtd_planos_inativos.toLocaleString(
                                                        "de-DE"
                                                    )}
                                                </td>
                                            </tbody>
                                        </tr>

                                        <tr>
                                            <td>Id</td>
                                            <td>Plano</td>
                                            <td>Empr/Plano</td>
                                            <td>Recnum</td>
                                            <td>Descrição</td>
                                            <td>Situação</td>
                                            <td>Status TR</td>
                                            <td>Operador</td>
                                            <td>BSFOnline</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>{tableContent}</tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer,
    };
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators(
            {
                ...UserActions,
                ...ManagerStatusActions,
                ...RedirectActions,
                ...StatusActions,
            },
            dispatch
        ),
    };
}
export default withRouter(
    connect(mapStateProps, mapDispatchProps)(EmpresaPlanos)
);
