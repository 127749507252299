import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
//import moment from 'moment';
import Alert from 'react-s-alert';
import InputMask from 'react-input-mask';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';


class ExcelNetPointsPagamentos extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.handlerSubmit = this.handlerSubmit.bind(this);
        this.state = {
            auth: this.core.getAuth(),
            div_permitida1: <div></div>,          
            div_permitida2: <div></div>          
        }
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();

        let url = event.currentTarget.getAttribute("action");
        let paramters = "?";

        if(url === null){
            Alert.error("Problemas de configuração.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 8000
            });
            return false;
        }

        let plano =  event.target.plano.value;
        let date_ini= event.target.date_ini.value;
        let date_fim = event.target.date_fim.value;
        let empresa_pesquisa = event.target.empresa_pesquisa.value;
        let empresas_cadastradas = null;
        let remessa_pendente = null;

        switch(event.target.cadastro_cpf.value){
        case "1":
            empresas_cadastradas = "sim";
            break;
        case "2":
            empresas_cadastradas = "nao";
            break;
        default:
            empresas_cadastradas = "todas";
            break;
        }

        switch(event.target.remessa_pendente.value){
            case "1":
                remessa_pendente = "sim";
                break;
            case "2":
                remessa_pendente = "nao";
                break;
            default:
                remessa_pendente = "todas";
                break;
        }
            
        paramters += 'date_ini=' + date_ini;
        paramters += '&date_fim=' + date_fim;
        if (plano !== null) {
            paramters += '&plano=' + plano;
        }
        if (empresa_pesquisa !== null) {
            paramters += '&empresa_pesquisa=' + empresa_pesquisa;
        }       
        if (empresas_cadastradas !== null) {
            paramters += '&empresas_cadastradas=' + empresas_cadastradas;
        }
        if (remessa_pendente !== null) {
            paramters += '&remessa_pendente=' + remessa_pendente;
        }

        console.log("Parametros=>", paramters);
        if(paramters !== "?"){
            window.open( url + paramters , '_blank');
        }else{
            window.open( url , '_blank');
        }

        event.currentTarget.reset();
        return true;
    }

    render(){
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        //url dos relatórios
        let urlNetPoints = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-netpoints-pagamentos";

        return <div className="page">
            <div className="page-content">
                <h2>Pagamentos - Clube de Vantagens (Excel)</h2>
                <p>Consulte e gere uma planilha com os pagamentos das empresas Participantes do Clube de Vantagens, feitas pelo Sistema de Cobrança BSFonline</p>

                {/*inicio*/}
                <div className="panel">
                    <h3 className='panel-title'>Informe o intervalo de data para a geração do relatório</h3>
                    <div className="panel-body container-fluid">
                        <form action={urlNetPoints} onSubmit={this.handlerSubmit}>
                        <div className="row">
                            <div className="col-sm-1">
                                <div className="form-group">
                                    <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="form-group">
                                    <InputMask name="date_ini" id="data_ini2" placeholder="Data início" className="form-control" mask="99/99/9999" />
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="form-group">
                                    <InputMask name="date_fim" id="data_fim2" placeholder="Data fim" className="form-control" mask="99/99/9999" />
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="form-group">
                                    <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Todas as Empresas" className="form-control" />
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="form-group">
                                <label> <b>Cadastro NetPoints</b> </label> <br/>
                                    <input type="radio" name="cadastro_cpf" value="1" /> Com Vínculo de CPF <br/>
                                    <input type="radio" name="cadastro_cpf" value="2" /> Sem Vínculo de CPF <br/>
                                    <input type="radio" name="cadastro_cpf" value="9" /> Ambos <br/>

                                </div>
                            </div>
                            <div className="col-sm-2">
                                        <div className="form-group">
                                        <label> <b>Remessa Pendente</b> </label> <br/>
                                            <input type="radio" name="remessa_pendente" value="1" /> Sim  <br/>
                                            <input type="radio" name="remessa_pendente" value="2" /> Nao <br/>
                                            <input type="radio" name="remessa_pendente" value="9" /> Ambos <br/>
                                        </div>
                            </div>
                            <div className="col-sm-1">
                                <div className="form-group">
                                    <button className="btn btn-primary margin-btn">EXCEL</button>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        /*fim*/
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(ExcelNetPointsPagamentos))
