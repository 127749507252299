import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class ContadorOperador
 extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            qtd_total: 0,
            paramters: null,
            critica: 'nao',            
            excel: 'nao'            
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        data.pesquisa_parcial = 'sim';
        data.operador_pesquisa = event.target.operador_pesquisa.value;
        data.contador_pesquisa = event.target.contador_pesquisa.value;
   
        this.state.paramters = "?";
        this.state.paramters += 'pesquisa_parcial=' + data.pesquisa_parcial;
        this.state.paramters += '&operador_pesquisa=' + data.operador_pesquisa;
        this.state.paramters += '&contador_pesquisa=' + data.contador_pesquisa;
        this.state.paramters += '&token=' + this.core.getToken();

        console.log("Fetch");
        this.props.action.loadingStatus();
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-contador-operador", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            response.content.map ((val, index) => {
            })
    
        self.setState({
            relatorio: response.content,
            qtd_total: response.content.length
        });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });   
    }

    showLista(){
        console.log("showLista1");
        this.setState({
            excel: 'nao'
        });
    }

    getTableShow(){
        console.log("getTableShow=>");
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.id_admin}</td>
                <td>{data.cnpj_admin}</td>
                <td>{data.razao_admin}</td>
                <td>{data.uf_admin}</td>
                <td>{data.id_admin_oprdr}</td>
                <td>{data.cpf_admin_oprdr}</td>
                <td>{data.nome_admin_oprdr}</td>
                <td>{data.situacao}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={8}>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Contador x Operadores</h2>
                <p>Contadores Cadastrados no Sistemas BSFOnline x Operadores</p>

                <div className="panel">
                    <div className='panel-title'>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="contador_pesquisa" id="contador_pesquisa" placeholder="Todos os Contadores" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="operador_pesquisa" id="operador_pesquisa" placeholder="Todas os Operadores" className="form-control" />
                                        </div>
                                    </div>


                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panel-body container-fluid">

                            <table className="table table-striped table-doc">
                                <tr>
                                    <thead>
                                        <td>Totais</td>
                                        <td>Operadores</td>
                                    </thead>
                                    <tbody>
                                        <td></td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                    </tbody>
                                </tr>
                            </table>

                            <table className="table table-striped table-doc">
                                <thead>
                                    <tr>
                                        <td>ID</td>
                                        <td>CNPJ Contabilidade</td>
                                        <td>Contabilidade</td>
                                        <td>UF</td>
                                        <td>ID</td>
                                        <td>CPF Operador</td>
                                        <td>Nome</td>
                                        <td>Situação</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableContent}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(ContadorOperador
))
