import React from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';
import moment from 'moment';
import * as firebase from 'firebase';
import load from '../../../images/loading.gif';
import {BarChart, PieChart, Pie, Sector, Cell, Legend, Tooltip,Label , CartesianGrid, XAxis,Bar, LabelList, text, YAxis} from 'recharts';
import { ModalDialog, ModalContainer } from 'react-modal-dialog-react16'
import SweetAlert from 'sweetalert-react';
import '../../../assets/css/sweetalert.css'
//class
import Table from '../../geral/Table';
import $ from 'jquery';
import List from 'list.js';
import { saveAs } from 'file-saver/FileSaver';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';
import Core from '../../../class/Core';


class IndexNotificacoes extends React.Component {

    constructor(props) {
        super(props)

        this.core = new Core();
        this.core.isAuth();
        this.state = {
            head: ['ID', "NOME",'MODELO', 'DATA ENVIO','INFO', 'RELATÓRIO', 'AÇÃO'],
            columns: [],
            redirect: null,
            RelatorioHead: ['CNPJ', 'STATUS'],
            Relatoriocolumns: [],
            modalisLoad: false,
            planosTR: [],
            dados:{
                enviados:0,
                pendentes: 0,
                lidos: 0,
                naoLidos: 0,
                ativos: 0,
                ativosLidos:0,
                ativosNaoLidos:0,
            },
            titleModal: null,
            link : null,
            info: [],
            name_model: null,
            show: false,
            delNot : null

        }



        const config = {
            apiKey: "AIzaSyCHvahxPOqz05KbYAn2weTo_TCDIzLpvTw",
            authDomain: "bsf-connecta.firebaseapp.com",
            databaseURL: "https://bsf-connecta.firebaseio.com",
            projectId: "bsf-connecta",
            storageBucket: "bsf-connecta.appspot.com",
            messagingSenderId: "257892754116"
        }

        if (!firebase.apps.length) {
            firebase.initializeApp(config);
        }

        this.modalRemove = this.modalRemove.bind(this)

    }

        teste(id){
        return <button class='btn btn-default not-info' onClick={() =>  this.handleClickInfo(id)} data-toggle='modal' data-target='#myModalInfo' data-id={id}><i class='fa fa-info-circle' aria-hidden='true'></i></button>
    }

    componentWillMount() {
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();
        this.props.action.loadingStatus();
        self.props.action.loadedStatus();


        //Carregando Notificações
        fetch(self.core.getUrl() + self.core.getVersionAPI() + 'email/notificacoes', {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
        })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                console.log(response)
                let data = response.content.map((data, index) => {
                    let d = new Date(data.created_at);
                    let formatted = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;

                    return {
                        id: data.id_notfo,
                        nome: data.nome_notfo,
                        modelo: data.model_notfo,
                        tipo: formatted,
                        info: `<button class='btn btn-default not-info' data-toggle='modal' data-target='#myModalInfo' data-info='${data.id_notfo}'><i class='fa fa-info-circle' aria-hidden='true' data-info='${data.id_notfo}'></i></button>`,
                        relatorio: "<button class='btn btn-info btn-relatoio-item' data-title='" + data.nome_notfo + "' data-idNOT='" + data.id_notfo + "' data-toggle='modal' data-target='#myModal'><i class='fa fa-pie-chart' aria-hidden='true' data-title='" + data.nome_notfo + "' data-idNOT='" + data.id_notfo + "'></i></button>",
                        acao: "<button class='btn btn-sm btn-danger btn-remove-item' data-id='" + data.id_notfo + "'>Remover</button>"
                    }
                })

                self.setState({
                    columns: data
                });
            }).catch(err => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            })



            //Planos
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "planos", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
            }
        )
        .then(res => self.core.responseConvert(res))
        .then((response) => {

            ///mapeia o valores vindo de planos e faz um sort para ordenação de texto
            let planos = response.content.filter((data, index)=>{
                if(data.id_tr_plno !== 8888 && data.id_tr_plno !== 9999){
                    return true
                }
            }).map((data, index) => {

                let tela_rosa_id = data.id_tr_plno || 0
                return {
                    value: data.id_plno,
                    label: tela_rosa_id + " - " +data.dscr_plno,
                    id: tela_rosa_id
                }
            }).sort(function(a, b){
                return parseInt(a.id) - parseInt(b.id)
            });

            //adicionando todos os planos como opção
            planos.splice(0,0, {
                value: "all",
                label: "TODOS OS PLANOS",
                id: 0
            })

            this.setState({
                planos: planos
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err);
            self.props.action.loadedStatus();
        });
    }

     //Button Confirm


    modalRemove(id){
         let self = this;

           if (id) {
                self.props.action.loadingStatus();
                fetch(self.core.getUrl() + self.core.getVersionAPI() + "email/notificacoes/" + id, {
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                    },
                    method: 'DELETE'
                    }
                    )
                    .then(res => self.core.responseConvert(res))
                    .then((response) => {

                        self.updateData();
                    })
                    .catch((err) => {
                        self.core.catchFecth(err)
                        self.props.action.loadedStatus();
                    });
            }
    }

    componentDidMount() {

        let self = this;
        $('body').on('click', '.btn-remove-item', async(e) => {
            let id = $(e.target).attr('data-id');

           // let result = await window.confirm("Deseja remover realmente remover essa notificação?");
             this.setState({
                 delNot: id,
                 show: true
             })



        });
        // Carregar Relatorio
        const db = firebase.database();

        $('body').on('click', '.btn-relatoio-item', async (e) => {

            let ID_NOT = $(e.target).attr('data-idNOT');
            let ID_TITLE =  $(e.target).attr('data-title');
             self.props.action.loadingStatus();
            this.setState({
                modalisLoad: false,
                titleModal: ID_TITLE,
                codRelatorio: ID_NOT,
                link: null,
                dados:{
                    enviados:0,
                    pendentes: 0,
                    lidos: 0,
                    naoLidos:0,
                    ativos: 0,
                    ativosLidos:0,
                    ativosNaoLidos:0,
            }
            })
            const ref = db.ref('empresa').limitToFirst(10);

            let listNotification = document.getElementById('listNotification');

          //  $('#myModalLabel').html(ID_TITLE);


            fetch(self.core.getUrlConecta() + 'relatorio/notification', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    relatorio: ID_NOT
                })

            })
            .then(response => response.json())
            .then(resp => {
            		 self.props.action.loadedStatus();
                     this.setState({
                         dados: {
                             enviados: Number(resp.enviados[0].count),
                             pendentes: Number(resp.pendentes[0].count),
                             lidos: Number(resp.lidos[0].count),
                             naoLidos:Number(resp.naoLidos[0].count),
                             ativos: resp.ativos,
                             ativosLidos: resp.ativosLidos,
                             ativosNaoLidos: resp.ativosNaoLidos
                         },

                     })



            })




            this.setState({
                    modalisLoad: true
            })

        });


        // Exibir relatorio do envio

       $('body').on('click', '.not-info', async (e) => {

         self.props.action.loadingStatus();

        this.setState({
            name_model: null
          })
            let ID_NOT = $(e.target).attr('data-info');

            if(ID_NOT){
                fetch(self.core.getUrl() + self.core.getVersionAPI() + "email/notificacoes/" + ID_NOT, {
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                    },
                    method: 'GET'
                })
                    .then(res => self.core.responseConvert(res))
                    .then((response) => {
                        this.getModelInfo(response.content.model_notfo)
                        this.setState({
                            info: response.content
                        })
                        if(this.state.info.plno_notfo){
                        let planosInfo = this.state.info.plno_notfo.split(',')
                        let item =  planosInfo.map( value => {
                                          // return  this.state.planos.map(v => v.value === Number(value))
                                           return  this.state.planos.map(v => {
                                              if(v.value == Number(value)){
                                                  return <li key={v.id}>{v.label}</li>
                                              }
                                           })

                                           })


                           this.setState({
                               planosTR : item
                           })
                         } else {
                             this.setState({
                               planosTR : []
                           })
                         }

                          self.props.action.loadedStatus();

                    })
                    .catch((err) => {
                        self.core.catchFecth(err)
                        self.props.action.loadedStatus();
                    });
            }
        })
    }

      handleClickInfo(ID_NOT) {

          let self = this;
          this.setState({
              name_model: null
          })

          if (ID_NOT) {
              fetch(self.core.getUrl() + self.core.getVersionAPI() + "email/notificacoes/" + ID_NOT, {
                      headers: {
                          'Accept': 'application/json, text/plain, */*',
                          'Content-Type': 'application/json',
                          'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                      },
                      method: 'GET'
                  })
                  .then(res => self.core.responseConvert(res))
                  .then((response) => {

      





                      this.setState({
                          info: response.content
                      })




                       this.getModelInfo(response.content.model_notfo)




                  })

                  .catch((err) => {
                      self.core.catchFecth(err)
                      self.props.action.loadedStatus();
                  });
          }

      }




    updateData() {
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();
        this.props.action.loadingStatus();
        self.props.action.loadedStatus();


        //Carregando Notificações
        fetch(self.core.getUrl() + self.core.getVersionAPI() + 'email/notificacoes', {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
        })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                let data = response.content.map((data, index) => {

                    let d = new Date(data.created_at);
                    let formatted = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;

                    return {
                        id: data.id_notfo,
                        nome: data.nome_notfo,
                        tipo: formatted,
                        info: `<button class='btn btn-default not-info' data-toggle='modal' data-target='#myModalInfo' data-id='${data.id_notfo}'><i class='fa fa-info-circle' aria-hidden='true'></i></button>`,
                        relatorio: "<button class='btn btn-info btn-relatoio-item' data-idNOT='" + data.id_notfo + "' data-toggle='modal' data-target='#myModal'><i class='fa fa-pie-chart' aria-hidden='true' data-idNOT='" + data.id_notfo + "'></i></button>",
                        acao: "<button class='btn btn-sm btn-danger btn-remove-item' data-id='" + data.id_notfo + "'>Remover</button>"

                    }
                })

                self.setState({
                    columns: data
                });



            }).catch(err => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            })
    }

    save(link,filename){
        var a = document.createElement("a");
        a.href = link;
        a.setAttribute("download", filename);
        var b = document.createEvent("MouseEvents");
        b.initEvent("click", false, true);
        a.dispatchEvent(b);
        return false;
    }

    handleClick(status){

         let self = this;

        self.props.action.loadingStatus();


        fetch(self.core.getUrlConecta() + 'relatorio/download', {
            method:'POST',
            headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
            },
            body:JSON.stringify({
                relatorio: this.state.codRelatorio,
                status: status
            })
        }).then(response => {
            return response.json()
        }).then( res => {
            setTimeout(()=>{
                this.setState({
                    link: res.link
                })
                self.props.action.loadedStatus();
            },3000)

        })
    }
    showChart(){

        if(this.state.modalisLoad === true){
            //Relatorio de enviados e lidos
            const data = [
                {name: 'Não lidos', value: this.state.dados.naoLidos },
                {name: 'Lidos', value: this.state.dados.lidos},

            ];

            const data3 = [

                {name:'Não Lidos Pagantes',value: this.state.dados.ativosNaoLidos},

                {name:'Pagantes Lidos', value: this.state.dados.ativosLidos },
            ]
            //Relatorio de enviados pendente
            const data2 = [
                {name: 'Enviados', value: this.state.dados.enviados - this.state.dados.pendentes },
                {name: 'Pendentes', value: this.state.dados.pendentes },
            ];




             const data4 = [
                {name:'Enviados', value: this.state.dados.enviados - this.state.dados.ativos},
                {name:'Pagantes', value: this.state.dados.ativos},
                {name:'Pagantes Lidos', value: this.state.dados.lidos - this.state.dados.ativosLidos },
            ]


        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x  = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy  + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} 	dominantBaseline="central">
            {(percent * 100).toFixed(0)}%
            </text>
            );
        };




        const COLORS = [ '#01897B','#D4E056', '#FFEE58','#FFB200','#FF7143'];
        const COLORS2 = [ '#01897B','#D4E056', '#FFEE58','#FFB200','#FF7143'];
        COLORS2.reverse()
        return <div>
            <div className="col-sm-12">

                <PieChart width={800} height={400} onMouseEnter={this.onPieEnter}>

	 				<Legend verticalAlign="top" layout="horizontal" align="center"/>

                            <Pie
                                data={data}
                                cx={200}
                                cy={200}
                                innerRadius={90}
                                outerRadius={140}
                                label
                            >
                            { data.map((entry, index) =>
                                        <Cell fill={COLORS[index % COLORS.length]}/>)
                                    }
                                      <Label value="Total" offset={0} position="center" style={{fontWeight : '500'}} />
                            </Pie>


                             <Pie
                                data={data3}
                                cx={560}
                                cy={200}
                                innerRadius={90}
                                outerRadius={140}

                                label
                            >
                            { data3.map((entry, index) =>
                                        <Cell fill={COLORS2[index % COLORS2.length]}/>)
                            }
                              <Label value="Pagantes" offset={0} position="center" style={{fontWeight : '500'}} />
                            </Pie>
                            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    </PieChart>
                </div>


                   <div className="col-sm-12">
            <PieChart width={800} height={400} onMouseEnter={this.onPieEnter}>

                        <Pie
                            data={data2}
                            cx={200}
                            cy={200}
                            innerRadius={90}
                            outerRadius={140}
                            labelLine={false}
                            fill="#8884d8"
                            paddingAngle={5}

                            label
                        >
                        { data.map((entry, index) =>
                                    <Cell fill={COLORS[index % COLORS.length]}/>)
                                }
                            <Label value="Total" offset={0} position="center" style={{fontWeight : '500'}} />

                        </Pie>
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <Legend verticalAlign="center" layout="horizontal"  verticalAlign='top' height={36} style={{fontWeight : '500'}}/>
                        <text x="20" y="3000" dy="0.71em" style={{blackgroundColor:'orange' }}>
                            <tspan x="500" y="100" dy="0.71em">Total enviados ={this.state.dados.enviados}</tspan>
                            <tspan x="500" y="110" dy="1.81em">Total de pagantes = {this.state.dados.ativos}</tspan>
                            <tspan x="500" y="120" dy="2.91em">Total lidos = {this.state.dados.lidos}</tspan>
                            <tspan x="500" y="130" dy="4.01em">Pagantes lidos = {this.state.dados.ativosLidos}</tspan>
                        </text>

                </PieChart>

            </div>


                </div>

        }
    }


    tipoEmp(dado) {
        switch (dado) {
            case 'disponibilidade':
                return 'TODAS AS EMPRESAS'
                break;
            case 'inadimplentes':
                return 'EMPRESAS INADIMPLENTES'
                break;
            case 'nao-impressos':
                return 'EMPRESAS SEM IMPRESSÃO'
                break;

            default:
                 return 'NENHUM TIPO SELECIONADO'
                break;
        }
    }


     getModelInfo(modelo_id){
        let self = this;
        if(modelo_id){
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "email/email-modelo/" + modelo_id, {
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                    },
                    method: 'GET'
                })
                .then(res => self.core.responseConvert(res))
                .then((response) => {
                    this.setState({
                        name_model: response.content.obs_eml_modl || response.content.assunto_eml_modl
                    })
                })
                .catch((err) => {
                    self.core.catchFecth(err)
                    self.props.action.loadedStatus();
                });
        }
    }




    render() {
        let modalContent = null;
        if (this.props.redirect.url) {
            return <Redirect to={this.props.redirect.url} />;
        }


        return <div className="page">
         <SweetAlert
            show = {this.state.show}
            title="Notificação"
            text="Deseja remover realmente essa notificação?"
            showCancelButton
            onConfirm={() => {

              this.setState({ show: false });
             return this.modalRemove(this.state.delNot)
            }}
            onCancel={() => {
              console.log('cancel');
              this.setState({ show: false});
            }}
                     />
            <div className="page-content">
                <h2>Notificações</h2>
                <p>Gerêncie e crie notificações.</p>

                {/* exibe  */}
                <div className="panel">
                    <div className="panel-title">
                        <Link className="btn btn-dark is_link" to={'/manager/notificacoes/create'}>
                            <i className="fa fa-plus-circle" aria-hidden="true"></i> CRIAR NOTIFICAÇÃO
                        </Link>
                    </div>

                    <div className="panel-body container-fluid">
                        <Table
                            head={this.state.head}
                            columns={this.state.columns}
                        />
                    </div>
                </div>
                {/*fim*/}
            </div>

            <div className="modal fade" id="myModal" tabindex={-1} role="dialog" aria-labelledby="myModalLabel" >
                <div className="modal-dialog" role="document" style={{width:'900px',maxWidth:'865px'}}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title" id="myModalLabel">{ this.state.titleModal}</h4>
                        </div>

                        <div className="modal-body" id="Lista">
                            <div className="col-sm-12">
                                    {this.state.modalisLoad === true ? this.showChart() : ''}
                            </div>
                            <div className="col-sm-12 text-right">
                            { (this.state.link === null)? (<div><button className="btn btn-success" onClick={()=> this.handleClick('enviados')}>
                                    <i className="fa fa-table" aria-hidden="true"></i> Gerar relatório de enviados
                                </button> <button className="btn btn-success" onClick={() => this.handleClick('lidos')}>
                                    <i className="fa fa-table" aria-hidden="true"></i> Gerar relatório de lidos
                                </button></div>) :  (<a className="btn btn-danger" style={{color:'white'}} onClick={() =>  this.save(this.state.link,'save.xlsx')}>Download</a>)}

                            </div>



                        </div>

                    </div>
                </div>
            </div>



            <div className="modal fade" id="myModalInfo" tabindex={-2} role="dialog" aria-labelledby="myModalLabel" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                           <div className="col-sm-12">
                                <button type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title" id="myModalLabel">Notificação: {this.state.info.id_notfo}</h4>
                           </div>
                        </div>

                        <div className="modal-body" id="Lista">
                            <div className="col-sm-12">
                                <p><b>MODELO: </b> {(this.state.name_model === null) ? 'SEM MODELO' : this.state.name_model}</p >
                                <p><b>EMPRESAS: </b> {this.tipoEmp(this.state.info.tpo_notfo)}</p>

                                {(this.state.planosTR) && (<div>
                                      <p><b>PLANOS SELECIONADOS:</b></p>
                                <ul>
                                  {this.state.planosTR}
                                </ul>

                                   </div> )}

                                {(this.state.info.tipo_envo_notfo) && (
                                    <p><b>TIPO DE ENVIO: </b> {this.state.info.tipo_envo_notfo} </p>
                                )}

                                 {(this.state.info.cnpj_notfo) && (
                                    <p><b>CNPJ: </b> {this.state.info.cnpj_notfo} </p>
                                )}

                                 {(this.state.info.log) &&
                                        (<div>

                                            <p><b>Responsável:</b> {JSON.parse(this.state.info.log['usuario'])['nome']}</p>
                                            <p><b>Data/Hora:</b>     {moment(this.state.info.log['hora']).format('DD/MM/YYYY, HH:mm:ss')}</p>

                                        </div>)}




                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>;
    }

}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}

export default withRouter(connect(mapStateProps, mapDispatchProps)(IndexNotificacoes));
