import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';


class ConsultaReceita extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            paramters: null,
            renderModal: <div></div>,
            selecao: {},
            qtd_total: 0,
            nome_arquivo_original: null,
            auth: this.core.getAuth(),
            upload: 'nao',
            processado: "invisible",
            limite_registros: null             
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();

        let limite = null;

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/empresa-consulta-receita-limite", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'get',
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            limite = response.content;
    
            self.setState({
                limite_registros: limite
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });   
    }


    handlerSubmit(event){
        event.preventDefault();
        let self = this;
        let form = new FormData();

        let data = {
        }
        if (event.target.arquivo.files[0] === undefined || event.target.arquivo.files[0] === null) {
            data.nome_arquivo =  '';
        } else {
            data.nome_arquivo = event.target.arquivo.files[0];
        }
        data.upload = this.state.upload;

        form.append("nome_arquivo", data.nome_arquivo);
        form.append("upload", data.upload);
 
        if (this.state.upload === 'sim') {
            this.props.action.loadingStatus();
            fetch(this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/empresa-consulta-receita", {
                headers: {
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: form
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                self.setState({
                    relatorio: response.content, 
                    qtd_total: response.content[0].qtd_registros,
                    nome_arquivo_original: response.nome_arquivo_original,
                    processado: response.content[0].processado 
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });
        } else {
            let paramters = "?";
            paramters += 'nome_arquivo=' + this.state.nome_arquivo_original;
            paramters += '&upload=' + data.upload;
            paramters += '&token=' + this.core.getToken();    
            let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/empresa-consulta-receita" + paramters;
            window.location.href = urlExcel                   
        }
    }

    uploadPlanilha(){
        console.log("uploadPlanilha");
        this.setState({
            upload: 'sim'
        });
    }

    downloadConsulta(){
        console.log("downloadConsulta");
        this.setState({
            upload: 'nao'
        });
    }

    getTableShow(){
        {/*}
        this.setState({
            processado: 'invisible'
        });*/}
        return <tr></tr>
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={1}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa.</b>
                    </center>
                </td>
            </tr>
        }
        return <div className="page">
            <div className="page-content">
                <h2>Empresa - Consulta Base de Dados Receita Federal</h2>
                <p>Consulte os dados das empresas na base de dados da Receita Federal</p>

                <div className="panel">
                    <div className='panel-title'>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div>
                                    <h3>
                                        Informe a Planilha (formato Excel). É obrigatório existir a coluna CNPJ, demais colunas serão desprezadas. Máximo de {this.state.limite_registros} registros.
                                    </h3>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <input className="form-control" type="file" name="arquivo" placeholder="Nome" onChange={(event)=> { 
                                                this.setState({
                                                    processado: 'invisible'
                                                });
                                                return false
                                            }}/>
                                        </div>

                                        <div className="col-sm-2">
                                            <button type="submit" className="btn btn-primary"  onClick={event =>{  this.uploadPlanilha();}}><i className="fa fa-upload"></i>Upload</button>
                                        </div>
                                        <div>
                                        </div>
                                        <div className="col-sm-2">
                                            <button type="submit" className={`btn btn-info ${this.state.processado}`}  onClick={event =>{  this.downloadConsulta();}}><i className="fa fa-download"></i>Obter</button>
                                        </div>

                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">

                        <table className="table table-striped table-doc">
                                <tr>
                                    <thead>
                                        <td>Totais:</td>
                                        <td>Registros Carregados:</td>
                                    </thead>
                                    <tbody>
                                        <td></td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                    </tbody>
                                </tr>
                        </table>

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(ConsultaReceita))
