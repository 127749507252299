import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class Analise2Plano extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            qtd_planos: 0,
            qtd_empresas_distinct_tr: 0,
            qtd_empresas_distinct_bsf: 0,
            qtd_empresas_tr: 0,
            qtd_empresas_bsf: 0,
            qtd_boletos_tr: 0,
            qtd_boletos_bsf: 0,
            qtd_boletos_impressos: 0,
            qtd_boletos_pagos: 0,
            paramters: null,
            excel: 'nao'            
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
            ano_vecto : event.target.ano_vecto.value,
            mes_vecto : event.target.mes_vecto.value
        }

        this.state.paramters = "?";
        this.state.paramters += 'mes_vecto=' + data.mes_vecto;
        this.state.paramters += '&ano_vecto=' + data.ano_vecto;
        this.state.paramters += '&token=' + this.core.getToken();

        if (this.state.excel != 'sim') {
            this.props.action.loadingStatus();
            console.log("Fetch");
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-planos-analise2", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                console.log("Planos=>response.content:", response.content)
                this.state.qtd_planos = 0
                this.state.qtd_boletos_tr = 0
                this.state.qtd_boletos_bsf = 0
                this.state.qtd_empresas_tr = 0
                this.state.qtd_empresas_bsf = 0
                this.state.qtd_empresas_distinct_tr = 0
                this.state.qtd_empresas_distinct_bsf = 0
                this.state.qtd_boletos_impressos = 0
                this.state.qtd_boletos_pagos = 0

                response.content.map ((val, index) => {
                    if (val.plano == 19191) {
                        this.state.qtd_empresas_distinct_tr += val.qtd_empresas_tr
                        this.state.qtd_empresas_distinct_bsf += val.qtd_empresas_bsf
                    } else {
                        this.state.qtd_planos +=1
                        this.state.qtd_boletos_tr += val.qtd_boletos_tr
                        this.state.qtd_boletos_bsf += val.qtd_boletos_bsf
                        this.state.qtd_empresas_tr += val.qtd_empresas_tr
                        this.state.qtd_empresas_bsf += val.qtd_empresas_bsf
                        this.state.qtd_boletos_impressos += val.qtd_boletos_impressos
                        this.state.qtd_boletos_pagos += val.qtd_boletos_pagos    
                    }
                })
        
            self.setState({
                relatorio: response.content 
            });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });   
        }
        if (this.state.excel === 'sim') {
            console.log("Chamando Excel:");
            let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-planos-analise2"+this.state.paramters;
            window.location.href = urlExcel        
        } 
    }

    showLista(){
        console.log("showLista1");
        this.setState({
            excel: 'nao'
        });
    }

    showExcel(){
        console.log("showExcel1");
        this.setState({
            excel: 'sim'
        });
    }

    getTableShow(){
        console.log("getTableShow=>");
        return this.state.relatorio.map((data, index) => {
            let plano = null;
            let qtd_empresas_bsf = null;
            let qtd_boletos_bsf = null;
            if (data.plano == 19191) {
            } else {
                if ((data.qtd_empresas_tr !== data.qtd_empresas_bsf) || data.qtd_boletos_tr !== data.qtd_boletos_bsf) {
                    plano = <td><strong>{String(data.plano).padStart(3,"0")}</strong></td>;
                } else {
                    plano = <td>{String(data.plano).padStart(3,"0")}</td>;
                }
                if (data.qtd_empresas_tr !== data.qtd_empresas_bsf) {
                    qtd_empresas_bsf = <td><strong>{String(data.qtd_empresas_bsf).padStart(5,"0")}</strong></td>
                } else {
                    qtd_empresas_bsf = <td>{String(data.qtd_empresas_bsf).padStart(5,"0")}</td>
                }
                if (data.qtd_boletos_tr !== data.qtd_boletos_bsf) {
                    qtd_boletos_bsf = <td><strong>{String(data.qtd_boletos_bsf).padStart(5,"0")}</strong></td>
                } else {
                    qtd_boletos_bsf = <td>{String(data.qtd_boletos_bsf).padStart(5,"0")}</td>
                }

                return <tr key={index} >
                {plano}
                <td>{String(data.qtd_empresas_tr).padStart(5,"0")}</td>
                {qtd_empresas_bsf}
                <td>{String(data.qtd_boletos_tr).padStart(5,"0")}</td>
                {qtd_boletos_bsf}
                <td>{String(data.qtd_boletos_impressos).padStart(5,"0")}</td>
                <td>{String(data.qtd_boletos_pagos).padStart(5,"0")}</td>
                </tr>    
            }
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={7}>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Planos - Análise de Planos</h2>
                <p>Consulte a Análise dos Planos, Total de Empresas, Boletos Gerados, Boletos Impressos e Boletos Pagos</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe o Mês/Ano de Vencimento para Pesquisa
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="mes_vecto" id="mes_vecto" placeholder="Mes Vencimento" className="form-control" mask="99" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="ano_vecto" id="ano_vecto" placeholder="Ano Vencimento" className="form-control" mask="9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panel-body container-fluid">
                            <table className="table table-striped table-doc">
                                <tr>
                                    <thead>
                                        <td>Totais</td>
                                        <td>Planos</td>
                                        <td>Empresas Tela Rosa (nos Planos)</td>
                                        <td>Empresas Tela Rosa </td>
                                        <td>Empresas BSF (nos Planos)</td>
                                        <td>Empresas BSF </td>
                                        <td>Boletos Tela Rosa</td>
                                        <td>Boletos BSF</td>
                                        <td>Boletos Impressos</td>
                                        <td>Boletos Pagos</td>
                                    </thead>
                                    <tbody>
                                        <td></td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_planos.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_empresas_tr.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_empresas_distinct_tr.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_empresas_bsf.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_empresas_distinct_bsf.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_boletos_tr.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_boletos_bsf.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_boletos_impressos.toLocaleString('de-DE')}</td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_boletos_pagos.toLocaleString('de-DE')}</td>
                                    </tbody>
                                </tr>
                            </table>

                            <table className="table table-striped table-doc">
                                <thead>
                                    <tr>
                                        <h2> Análise Plano </h2>
                                    </tr>
                                    <tr>
                                        <td>Plano</td>
                                        <td>Empresas Tela Rosa</td>
                                        <td>Empresas BSFOnline</td>
                                        <td>Boletos Tela Rosa</td>
                                        <td>Boletos BSFOnline</td>
                                        <td>Boletos Impressos</td>
                                        <td>Boletos Pagos</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableContent}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(Analise2Plano))
