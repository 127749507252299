import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class Empresas
 extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            qtd_total: 0,
            paramters: null,
            critica: 'nao',            
            excel: 'nao'            
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        data.pesquisa_parcial = 'sim';
        data.empresa_pesquisa = event.target.empresa_pesquisa.value;
        data.plano = event.target.plano.value;
        data.sigla_pesquisa = event.target.sigla_pesquisa.value;
        data.uf_pesquisa = event.target.uf_pesquisa.value;
        data.date_ini1 = event.target.date_ini1.value;
        data.date_fim1 = event.target.date_fim1.value;
        data.date_ini2 = event.target.date_ini2.value;
        data.date_fim2 = event.target.date_fim2.value;
        data.date_ini3 = event.target.date_ini3.value;
        data.date_fim3 = event.target.date_fim3.value;
       
        this.state.paramters = "?";
        this.state.paramters += 'pesquisa_parcial=' + data.pesquisa_parcial;
        this.state.paramters += '&empresa_pesquisa=' + data.empresa_pesquisa;
        this.state.paramters += '&plano=' + data.plano;
        this.state.paramters += '&sigla_pesquisa=' + data.sigla_pesquisa;
        this.state.paramters += '&uf_pesquisa=' + data.uf_pesquisa;
        this.state.paramters += '&date_ini1=' + data.date_ini1;
        this.state.paramters += '&date_fim1=' + data.date_fim1;
        this.state.paramters += '&date_ini2=' + data.date_ini2;
        this.state.paramters += '&date_fim2=' + data.date_fim2;
        this.state.paramters += '&date_ini3=' + data.date_ini3;
        this.state.paramters += '&date_fim3=' + data.date_fim3;
        this.state.paramters += '&uf_pesquisa=' + data.uf_pesquisa;
        this.state.paramters += '&excel=' + this.state.excel;
        this.state.paramters += '&token=' + this.core.getToken();

        console.log("Fetch");
        if (this.state.excel != 'sim') {
            this.props.action.loadingStatus();
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/serasa/lista-empresas" + this.state.paramters, {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'GET'
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                console.log("Planos=>response.content:", response.content)
                response.content.map ((val, index) => {
                })
        
            self.setState({
                relatorio: response.content,
                qtd_total: response.content.length
            });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });   
        } 
        if (this.state.excel === 'sim') {
            console.log("Chamando Excel:");
            let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/serasa/lista-empresas"+this.state.paramters;
            window.location.href = urlExcel        
        } 
    }

    showLista(){
        console.log("showLista1");
        this.setState({
            excel: 'nao'
        });
    }

    showExcel(){
        console.log("showExcel1");
        this.setState({
            excel: 'sim'
        });
    }


    getTableShow(){
        console.log("getTableShow=>");
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.empr_ds_razao_social}</td>
                <td>{data.empr_cd_cnpj}</td>
                <td>{data.enti_ds_sigla}</td>
                <td>{data.enti_ds_uf}</td>
                <td>{data.conv_cd_nseq}</td>
                <td>{data.emcv_dt_notificacao1}</td>
                <td>{data.emcv_dt_notificacao2}</td>
                <td>{data.emcv_dt_notificacao3}</td>
                <td>{data.emcv_fl_negativacao}</td>
                <td>{data.emcv_cd_protocolo_webben}</td>
                <td>{data.emcv_dt_retirada_serasa}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={11}>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>SERASA - Entidades</h2>
                <p>Consulte os empregadores inseridos no processo de Cobrança SERASA pelas Entidades do Beneficio Social Familiar</p>

                <div className="panel">
                    <div className='panel-title'>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label>Plano</label>
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <label>Sigla Entidade</label>
                                        <div className="form-group">
                                            <input name="sigla_pesquisa" id="sigla_pesquisa" placeholder="Todas as Siglas" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-sm-1">
                                        <label>UF</label>
                                        <div className="form-group">
                                            <input name="uf_pesquisa" id="uf_pesquisa" placeholder="Todas" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <label>Empresa</label>
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Todas as Empresas" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label>(1a Notificacao)</label>
                                            <InputMask name="date_ini1" id="data_ini1" placeholder="Data início" className="form-control" mask="99/99/9999"/>
                                            <InputMask name="date_fim1" id="data_fim1" placeholder="Data fim" className="form-control" mask="99/99/9999"/>
                                        </div>

                                        <div className="form-group">
                                            <label>(2a Notificacao)</label>
                                            <InputMask name="date_ini2" id="data_ini2" placeholder="Data início" className="form-control" mask="99/99/9999"/>
                                            <InputMask name="date_fim2" id="data_fim2" placeholder="Data fim" className="form-control" mask="99/99/9999"/>
                                        </div>

                                        <div className="form-group">
                                            <label>(Envio Serasa)</label>
                                            <InputMask name="date_ini3" id="data_ini3" placeholder="Data início" className="form-control" mask="99/99/9999"/>
                                            <InputMask name="date_fim3" id="data_fim3" placeholder="Data fim" className="form-control" mask="99/99/9999"/>
                                        </div>
                                    </div>

                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panel-body container-fluid">

                            <table className="table table-striped table-doc">
                                <tr>
                                    <thead>
                                        <td>Totais:</td>
                                        <td>Empresas</td>
                                    </thead>
                                    <tbody>
                                        <td></td>
                                        <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                    </tbody>
                                </tr>
                            </table>

                            <table className="table table-striped table-doc">
                                <thead>
                                    <tr>
                                        <td>Empregador</td>
                                        <td>CNPJ/CPF</td>
                                        <td>Sigla</td>
                                        <td>UF</td>
                                        <td>Plano</td>
                                        <td>1a Notificacao</td>
                                        <td>2a Notificacao</td>
                                        <td>Envio SERASA</td>
                                        <td>Negativação</td>
                                        <td>Protocolo</td>
                                        <td>Retirada Serasa</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableContent}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(Empresas
))
