import Core from '../class/Core';

let core = new Core();

export const getUser = (username, password) => {
    //chamada fetch
    return (dispatch) => {
        dispatch({
            type: 'STATUS_LOADING'
        });
        fetch(core.getUrl() + core.getVersionAPI() + 'usuario/autenticacao', {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                email_user: username,
                senha_user: password,
            })
        })
        .then(core.responseConvert)
        .then((data) =>{
            let user = data.content;
            dispatch({
                type: 'USER_LOGIN', 
                data: user
            });
            dispatch({
                type: 'STATUS_LOADED', 
            });
        })
        .catch((err) => {
            core.catchFecth(err);
            dispatch({
                type: 'STATUS_LOADED', 
            });
        });
    };
};

export const getCorretora = ( password ) => {
    //chamada fetch
    return (dispatch) => {
        dispatch({
            type: 'STATUS_LOADING'
        });
        fetch(core.getUrl() + core.getVersionAPI() + 'corretora/acesso', {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                password: password,
            })
        })
        .then(core.responseConvert)
        .then((data) =>{
            let user = data.content;
            dispatch({
                type: 'USER_LOGIN', 
                data: user
            });
            dispatch({
                type: 'STATUS_LOADED', 
            });
        })
        .catch((err) => {
            core.catchFecth(err);
            dispatch({
                type: 'STATUS_LOADED', 
            });
        });
    };
};



export const isAuth = () => {
    return {
        type: 'USER_IS_AUTH'
    }
};

export const logoutUser = () => {
    return {
        type: 'USER_LOGOUT'
    }
};
