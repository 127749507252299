export const inputHandleChange = (value, selfClass, sub = null) => {
    console.log(value)
    let objInsert = {}
    if(sub){
        objInsert[sub] = Object.assign(selfClass.state[sub], { ...value})
    }else{
        objInsert = Object.assign(objInsert, { ...value })
    }

    selfClass.setState(objInsert)
}
