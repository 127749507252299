import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as StatusActions from '../../actions/StatusActions';

class SideMenu extends React.Component{
    getContent(){
        return <div className="site-gridmenu">
            <div>
                <div>
                    <ul>
                        <li>
                            <a href="../apps/mailbox/mailbox.html">
                            <i className="icon md-email"></i>
                            <span>Mailbox</span>
                            </a>
                        </li>
                        <li>
                            <a href="../apps/calendar/calendar.html">
                            <i className="icon md-calendar"></i>
                            <span>Calendar</span>
                            </a>
                        </li>
                        <li>
                            <a href="../apps/contacts/contacts.html">
                            <i className="icon md-account"></i>
                            <span>Contacts</span>
                            </a>
                        </li>
                        <li>
                            <a href="../apps/media/overview.html">
                            <i className="icon md-videocam"></i>
                            <span>Media</span>
                            </a>
                        </li>
                        <li>
                            <a href="../apps/documents/categories.html">
                            <i className="icon md-receipt"></i>
                            <span>Documents</span>
                            </a>
                        </li>
                        <li>
                            <a href="../apps/projects/projects.html">
                            <i className="icon md-image"></i>
                            <span>Project</span>
                            </a>
                        </li>
                        <li>
                            <a href="../apps/forum/forum.html">
                            <i className="icon md-comments"></i>
                            <span>Forum</span>
                            </a>
                        </li>
                        <li>
                            <a href="../index.html">
                            <i className="icon md-view-dashboard"></i>
                            <span>Dashboard</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>;
    }

    render(){
        if(this.props.managerStatus){
            return this.getContent();
        }else{
            return <div></div>;
        }
    }
}

function mapStateProps(state, props ){
    return {
        statusApp: state.status,
        managerStatus: state.managerStatus
    }
}

function mapDispatchProps(dispatch){
    return {
        action: bindActionCreators(StatusActions, dispatch )
    }
}
export default connect(mapStateProps, mapDispatchProps)(SideMenu);