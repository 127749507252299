import React from 'react';
import Alert from "react-s-alert";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';
import { isNullOrUndefined } from 'util';

class TrabalhadorEmpresaApoiar extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            trabalhador_res: [],
            trabalhador_dados: [],
            qtd_total: 0,
            qtd_ativos: 0,
            qtd_demitidos: 0,
            qtd_afastados: 0,
            qtd_opositores: 0,
            qtd_clt: 0,
            qtd_temporario: 0,
            qtd_pj: 0,
            auth: this.core.getAuth(),
            paramters: null,
            excel: 'nao'            
        }
        this.getTrabalhador = this.getTrabalhador.bind(this);
        this.getTableShow = this.getTableShow.bind(this);
        this.showTrabalhador = this.showTrabalhador.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        data.plano = event.target.plano.value;
        data.empresa_pesquisa = event.target.empresa_pesquisa.value;
        data.trabalhador_pesquisa = event.target.trabalhador_pesquisa.value;
        switch(event.target.tipo_tbldr.value){
            case "1":
                data.tipo_trabalhador_pesquisa = "1";
                break;
            case "2":
                data.tipo_trabalhador_pesquisa = "2";
                break;
            case "3":
                data.tipo_trabalhador_pesquisa = "3";
                break;
            default:
                data.tipo_trabalhador_pesquisa = "9";
                break;
        }

        switch(event.target.opositor.value){
            case "1":
                data.opositor_pesquisa = "1";
                break;
            case "2":
                data.opositor_pesquisa = "2";
                break;
            default:
                data.opositor_pesquisa = "9";
                break;
        }

        switch(event.target.regime.value){
            case "1":
                data.tipo_regime_trabalho_pesquisa = "C";
                break;
            case "2":
                data.tipo_regime_trabalho_pesquisa = "T";
                break;
            case "3":
                data.tipo_regime_trabalho_pesquisa = "P";
                break;
            default:
                data.tipo_regime_trabalho_pesquisa = "9";
                break;
        }

        switch(event.target.celular.value){
            case "1":
                data.com_celular = "sim";
                break;
            case "2":
                data.com_celular = "nao";
                break;
            default:
                data.com_celular = "9";
                break;
        }

        switch(event.target.email.value){
            case "1":
                data.com_email = "sim";
                break;
            case "2":
                data.com_email = "nao";
                break;
            default:
                data.com_email = "9";
                break;
        }



        data.pesquisa_parcial =  'nao';
        data.apoiar = 'sim';
        this.state.paramters     = "?";
        this.state.paramters    += 'pesquisa_parcial=' + data.pesquisa_parcial;
        this.state.paramters    += '&plano=' + data.plano;
        this.state.paramters    += '&empresa_pesquisa=' + data.empresa_pesquisa;
        this.state.paramters    += '&trabalhador_pesquisa=' + data.trabalhador_pesquisa;
        this.state.paramters    += '&tipo_trabalhador_pesquisa=' + data.tipo_trabalhador_pesquisa;
        this.state.paramters    += '&opositor_pesquisa=' + data.opositor_pesquisa;
        this.state.paramters    += '&tipo_regime_trabalho_pesquisa=' + data.tipo_regime_trabalho_pesquisa;
        this.state.paramters    += '&email_user=' + this.state.auth.email;
        this.state.paramters    += '&grupo_user=' + this.state.auth.config.grupo.nome;
        this.state.paramters    += '&token=' + this.core.getToken();
        this.state.paramters    += '&com_celular=' + data.com_celular;
        this.state.paramters    += '&com_email=' + data.com_email;
        this.state.paramters    += '&apoiar=' +data.apoiar;

        this.critica = 'ok';

        if (this.state.excel != 'sim' && (this.critica === 'ok')) {

            this.props.action.loadingStatus();

            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-trabalhador-empresa", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                this.state.qtd_total = 0
                this.state.qtd_ativos = 0
                this.state.qtd_demitidos = 0
                this.state.qtd_afastados = 0
                this.state.qtd_opositores = 0
                this.state.qtd_clt = 0
                this.state.qtd_temporario = 0
                this.state.qtd_pj = 0

                /*
                if (response.content[0].consulta_totais == "sim") {
                    response.content.map ((val, index) => {
                        this.setState({
                        qtd_total      : val.qtd_ativos + val.qtd_demitidos + val.qtd_afastados
                        ,qtd_ativos     : val.qtd_ativos
                        ,qtd_demitidos  : val.qtd_demitidos
                        ,qtd_afastados  : val.qtd_afastados
                        ,qtd_opositores : val.qtd_opositores
                        ,qtd_clt        : val.qtd_clt
                        ,qtd_temporario : val.qtd_temporario
                        ,qtd_pj         : val.qtd_pj
                        })

                    })
                } else {
                    response.content[0].lista_trabalhadores.map ((val, index) => {
                        this.state.qtd_total+=1; 
                        switch(val.fk_tipo_tbldr){
                            case 1:
                                this.state.qtd_ativos+=1;
                                break;
                            case 2:
                                this.state.qtd_afastados+=1;
                                break;
                            case 3:
                                this.state.qtd_demitidos+=1;
                                break;
                            default:
                                break;
                        }
                        if (val.opst_tbldr) {
                            this.state.qtd_opositores+=1;                    
                        }
                        switch(val.rgme_tbldr){
                            case 'C':
                                this.state.qtd_clt+=1;
                                break;
                            case 'T':
                                this.state.qtd_temporario+=1;
                                break;
                            case 'P':
                                this.state.qtd_pj+=1;
                                break;
                            default:
                                break;
                        }
                    })
                }
                */
                self.setState({
                    relatorio       : response.content 
                    ,qtd_total      : (parseFloat(response.qtd_ativos) + parseFloat(response.qtd_demitidos) + parseFloat(response.qtd_afastados))
                    ,qtd_ativos     : parseFloat(response.qtd_ativos)
                    ,qtd_demitidos  : parseFloat(response.qtd_demitidos)
                    ,qtd_afastados  : parseFloat(response.qtd_afastados)
                    ,qtd_opositores : parseFloat(response.qtd_opositores)
                    ,qtd_clt        : parseFloat(response.qtd_clt)
                    ,qtd_temporario : parseFloat(response.qtd_temporario)
                    ,qtd_pj         : parseFloat(response.qtd_pj)

                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });
        } 
        if (this.state.excel === 'sim' && (this.critica === 'ok')) {
            if (this.consisteParametros(data.empresa_pesquisa)) {
                let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-trabalhador-empresa"+this.state.paramters;
                window.location.href = urlExcel        
            }
        }
    }

    showLista(){
        this.state.excel = 'nao'
    }

    showExcel(){
        this.state.excel = 'sim'
    }

    consisteParametros(prm_empresa_pesquisa){
        //if(prm_empresa_pesquisa ===  ""){
        if(this.state.qtd_total > 10000) {
                Alert.error("Empresa deve ser Informada", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 8000
            });
            return false;
        }
        return true;
    }


    DesativarTrabalhador(prm_id_tbldr){
        let self=this;
        let data = {
            id_tbldr : prm_id_tbldr
        }

        let urlDesativarTrabalhador = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/empresa-trabalhador-desativar";
        fetch(urlDesativarTrabalhador, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })

        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.refs.formulario.click()
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    DemitirTrabalhador(prm_id_tbldr, prm_dt_demi_tbldr){
        let self=this;
        let data = {
            id_tbldr : prm_id_tbldr,
            dt_demi_tbldr : prm_dt_demi_tbldr
        }

        if (isNullOrUndefined(prm_dt_demi_tbldr) || (prm_dt_demi_tbldr === "") ) {
            Alert.error("Data de Demissão deve ser informada", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 3000
            });    
            return;
        }

        let urlDemitirTrabalhador = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/empresa-trabalhador-demitir";
        fetch(urlDemitirTrabalhador, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })

        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.refs.formulario.click()
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    ReadmitirTrabalhador(prm_id_tbldr){
        let self=this;
        let data = {
            id_tbldr : prm_id_tbldr
        }
        let urlReadmitirTrabalhador = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/empresa-trabalhador-readmitir";
        fetch(urlReadmitirTrabalhador, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })

        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.refs.formulario.click()
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    AtualizarTrabalhador(prm_id_tbldr){
        let self=this;
        let urlAtualizarTrabalhador = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/empresa-trabalhador-atualizar";
        fetch(urlAtualizarTrabalhador, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(this.state.trabalhador_dados)
        })

        .then(res => self.core.responseConvert(res))
        .then((response) => {
            self.refs.formulario.click()
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    showTrabalhador(prm_id_tbldr){
        this.setState({
            trabalhador_res: []
        })
        let self = this;
        let data = {
            id_tbldr : prm_id_tbldr
        }

        let urlDadosTrabalhador = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/empresa-trabalhador-consulta";        
        this.props.action.loadingStatus();

        fetch(urlDadosTrabalhador, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })

        .then(res => self.core.responseConvert(res))
        .then((response) => {
            
            self.setState({
               // trabalhador_res: trabalhador_html,
                trabalhador_dados: response.content
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        })
    }

    getTrabalhador(){
        return <div class="row">
                    <div className="form-group">
                        <label>Nome</label>
                        <input className="form-control" type="text" name="nome_tbldr" required
                            value={this.state.trabalhador_dados.nome_tbldr || ""} label="Nome"
                            onChange={(e) => this.setState({
                                trabalhador_dados: Object.assign(this.state.trabalhador_dados, {nome_tbldr: e.target.value})
                            })}
                        />
                    </div>

                    <div className="form-group">
                        <label>CPF</label>
                        <InputMask mask="999.999.999-99" className="form-control" name="cpf_tbldr" required
                            value={this.state.trabalhador_dados.cpf_tbldr || ""}
                            onChange={(e) => this.setState({
                                trabalhador_dados: Object.assign(this.state.trabalhador_dados, {cpf_tbldr: e.target.value})
                            })}

                        />
                    </div>
                    <div className="form-group">
                        <label>Data Nascimento</label>
                        <InputMask mask="99/99/9999" className="form-control" name="dt_nasc_tbldr" required 
                            value={this.state.trabalhador_dados.dt_nasc_tbldr || ""}
                            onChange={(e) => this.setState({
                                trabalhador_dados: Object.assign(this.state.trabalhador_dados, {dt_nasc_tbldr: e.target.value})
                            })}

                        /> 
                    </div>
                    <div className="form-group">
                        <label>Data Admissao</label>
                        <InputMask mask="99/99/9999" className="form-control" name="dt_admi_tbldr" required
                            value={this.state.trabalhador_dados.dt_admi_tbldr || ""} 
                            onChange={(e) => this.setState({
                                trabalhador_dados: Object.assign(this.state.trabalhador_dados, {dt_admi_tbldr: e.target.value})
                            })}

                        /> 
                    </div>
                    <div className="form-group">
                        <label>Data Demissao</label>
                        <InputMask mask="99/99/9999" className="form-control" name="dt_demi_tbldr" required
                            value={this.state.trabalhador_dados.dt_demi_tbldr || ""}
                            onChange={(e) => this.setState({
                                trabalhador_dados: Object.assign(this.state.trabalhador_dados, {dt_demi_tbldr: e.target.value})
                            })}
                        />
                    </div>
                    <div className="form-group">
                        <label>Função</label>
                        <input className="form-control" type="text" name="funcao" required pattern=".{3,}" 
                            value={this.state.trabalhador_dados.fncao_tbldr || ""} 
                            onChange={(e) => this.setState({
                                trabalhador_dados: Object.assign(this.state.trabalhador_dados, {fncao_tbldr: e.target.value})
                            })}

                        />
                    </div>
                    <div className="form-group">
                        <table className="table table-striped table-doc">
                            <tbody>
                                <td>
                                    <button className="btn btn-info"  onClick={event => this.DesativarTrabalhador(this.state.trabalhador_dados.id_tbldr)} data-toggle="modal" data-target="#DesativartbldrModal">Excluir</button>
                                </td>
                                <td>
                                    <button className="btn btn-info"  onClick={event => this.DemitirTrabalhador(this.state.trabalhador_dados.id_tbldr, this.state.trabalhador_dados.dt_demi_tbldr)} data-toggle="modal" data-target="#DemitirtbldrModal">Demitir</button>
                                </td>
                                <td>
                                    <button className="btn btn-info"  onClick={event => this.ReadmitirTrabalhador(this.state.trabalhador_dados.id_tbldr)} data-toggle="modal" data-target="#ReadmitirtbldrModal">Readmitir</button>
                                </td>
                                <td>
                                    <button className="btn btn-info"  onClick={event => this.AtualizarTrabalhador(this.state.trabalhador_dados.id_tbldr)} data-toggle="modal" data-target="#AtualizartbldrModal">Atualizar</button>
                                </td>
                            </tbody>
                        </table>
                    </div> 
            </div>;
     }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            let opositor = '';
            if (data.opst_tbldr) {
                opositor = 'sim'
            }
            let regime_trabalho = null;
            switch(data.rgme_tbldr){
                case 'C':
                    regime_trabalho = 'CLT';
                    break;
                case 'T':
                    regime_trabalho = 'Temp';
                    break;
                case 'P':
                    regime_trabalho = 'PJ';
                    break;
                case 'D':
                    regime_trabalho = 'DIR';
                break;
                case 'F':
                    regime_trabalho = 'FREELANCER';
                break;
                default:
                    break;
            } 
            let botao1 = null;  
            if(this.state.auth.email === "d.sis@beneficiosocial.com.br") {
                botao1 =    
                <td>
                    <button className="btn btn-info" onClick={event => this.showTrabalhador(data.id_tbldr)} data-toggle="modal" data-target="#trabModal">Alterar</button>
                </td>    

            } else {
                botao1 = <td></td>
            }
 
            return <tr key={index} >
                <td>{String(data.id_tbldr).padStart(7,"0")}</td>
                <td>{data.nome_tbldr}</td>
                <td>{data.cpf_tbldr}</td>
                <td>{data.dt_nasc_tbldr}</td>
                <td>{data.razao_empr}</td>
                <td>{data.cnpj_empr}</td>
                <td>{String(data.id_tr_plno).padStart(3,"0")}</td>
                <td>{String(data.id_empr_plno).padStart(6,"0")}</td>
                <td>{data.dscr_tipo_tbldr}</td>
                <td>{data.dt_admi_tbldr}</td>
                <td>{data.dt_demi_tbldr}</td>
                <td>{opositor}</td>
                <td>{regime_trabalho}</td>
                <td>{data.created_at}</td>
                <td>{data.updated_at}</td>
                <td>{data.cide_tbldr}</td>
                <td>{data.cel_tbldr}</td>
                {botao1}
            </tr>
        });
    }

    render(){
        let tableContent;
        let tableTrabalhador;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        tableTrabalhador = this.getTrabalhador()

        if(this.state.qtd_total > 0){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={18}>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Trabalhador - Empregador (APOIAR)</h2>
                <p>Consulte os Trabalhadores Cadastrados nos Empregadores no Sistema BSFOnline</p>

                <div className="modal fade" id="trabModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Alterar Dados do Trabalhador</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {tableTrabalhador}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                        Informe os parametros necessários para a pesquisa
                        </h3>
                        <div  className="seleciona-trabalhador" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="trabalhador_pesquisa" id="trabalhador_pesquisa" placeholder="Todos os Trabalhadores" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Todos os Empregadores" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label> <b>Tipo Trabalhador</b> </label> <br/>
                                                <input type="radio" name="tipo_tbldr" value="1" /> Ativos <br/>
                                                <input type="radio" name="tipo_tbldr" value="2" /> Afastados <br/>
                                                <input type="radio" name="tipo_tbldr" value="3" /> Demitidos <br/>
                                                <input type="radio" name="tipo_tbldr" value="9" /> Todos <br/>
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label> <b>Opositor</b> </label> <br/>
                                                <input type="radio" name="opositor" value="1" /> Sim <br/>
                                                <input type="radio" name="opositor" value="2" /> Não <br/>
                                                <input type="radio" name="opositor" value="9" /> Ambos <br/>
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label> <b>Contratação</b> </label> <br/>
                                                <input type="radio" name="regime" value="1" /> CLT <br/>
                                                <input type="radio" name="regime" value="2" /> Temp <br/>
                                                <input type="radio" name="regime" value="3" /> PJ <br/>
                                                <input type="radio" name="regime" value="4" /> DIR <br/>
                                                <input type="radio" name="regime" value="5" /> FREELANCER <br/>
                                                <input type="radio" name="regime" value="9" /> Todos <br/>
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label> <b>Celular</b> </label> <br/>
                                                <input type="radio" name="celular" value="1" /> Com <br/>
                                                <input type="radio" name="celular" value="2" /> Sem <br/>
                                                <input type="radio" name="celular" value="9" /> Todos <br/>
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label> <b>Email</b> </label> <br/>
                                                <input type="radio" name="email"  value="1" /> Com <br/>
                                                <input type="radio" name="email"  value="2" /> Sem <br/>
                                                <input type="radio" name="email"  value="9" /> Todos <br/>
                                        </div>
                                    </div>

                                    <div className="col-sm-1">
                                        <button type="Submit" ref="formulario" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                        
                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                    <td>Totais</td>
                                    <td>Trabalhadores</td>
                                    <td>Ativos</td>
                                    <td>Afastados</td>
                                    <td>Demitidos</td>
                                    <td>Opositores</td>
                                    <td>CLT</td>
                                    <td>Temporário</td>
                                    <td>PJ</td>
                                </thead>
                                <tbody>
                                    <td></td>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_ativos.toLocaleString('de-DE')}</td>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_afastados.toLocaleString('de-DE')}</td>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_demitidos.toLocaleString('de-DE')}</td>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_opositores.toLocaleString('de-DE')}</td>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_clt.toLocaleString('de-DE')}</td>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_temporario.toLocaleString('de-DE')}</td>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_pj.toLocaleString('de-DE')}</td>
                                </tbody>
                            </tr>
                        </table>

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>Id</td>
                                    <td>Nome</td>
                                    <td>CPF</td>
                                    <td>Dt Nasc</td>
                                    <td>Empregador</td>
                                    <td>CNPJ/CPF</td>
                                    <td>Plano</td>
                                    <td>Empr/Plno</td>
                                    <td>Situacao</td>
                                    <td>Dt Admissao</td>
                                    <td>Dt Demissao</td>
                                    <td>Opositor</td>
                                    <td>Regime</td>
                                    <td>Dt Inclusão</td>
                                    <td>Dt Atualização</td>
                                    <td>Cidade</td>
                                    <td>Telefone</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(TrabalhadorEmpresaApoiar))
