import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

import { isNull } from 'util';
import { isNullOrUndefined } from 'util';
import Alert from 'react-s-alert';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class EmpresasMigradasPlanoInadimp extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            qtd_total: 0,
            auth: this.core.getAuth(),
            empresa: null,
            paramters: null,
            excel: 'nao'
            
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
            ano_vecto   : event.target.ano_vecto.value,
            mes_vecto   : event.target.mes_vecto.value,
            plano       : event.target.plano.value
        }

        this.state.paramters = "?";
        this.state.paramters += 'mes_vecto=' + data.mes_vecto;
        this.state.paramters += '&ano_vecto=' + data.ano_vecto;
        this.state.paramters += '&plano=' + data.plano;
    
        if (this.state.excel != 'sim') {
            this.props.action.loadingStatus();
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-migradas-plano-inadimp", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                self.setState({
                    relatorio: response.content, 
                    qtd_total: response.content.length
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });    
        } else {
            console.log("Chamando Excel:");
            if (this.consisteParametros(data.mes_vecto, data.ano_vecto)) {
                console.log("Chamando Excel OK");
                let urlMigradas = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-empresa-migradas-plano-inadimp"+this.state.paramters;
                window.location.href = urlMigradas
            }
        }
    }

    showLista(){
        console.log("showLista1");
        this.state.excel = 'nao'
    }
 
    showExcel(){
        console.log("showExcel1");
        this.state.excel = 'sim'
    }
 
    consisteParametros(prm_mes_vecto, prm_ano_vecto){
        console.log("consisteParametros");
        if (((prm_mes_vecto === '') || isNullOrUndefined(prm_mes_vecto))) {
            Alert.error("Mes do Vencimento deve ser Informado.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 3000
            });

            this.state.excel = 'nao'
            return false;    
        }
        if (((prm_ano_vecto === '') || isNullOrUndefined(prm_ano_vecto))) {
            Alert.error("Ano do Vencimento deve ser Informado.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 3000
            });

            this.state.excel = 'nao'
            return false;    
        }

        return true;
    }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.empr_cd_cnpj}</td>
                <td>{data.empr_ds_razao_social}</td>
                <td>{String(data.conv_cd_nseq).padStart(3,"0")}</td>
                <td>{data.ult_recolhimento}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        let tableTrabalhadores;
        this.state.excel = 'nao'
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={4}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa.</b>
                    </center>
                </td>
            </tr>
        }

        this.state.div_totais = 
        <table className="table table-striped table-doc">
        <tr>
            <thead>
                <td>Totais</td>
                <td>Qtd Empresas</td>
            </thead>
            <tbody>
                <td></td>
                <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
            </tbody>
        </tr>
        </table>

        return <div className="page">
            <div className="page-content">
                <h2>Empresas Migradas - Inadimplentes</h2>
                <p>Consulte as empresas que foram migradas para o Sistema BSFOnline e que estão Inadimplentes</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Selecione o Intervalo de Datas para a pesquisa de pagamentos, demais campos são opcionais
                        </h3>

                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-1">
                                        <div className="form-group">
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="mes_vecto" id="mes_vecto" placeholder="Mes Vencimento" className="form-control" mask="99" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="ano_vecto" id="ano_vecto" placeholder="Ano Vencimento" className="form-control" mask="9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">
                        {this.state.div_totais}

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>CNPJ</td>
                                    <td>Empresa</td>
                                    <td>Plano</td>
                                    <td>Ult. Recolhimento</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(EmpresasMigradasPlanoInadimp))
