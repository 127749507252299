import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class TrabalhadorGanhador
 extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            qtd_total: 0,
            paramters: null,
            critica: 'nao',
            array_sorteios:[]                     
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    async  onChange(e){
        let self = this;
        let inputChecked = document.querySelectorAll('input[type=checkbox]')
        let checkboxes =  Array.from(inputChecked)
        let radioChecked =  await checkboxes.filter((checkbox) => checkbox.checked)
            .map((checkbox) => checkbox.value);
        if(radioChecked.length){
            self.setState({
                id_sorteioChecked : radioChecked
            })
        } else {
            self.setState({
                id_sorteioChecked : []
            })
        }
    }

    componentWillMount(){
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();

        let sorteios = [];

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/sorteio/lista-sorteios", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            console.log("Sorteios=>response.content:", response.content)
            let i = 0;
            response.content.map ((val, index) => {
                sorteios[i++] = {id_sort: val.id_sort, dt_sort: val.dt_sort};
            })
    
        self.setState({
            array_sorteios: sorteios
        });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });   

    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
        }

        data.numero_loteria = event.target.numero_loteria.value;
        data.id_sorteio = this.state.id_sorteioChecked
        console.log("Sorteio=>", data.id_sorteio, "<=");

        console.log("Fetch");
        this.props.action.loadingStatus();
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/sorteio/lista-trabalhador-ganhador", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            console.log("Planos=>response.content:", response.content)
            response.content.map ((val, index) => {
            })
    
        self.setState({
            relatorio: response.content,
            qtd_total: response.content.length
        });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });   
    }

    getTableShow(){
        console.log("getTableShow=>");
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.id_tbldr}</td>
                <td>{data.nome_tbldr}</td>
                <td>{data.cpf_tbldr}</td>
                <td>{data.situacao_tbldr}</td>
                <td>{data.num_sort_num}</td>
                <td>{data.dt_inscricao}</td>
                <td>{data.plano}</td>
                <td>{data.razao_empr}</td>
                <td>{data.cnpj_empr}</td>
                <td>{data.uf}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={10}>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Sorteio APP Premiável - Trabalhador Vencedor</h2>
                <p>Consulte o Trabalhador Vencedor do Sorteio</p>

                <div className="panel">
                    <div className='panel-title'>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="numero_loteria" id="numero_loteria" placeholder="Número Loteria" className="form-control" />
                                        </div>
                                    </div>
                                    {/*}
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label> <b>Sorteio</b> </label> <br/>
                                                <input type="radio" name="sorteio" value="1" /> 09/Março/2019 <br/>
                                                <input type="radio" name="sorteio" value="2" /> 13/Abril/2019 <br/>
                                                <input type="radio" name="sorteio" value="3" /> 11/Maio/2019 <br/>
                                                <input type="radio" name="sorteio" value="4" /> 08/Junho/2019 <br/>
                                        </div>
                                    </div>
                                    */}
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                        <label> <b>Sorteio</b> </label> <br/>
                                            {this.state.array_sorteios.map(item => (
                                                <tr>                                                                                                      
                                                    <input type="checkbox" name={item.id_sort} value={item.id_sort} onChange={this.onChange}/> {item.dt_sort} <br/>
                                                </tr>                    
                                            ))} 
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary">pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panel-body container-fluid">

                            <table className="table table-striped table-doc">
                                <thead>
                                    <tr>
                                        <td>ID</td>
                                        <td>Nome</td>
                                        <td>CPF</td>
                                        <td>Situação</td>
                                        <td>Número</td>
                                        <td>Data Inscrição</td>
                                        <td>Plano</td>
                                        <td>Razao Social</td>
                                        <td>CNPJ</td>
                                        <td>UF</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableContent}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(TrabalhadorGanhador
))
