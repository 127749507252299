import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';
import Alert from 'react-s-alert';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';
import { isNull } from 'util';
import { isNullOrUndefined } from 'util';

class NetPointsPagamentos extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.4 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            trabs: [],
            qtd_total: 0,
            vlr_total: 0,
            pontos_total: 0,
            paramters: null,
            auth: this.core.getAuth(),
            excel: 'nao'            

        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;
        let data = {
            plano: event.target.plano.value,
        }

        data.date_ini = event.target.date_ini.value;
        data.date_fim = event.target.date_fim.value;
        data.empresa_pesquisa = event.target.empresa_pesquisa.value;

        switch(event.target.cadastro_cpf.value){
            case "1":
                data.empresas_cadastradas = "sim";
                break;
            case "2":
                data.empresas_cadastradas = "nao";
                break;
            default:
                data.empresas_cadastradas = "todas";
                break;
        }

        switch(event.target.remessa_pendente.value){
            case "1":
                data.remessa_pendente = "sim";
                break;
            case "2":
                data.remessa_pendente = "nao";
                break;
            default:
                data.remessa_pendente = "todas";
                break;
        }

        switch(event.target.operador_cadastrado.value){
            case "1":
                data.operador_cadastrado = "sim";
                break;
            case "2":
                data.operador_cadastrado = "nao";
                break;
            default:
                data.operador_cadastrado = "todos";
                break;
        }

        this.state.paramters = "?";
        let plano =  event.target.plano.value;
        let date_ini= event.target.date_ini.value;
        let date_fim = event.target.date_fim.value;
        let empresa_pesquisa = event.target.empresa_pesquisa.value;
        let empresas_cadastradas = null;
        let remessa_pendente = null;
        let operador_cadastrado = null;

        switch(event.target.cadastro_cpf.value){
        case "1":
            empresas_cadastradas = "sim";
            break;
        case "2":
            empresas_cadastradas = "nao";
            break;
        default:
            empresas_cadastradas = "todas";
            break;
        }
        
        switch(event.target.remessa_pendente.value){
            case "1":
                remessa_pendente = "sim";
                break;
            case "2":
                remessa_pendente = "nao";
                break;
            default:
                remessa_pendente = "todas";
                break;
        }

        switch(event.target.operador_cadastrado.value){
            case "1":
                operador_cadastrado = "sim";
                break;
            case "2":
                operador_cadastrado = "nao";
                break;
            default:
                operador_cadastrado = "todos";
                break;
        }
    
        this.state.paramters += 'date_ini=' + date_ini;
        this.state.paramters += '&date_fim=' + date_fim;
        if (plano !== null) {
            this.state.paramters += '&plano=' + plano;
        }
        if (empresa_pesquisa !== null) {
            this.state.paramters += '&empresa_pesquisa=' + empresa_pesquisa;
        }       
        if (empresas_cadastradas !== null) {
            this.state.paramters += '&empresas_cadastradas=' + empresas_cadastradas;
        }
        if (remessa_pendente !== null) {
            this.state.paramters += '&remessa_pendente=' + remessa_pendente;
        }
        if (operador_cadastrado !== null) {
            this.state.paramters += '&operador_cadastrado=' + operador_cadastrado;
        }

        console.log("parametros=>", this.state.paramters);

        if (this.state.excel != 'sim') {
            this.props.action.loadingStatus();    
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-netpoints-pagamentos", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                let vlr_pago = 0
                let pontos = 0
                response.content.map ((val, index) => {
                    vlr_pago += parseFloat(val.vlr_pago)
                    pontos += parseFloat(val.pontos_netpoints)
                })
                self.setState({
                    relatorio: response.content, 
                    qtd_total: response.content.length,
                    vlr_total: vlr_pago,
                    pontos_total: pontos
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });    
        } else {
            console.log("Chamando Excel:", data.date_ini, "!");
            if (this.consisteParametros(data.date_ini, data.date_fim)) {
                console.log("Chamando Excel OK");
                let urlNetPoints = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-netpoints-pagamentos"+this.state.paramters;
                window.location.href = urlNetPoints
            }
        }
    }

    showExcel(){
        console.log("showExcel1");
        this.state.excel = 'sim'
    }
 
    showLista(){
        console.log("showLista1");
        this.state.excel = 'nao'
    }
 
    consisteParametros(prm_date_ini, prm_date_fim){
        console.log("consisteParametros");
        if (((prm_date_ini === '') || isNullOrUndefined(prm_date_ini))) {
            Alert.error("Data Inicial deve ser Informada.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 3000
            });

            this.state.excel = 'nao'
            return false;    
        }
        if (((prm_date_fim === '') || isNullOrUndefined(prm_date_fim))) {
            Alert.error("Data Final deve ser Informada.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 3000
            });

            this.state.excel = 'nao'
            return false;    
        }
        return true;
    }


    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            return <tr key={index} >
                <td>{data.cnpj}</td>
                <td>{data.razao_social}</td>
                <td>{data.placa}</td>
                <td>{data.vencimento_original}</td>
                <td>{data.pagamento}</td>
                <td>{data.nosso_numero}</td>
                <td style={{textAlign:"right"}}>R$ {parseFloat(data.vlr_pago).toFixed(2)}</td>
                <td style={{textAlign:"right"}}>{data.pontos_netpoints}</td>
                <td>{data.remessa_netpoints}</td>
                <td>{data.dt_cadastro}</td>
                <td>{data.id_empr_blto_ntpts}</td>
                <td>{data.fl_operador}</td>
                <td>{data.nome_operador}</td>
                <td>{data.cpf_operador}</td>
                <td>{data.email_operador}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        this.state.excel = 'nao'
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={15}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa.</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Pagamentos - Clube de Vantagens</h2>
                <p>Consulte os pagamentos das empresas Participantes do Clube de Vantagens, feitas pelo Sistema de Cobrança BSFonline</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Selecione o Intervalo de Datas para a pesquisa de pagamentos, demais campos são opcionais
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-1">
                                        <div className="form-group">
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <div className="form-group">
                                            <InputMask name="date_ini" id="data_ini2" placeholder="Data início" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <div className="form-group">
                                            <InputMask name="date_fim" id="data_fim2" placeholder="Data fim" className="form-control" mask="99/99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" placeholder="Todas as Empresas" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                        <label> <b>Cadastro NetPoints</b> </label> <br/>
                                            <input type="radio" name="cadastro_cpf" value="1" /> Com Vínculo de CPF <br/>
                                            <input type="radio" name="cadastro_cpf" value="2" /> Sem Vínculo de CPF <br/>
                                            <input type="radio" name="cadastro_cpf" value="9" /> Ambos <br/>

                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                        <label> <b>Remessa Pendente</b> </label> <br/>
                                            <input type="radio" name="remessa_pendente" value="1" /> Sim  <br/>
                                            <input type="radio" name="remessa_pendente" value="2" /> Nao <br/>
                                            <input type="radio" name="remessa_pendente" value="9" /> Ambos <br/>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                        <label> <b>Operador Cadastrado</b> </label> <br/>
                                            <input type="radio" name="operador_cadastrado" value="1" /> Sim  <br/>
                                            <input type="radio" name="operador_cadastrado" value="2" /> Nao <br/>
                                            <input type="radio" name="operador_cadastrado" value="9" /> Todos <br/>
                                        </div>
                                    </div>

                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary" onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>

                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                        
                    <div className="panel-body container-fluid">
                        <form onSubmit={this.handlerSubmit2}>
                            <div className="row">
                                <table className="table table-striped table-doc">
                                    <tr>
                                        <thead>
                                            <td>Qtd Pagamentos</td>
                                            <td>Valor Total Pago</td>
                                            <td>Total de Pontos</td>
                                        </thead>
                                        <tbody>
                                            <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                            <td style={{textAlign:"right"}}>R$ {this.state.vlr_total.toLocaleString('de-DE')}</td>
                                            <td style={{textAlign:"right"}}>{this.state.pontos_total.toLocaleString('de-DE')}</td>
                                            <td></td>
                                        </tbody>
                                    </tr>
                                </table>
                                <table className="table table-striped table-doc">
                                    <thead>
                                        <tr>
                                            <td>CNPJ</td>
                                            <td>Empresa</td>
                                            <td>Plano</td>
                                            <td>Vecto Original</td>
                                            <td>Data Pagamento</td>
                                            <td>Nosso Numero</td>
                                            <td>Vlr Pago</td>
                                            <td>Pontos</td>
                                            <td>Remessa</td>
                                            <td>Dt Vínculo</td>
                                            <td>ID Transacao</td>
                                            <td>Operador</td>
                                            <td>Nome</td>
                                            <td>CPF</td>
                                            <td>Email</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableContent}
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
                </div> 
            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(NetPointsPagamentos))
