import React from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router';
import { Route, Switch } from 'react-router-dom';

//theme
import Menu from './components/theme/Menu';
import TopBar from './components/theme/TopBar';
import SideMenu from './components/theme/SideMenu';
import Footer from './components/theme/Footer';

//pages and middleware
import Login from './components/pages/login/Home';
import LoginCorretora from './components/pages/login/LoginCorretora';

//manager
import Dashboard from './components/pages/dashboard/Index';

//user
import UserIndex from './components/pages/usuarios/Index';
import Perfil from './components/pages/usuarios/Perfil';

//modelos de email
import IndexEmailModelos from './components/pages/emails-modelos/IndexEmailModelos';
import CreateEmailModelos from './components/pages/emails-modelos/CreateEmailModelos';
import EditEmailModelos from './components/pages/emails-modelos/EditEmailModelos';

//Campanhas
import IndexCampanhas from './components/pages/campanhas/IndexCampanhas';
import CreateCampanhas from './components/pages/campanhas/CreateCampanhas';
import EditCampanhas from './components/pages/campanhas/EditCampanhas';

import ReportEmails from './components/pages/relatorio-email';



//Notificações
import IndexNotificacoes from './components/pages/notificacoes/IndexNotificacoes';
import CreateNotificacoes from './components/pages/notificacoes/CreateNotificacoes';
import EditNotificacoes from './components/pages/notificacoes/EditNotificacoes';

//disparos de emails
import IndexEnvioEmails from './components/pages/envio-emails/IndexEnvioEmails';
import CreateEnvioEmails from './components/pages/envio-emails/CreateEnvioEmails';
import VisualizarEnvioEmails from './components/pages/envio-emails/VisualizarEnvioEmails';
import ConsultarBlackList from './components/pages/envio-emails/ConsultarBlackList';

//Filas de E-mais
import IndexFilasEmails from './components/pages/filas-emails/IndexFilasEmails';
import IndexFilasNotificacoes from './components/pages/filas-notificacoes/indexFilasNotificacoes';

//Relatorios Vida Em Grupo
import TrabalhadorVidaEmGrupo from './components/pages/vidaemgrupo/ExcelTrabalhador';
import PagamentoSegProprio from './components/pages/vidaemgrupo/PagamentoSegProprio';
import PagamentoSegSindical from './components/pages/vidaemgrupo/PagamentoSegSindical';
import TrabalhadorSegProprio from './components/pages/vidaemgrupo/TrabalhadorSegProprio';
import TrabalhadorSegSindical from './components/pages/vidaemgrupo/TrabalhadorSegSindical';

//Relatorios NetPoints
import NetPointsPagamentos from './components/pages/netpoints/NetPointsPagamentos';
import NetPointsEmpresas from './components/pages/netpoints/NetPointsEmpresas';
import ExcelNetPointsPagamentos from './components/pages/netpoints/ExcelNetPointsPagamentos';

//Relatorio Internos
import DiferencasPagamentos from './components/pages/resumos/ExcelDiferencasPagamentos';
import ResumoPagamentos from './components/pages/resumos/ResumoPagamentos'
import ResumoEventos from './components/pages/resumos/ResumoEventos'
import ResumoImpressoes from './components/pages/resumos/ResumoImpressoes'
import ResumoImpressoesAnalitico from './components/pages/resumos/ResumoImpressoesAnalitico'
import ResumoImpressoesPorVencimentos from './components/pages/resumos/ResumoImpressoesPorVencimento'
import PagamentosRetornos from './components/pages/resumos/PagamentosRetornos'
import PagamentosVencimentos from './components/pages/resumos/PagamentosVencimentos'
import PagamentosContas from './components/pages/resumos/PagamentosContas'
import PagamentosDuplicados from './components/pages/relatorios_internos/PagamentosDuplicados'
import CancelarBoletosPagos from './components/pages/relatorios_internos/CancelarBoletosPagos';
import CobrancaBSF from './components/pages/relatorios_internos/CobrancaBSF';
import EstornoBanco from './components/pages/relatorios_internos/EstornoBanco';
import UltimoRecxPagto from './components/pages/relatorios_internos/UltimoRecxPagto';
import AgendaVencimento from './components/pages/relatorios_internos/AgendaVencimento'

//Analise Inadimplentes
import InadimplentesComImpressao from './components/pages/inadimplentes/InadimplentesComImp'
import InadimplentesSemImpressao from './components/pages/inadimplentes/InadimplentesSemImp'
import ControleComplemento from './components/pages/inadimplentes/ControleComplemento'

//Relatorios Trabalhadores
import TrabalhadorEmpresa from './components/pages/trabalhadores/TrabalhadorrEmpresa';
import ExcelTrabalhadorPagamento from './components/pages/trabalhadores/ExcelTrabalhadorPagamento';
import TrabalhadorPagamento from './components/pages/trabalhadores/TrabalhadorrPagamento';
import TrabalhadorEmpresaSemDireito from './components/pages/trabalhadores/TrabalhadorrEmpresaSemDireito';
import TrabalhadorAlterar from './components/pages/trabalhadores/TrabalhadorrAlterar';
import TrabalhadorIncluir from './components/pages/trabalhadores/TrabalhadorrIncluir';

//Relatorios Empresas
import Pagamentos from './components/pages/relatorios_internos/Pagamentos'
import Impressoes from './components/pages/relatorios_internos/Impressoes'
import ImpressoesPorVencto from './components/pages/relatorios_internos/ImpressoesPorVencto'
import Emails from './components/pages/relatorios_internos/Emails'
import Operadores from './components/pages/relatorios_internos/Operadores'
import ListaEmpresa from './components/pages/empresas/ListaEmpresa';
import ConsultaEmpresa from './components/pages/empresas/ConsultaEmpresa';
import TipoCobranca from './components/pages/empresas/EmpresaTipoCobranca';
import EmpresaBoletos from './components/pages/empresas/EmpresaBoletos';
import EmpresaPlanos from './components/pages/empresas/EmpresaPlanos';
import PlanoEmpresas from './components/pages/empresas/PlanoEmpresas';
import LogEmpresa from './components/pages/empresas/LogEmpresa';
import EmpresaTrabalhadorPlanilha from './components/pages/empresas/EmpresaTrabalhadorPlanilha';
import EmpresaTrabalhadorUpload from './components/pages/empresas/EmpresaTrabalhadorUpload';
import ConsultaReceita from './components/pages/relatorios_internos/ConsultaReceita'
import ConsultaReceitaCNPJ from './components/pages/relatorios_internos/ConsultaReceitaCNPJ'

//Relatorios Empresas Personal
import EmpresasMigradas from './components/pages/empresas/EmpresasMigradas';
import EmpresasMigradasPlano from './components/pages/empresas/EmpresasMigradasPlano';
import EmpresasMigradasPlanoPagamentos from './components/pages/empresas/EmpresasMigradasPlanoPagamentos';
import EmpresasMigradasPlanoInadimp from './components/pages/empresas/EmpresasMigradasPlanoInadimp';
import EmpresasPersonal from './components/pages/empresas/EmpresasPersonal';
import ImpressoesPersonal from './components/pages/empresas/ImpressoesPersonal';

//Relatorios PAS
import PagamentosPAS from './components/pages/PAS/PagamentosPAS'
import ImpressoesPAS from './components/pages/PAS/ImpressoesPAS'
import TrabalhadorPAS from './components/pages/PAS/ExcelTrabalhadorPAS';
import BoletosPAS from './components/pages/PAS/EmpresaBoletosPAS'
import ComparativoPAS from './components/pages/PAS/ComparativoPAS';
import ComparativoTrabalhadorPAS from './components/pages/PAS/ComparativoTrabalhadorPAS';

//Relatorios PRAGAS
import PagamentosPRAGAS from './components/pages/PRAGAS/PagamentosPRAGAS'
import ImpressoesPRAGAS from './components/pages/PRAGAS/ImpressoesPRAGAS'
import BoletosPRAGAS from './components/pages/PRAGAS/EmpresaBoletosPRAGAS'
import ComparativoPRAGAS from './components/pages/PRAGAS/ComparativoPRAGAS';

//Relatorios SINSAGE
import PagamentosSINSAGE from './components/pages/SINSAGE/PagamentosSINSAGE'
import ImpressoesSINSAGE from './components/pages/SINSAGE/ImpressoesSINSAGE'
import BoletosSINSAGE from './components/pages/SINSAGE/EmpresaBoletosSINSAGE'
import ComparativoSINSAGE from './components/pages/SINSAGE/ComparativoSINSAGE';

//Relatorios SINBERF
import PagamentosSINBERF from './components/pages/SINBERF/PagamentosSINBERF'
import ImpressoesSINBERF from './components/pages/SINBERF/ImpressoesSINBERF'
import BoletosSINBERF from './components/pages/SINBERF/EmpresaBoletosSINBERF'
import ComparativoSINBERF from './components/pages/SINBERF/ComparativoSINBERF';

//Relatorios APOIAR
import PagamentosAPOIAR from './components/pages/APOIAR/PagamentosAPOIAR'
import ImpressoesAPOIAR from './components/pages/APOIAR/ImpressoesAPOIAR'
import BoletosAPOIAR from './components/pages/APOIAR/EmpresaBoletosAPOIAR'
import TrabalhadoresAPOIAR from './components/pages/APOIAR/TrabalhadorrEmpresaAPOIAR'
import OperadoresAPOIAR from './components/pages/APOIAR/OperadoresAPOIAR'

//Relatorio de Trabalhadores - PRESTADORES
import Trabalhadores_CMD from './components/pages/CMD/TrabalhadorPagamento_CMD';
import TrabalhadoresDemitidos_CMD from './components/pages/CMD/TrabalhadorDemitido_CMD';
import Trabalhadores_FAIRFAX from './components/pages/FAIRFAX/TrabalhadorPagamento_FAIRFAX';
import TrabalhadoresDemitidos_FAIRFAX from './components/pages/FAIRFAX/TrabalhadorDemitido_FAIRFAX';

//Relatorios Entidades
import AcessoNetEntidade from './components/pages/entidades/AcessoNetEntidade';
import PlanosEntidade from './components/pages/entidades/PlanosEntidade';

//Relatorios Planos
import Analise1Plano from './components/pages/planos/Analise1Plano';
import Analise2Plano from './components/pages/planos/Analise2Plano';
import Analise3Plano from './components/pages/planos/Analise3Plano';
import ResyncBoletos from './components/pages/relatorios_internos/ResyncBoletos';

//Conecta
import TrabalhadorConecta from './components/pages/conecta/TrabalhadorConecta'
import SmsPushConecta from './components/pages/conecta/SmsPushConecta'

//Sorteio
import TrabalhadorSorteio from './components/pages/sorteio/TrabalhadorSorteio'
import TrabalhadorGanhador from './components/pages/sorteio/TrabalhadorGanhador'
import Ganhadores from './components/pages/sorteio/Ganhadores'

//Ponto Remoto
import EmpresasPontoRemotoMADIS from './components/pages/MADIS/EmpresasPontoRemoto'
import EmpresasPontoRemotoMyWork from './components/pages/MyWork/EmpresasPontoRemoto'

//Comparativo Postos

import ComparativoPostos from './components/pages/relatorios_internos/ComparativoPostos';

//Contador
import ContadorEmpresa from './components/pages/contador/ContadorEmpresa'
import Contador from './components/pages/contador/Contador'
import ContadorOperador from './components/pages/contador/ContadorOperador'

//SERASA
import SerasaEmpresas from './components/pages/SERASA/Empresas'

//GATEWAY
import TransacoesEmpresas from './components/pages/Gateway/EmpresaTransacoes'

//DEPENDENTE
import PagamentoOdontologico from './components/pages/odontologico/PagamentoOdontologico'

//SMS
//VIDALINK
import CartoesVidalink from './components/pages/SMS/CartoesVidalink'
import CartoesNaoValidados from './components/pages/SMS/CartoesNaoValidados'
import CartoesValidade from './components/pages/SMS/CartoesValidade'
import CartoesFuncional from './components/pages/SMS/CartoesFuncional'
import PlanoInativado from './components/pages/SMS/PlanoInativado'
import SmsEmpresasCnpj from './components/pages/SMS/SmsEmpresasCnpj'

import EmailConfig from './components/pages/email-config'
import PagamentoAdicional from './components/pages/adicional/Pagamentos';
import TrabalhadorPagamento_CMD from './components/pages/CMD/TrabalhadorPagamento_CMD';
import TrabalhadorDemitido_CMD from './components/pages/CMD/TrabalhadorDemitido_CMD';
import TrabalhadorPagamento_FAIRFAX from './components/pages/FAIRFAX/TrabalhadorPagamento_FAIRFAX';
import TrabalhadorDemitido_FAIRFAX from './components/pages/FAIRFAX/TrabalhadorDemitido_FAIRFAX';

let history = createBrowserHistory();

class Routes extends React.Component{
    render(){
        return <div className="scope-route">
            <TopBar />
            <Menu />
            <SideMenu />
            <Router history={history}>
                <Switch>
                    {/*LOGIN*/}
                    <Route exact path="/" component={LoginCorretora} />
                    <Route exact path="/login" component={Login} />

                    {/*PRIVATE ROUTES*/}
                    {/*BASE E USUARIOS*/}
                    <Route exact path="/manager/dashboard" component={Dashboard}/>
                    <Route exact path="/manager/usuarios" component={UserIndex}/>
                    <Route exact path="/manager/perfil" component={Perfil}/>

                    {/*RELATORIOS VIDA EM GRUPO*/}
                    <Route exact path="/manager/vidaemgrupo/trabalhador" component={TrabalhadorVidaEmGrupo}/>
                    <Route exact path="/manager/vidaemgrupo/pagamentosegproprio" component={PagamentoSegProprio}/>
                    <Route exact path="/manager/vidaemgrupo/pagamentosegsindical" component={PagamentoSegSindical}/>
                    <Route exact path="/manager/vidaemgrupo/trabalhadorsegproprio" component={TrabalhadorSegProprio}/>
                    <Route exact path="/manager/vidaemgrupo/trabalhadorsegsindical" component={TrabalhadorSegSindical}/>

                    {/*RELATORIOS NETPOINTS*/}
                    <Route exact path="/manager/netpoints/pagamentos" component={NetPointsPagamentos}/>
                    <Route exact path="/manager/netpoints/empresas" component={NetPointsEmpresas}/>
                    <Route exact path="/manager/netpoints/excelpagamentos" component={ExcelNetPointsPagamentos}/>

                    {/*RELATORIOS INTERNOS*/}
                    <Route exact path="/manager/relatorios/diferencas" component={DiferencasPagamentos}/>
                    <Route exact path="/manager/relatorios/resumopagamentos" component={ResumoPagamentos}/>
                    <Route exact path="/manager/relatorios/resumoimpressoes" component={ResumoImpressoes}/>
                    <Route exact path="/manager/relatorios/resumoimpressoesanalitico" component={ResumoImpressoesAnalitico}/>
                    <Route exact path="/manager/relatorios/resumoimpressoesvencimentos" component={ResumoImpressoesPorVencimentos}/>
                    <Route exact path="/manager/relatorios/pagamentoscontas" component={PagamentosContas}/>
                    <Route exact path="/manager/relatorios/pagamentosretornos" component={PagamentosRetornos}/>
                    <Route exact path="/manager/relatorios/pagamentosvencimentos" component={PagamentosVencimentos}/>
                    <Route exact path="/manager/relatorios/pagamentosduplicados" component={PagamentosDuplicados}/>
                    <Route exact path="/manager/relatorios/cancelarboletospagos" component={CancelarBoletosPagos}/>
                    <Route exact path="/manager/relatorios/cobrancabsf" component={CobrancaBSF}/>
                    <Route exact path="/manager/relatorios/resyncboletos" component={ResyncBoletos}/>
                    <Route exact path="/manager/relatorios/estornobanco" component={EstornoBanco}/>
                    <Route exact path="/manager/relatorios/ultimorecxpagto" component={UltimoRecxPagto}/>
                    <Route exact path="/manager/relatorios/webben" component={ResumoEventos}/>
                    <Route exact path="/manager/relatorios/postos" component={ComparativoPostos}/>
                    <Route exact path="/manager/relatorios/agenda-vencimento" component={AgendaVencimento}/>
                    <Route exact path="/manager/relatorios/consultareceita" component={ConsultaReceita}/>
                    <Route exact path="/manager/relatorios/consultareceitaCNPJ" component={ConsultaReceitaCNPJ}/>

                    {/*EMPRESAS*/}
                    <Route exact path="/manager/relatorios/pagamentos" component={Pagamentos}/>
                    <Route exact path="/manager/relatorios/impressoes" component={Impressoes}/>
                    <Route exact path="/manager/relatorios/impressoesporvencto" component={ImpressoesPorVencto}/>
                    <Route exact path="/manager/relatorios/inadimplentescomimp" component={InadimplentesComImpressao}/>
                    <Route exact path="/manager/relatorios/inadimplentessemimp" component={InadimplentesSemImpressao}/>
                    <Route exact path="/manager/relatorios/controlecomplemento" component={ControleComplemento}/>
                    <Route exact path="/manager/relatorios/emails" component={Emails}/>
                    <Route exact path="/manager/relatorios/ses" component={ReportEmails}/>
                    <Route exact path="/manager/relatorios/operadores" component={Operadores}/>
                    <Route exact path="/manager/relatorios/tipocobranca" component={TipoCobranca}/>

                    {/*RELATORIOS TRABALHADORES*/}
                    <Route exact path="/manager/trabalhadores/trabalhadorempresa" component={TrabalhadorEmpresa}/>
                    <Route exact path="/manager/trabalhadores/trabalhadorpagamento" component={TrabalhadorPagamento}/>
                    <Route exact path="/manager/trabalhadores/exceltrabalhadorpagamento" component={ExcelTrabalhadorPagamento}/>
                    <Route exact path="/manager/trabalhadores/trabalhadorempresasemdireito" component={TrabalhadorEmpresaSemDireito}/>
                    <Route exact path="/manager/trabalhadores/trabalhadoralterar" component={TrabalhadorAlterar}/>
                    <Route exact path="/manager/trabalhadores/trabalhadorincluir" component={TrabalhadorIncluir}/>

                    {/*PAS*/}
                    <Route exact path="/manager/PAS/pagamentosPAS" component={PagamentosPAS}/>
                    <Route exact path="/manager/PAS/impressoesPAS" component={ImpressoesPAS}/>
                    <Route exact path="/manager/PAS/trabalhadorPAS" component={TrabalhadorPAS}/>
                    <Route exact path="/manager/PAS/boletosPAS" component={BoletosPAS}/>
                    <Route exact path="/manager/PAS/comparativo" component={ComparativoPAS}/>
                    <Route exact path="/manager/PAS/comparativo/trabalhador" component={ComparativoTrabalhadorPAS}/>

                    {/*PRAGAS*/}
                    <Route exact path="/manager/PRAGAS/pagamentosPRAGAS" component={PagamentosPRAGAS}/>
                    <Route exact path="/manager/PRAGAS/impressoesPRAGAS" component={ImpressoesPRAGAS}/>
                    <Route exact path="/manager/PRAGAS/boletosPRAGAS" component={BoletosPRAGAS}/>
                    <Route exact path="/manager/PRAGAS/comparativo" component={ComparativoPRAGAS}/>

                    {/*SINSAGE*/}
                    <Route exact path="/manager/SINSAGE/pagamentosSINSAGE" component={PagamentosSINSAGE}/>
                    <Route exact path="/manager/SINSAGE/impressoesSINSAGE" component={ImpressoesSINSAGE}/>
                    <Route exact path="/manager/SINSAGE/boletosSINSAGE" component={BoletosSINSAGE}/>
                    <Route exact path="/manager/SINSAGE/comparativo" component={ComparativoSINSAGE}/>

                    {/*SINBERF*/}
                    <Route exact path="/manager/SINBERF/pagamentosSINBERF" component={PagamentosSINBERF}/>
                    <Route exact path="/manager/SINBERF/impressoesSINBERF" component={ImpressoesSINBERF}/>
                    <Route exact path="/manager/SINBERF/boletosSINBERF" component={BoletosSINBERF}/>
                    <Route exact path="/manager/SINBERF/comparativo" component={ComparativoSINBERF}/>

                    {/*APOIAR*/}
                    <Route exact path="/manager/APOIAR/pagamentosAPOIAR" component={PagamentosAPOIAR}/>
                    <Route exact path="/manager/APOIAR/impressoesAPOIAR" component={ImpressoesAPOIAR}/>
                    <Route exact path="/manager/APOIAR/boletosAPOIAR" component={BoletosAPOIAR}/>
                    <Route exact path="/manager/APOIAR/trabalhadoresAPOIAR" component={TrabalhadoresAPOIAR}/>
                    <Route exact path="/manager/APOIAR/operadoresAPOIAR" component={OperadoresAPOIAR}/>

                    {/*RELATORIOS EMPRESAS*/}
                    <Route exact path="/manager/empresas/listaempresa" component={ListaEmpresa}/>
                    <Route exact path="/manager/empresas/consultaempresa" component={ConsultaEmpresa}/>
                    <Route exact path="/manager/empresas/empresaboletos" component={EmpresaBoletos}/>
                    <Route exact path="/manager/empresas/empresaplanos" component={EmpresaPlanos}/>
                    <Route exact path="/manager/empresas/planoempresas" component={PlanoEmpresas}/>
                    <Route exact path="/manager/empresas/logempresa" component={LogEmpresa}/>
                    <Route exact path="/manager/empresas/empresatrabalhadorplanilha" component={EmpresaTrabalhadorPlanilha}/>
                    <Route exact path="/manager/empresas/empresatrabalhadorupload" component={EmpresaTrabalhadorUpload}/>

                    {/*RELATORIOS EMPRESAS PERSONAL*/}
                    <Route exact path="/manager/empresas/empresasmigradas" component={EmpresasMigradas}/>
                    <Route exact path="/manager/empresas/empresasmigradasplano" component={EmpresasMigradasPlano}/>
                    <Route exact path="/manager/empresas/empresasmigradasplanopagamentos" component={EmpresasMigradasPlanoPagamentos}/>
                    <Route exact path="/manager/empresas/empresasmigradasplanoinadimp" component={EmpresasMigradasPlanoInadimp}/>
                    <Route exact path="/manager/empresas/empresaspersonal" component={EmpresasPersonal}/>
                    <Route exact path="/manager/empresas/impressoespersonal" component={ImpressoesPersonal}/>


                    {/*RELATORIOS TRABALHADORES - PRESTADORES*/}
                    <Route exact path="/manager/assistencia/cobranca" component={PagamentoAdicional}/>

                    {/*RELATORIOS TRABALHADORES - PRESTADORES*/}


                    <Route exact path="/manager/prestadores/Favorecido/trabalhadores" component={TrabalhadorPagamento_CMD}/>
                    <Route exact path="/manager/prestadores/Favorecido/trabalhadoresdemitidos" component={TrabalhadorDemitido_CMD}/>
                    <Route exact path="/manager/prestadores/CMD/trabalhadores" component={TrabalhadorPagamento_CMD}/>
                    <Route exact path="/manager/prestadores/CMD/trabalhadoresdemitidos" component={TrabalhadorDemitido_CMD}/>
                    <Route exact path="/manager/prestadores/FAIRFAX/trabalhadores" component={TrabalhadorPagamento_FAIRFAX}/>
                    <Route exact path="/manager/prestadores/FAIRFAX/trabalhadoresdemitidos" component={TrabalhadorDemitido_FAIRFAX}/>

                    {/*RELATORIOS ENTIDADES*/}
                    <Route exact path="/manager/entidades/acessonet" component={AcessoNetEntidade}/>
                    <Route exact path="/manager/entidades/planos" component={PlanosEntidade}/>

                    {/*RELATORIOS PLANOS*/}
                    <Route exact path="/manager/planos/analise1" component={Analise1Plano}/>
                    <Route exact path="/manager/planos/analise2" component={Analise2Plano}/>
                    <Route exact path="/manager/planos/analise3" component={Analise3Plano}/>

                    {/*RELATORIOS CONECTA*/}
                    <Route exact path="/manager/conecta/trabalhadorconecta" component={TrabalhadorConecta}/>
                    <Route exact path="/manager/conecta/smspush" component={SmsPushConecta}/>

                    {/*RELATORIOS SORTEIO*/}
                    <Route exact path="/manager/sorteio/trabalhadorsorteio" component={TrabalhadorSorteio}/>
                    <Route exact path="/manager/sorteio/trabalhadorganhador" component={TrabalhadorGanhador}/>
                    <Route exact path="/manager/sorteio/ganhadores" component={Ganhadores}/>

                    {/*SERASA ENTIDADES*/}
                    <Route exact path="/manager/serasa/empresas" component={SerasaEmpresas}/>

                    {/*GATEWAY*/}
                    <Route exact path="/manager/gateway/transacoesempresas" component={TransacoesEmpresas}/>

                    {/*SMS*/}
                    {/*VIDALINK*/}
                    <Route exact path="/manager/sms/cartoesvidalink" component={CartoesVidalink}/>
                    {/*Cartao Nao Validados*/}
                    <Route exact path="/manager/sms/cartoesnaovalidados" component={CartoesNaoValidados}/>
                    {/*Cartao x Validade*/}
                    <Route exact path="/manager/sms/cartoesvalidade" component={CartoesValidade}/>
                    {/*Funcional*/}
                    <Route exact path="/manager/sms/cartoesfuncional" component={CartoesFuncional}/>
                    {/*Plano Inativado*/}
                    <Route exact path="/manager/sms/planoinativado" component={PlanoInativado}/>
                    {/*Empresas*/}
                    <Route exact path="/manager/sms/smsempresascnpj" component={SmsEmpresasCnpj}/>

                    {/*RELATORIOS CONTADOR*/}
                    <Route exact path="/manager/contador/contadorempresa" component={ContadorEmpresa}/>
                    <Route exact path="/manager/contador/contador" component={Contador}/>
                    <Route exact path="/manager/contador/contadoroperador" component={ContadorOperador}/>

                    {/*RELATORIOS PONTO REMOTO*/}
                    <Route exact path="/manager/MADIS/empresaspontoremoto" component={EmpresasPontoRemotoMADIS}/>
                    <Route exact path="/manager/MyWork/empresaspontoremoto" component={EmpresasPontoRemotoMyWork}/>

                    {/*MODELOS DE EMAIL*/}
                    <Route exact path="/manager/modelos-email" component={IndexEmailModelos}/>
                    <Route exact path="/manager/modelos-email/create" component={CreateEmailModelos}/>
                    <Route exact path="/manager/modelos-email/edit/:id" component={EditEmailModelos}/>

                    {/*CAMPANHAS*/}
                    <Route exact path="/manager/campanhas" component={IndexCampanhas} />
                    <Route exact path="/manager/campanhas/create" component={CreateCampanhas} />
                    <Route exact path="/manager/campanhas/edit/:id" component={EditCampanhas} />

                    {/*Notificações*/}
                    <Route exact path="/manager/notificacoes" component={IndexNotificacoes} />
                    <Route exact path="/manager/notificacoes/create" component={CreateNotificacoes} />
                    <Route exact path="/manager/notificacoes/edit/:id" component={EditNotificacoes} />

                    {/*Fila de E-mails*/}
                    <Route exact path="/manager/filas-emails" component={IndexFilasEmails} />
                    {/*Fila de Notificações*/}
                    <Route exact path="/manager/filas-notificacoes" component={IndexFilasNotificacoes} />

                    {/*ENVIO DE EMAIL*/}
                    <Route exact path="/manager/envio-emails" component={IndexEnvioEmails}/>
                    <Route exact path="/manager/envio-emails/grupo/:uuid" component={VisualizarEnvioEmails}/>
                    <Route exact path="/manager/envio-emails/create" component={CreateEnvioEmails}/>
                    <Route exact path="/manager/envio-emails/blacklist" component={ConsultarBlackList}/>
                    <Route exact path="/manager/email-config" component={EmailConfig}/>

                    <Route exact path="/manager/odontologico-pagamento" component={PagamentoOdontologico}/>
                </Switch>
            </Router>
            <Footer />
        </div>
    }
}

export default Routes;
