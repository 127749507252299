import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import moment from 'moment';
import InputMask from 'react-input-mask';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class ImpressoesAPOIAR extends React.Component{
    constructor(props){
        super(props);

        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;

        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            renderModal: <div></div>,
            selecao: {},
            trabs: [],
            qtd_total: 0,
            vlr_total: 0,
            qtd_trabalhadores: 0,
            empresa: null,
            nnumero: null

        }

        this.getTableShow = this.getTableShow.bind(this);
        this.showTrabs = this.showTrabs.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
            empresa_pesquisa: event.target.empresa_pesquisa.value
        }

        data.pesquisa_parcial =  'nao';
        data.nnumero_pesquisa =  event.target.nnumero_pesquisa.value;
        switch(event.target.remessa.value){
            case "1":
                data.remessa = "1";
                break;
            case "2":
                data.remessa = "2";
                break;
            case "3":
                data.remessa = "3";
                break;
            default:
                data.remessa = "";
                break;
        }

        data.mesano_vecto       = event.target.mesano_vecto.value;
        data.date_ini           = event.target.date_ini.value;
        data.date_fim           = event.target.date_fim.value;
        //data.parceiro_pesquisa  = '14';
        data.parceiro_pesquisa  = '32';

        this.state.paramters = "?";
        this.state.paramters += 'empresa_pesquisa=' + data.empresa_pesquisa;
        this.state.paramters += '&mesano_vecto=' + data.mesano_vecto;
        this.state.paramters += '&nnumero_pesquisa=' + data.nnumero_pesquisa;
        this.state.paramters += '&pesquisa_parcial=' + data.pesquisa_parcial;
        this.state.paramters += '&remessa=' + data.remessa;
        this.state.paramters += '&date_ini=' + data.date_ini;
        this.state.paramters += '&date_fim=' + data.date_fim;
        this.state.paramters += '&parceiro_pesquisa=' + data.parceiro_pesquisa;
        

        if (this.state.excel != 'sim') {
            this.props.action.loadingStatus();

            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-impressoes-parceiro", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                let vlr_impresso = 0
                let qtd_tbldr = 0
                response.content.map ((val, index) => {
                    vlr_impresso += parseFloat(val.vlr_boleto)
                    qtd_tbldr += parseFloat(val.ativos)
                })
    
                self.setState({
                    relatorio: response.content,
                    vlr_total: vlr_impresso,
                    qtd_trabalhadores: qtd_tbldr,
                    qtd_total: response.content.length
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });   
        } else {
            console.log("Chamando Excel!", this.state.paramters);
            if (this.consisteParametros()) {
                console.log("Chamando Excel OK");
                let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-empresa-impressoes-parceiro"+this.state.paramters;
                window.location.href = urlExcel+'&token='+self.core.getToken();     
            }
        }
    }

    showTrabs(id_empr_blto_impr, razao_social, nosso_numero){
        this.empresa = razao_social;
        this.nnumero = nosso_numero;
        this.setState({
            trabs: []
        })
        let self = this;
        this.props.action.loadingStatus();

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/empresas-trabalhadores-boleto-impresso?id_empr_blto_impr=" + id_empr_blto_impr
        ,{method: 'GET'}
        )
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            let trabsres = response.content.map((trab, index) => {
                    return <tr key={index} >
                        <td>{trab.cpf}</td>
                        <td>{trab.nome}</td>
                        <td>{trab.sexo}</td>
                        <td>{trab.nascimento}</td>
                        <td>{trab.admissao}</td>
                        <td>{trab.status}</td>
                    </tr>
                });

            self.setState({
                trabs: trabsres
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        })
    }

    showBoleto(token_empr_blto_impr, id_empr_blto_impr){
        let parametros = "invoice/" + token_empr_blto_impr + "?id=" + id_empr_blto_impr;
        window.open(this.core.getUrl() + parametros, 'newwindow', 'width=' + this.width + ',height=' + this.height);
    }

    showLista(){
        console.log("showLista1");
        this.state.excel = 'nao'
    }

    showExcel(){
        console.log("showExcel1");
        this.state.excel = 'sim'
    }

    consisteParametros(){
        console.log("consisteParametros");
        //nao tenenhuma regra para essa geracao de excel
        return true;
    }


    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            let botao1 = null;
            let botao2 = null;
            //console.log(data);
                if (data.cobranca === 'BSFOnline') {
                    botao1 = 
                        <td>
                            <button className="btn btn-info" onClick={event => this.showTrabs(data.id_empr_blto_impr, data.razao_social, data.nosso_numero)} data-toggle="modal" data-target="#trabModal">Trabalhadores</button>
                        </td>
                    botao2 = 
                        <td>
                            <button className="btn btn-info" onClick={event => this.showBoleto(data.token_impr, data.id_empr_blto_impr)} data-toggle="modal" data-target="#boletoModal">Boleto</button>
                        </td>
                } else {
                    botao1 = 
                        <td>
                            ----------x----------
                        </td>
                    botao2 = 
                        <td>
                            ----x----
                        </td>
                }
    
            return <tr key={index} >
                <td>{data.cnpj}</td>
                <td>{data.razao_social}</td>
                <td>{data.id_empr_plno}</td>
                <td>{data.vencimento_original}</td>
                <td>{data.impressao}</td>
                <td>{data.vlr_boleto}</td>
                <td>{data.nosso_numero}</td>
                <td>{data.pagamento}</td>
                <td>{data.vlr_pago}</td>
                <td>{data.id_empr_blto_impr}</td>
                <td>{data.id_empr_blto}</td>
                <td>{data.ativos}</td>
                {botao1}
                {botao2}
                <td>{data.complemento}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        let tableTrabalhadores;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.trabs.length){
            tableTrabalhadores = this.state.trabs;
        }
        else {
            tableTrabalhadores = <tr>
                        <td colSpan={5}>
                            <b> Nenhum Trabalhador vinculado a esse boleto.</b>
                        </td>
                        </tr>
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={15}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Impressões - Boletos de Pagamento BSFOnline - APOIAR</h2>
                <p>Consulte os boletos impressos pelas empresas no Sistema de Cobrança BSFonline</p>

                <div className="modal fade" id="trabModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Trabalhadores da Empresa {this.empresa}, boleto N.Número {this.nnumero}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <table style={{width: 100 + '%'}}>
                                <thead>
                                <tr>
                                    <td>CPF</td>
                                    <td>Nome</td>
                                    <td>Sexo</td>
                                    <td>Nascimento</td>
                                    <td>Admissao</td>
                                    <td>Status</td>
                                </tr>
                                </thead>
                                <tbody>
                                    {tableTrabalhadores}
                                </tbody>
                            </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">FECHAR</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Selecione a empresa para a pesquisa das impressões dos boletos de pagamento.
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label><small>Data Inicio Data Impressão</small></label>
                                            <InputMask name="date_ini" id="data_ini2" className="form-control" mask="99/99/9999"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label><small>Data Fim Data Impressão</small></label>
                                            <InputMask name="date_fim" id="data_fim2" className="form-control" mask="99/99/9999"/>
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label><small>Empresa</small></label>
                                            <input name="empresa_pesquisa" id="empresa_pesquisa" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label><small>Nosso Número</small></label>
                                            <input name="nnumero_pesquisa" id="nnumero_pesquisa" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label><small>Mes/Ano Vecto</small></label>
                                            <InputMask name="mesano_vecto" id="mesano_vecto" className="form-control" mask="99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                        <label> <b>Remessa</b> </label> <br/>
                                            <input type="radio" name="remessa" value="1" />Em Aberto <br/>
                                            <input type="radio" name="remessa" value="2" />Pagas <br/>
                                            <input type="radio" name="remessa" value="3" />Canceladas <br/>
                                            <input type="radio" name="remessa" value="9" />Todas <br/>
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">
                        {/* formulario envia para handlerSubmitPlanoTrabalhadores*/}

                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                    <td>Totais</td>
                                    <td>Qtd Impressoes</td>
                                    <td>Valor Total Impressoes</td>
                                    <td>Qtd Total Trabalhadores</td>
                                </thead>
                                <tbody>
                                    <td></td>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                    <td style={{textAlign:"right"}}>R$ {this.state.vlr_total.toFixed(2).toLocaleString('pt-br')}</td>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_trabalhadores.toLocaleString('pt-br')}</td>
                                </tbody>
                            </tr>
                        </table>

                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>CNPJ</td>
                                    <td>Empresa</td>
                                    <td>Empr/Plno</td>
                                    <td>Vcto Original</td>
                                    <td>Data Impressao</td>
                                    <td>Vlr Boleto</td>
                                    <td>Nosso Numero</td>
                                    <td>Data Pagamento</td>
                                    <td>Vlr Pago</td>
                                    <td>Id Impressao</td>
                                    <td>Id Boleto</td>
                                    <td>Qtd Trabalhadores</td>
                                    <td>Trabalhadores</td>
                                    <td>Boleto</td>
                                    <td>Complemento</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(ImpressoesAPOIAR))
