import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as UserActions from '../../../actions/UserActions';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusManagerActions from '../../../actions/StatusManagerActions';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import Alert from 'react-s-alert'
import  Table from '../../geral/Table';
import moment from 'moment';
import { ModalDialog, ModalContainer } from 'react-modal-dialog-react16'
import $ from 'jquery';


//actions

import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';

import * as StatusActions from '../../../actions/StatusActions';
import * as EnvioEmailsActions from '../../../actions/EnvioEmailsActions';





class EmailConfig extends React.Component {
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        

        this.state = {
            head: ['NOME', 'ID','ACCESS','BOUNCE', 'REGIÃO',  'STATUS', "AÇÕES"],
            columns: [],
            dados : []
        }

        this.handlerSubmit = this.handlerSubmit.bind(this)
        this._renderTable = this._renderTable.bind(this)
        this._alertSuccess = this._alertSuccess.bind(this)
    }

    _alertSuccess(){
        Alert.success('Conta alterada com sucesso!', {
            position: 'top-right',
            effect: 'bouncyflip',
            beep: true,
            timeout: 3000,
            offset: 100

        });
    }
    _renderTable(){
        let self = this
        fetch(self.core.getUrlConecta() + "aws/list", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
        }
        )
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            

            let lista = response.content.map((data,index) => {
                let ob = {
                    aws_name: data.aws_name,
                    aws_id: data.aws_id,
                    aws_access: data.aws_access,
                    aws_bounce: data.aws_bounce,
                    aws_region: data.aws_region,
                    aws_status: data.aws_status ? "<span class='badge badge-lg badge-success'>Ativado</span>" : "<span class='badge badge-lg badge-danger'>Inativo</span>",
                    aws_button: !data.aws_status ?  "<button class='btn btn-raised btn-success btn-block active btn-sm btn-active-aws' data-id="+ data.aws_ses_id +">Ativar</button>" : ""
                }
                return ob
            })


            self.setState({
                columns: lista
            });
        })
        .catch((err) => {
            self.core.catchFecth(err);

        });

    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
        let self = this
        this._renderTable()
        
    }

    componentDidMount(){
        let self = this

        $('body').on('click', '.btn-active-aws',(e)=>{

            let id = $(e.target).attr('data-id'); //pegando id do botao clickado
            fetch(self.core.getUrlConecta()+ 'aws/active/' + id, {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
            })
                .then(res => res.json())
                .then((response) => {
                    this.setState({
                        logs: response.content,
                        isLoading: false
                    })
                    console.log(this.state)
                     return response;
    
                })
                .then( resp => {
                    self.props.action.loadedStatus();
                    self._renderTable()
                    self._alertSuccess()
                })
                .catch(err => {
                       this.setState({
                        isShowingModal: false
                        })
                    self.core.catchFecth(err)
                    self.props.action.loadedStatus();
                })
            })

    }

    handlerSubmit(event){
        event.preventDefault()
        let self = this

        let send = {
            MAIL_PASSWORD : event.target.MAIL_PASSWORD.value,
            MAIL_USERNAME : event.target.MAIL_USERNAME.value,
            AWS_REGION : event.target.AWS_REGION.value,
            MAIL_HOST : event.target.MAIL_HOST.value,
            MAIL_PORT : event.target.MAIL_PORT.value,
            MAIL_DRIVER : event.target.MAIL_DRIVER.value,
            AWS_ACCESS : event.target.AWS_ACCESS.value,
            AWS_ID : event.target.AWS_ID.value,
            AWS_BOUNCE : event.target.AWS_BOUNCE.value
        }


        fetch(self.core.getUrlConecta() + 'accounts/update',{
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'PUT',
            body: JSON.stringify(send)
        }) 
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            Alert.success("Dados atualizado com sucesso!", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 8000
            })

        })
        .catch((err) => {
            self.core.catchFecth(err)

        })
       
    }

    render() {
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }
        console.log(this.state.dados)
        return <div className="page">
            <div className="page-content">
                <h1> Configurações de AWS SES</h1>
            <div className="panel">
            <div className="panel-body container-fluid">
                        <Table
                            head={this.state.head}
                            columns={this.state.columns}
                        />
                    </div>

            </div>
                
            </div>
         </div>
    }
}

function mapStateProps(state, props ){
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch){
    return {
        action: bindActionCreators({
                ...UserActions,
                ...StatusManagerActions,
                ...RedirectActions,
                ...StatusActions
            }, dispatch )
        }
}

export default withRouter(connect(mapStateProps, mapDispatchProps)(EmailConfig));
