import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';
import Alert from 'react-s-alert';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';
import { isNull } from 'util';
import { isNullOrUndefined } from 'util';

class InadimplentesComImp extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
            qtd_total: 0,
            vlr_total: 0,
            auth: this.core.getAuth(),
            paramters: null,
            critica: 'nao',            
            excel: 'nao'            
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        console.log("handlerSubmit");
        event.preventDefault();
        let self = this;

        let data = {
        }

        data.plano              =  event.target.plano.value;
        data.mesano_vecto_de    = event.target.mesano_vecto_de.value;
        data.mesano_vecto_ate  = event.target.mesano_vecto_ate.value;

        this.state.paramters = "?";
        this.state.paramters += 'mesano_vecto_de=' + data.mesano_vecto_de;
        this.state.paramters += '&mesano_vecto_ate=' + data.mesano_vecto_ate;
        if (data.plano !== null) {
            this.state.paramters += '&plano=' + data.plano;
        }
        this.state.paramters += '&token=' + this.core.getToken();

        this.critica = 'ok';
        if (isNullOrUndefined(data.mesano_vecto_de) || (data.mesano_vecto_de === "") ) {
            Alert.error("Mes/Ano do Vencimento (de1) deve ser informado.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 3000
            });    
            this.critica = 'erro';
        }

        /*
        if (isNullOrUndefined(data.plano) || (data.plano === "") ) {
            Alert.error("Plano deve ser informado.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 3000
            });    
            this.critica = 'erro';
        }
        */

        if (this.state.excel != 'sim' && (this.critica === 'ok')) {
            this.props.action.loadingStatus();
            fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-empresa-inadimplentes-comimp", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => self.core.responseConvert(res))
            .then((response) => {
                let vlr_blto = 0
                response.content.map ((val, index) => {
                    vlr_blto += parseFloat(val.vlr_blto)
                })
                self.setState({
                    relatorio: response.content, 
                    qtd_total: response.content.length,
                    vlr_total: vlr_blto
                });
                self.props.action.loadedStatus();
            })
            .catch((err) => {
                self.core.catchFecth(err)
                self.props.action.loadedStatus();
            });    
        } 
        if (this.state.excel === 'sim' && (this.critica === 'ok')) {
            console.log("Chamando Excel:", this.state.paramters, "!");
            if (this.consisteParametros(data.mesano_vecto_de)) {
                console.log("Chamando Excel OK");
                let urlExcel = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-empresa-inadimplentes-comimp"+this.state.paramters;
                window.location.href = urlExcel        
            }
        }
    }

    showLista(){
        console.log("showLista1");
        this.state.excel = 'nao'
    }

    showExcel(){
        console.log("showExcel1");
        this.state.excel = 'sim'
    }

    consisteParametros(prm_mesano_vecto_de){
        console.log("consisteParametros");
        if (((prm_mesano_vecto_de === '') || isNullOrUndefined(prm_mesano_vecto_de))) {
            Alert.error("Mes/Ano do Vencimento deve ser informado.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 3000
            });

            this.state.excel = 'nao'
            return false;    
        }
        return true;
    }

    getTableShow(){
        return this.state.relatorio.map((data, index) => {
            let vlr_ult = null 
            if (data.qtd_impr > 1) {
                vlr_ult = <td style={{textAlign:"right"}}>R$ {parseFloat(data.vlr_ult_blto).toFixed(2)}</td>
            } else {
                vlr_ult = <td></td>
            }   
            return <tr key={index} >
                <td>{data.id_empr_blto}</td>
                <td>{data.id_tr}</td>
                <td>{data.cnpj}</td>
                <td>{data.razao_social}</td>
                <td>{data.placa}</td>
                <td>{data.recnum}</td>
                <td>{data.vencimento}</td>
                <td style={{textAlign:"right"}}>R$ {parseFloat(data.vlr_blto).toFixed(2)}</td>
                <td>{data.dt_impr}</td>
                {vlr_ult}
                <td>{data.dt_ult_impr}</td>
                <td>{data.qtd_impr}</td>
                <td>{data.ult_recolhimento}</td>
                <td>{data.dt_cadastro}</td>
                <td>{data.dt_inativacao}</td>
                <td>{data.resp_cobranca}</td>
                <td>{data.resp_cobranca_plano}</td>
                <td>{data.resp_cobranca_vigente}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        this.state.excel = 'nao'
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={18}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Inadimplentes - Com Impressão de Boletos</h2>
                <p>Consulte as Empresas Inadimplentes que imprimiram boleto no vencimento.</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe o Intervalo de Datas para Pesquisa
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="plano" id="plano" placeholder="Plano" className="form-control" mask="999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="mesano_vecto_de" id="mesano_vecto_de" placeholder="Mês/Ano Vencimento (de)" className="form-control" mask="99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <InputMask name="mesano_vecto_ate" id="mesano_vecto_ate" placeholder="Mês/Ano Vencimento (até)" className="form-control" mask="99/9999" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary"  onClick={event =>{  this.showLista();}}>pesquisar</button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-info" onClick={event =>{  this.showExcel();}} data-toggle="modal" data-target="#excelModal">Excel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel-body container-fluid">
                        <table className="table table-striped table-doc">
                            <tr>
                                <thead>
                                    <td>Totais</td>
                                    <td>Qtd Boletos</td>
                                    <td>Valor Total</td>
                                </thead>
                                <tbody>
                                    <td></td>
                                    <td style={{textAlign:"right"}}>{this.state.qtd_total.toLocaleString('de-DE')}</td>
                                    <td style={{textAlign:"right"}}>R$ {this.state.vlr_total.toLocaleString('de-DE')}</td>
                                </tbody>
                            </tr>
                        </table>
                        
                        <table className="table table-striped table-doc">
                            <thead>
                                <tr>
                                    <td>ID BSF</td>
                                    <td>ID Tela Rosa</td>
                                    <td>CNPJ</td>
                                    <td>Empresa</td>
                                    <td>Plano</td>
                                    <td>Recnum</td>
                                    <td>Data Vencimento</td>
                                    <td>Vlr Boleto (1a)</td>
                                    <td>Data Impressão (1a) </td>
                                    <td>Vlr Boleto (última)</td>
                                    <td>Data Impressão (última)</td>
                                    <td>Qtd Impressões</td>
                                    <td>Ult. Recolhimento</td>
                                    <td>Cadastramento</td>
                                    <td>Inativação</td>
                                    <td>Resp. Cobrança</td>
                                    <td>Resp. Cobrança Plano</td>
                                    <td>Resp. Cobrança Vigente</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(InadimplentesComImp))
