import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';
import  Table from '../../geral/Table';
import $ from 'jquery';

//class
import Core from '../../../class/Core';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';


class IndexEmailModelos extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.state = {
            lista: null,
            head: ['ID', "ASSUNTO", 'OBSERVAÇÕES', 'AÇÃO'],
            columns: [],
            auth: this.core.getAuth()            
        }

        this.updateData = this.updateData.bind(this);
    }


    componentWillMount(){
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();

        this.props.action.loadingStatus();

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "email/modelos", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
            }
        )
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            let data = response.content.map((data, index) => {

                if(this.state.auth.email === "luan.augusto@audaz.com.br") {
                    return {
                        id: data.id,
                        assunto: data.assunto,
                        observacoes: data.observacao,
                        acao: "<a href='#' class='btn btn-sm btn-success' data-id='" + data.id + "' id='visualizar'>Visualizar</a>"

                    }
                } else {
                    return {
                        id: data.id,
                        assunto: data.assunto,
                        observacoes: data.observacao,
                        acao: "<a href='#' class='btn btn-sm btn-success' data-id='" + data.id + "' id='visualizar'>Visualizar</a> <a href='/manager/modelos-email/edit/" + data.id + "' class='btn btn-sm btn-info'>Editar</a> <button class='btn btn-sm btn-danger btn-remove-item' data-id='" + data.id + "'>Remover</button>"
                    }        
                }

            })

            data.sort((a, b) => {
                return a.id - b.id
            })

            self.setState({
                lista: response.content,
                columns: data
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    updateData(){
        let self = this;
        this.props.action.showManager();
        this.props.action.redirectClear();

        this.props.action.loadingStatus();

        fetch(self.core.getUrl() + self.core.getVersionAPI() + "email/modelos", {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                },
            }
        )
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            let data = response.content.map((data, index) => {
                if(this.state.auth.email === "luan.augusto@audaz.com.br") {
                    return {
                        id: data.id,
                        assunto: data.assunto,
                        observacoes: data.observacao,
                        acao: "<a href='#' class='btn btn-sm btn-success' data-id='" + data.id + "' id='visualizar'>Visualizar</a>"
                    }
                } else {
                    return {
                        id: data.id,
                        assunto: data.assunto,
                        observacoes: data.observacao,
                        acao: "<a href='#' class='btn btn-sm btn-success' data-id='" + data.id + "' id='visualizar'>Visualizar</a> <a href='/manager/modelos-email/edit/" + data.id + "' class='btn btn-sm btn-info'>Editar</a> <button class='btn btn-sm btn-danger btn-remove-item' data-id='" + data.id + "'>Remover</button>"
                    }        
                }
        })

            data.sort((a, b) => {
                return b.id - a.id
            })

            self.setState({
                lista: response.content,
                columns: data
            });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });
    }

    componentDidMount(){
        let self = this;
        $('body').on('click', '.btn-remove-item',  (e) => {
            let id = $(e.target).attr('data-id');
            let result = window.confirm("Deseja remover esse modelo de email?");
            if(result){
                fetch(self.core.getUrl() + self.core.getVersionAPI() + "email/email-modelo/" + id, {
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/json',
                            'Authorization': self.core.formatAutorizationToken(self.core.getToken())
                        },
                        method: 'DELETE'
                    }
                )
                    .then(res => self.core.responseConvert(res))
                    .then((response) => {

                        self.updateData();
                    })
                    .catch((err) => {
                        self.core.catchFecth(err)
                        self.props.action.loadedStatus();
                    });
            }
        });

        $('body').on('click', '#visualizar', (e) =>{
            if(window.windowsPreview){
                window.windowsPreview.document.getElementsByTagName('body')[0].innerHTML = ''
            }

            let id = parseInt($(e.target).attr('data-id'))
            let element = this.state.lista.filter((val) => {
                return val.id === id
            })

            //console.log(element)

            if(element.length === 1){
                window.windowsPreview = window.open("","VISUALIZAR MODELO", "height:700,width=800")
                window.windowsPreview.document.write(element[0].conteudo)
            }
        })
    }

    render(){
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        //return render
        return <div className="page">
            <div className="page-content">
                <h2>Modelos de Email</h2>
                <p>Gerêncie e crie os modelos de email.</p>

                {/* exibe  */}
                <div className="panel">
                    <div className="panel-title">
                        <Link className="btn btn-dark is_link" to={'/manager/modelos-email/create'}>
                            <i className="fa fa-plus-circle" aria-hidden="true"></i> CRIAR MODELO
                        </Link>
                    </div>
                    <div className="panel-body container-fluid">
                        <Table
                            head={this.state.head}
                            columns={this.state.columns}
                        />
                    </div>
                </div>
                {/*fim*/}
            </div>
        </div>;
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}

export default withRouter(connect(mapStateProps, mapDispatchProps)(IndexEmailModelos))
