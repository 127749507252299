export default (state =[], payload) => {
    switch(payload.type){
        case 'STATUS_LOADING':
            state = true;
            return state;
       case 'STATUS_LOADED':
            state = false;
            return state;
        default:
            state = false;
            return state;
    }
}
