import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import moment from 'moment';
import Alert from 'react-s-alert';
import InputMask from 'react-input-mask';
import { isNull, isUndefined } from 'util';
import { isNullOrUndefined } from 'util';


//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';


class ExcelDiferencasPagamentos extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.handlerSubmit = this.handlerSubmit.bind(this);
        this.state = {
            auth: this.core.getAuth(),
            div_permitida1: <div></div>,          
            div_permitida2: <div></div>          
        }
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();

        let paramters = "?";

        let data_pesquisa = event.target.data_pesquisa.value;
        let data_aux = moment(data_pesquisa, 'DD/MM/YYYY', true);
        let now = moment();
        let url = event.currentTarget.getAttribute("action");
        let offset = event.target.offset.value;
        let limit = event.target.limit.value;

        if(url === null){
            Alert.error("Problemas de configuração.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 8000
            });
            return false;
        }

        if(!data_aux.isValid()){
            Alert.error("A data não foi informada ou é inválida.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 8000
            });
            return false;
        }

        if(!data_aux.isSameOrBefore(now)){
            Alert.error("A data não pode ser maior que a data atual.", {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 8000
            });
            return false;
        }

        if(paramters !== '?'){
            paramters += '&';
        }

        paramters += 'data_pesquisa=' + data_pesquisa;

        if (offset !== null) {
            paramters += '&offset=' + offset;
        }

        if (limit !== null) {
            paramters += '&limit=' + limit;
        }

        paramters +='&token='+this.core.getToken();
        
        //baixa o relatório
        if(paramters !== "?"){
            window.open( url + paramters , '_blank');
        }else{
            window.open( url , '_blank');
        }

        event.currentTarget.reset();
        return true;
    }

    render(){
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        //url dos relatórios
        let urlDiferenca = this.core.getUrl() + this.core.getVersionAPI() + "empresa/relatorios/excel-diferenca-boleto";

        //return render
        //if(this.state.auth.nome === "Paulo Vietri") {
            this.state.div_permitida1 = 
            <div className="col-sm-2">
                <div className="form-group">
                    <label>Offset</label>
                    <input type="number" className='form-control' name='offset'/>
                </div>
            </div>
            this.state.div_permitida2 = 
            <div className="col-sm-2">
                <div className="form-group">
                    <label>Limite</label>
                    <input type="number" className='form-control' name='limit'/>
                </div>
            </div>
        //} else {
        //    this.state.div_permitida1 = <div></div>
        //    this.state.div_permitida2 = <div></div>
        //}


        return <div className="page">
            <div className="page-content">
                <h3>Diferenças</h3>
                <p>Consulta as Diferenças: Valor Pago x Valor Impresso e Sincronismo Entre os Sistemas Cobranca, Tela Rosa, Personal e WEBBEN</p>
                <p>Será disponibilizado para download uma planilha em Excel</p>

                {/*relatorio de planos/trabalhadores*/}
                <div className="panel">
                    <h3 className='panel-title'>Informe a data para a geração do relatório</h3>
                    <div className="panel-body container-fluid">
                        {/* formulario envia para handlerSubmitPlanoTrabalhadores*/}
                        <form action={urlDiferenca} onSubmit={this.handlerSubmit}>
                            <div className="row">
                                <div className="col-sm-2">
                                        <div className="form-group">
                                            <label><small>Data Pesquisa</small></label>
                                            <InputMask name="data_pesquisa" id="data_pesquisa" placeholder="Data Pesquisa" className="form-control" mask="99/99/9999" />
                                        </div>
                                </div>
                                {this.state.div_permitida1}
                                {this.state.div_permitida2}
                                <div className="col-sm-1">
                                    <div className="form-group">
                                        <button className="btn btn-primary margin-btn">GERAR</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {/*fim*/}
            </div>
        </div>;
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(ExcelDiferencasPagamentos))
