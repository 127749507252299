import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Core from '../../../class/Core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

//actions
import * as UserActions from '../../../actions/UserActions';
import * as ManagerStatusActions from '../../../actions/StatusManagerActions.js';
import * as RedirectActions from '../../../actions/RedirectActions';
import * as StatusActions from '../../../actions/StatusActions';

class PlanosEntidade extends React.Component{
    constructor(props){
        super(props);
        this.core = new Core();
        this.core.isAuth();
        this.width = window.innerWidth * 0.66 ;
        this.height = this.width * window.innerHeight / window.innerWidth ;
        //define locale moment
        moment.locale('pt-BR');
        this.state = {
            relatorio: [],
        }

        this.getTableShow = this.getTableShow.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    componentWillMount(){
        this.props.action.showManager();
        this.props.action.redirectClear();
    }

    handlerSubmit(event){
        event.preventDefault();
        let self = this;

        let data = {
            acesso_net_pesquisa : event.target.acesso_net_pesquisa.value,
            sigla_entidade : event.target.sigla_entidade.value
        }
    
        this.props.action.loadingStatus();

        console.log("Fetch", event.target.acesso_net_pesquisa.value);
        fetch(self.core.getUrl() + self.core.getVersionAPI() + "empresa/relatorios/lista-entidade-planos", {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': self.core.formatAutorizationToken(self.core.getToken())
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => self.core.responseConvert(res))
        .then((response) => {
            console.log("Planos=>response.content:", response.content)
        self.setState({
            relatorio: response.content, 
            qtd_total: response.content.length
        });
            self.props.action.loadedStatus();
        })
        .catch((err) => {
            self.core.catchFecth(err)
            self.props.action.loadedStatus();
        });    
    }

    getTableShow(){
        console.log("getTableShow=>");
        let patronal = null;
        let laboral = null;
        return this.state.relatorio.map((data, index) => {
            if (data.coen_fl_patronal === 1) {
                patronal = "sim"
            } else {
                patronal = " "
            }
            if (data.coen_fl_laboral === 1) {
                laboral = "sim"
            } else {
                laboral = " "
            }
            return <tr key={index} >
                <td>{data.conv_cd_nseq}</td>
                <td>{data.conv_ds_descricao}</td>
                <td>{data.seat_ds_descricao}</td>
                <td>{data.enti_ds_senha_net}</td>
                <td>{patronal}</td>
                <td>{laboral}</td>
            </tr>
        });
    }

    render(){
        let tableContent;
        if(this.props.redirect.url){
            return <Redirect to={this.props.redirect.url} />;
        }

        if(this.state.relatorio.length){
            tableContent = this.getTableShow();
        }else{
            tableContent = <tr>
                <td colSpan={7}>
                    <center>
                        <b>Informe os parametros necessários para a pesquisa</b>
                    </center>
                </td>
            </tr>
        }

        return <div className="page">
            <div className="page-content">
                <h2>Entidades Sindicais - Planos</h2>
                <p>Consulte os Planos que Entidade participa</p>

                <div className="panel">
                    <div className='panel-title'>
                        <h3>
                            Informe a Senha Net ou a Sigla da Entidade para Pesquisa
                        </h3>
                        <div  className="seleciona-mes-doc" >
                            <form onSubmit={this.handlerSubmit}>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <input name="acesso_net_pesquisa" id="acesso_net_pesquisa" placeholder="Informe a Senha Net (Tela Rosa)" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <input name="sigla_entidade" id="sigla_entidade" placeholder="Sigla (Tela Rosa)" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="Submit" className="btn btn-primary">pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panel-body container-fluid">
                            <table className="table table-striped table-doc">
                                <thead>
                                    <tr>
                                        <h2> Planos </h2>
                                    </tr>

                                    <tr>
                                        <td>Plano</td>
                                        <td>Nome</td>
                                        <td>Segmento</td>
                                        <td>Senha Net</td>
                                        <td>Patronal</td>
                                        <td>Laboral</td>
                                     </tr>
                                </thead>
                                <tbody>
                                    {tableContent}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div> 


            </div>
        </div>
    }
}

function mapStateProps(state, props) {
    return {
        user: state.user,
        managerStatus: state.managerStatus,
        redirect: state.redirectReducer
    }
}

function mapDispatchProps(dispatch) {
    return {
        action: bindActionCreators({
            ...UserActions,
            ...ManagerStatusActions,
            ...RedirectActions,
            ...StatusActions
        }, dispatch)
    }
}
export default withRouter(connect(mapStateProps, mapDispatchProps)(PlanosEntidade))
